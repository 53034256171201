import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const settings = JSON.parse(localStorage.getItem("settings"));

const initialState = {
    appSettings: settings ? settings : null,
    appLoading: false,
};

export const setAppSettings = createAsyncThunk("app/appSettings", (settings) => {
    localStorage.setItem('settings', JSON.stringify(settings));
    return settings;
}
);

export const setAppLoading = createAsyncThunk('invoice/setAppLoading', async (val) => {
    return val;
})

export const appSettingsSlice = createSlice({
    name: "appSettings",
    initialState,
    reducers: {
        reset: (state) => {
            state.appSettings = null;
            state.appLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setAppSettings.fulfilled, (state, action) => {
                state.appSettings = action.payload;
            })
            .addCase(setAppLoading.fulfilled, (state, action) => {
                state.appLoading = action.payload
            })
    },
});

export const { reset } = appSettingsSlice.actions;
export default appSettingsSlice.reducer;