import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { useGetClients } from "../../components/TimeTracker/TimeTrackerOverview/useTimeTracker";
import ReportServicesFilter from "../../components/Reporting/ReportingServices/ReportingServicesFilter";
import ReportServicesTotalHours from "../../components/Reporting/ReportingServices/ReportingServicesTotalHours";
import ReportServicesTotalCost from "../../components/Reporting/ReportingServices/ReportServicesTotalCost";
import ReportServicesTotalInvoiced from "../../components/Reporting/ReportingServices/ReportServicesTotalInvoiced";
import ReportServicesTotalRoi from "../../components/Reporting/ReportingServices/ReportServicesTotalRoi";
import ReportServicesHoursPerServiceFilter from "../../components/Reporting/ReportingServices/ReportPerServiceTotalHoursGraphFilter";
import ReportServicesHoursPerServiceGraph from "../../components/Reporting/ReportingServices/ReportPerServiceTotalHoursGraph";
import ReportCostInvoicedGraph from "../../components/Reporting/ReportingServices/ReportServiceCostGraph";
import ReportCostInvoicedGraphFilter from "../../components/Reporting/ReportingServices/ReportServiceCostGraphFilter";
import { useFetchTrackReportDetails, useFetchClientInvoices, useFetchExpenses, useFetchProjectCosts } from "../../features/reporting/reportingOverview/useReportingOverview";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { useTagDetails } from "../../components/TimeTracker/Timer/tracker/TimerDataApi";
import TotalHoursPerServiceGraphFilter from "../../components/Reporting/ReportingServices/GraphFilters/ReportTotalHoursPerService/ReportTotalHoursPerServiceFilter";
import CostPerServiceGraphFilter from "../../components/Reporting/ReportingServices/GraphFilters/ReportCostPerService/ReportCostPerServiceFilter";
import { setAppLoading } from "../../features/appSettings/appSettingsSlice";

function ReportingServices() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [isGraphLoaded, setIsGraphLoaded] = useState(false);
    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const filteredCurrencies = useSelector((state) => state.reportingServices.servicesCurrency);

    const { data: tag = [], isLoading: tagLoading, isError: tagError } = useTagDetails(token);
    const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
    const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);
    const { data: projectCost = [], isLoading: projectCostLoading, isError: projectCostError } = useFetchProjectCosts(token);
    const { data: clientInvoices = [], isLoading: clientInvoicesLoading, isError: clientInvoicesError } = useFetchClientInvoices(token);
    const { data: expenses = [], isLoading: expensesLoading, isError: expensesError } = useFetchExpenses(token);

    useEffect(() => {
        const handleLoading = async () => {
            await dispatch(setAppLoading(tagLoading));
            await dispatch(setAppLoading(clientLoading));
            await dispatch(setAppLoading(trackReportLoading));
            await dispatch(setAppLoading(projectCostLoading));
            await dispatch(setAppLoading(clientInvoicesLoading));
            await dispatch(setAppLoading(expensesLoading));
        };
        handleLoading();
    }, [tagLoading, clientLoading, trackReportLoading, projectCostLoading, clientInvoicesLoading, expensesLoading]);


    useEffect(() => {
        dispatch(setHeaderText("Services"));
        const timer = setTimeout(() => {
            setIsGraphLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="react-outer">
            <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
                <ReportServicesFilter tag={tag} clients={clients} trackReport={trackReport} projectCost={projectCost} />
            </div>
            <div className="repot-tatal-box-sec">
                <ReportServicesTotalHours trackReport={trackReport} filteredCurrencies={filteredCurrencies} />
                <ReportServicesTotalCost trackReport={trackReport} projectCost={projectCost} filteredCurrencies={filteredCurrencies} />
                <ReportServicesTotalInvoiced clientInvoices={clientInvoices} filteredCurrencies={filteredCurrencies} />
                <ReportServicesTotalRoi trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} filteredCurrencies={filteredCurrencies} />
            </div>
            <div className="outer-report-graph-piechart">
                <div className="graph-main-outer-time graph-main-outer-time-report">
                    <div className="top-graph">
                        <TotalHoursPerServiceGraphFilter
                            trackReport={trackReport}
                            trackReportLoading={trackReportLoading}
                            trackReportError={trackReportError}
                            filteredCurrencies={filteredCurrencies}
                        />
                    </div>
                    {isGraphLoaded ? (
                        <ReportServicesHoursPerServiceGraph
                            trackReport={trackReport}
                            trackReportLoading={trackReportLoading}
                            trackReportError={trackReportError}
                            filteredCurrencies={filteredCurrencies}
                        />
                    ) : (
                        <div className="outter-load-table">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    )}
                </div>
                <div className="pie-chart-right-no"></div>
            </div>
            <div className="outer-report-graph-piechart">
                <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
                    <div className="top-graph">
                        <CostPerServiceGraphFilter
                            trackReport={trackReport}
                            trackReportLoading={trackReportLoading}
                            trackReportError={trackReportError}
                            projectCost={projectCost}
                            filteredCurrencies={filteredCurrencies}
                        />
                    </div>
                    <ReportCostInvoicedGraph
                        trackReport={trackReport}
                        trackReportLoading={trackReportLoading}
                        trackReportError={trackReportError}
                        projectCost={projectCost}
                        filteredCurrencies={filteredCurrencies}
                    />
                </div>
                <div className="pie-chart-right-no"></div>
            </div>
        </div>
    );
}
export default ReportingServices; 