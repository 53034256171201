import React, { useEffect } from "react";
import "./ReportPersonal.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../ReportingPersonal/ReportingTimeOffRequest/ReportingTimeOffRequest.css";
import ReportReimbursmentFilter from "./ReportingReimbursement/ReportReimbursementFilter";
import TotalReimburesentCard from "./ReportingReimbursement/TotalReimburesentCard";
import TotalReimburesentOutstanding from "./ReportingReimbursement/TotalReimburesentOutstandingCard";
import TotalReimburesentOverdue from "./ReportingReimbursement/TotalReimburesentOverdue";
import ReimburesmentTable from "./ReportingReimbursement/ReportReimburesmentsTable";
import { useFetchReimbursementInvoiceReport } from "../../../features/reporting/reportingPersonnel/useReportingReimbursement";
import { useUserById } from "../../TimeOff/timeOffApi";
import { setInvoiceType } from "../../../features/invoice/invoiceSlice";
import { setAppLoading } from "../../../features/appSettings/appSettingsSlice";

function ReportingReimbursement() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: users = [], isLoading: userLoading, isError: usersError, refetch: userRefetch } = useUserById(token);
  const { data: reimbursementInv = [], isLoading: reimbursementInvLoading, isError: reimbursementInvError, } = useFetchReimbursementInvoiceReport(token);

  useEffect(() => {
    const handleLoading = async () => {
      await dispatch(setAppLoading(reimbursementInvLoading));
      await dispatch(setAppLoading(userLoading));
    };
    handleLoading();
  }, [reimbursementInvLoading, userLoading, dispatch]);

  const handleClick = () => {
    // e.preventDefault(); // Prevent the default link behavior
    dispatch(setInvoiceType("Reimbursement"));
  };
  return (
    <div className="reporting-timeoff-request-outer-page client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportReimbursmentFilter
          reimbursementInv={reimbursementInv}
          users={users}
        />
      </div>

      <div className="repot-tatal-box-sec">
        <div className="total-request-outer">
          <TotalReimburesentCard reimbursementInv={reimbursementInv} />
        </div>
        <div className="total-request-outer">
          <TotalReimburesentOutstanding reimbursementInv={reimbursementInv} />
        </div>
        <div className="total-request-outer">
          <TotalReimburesentOverdue reimbursementInv={reimbursementInv} />
        </div>
        <div className="total-request-outer"></div>
      </div>

      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Reimbursements</div>
          <div className="top-graph">
            <ReimburesmentTable
              reimbursementInv={reimbursementInv}
              reimbursementInvLoading={reimbursementInvLoading}
              reimbursementInvError={reimbursementInvError}
            />
          </div>
        </div>
      </div>
      <div className="button-sec-invoice">
        <Link to="/user/invoices" onClick={handleClick}>View All</Link>
      </div>
    </div>
  );
}

export default ReportingReimbursement;
