import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import DateConverter from '../../../helpers/dateFormatChange';

const VendorPaymentStatus = ({ invData, payStatus }) => {
  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch('/api/vendor/invoices/paymentDetails', {
          method: 'POST',
          body: JSON.stringify({ _id: invData._id }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          if (data) {
            // setPaymentData(data);
            const createdDate = data && data.created_at;
            setPaymentDate(createdDate);
          }
        } else {
          if (invData) {
            if (invData.ven_inv_paid_date) {
              setPaymentDate(invData.ven_inv_paid_date);
            }
          }
          console.error('Failed to fetch payment details');
        }
      } catch (error) {
        // Non-blocking logging
        Promise.resolve().then(() => {
          if (invData) {
            if (invData.ven_inv_paid_date) {
              setPaymentDate(invData.ven_inv_paid_date);
            }
          }
          global.config.slackMessage(error.toString());
          console.error('Error fetching payment details:', error);
        });
      }
    };
  
    if ((invData.ven_inv_number && (payStatus === 3 || payStatus === 2))) {
      fetchPaymentDetails();
    }
  
  }, [invData.inv_number, payStatus]);

  // Determine the class and name based on the payment_status value
  let statusClass;
  let statusName;
  if (!paymentDate) {
    statusClass = 'pending';
    statusName = 'Not updated';
  } else {
    statusClass = 'manual';
    statusName = <DateConverter date={paymentDate} />;
  }
  return <span className={`payment-status ${statusClass}`}>{statusName}</span>;
};

export default VendorPaymentStatus;