import React, { useState, useRef } from "react";
import "./ProjectAlert.css";

const ProjectAlerts = () => {
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(70);
  const [budgetThreshold, setBudgetThreshold] = useState(70);

  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);

  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);

  const dummyTeams = [
    { _id: "1", first_name: "John", last_name: "Doe", role_id: 1 },
    { _id: "2", first_name: "Jane", last_name: "Smith", role_id: 2 },
    { _id: "3", first_name: "Alice", last_name: "Brown", role_id: 3 },
    { _id: "4", first_name: "Bob", last_name: "Williams", role_id: 4 },
    { _id: "5", first_name: "Charlie", last_name: "Davis", role_id: 5 },
  ];

  const toggleDropdown = (type) => {
    if (type === "time") {
      setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
    } else {
      setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
    }
  };

  const handleSelectAllTeams = (type) => {
    if (type === "time") {
      if (selectTimeThresholdAllTeam) {
        setSelectedTimeThresholdTeamsEdit([]);
      } else {
        setSelectedTimeThresholdTeamsEdit(dummyTeams.map((team) => team._id));
      }
      setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
    } else {
      if (selectBudgetThresholdAllTeam) {
        setSelectedBudgetThresholdTeamsEdit([]);
      } else {
        setSelectedBudgetThresholdTeamsEdit(dummyTeams.map((team) => team._id));
      }
      setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
    }
  };

  const handleCheckboxChange = (teamId, type) => {
    if (type === "time") {
      setSelectedTimeThresholdTeamsEdit((prev) =>
        prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]
      );
    } else {
      setSelectedBudgetThresholdTeamsEdit((prev) =>
        prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]
      );
    }
  };

  return (
    <div className="project-alert-outer">
      {/* Time Alert */}
      <div className="alert-section for-time-outer">
        <label className="alert-label">
          <input type="checkbox" checked={timeChecked} onChange={() => setTimeChecked(!timeChecked)} />
          <span>For time</span>
        </label>

        <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
          <div className="input-group">
            <span>If project reaches</span>
            <input type="number" value={timeThreshold} onChange={(e) => setTimeThreshold(e.target.value)} />
            <span>% of estimate</span>
          </div>

          <div className="dropdown-group">
            <span>Alert</span>
            <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
              <div className="drop-down-inner" onClick={() => toggleDropdown("time")}>
                <div className="selected-options1">
                  {selectedTimeThresholdTeamsEdit.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedTimeThresholdTeamsEdit.length === dummyTeams.length ? (
                          "All"
                        ) : (
                          <>
                            {dummyTeams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])?.first_name || "User"}
                            {selectedTimeThresholdTeamsEdit.length > 1 && (
                              <div className="selected-option-count">
                                {selectedTimeThresholdTeamsEdit.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isTimeThresholdTeamDropdownOpen && (
                <div className="dropdown-list1">
                  <label className="checkbox-label1">
                    <input type="checkbox" checked={selectTimeThresholdAllTeam} onChange={() => handleSelectAllTeams("time")} />
                    All Teams
                  </label>
                  {dummyTeams.map((team) => (
                    <label className="checkbox-label1" key={team._id}>
                      <input
                        type="checkbox"
                        checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                        onChange={() => handleCheckboxChange(team._id, "time")}
                      />
                      {team.first_name} {team.last_name}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Budget Alert */}
      <div className="alert-section for-budget-outer">
        <label className="alert-label">
          <input type="checkbox" checked={budgetChecked} onChange={() => setBudgetChecked(!budgetChecked)} />
          <span>For budget</span>
        </label>

        <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
          <div className="input-group">
            <span>If project reaches</span>
            <input type="number" value={budgetThreshold} onChange={(e) => setBudgetThreshold(e.target.value)} />
            <span>% of estimate</span>
          </div>

          <div className="dropdown-group">
            <span>Alert</span>
            <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
              <div className="drop-down-inner" onClick={() => toggleDropdown("budget")}>
                <div className="selected-options1">
                  {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedBudgetThresholdTeamsEdit.length === dummyTeams.length ? (
                          "All"
                        ) : (
                          <>
                            {dummyTeams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])?.first_name || "User"}
                            {selectedBudgetThresholdTeamsEdit.length > 1 && (
                              <div className="selected-option-count">
                                {selectedBudgetThresholdTeamsEdit.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isBudgetThresholdTeamDropdownOpen && (
                <div className="dropdown-list1">
                  <label className="checkbox-label1">
                    <input type="checkbox" checked={selectBudgetThresholdAllTeam} onChange={() => handleSelectAllTeams("budget")} />
                    All Teams
                  </label>
                  {dummyTeams.map((team) => (
                    <label className="checkbox-label1" key={team._id}>
                      <input
                        type="checkbox"
                        checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                        onChange={() => handleCheckboxChange(team._id, "budget")}
                      />
                      {team.first_name} {team.last_name}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAlerts;
