import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch } from 'react-redux';
import PlusIcon from "../../../images/icons/plus-icon.svg";
import { setHeaderText } from '../../../features/headerText/headerTextSlice';
import {useFetchSubscriptions} from "./services.js";


const PlanComponent = () => {
  const dispatch = useDispatch();
   var token = useSelector(
      (state) => state.masterAuth.masterUser.token
    );

   const { data: plans = [], isLoading, refetch: refetchProjects } = useFetchSubscriptions(token);

  const [newPlan, setNewPlan] = useState({
    name: '',
    monthlyPricing: '',
    yearlyPricing: '',
    features: []
  });

  const [dropdownOpen, setDropdownOpen] = useState({});

  useEffect(() => {
    dispatch(setHeaderText("Subscriptions"));
  }, []);


  const handleEditPlan = (index) => {
    // TO DO: implement edit plan functionality
  };

  const handleDisablePlan = (index) => {
    // TO DO: implement disable plan functionality
  };

  const handleDeletePlan = (index) => {
    //setPlans(plans.filter((plan, i) => i !== index));
  };

  const handleToggleDropdown = (index) => {
    setDropdownOpen((prevDropdownOpen) => ({
      ...prevDropdownOpen,
      [index]: !prevDropdownOpen[index]
    }));
  };

  return (
    <div className='master-subscription'>

      <div className="button-sec-invoice">
        <Link to="/master-user/subscriptions/add">
          <span className="icon-invoice">
            <img src={PlusIcon} alt="Add new Expense " />
          </span>
          Add Plan
        </Link>
      </div>
      <div className="master-subscription-outer">
        {plans.map((plan, index) => (
          <div key={index} className="master-subscription-inner">
            <div className='name-value'>
              <div className='name-value-left'>
                Name of plan
              </div>
              <div className='name-value-right'>
                {plan.name}
              </div>
            </div>
            <div className='name-value'>
              <div className='name-value-left'>
               Description
              </div>
              <div className='name-value-right'>
                {plan.description}
              </div>
            </div>

            <div className='name-value'>
              <div className='name-value-left'>
                Monthly pricing
              </div>
              <div className='name-value-right'>
                ${plan.monthlyPrice}
              </div>
            </div>
            <div className='name-value'>
              <div className='name-value-left'>
                Yearly pricing
              </div>
              <div className='name-value-right'>
                ${plan.yearlyprice}
              </div>
            </div>
            <div className='plan-features-master plan-features-master-last'>
              <div className='name-value'>
                <div className='name-value-left'>
                  Plan features:
                </div>
              </div>
              <ul>
                {plan.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
            <div className='mater-btn-box-sectiokn'>
              <button onClick={() => handleToggleDropdown(index)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                  <circle cx="2" cy="2" r="2" fill="#96A8BA" />
                  <circle cx="2" cy="10" r="2" fill="#96A8BA" />
                  <circle cx="2" cy="18" r="2" fill="#96A8BA" />
                </svg>
              </button>
              {dropdownOpen[index] && (
                <ul>
                  <li onClick={() => handleEditPlan(index)}>
                    <div className='icon-dot-inner'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path d="M18.1691 5.12872L14.1539 1.08987C14.0964 1.03193 14.0201 1.00011 13.9386 1.00011C13.857 1.00011 13.7812 1.03194 13.7236 1.08988L11.3448 3.48298L11.342 3.49154L11.2946 3.53357L2.10681 12.7767C2.06422 12.8195 2.03542 12.8734 2.02366 12.9321L1.00657 17.9953C0.986294 18.096 1.01753 18.1993 1.08973 18.2723C1.14732 18.3298 1.22358 18.3617 1.3051 18.3617C1.32538 18.3617 1.34526 18.3596 1.36391 18.3559L6.39776 17.3321C6.45698 17.3202 6.51011 17.2913 6.55229 17.2488L18.1691 5.56163C18.2267 5.5041 18.2579 5.42698 18.2579 5.34497C18.2579 5.26296 18.2263 5.18625 18.1691 5.12872ZM6.18643 16.7507L1.69324 17.6648L2.60124 13.1443L3.92751 11.81L7.5127 15.4168L6.18643 16.7507ZM7.94262 14.9839L4.35743 11.3775L11.5156 4.17619L15.1004 7.78256L7.94262 14.9839ZM15.5311 7.34964L11.9464 3.74327L13.9386 1.73903L17.5238 5.3454L15.5311 7.34964Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.6" />
                      </svg>
                    </div>
                    Edit Plan
                  </li>
                  <li onClick={() => handleDisablePlan(index)}>
                    <div className='icon-dot-inner'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                        <path d="M22.8492 7.60126C18.5984 2.70897 12.6489 2.46191 11.4989 2.46191C9.97674 2.46191 8.59669 2.69882 7.35487 3.06396L4.41519 0.179997C4.17056 -0.0599989 3.7733 -0.0599989 3.52865 0.179997C3.28402 0.419992 3.28402 0.809721 3.52865 1.04973L6.03876 3.51225C2.70806 4.83736 0.657994 7.0148 0.149759 7.60031C-0.0499196 7.83005 -0.0499196 8.16852 0.149759 8.39928C4.40058 13.2905 10.3501 13.5386 11.5001 13.5386C13.0222 13.5386 14.4034 13.3017 15.6452 12.9366L18.5838 15.8195C18.7061 15.9395 18.8671 16 19.027 16C19.188 16 19.348 15.9395 19.4703 15.8195C19.7149 15.5795 19.7149 15.1898 19.4703 14.9498L16.9612 12.4883C20.293 11.1631 22.342 8.98571 22.8502 8.4002C23.0499 8.17046 23.0499 7.83199 22.8502 7.60225L22.8492 7.60126ZM11.4989 12.3079C10.4786 12.3079 5.32969 12.0946 1.46882 8.00428C2.30204 7.14687 4.20269 5.45459 7.00748 4.46393L8.29025 5.72238C7.81459 6.36441 7.52602 7.148 7.52602 8.00029C7.52602 10.149 9.30851 11.8977 11.4988 11.8977C12.3675 11.8977 13.1663 11.6147 13.8207 11.148L14.6226 11.9347C13.6629 12.1634 12.6237 12.307 11.4987 12.307L11.4989 12.3079ZM13.8062 9.39297L10.0791 5.73655C10.4931 5.48528 10.9772 5.33347 11.4988 5.33347C12.998 5.33347 14.2169 6.52935 14.2169 8.00003C14.2169 8.51181 14.0623 8.98681 13.8062 9.39297ZM12.9197 10.2627C12.5057 10.514 12.0216 10.6658 11.4999 10.6658C10.0008 10.6658 8.78186 9.4699 8.78186 7.99922C8.78186 7.48744 8.93659 7.01257 9.19273 6.60642L12.9197 10.2627ZM15.9921 11.5365L14.7093 10.2781C15.185 9.63604 15.4735 8.85246 15.4735 8.00016C15.4735 5.85148 13.6911 4.10272 11.5008 4.10272C10.632 4.10272 9.83331 4.38579 9.17887 4.85246L8.37702 4.06581C9.33672 3.83709 10.3759 3.6935 11.5008 3.6935C12.5212 3.6935 17.6711 3.90683 21.5317 7.99712C20.6985 8.85556 18.7978 10.5478 15.9931 11.5375L15.9921 11.5365Z" fill="#6A7988" />
                      </svg>
                    </div>
                    Disable Plan
                  </li>
                  <li onClick={() => handleDeletePlan(index)}>
                    <div className='icon-dot-inner'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                        <path d="M16.9577 19.6445L17.4363 4.37763H19.5772V3.85209H14.0109V2.45942C14.0109 1.69739 13.4316 1.09302 12.7011 1.09302H7.966C7.23557 1.09302 6.65628 1.69739 6.65628 2.45942V3.85209H1.08997V4.37763H3.23085L3.70941 19.6445C3.73459 20.354 4.2887 20.9058 4.96875 20.9058H15.6732C16.3784 20.9058 16.9325 20.354 16.9577 19.6445ZM7.16001 2.45942C7.16001 1.98643 7.51263 1.61856 7.966 1.61856H12.7011C13.1545 1.61856 13.5071 1.98643 13.5071 2.45942V3.85209H7.16001V2.45942ZM4.21314 19.6182L3.73459 4.37763H16.9325L16.454 19.6182C16.4288 20.0387 16.1014 20.3803 15.6984 20.3803H4.96875C4.56576 20.3803 4.23833 20.0387 4.21314 19.6182Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M10.0811 8.37158H10.5848V16.2284H10.0811V8.37158Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M10.6747 16.3223H9.99109V8.27783H10.6747V16.3223ZM10.171 16.1346H10.4948V8.46552H10.171V16.1346Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M7.05884 8.37158H7.56258V16.2284H7.05884V8.37158Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M7.65252 16.3223H6.96887V8.27783H7.65252V16.3223ZM7.14878 16.1346H7.47261V8.46552H7.14878V16.1346Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M13.1046 8.37158H13.6084V16.2284H13.1046V8.37158Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                        <path d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.5" />
                      </svg>
                    </div>
                    Delete Plan
                  </li>
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanComponent;