import axios from "axios";
// import "./CheckEmail.css";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import { validate } from "react-email-validator";
import CryptoJS from "crypto-js";
import Logo from "../../../images/logo/to-logo.svg";
import { ThreeDots } from "react-loader-spinner";
import LoginImage from "../../../images/login/lg-img.png";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import Login from "../../../pages/auth/Login.jsx";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useNavigate, useParams } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { login, loginTemp } from '../../../features/auth/authSlice.js';
import { setForex } from "../../../features/forex/forexSlice.js";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import { setDashboardCurrencyRx } from "../../../features/invoice/invoiceSlice.js";


const GoogleLoginTeamsVerify = () => {


        const { token } = useParams();
        const dispatch = useDispatch();
        // const decryptedBytes = AES.decrypt(encodedEmail, "encryptionKey");
        console.log('token', token);
        const email = "";

        const SUPER_ADMIN = 1;
        const ADMIN = 2;
        const USER = 3;
        // BUSINESS: 4,
        const COLLABERATOR = 5;
        const PROJECT_MANAGER = 6;
        const TEAM_MANAGER = 7;



        const fetchForex = async () => {

                var settingsData = await axios.post(`api/forex/get`, { base_currency: "USD" }, {
                        headers: {

                        }
                });
                await dispatch(setForex(settingsData.data.json));
                return settingsData.data.json;

        }



        useEffect(() => {

                const getGoogleProfile = async () => {

                        const getProfile = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
                                headers: {
                                        Authorization: `Bearer ${token.trim()}`,
                                }
                        });
                        const googleUser = getProfile.data;
                        if (googleUser) {
                                try {
                                        const res = await fetch(`/api/user/google/login`, {
                                                method: "POST",
                                                headers: {
                                                        "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify({ email: googleUser.email, password: googleUser.email }),
                                        });
                                        const data = await res.json();

                                        if (data.token) {
                                                await dispatch(loginTemp(data));
                                                fetchForex();
                                                if (data.userType === 'x' || data.userType === 'Y') {
                                                        var settingsData = await axios.get(`/api/get/app-settings/${data.appId}`, {
                                                                headers: {
                                                                        Authorization: `Bearer ${data.token}`,
                                                                }
                                                        });
                                                        var favIconData = await axios.get(`/api/view/owner/by/appid/${data.appId}`, {
                                                                headers: {
                                                                        Authorization: `Bearer ${data.token}`,
                                                                },
                                                        });

                                                        let favIcon_fileName;
                                                        let app_owner_name;
                                                        let app_owner_id;
                                                        if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                favIcon_fileName = favIconData.data.fav_icon;
                                                                app_owner_name = favIconData.data.commercial_name;
                                                                app_owner_id = favIconData.data._id;
                                                        } else {
                                                                favIcon_fileName = "fav_icon_default.jpeg";
                                                                app_owner_name = "TalenTon";
                                                                app_owner_id = favIconData.data._id;
                                                        }

                                                        // await dispatch(setAppSettings(settingsData.data));
                                                        await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));

                                                } else {

                                                        var userSettingsData = await axios.get(`/api/get/user-settings/${data._id}`, {
                                                                headers: {
                                                                        Authorization: `Bearer ${data.token}`,
                                                                }
                                                        });

                                                        // await dispatch(setAppSettings(settingsData2.data));
                                                        var favIconData = await axios.get(`/api/view/owner/by/appid/${data.appId}`, {
                                                                headers: {
                                                                        Authorization: `Bearer ${data.token}`,
                                                                },
                                                        });
                                                        let favIcon_fileName;
                                                        let app_owner_name;
                                                        let app_owner_id;
                                                        if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                favIcon_fileName = favIconData.data.fav_icon;
                                                                app_owner_name = favIconData.data.commercial_name;
                                                                app_owner_id = favIconData.data._id;
                                                        } else {
                                                                favIcon_fileName = "fav_icon_default.jpeg";
                                                                app_owner_name = "TalenTon";
                                                                app_owner_id = favIconData.data._id;
                                                        }

                                                        var settingsData = await axios.get(`/api/get/app-settings/${data.appId}`, {
                                                                headers: {
                                                                        Authorization: `Bearer ${data.token}`,
                                                                }
                                                        });
                                                        // settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                        if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {
                                                                // settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                                                                // settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                                                                settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                        }
                                                        if (settingsData.data !== null) {
                                                                settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                                                                settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                                                        }
                                                        // await dispatch(setAppSettings(settingsData.data));
                                                        await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                                                }

                                                const response = await axios.get(`/api/dashboard/invoice-currency`, {
                                                        headers: {
                                                                Authorization: `Bearer ${data.token}`,
                                                        }
                                                });

                                                if (response.data.length > 0) {
                                                        localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
                                                        dispatch(setDashboardCurrencyRx(response.data[0]));
                                                }
                                                if (settingsData && settingsData.data?.twoFa === true) {
                                                        var twoFactorEnabled = true;
                                                        var twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();
                                                } else {
                                                        var twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
                                                        var twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
                                                }

                                                if (twoFactorEnabled && twoFactorEnabledAt) {
                                                        if (twoFactorEnabled && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
                                                                // 2FA is enabled and it's within the 30-day timeframe
                                                                dispatch(login(data));

                                                                window.location.href = `/user/dashboard`;
                                                                // navigate('/user/dashboard');
                                                        } else {
                                                                // Redirect user to enable 2FA
                                                                // navigate("/2fa");
                                                                window.location.href = `/2fa`;
                                                        }
                                                } else {
                                                        dispatch(login(data));
                                                        // navigate('/user/dashboard');
                                                        window.location.href = `/user/dashboard`;
                                                }
                                        } else {
                                                // setErrorMessage(data);
                                                navigate("/");
                                                toast.error(data.message, {
                                                        position: "bottom-right",
                                                        autoClose: 1500,
                                                        hideProgressBar: true,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                });
                                        }
                                        // setLoading(false);
                                } catch (err) {
                                        console.error(err.message);
                                        global.config.slackMessage(err.toString());
                                        toast.error(err.message, {
                                                position: "bottom-right",
                                                autoClose: 1500,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                        });
                                        // setLoading(false);
                                }


                        }
                };

                if (token) {

                        getGoogleProfile();

                }


        }, [token]);




        const navigate = useNavigate();

        const [loading, setLoading] = useState(false);
        const domainName = window.location.hostname;



        const getGooglePrifileFromToken = async (googleToken) => {

                try {

                        var googleProfile = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
                                headers: {
                                        Authorization: `Bearer ${googleToken}`,
                                }
                        });

                        return googleProfile.data;

                } catch (error) {

                        console.log(error);
                }


        }


        const [isLoading, setIsLoading] = React.useState(false);
        const handleSuccess = async (credentialResponse) => {
                setIsLoading(true);
                try {
                        console.log('Login Success:', credentialResponse);
                        localStorage.setItem('googleToken', credentialResponse);
                        const googleProfile = await getGooglePrifileFromToken(credentialResponse.access_token);

                        console.log('googleProfile', googleProfile);
                        // Here you would typically:
                        // 1. Send the credential to your backend
                        // 2. Get a session token back
                        // 3. Store the token
                        // 4. Redirect or update UI


                        // login part



                        localStorage.setItem('googleToken', credentialResponse.access_token);






                        setIsLoading(true);



                        // login part end




                } catch (error) {
                        console.error('Error processing login:', error);
                } finally {
                        setIsLoading(false);
                }
        };

        const handleError = () => {
                console.error('Login Failed');
        };



        return (
                <>
                        <div className="login-outer-forget auth-divide">
                                <div className="login-inner">
                                        <div className="top-logo-mobile">
                                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                        </div>
                                        <div className="lg-lft">
                                                <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                                                <img
                                                        src={LoginImageMobile}
                                                        alt="mobile"
                                                        className="mobile-lg-img"
                                                />
                                        </div>
                                        <div className="lg-rgt">
                                                <div className="top-logo">
                                                        <a href="/"><img src={Logo} alt="Talent On" /></a>
                                                </div>
                                                <div className="login-form-sec">
                                                        <ThreeDots
                                                                height="38"
                                                                width="100"
                                                                radius="9"
                                                                color="blue"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                        />

                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default GoogleLoginTeamsVerify;