import axios from "axios";
import Modal from "react-modal";
import "../../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import Close from "../../../../images/icons/black-close.svg";
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from 'rsuite';
import Tick from "../../../../images/singleinvoice/tick-green.svg";
// import { logout, reset } from "../../../features/auth/authSlice";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
// import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
// import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
// import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import { useFetchClientProjectManager } from "../../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import { useFetchClientDetails } from "../../../../components/TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import ProjectAlerts from "../../../../components/Clients/ClientProject/ProjectAlert.jsx";
import { useGetTeams } from "../../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import userType from "../../../../constants/userType.js";


const NewProjectsModal = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [clientId, setClientId] = useState("");
  const [discription, setDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [budget, setBudget] = useState("");
  const [timeLoading, setTimeLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [budgetThreshold, setBudgetThreshold] = useState(0);

  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);

  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  // const [totalHours, setTotalHours] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");

  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const [clientIdIsValid, setClientIdIsValid] = useState(false);
  const clientIdRef = useRef(null);
  const clientDropdownRef = useRef(null);

  const [shouldFetchProject, setShouldFetchProject] = useState(false);

  const token = useSelector(
    (state) => state.auth.token
  )

  const { data: userId = [], isLoading: isUserLoading, refetch: refetchProjectManager } = useFetchClientProjectManager(token, clientId);
  const { data: clientName = [], isLoading: clientLoading, refetch: refetchClients } = useFetchClientDetails(token);
  // const { data: teams = [], isLoading: teamLoading, isError: teamError, } = useGetTeams(token);

  const { data: allTeams = [], isLoading: teamLoading, isError: teamError } = useGetTeams(token);
  // Filter teams where `is_active: true` and have at least one active user
  const teams = allTeams.filter(team => team.is_active === true);

  const handleOpenProjectModal = () => {
    setIsProjectModalOpen(true);
    setTimeThreshold(0);
    setSelectedTimeThresholdTeamsEdit([]);
    setBudgetThreshold(0);
    setSelectedBudgetThresholdTeamsEdit([]);
  };
  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectName("");
    setClientId("");
    setDiscription("");
    setBillable(true);
    // setBudget("");
    setSelectedProjectManagers([]);
    // setTotalHours("");
    setSprint("");
    setSprintDiscription("");
    setProjectNameIsValid(false);
    setClientIdIsValid(false);
    setTimeThreshold(0);
    setSelectedTimeThresholdTeamsEdit([]);
    setBudgetThreshold(0);
    setSelectedBudgetThresholdTeamsEdit([]);
    setTimeChecked(false);
    setBudgetChecked(false);
    setIsSubmitLoading(false);
  };

  useEffect(() => {

    //refetchProjectManager();

    const radioLabels = document.querySelectorAll(".radio-option label");
    radioLabels.forEach((label) => {
      label.addEventListener("click", (e) => {
        const radioInput = label.previousElementSibling;
        radioInput.checked = true;
      });
    });
  }, [userId, clientId]);

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}` : (item.company_first_name ? `${item.company_first_name + (item.company_last_name && item.company_last_name)}` : `${item.company_name}(Company)`),
      value: item._id,
    }));

  const handleToggleClientDropdown = () => {
    setClientSearchTerm('');
    setClientDropdownOpen(!clientDropdownOpen);
  };

  const handleClientSearchInputChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const filteredClients = clientName
    .filter((client) =>
      client.client_name.toLowerCase().includes(clientSearchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
    );

  const dummyTeams = [
    { _id: "1", first_name: "John", last_name: "Doe", role_id: 1 },
    { _id: "2", first_name: "Jane", last_name: "Smith", role_id: 2 },
    { _id: "3", first_name: "Alice", last_name: "Brown", role_id: 3 },
    { _id: "4", first_name: "Bob", last_name: "Williams", role_id: 4 },
    { _id: "5", first_name: "Charlie", last_name: "Davis", role_id: 5 },
  ];

  const toggleDropdown = (type) => {
    if (type === "time") {
      setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
    } else {
      setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
    }
  };

  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };

  const handleTimeCheckboxChange = async () => {
    const newTimeChecked = !timeChecked;
    setTimeChecked(newTimeChecked);

    if (newTimeChecked) {
      if (timeThreshold === 0 && selectedTimeThresholdTeamsEdit.length === 0) {
        setTimeLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "time" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setTimeThreshold(data?.thresholdPercentage || 0);
          setSelectedTimeThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setTimeLoading(false); // Stop loading
        }
      }
    } else {
      setTimeThreshold(timeThreshold);
      setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
    }
  };

  const handleBudgetCheckboxChange = async () => {
    const newBudgetChecked = !budgetChecked;
    setBudgetChecked(newBudgetChecked);

    if (newBudgetChecked) {
      if (budgetThreshold === 0 && selectedBudgetThresholdTeamsEdit.length === 0) {
        setBudgetLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "budget" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setBudgetThreshold(data?.thresholdPercentage || 0);
          setSelectedBudgetThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setBudgetLoading(false); // Stop loading
        }
      }
    } else {
      setBudgetThreshold(budgetThreshold);
      setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
    }
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };

  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectTimeThresholdAllTeam(selectedTimeThresholdTeamsEdit.length === teams.length);
      // if (selectedTimeThresholdTeamsEdit.length === teams.length) {
      //     setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedTimeThresholdTeamsEdit, teams]);

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
      // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
      //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedBudgetThresholdTeamsEdit, teams]);

  const handleSelectAllTeams = (type) => {
    if (type === "time") {
      if (selectTimeThresholdAllTeam) {
        setSelectedTimeThresholdTeamsEdit([]);
      } else {
        setSelectedTimeThresholdTeamsEdit(dummyTeams.map((team) => team._id));
      }
      setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
    } else {
      if (selectBudgetThresholdAllTeam) {
        setSelectedBudgetThresholdTeamsEdit([]);
      } else {
        setSelectedBudgetThresholdTeamsEdit(dummyTeams.map((team) => team._id));
      }
      setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
    }
  };

  const handleCheckboxChange = (teamId, type) => {
    if (type === "time") {
      setSelectedTimeThresholdTeamsEdit((prev) =>
        prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]
      );
    } else {
      setSelectedBudgetThresholdTeamsEdit((prev) =>
        prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]
      );
    }
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideClienDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    // const isClickInsideSprintProjectDropdown = sprintProjectDropdownRef.current && sprintProjectDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintProjectDropdown) {
    //   setSprintProjectDropdownOpen(false);
    // }
    if (!isClickInsideClienDropdown) {
      setClientDropdownOpen(false);
    }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  // const handleProjectSubmit = async () => {
  //   setIsSubmitLoading(true);
  //   setProjectNameIsValid(false);
  //   setClientIdIsValid(false);
  //   if (projectName === "" || clientId === "") {
  //     if (projectName === "") {
  //       setProjectNameIsValid(true);
  //       projectNameRef.current.focus();
  //       return;
  //     } else if (clientId === "") {
  //       setClientIdIsValid(true);
  //       clientIdRef.current.focus();
  //       return;
  //     }
  //     toast.error("Please fill all required the fields", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     return;
  //   }
  //   let startdate, enddate;

  //   const requestData = {
  //     project_name: projectName.trim(),
  //     client_name: clientId,
  //     discription,
  //     is_billable: billable,
  //     // budget,
  //     total_hours: totalHours,
  //     project_manager: selectedProjectManagers.map(manager => manager.value),
  //   };
  //   const allSprints = [
  //     ...sprints,
  //     {
  //       sprint: sprint,
  //       sprintDiscription: sprintDiscription,
  //       sprintStartDate: startdate,
  //       sprintEndDate: enddate,
  //       id: Date.now(),
  //     },
  //   ]; // combine all Sprints including the last added one
  //   try {
  //     const response = await fetch("/api/project-details", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         ...requestData,
  //         sprint: allSprints.map((obj) => obj.sprint), // use all Sprints to send
  //         sprintDiscription: allSprints.map((obj) => obj.sprintDiscription), // use all Sprints to send
  //         sprintStartDate: allSprints.map((obj) => obj.sprintStartDate), // use all Sprints to send
  //         sprintEndDate: allSprints.map((obj) => obj.sprintEndDate), // use all Sprints to send
  //       }),
  //     });
  //     setSprints([]);
  //     setSprint("");
  //     if (response.ok) {
  //       console.log("response", response);
  //       const projectData = await response.json();
  //       const projectId = projectData?._id;

  //       if (timeChecked) {
  //         await fetch("/api/project/sprint/threshold", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             thresholdFor: "projects",
  //             recordId: projectId,
  //             clientId: clientId,
  //             thresholdType: "time",
  //             thresholdPercentage: timeThreshold,
  //             alertTo: selectedTimeThresholdTeamsEdit,
  //             thresholdTimeStatus: timeChecked,
  //           }),
  //         });
  //       }

  //       // If budgetChecked is true, send budget threshold request
  //       if (budgetChecked) {
  //         await fetch("/api/project/sprint/threshold", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             thresholdFor: "projects",
  //             recordId: projectId,
  //             clientId: clientId,
  //             thresholdType: "budget",
  //             thresholdPercentage: budgetThreshold,
  //             alertTo: selectedBudgetThresholdTeamsEdit,
  //            thresholdBudgetStatus: budgetChecked,
  //           }),
  //         });
  //       }
  //       if (!sprint) {
  //         toast.success("Project added successfully", {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) added Successfully`);
  //         dispatch(setProjectAdded());
  //         handleCloseProjectModal();
  //       } else {
  //         global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) and Sprints added Successfully`);
  //         toast.success("Project and sprint added successfully", {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         handleCloseProjectModal();
  //       }
  //     } else {
  //       global.config.activityLog(window.location.href, 'Project', `Failed to add Project (${projectName}) successfully`);
  //       toast.error("Failed to add project and sprint successfully", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }

  //   } catch (error) {
  //     console.error("Error adding project:", error);
  //     toast.error("An error occurred while adding the project", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //     });
  //   }
  //   setShouldFetchProject(prev => !prev);
  //   setIsSubmitLoading(false);
  //   dispatch(setProjectAdded());
  // };

  const handleProjectSubmit = async () => {
    setIsSubmitLoading(true);
    setProjectNameIsValid(false);
    setClientIdIsValid(false);

    if (projectName === "" || clientId === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        setIsSubmitLoading(false);
        return;
      } else if (clientId === "") {
        setClientIdIsValid(true);
        clientIdRef.current.focus();
        clientIdRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        setIsSubmitLoading(false);
        return;
      }
      toast.error("Please fill all required fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (timeChecked) {
      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    if (budgetChecked) {
      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    const requestData = {
      project_name: projectName.trim(),
      client_name: clientId,
      discription,
      is_billable: billable,
      // budget,
      // total_hours: totalHours,
      project_manager: selectedProjectManagers.map((manager) => manager.value),
    };

    const allSprints = [
      ...sprints,
      {
        sprint,
        sprintDiscription,
        sprintStartDate: null,
        sprintEndDate: null,
        id: Date.now(),
      },
    ];

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post("/api/project-details", {
        ...requestData,
        sprint: allSprints.map((obj) => obj.sprint),
        sprintDiscription: allSprints.map((obj) => obj.sprintDiscription),
        sprintStartDate: allSprints.map((obj) => obj.sprintStartDate),
        sprintEndDate: allSprints.map((obj) => obj.sprintEndDate),
      }, config);

      setSprints([]);
      setSprint("");

      if (response.status === 200) {
        const projectId = response.data?.id;

        if (timeChecked) {
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "projects",
            recordId: projectId,
            clientId,
            thresholdType: "time",
            thresholdPercentage: timeThreshold,
            alertTo: selectedTimeThresholdTeamsEdit,
            thresholdTimeStatus: timeChecked,
          }, config);
        }

        if (budgetChecked) {
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "projects",
            recordId: projectId,
            clientId,
            thresholdType: "budget",
            thresholdPercentage: budgetThreshold,
            alertTo: selectedBudgetThresholdTeamsEdit,
            thresholdBudgetStatus: budgetChecked,
          }, config);
        }

        if (!sprint) {
          toast.success("Project added successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          global.config.activityLog(window.location.href, "Project", `Project (${projectName}) added Successfully`);
          dispatch(setProjectAdded());
          handleCloseProjectModal();
        } else {
          global.config.activityLog(window.location.href, "Project", `Project (${projectName}) and Sprints added Successfully`);
          toast.success("Project and sprint added successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          handleCloseProjectModal();
        }
      } else {
        throw new Error("Failed to add project");
      }
    } catch (error) {
      console.error("Error adding project:", error);
      global.config.activityLog(window.location.href, "Project", `Failed to add Project (${projectName})`);
      toast.error("An error occurred while adding the project", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setIsSubmitLoading(false);
      dispatch(setProjectAdded());
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }


  const [dateRange, setDateRange] = useState([
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 2)),
  ]);

  const handleSelect = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0];
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 2);
      setDateRange([startDate, endDate]);
    }
  };


  return (

    <>
      <a className="new-client" href="#" onClick={handleOpenProjectModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Project
      </a>

      <Modal
        className="client-modal project-modal project-threshhold"
        isOpen={isProjectModalOpen}
        onRequestClose={handleCloseProjectModal}
        contentLabel="New Project"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="modal">
          <div className="modal-close-invite outter-modal-main">
            <a className="" onClick={handleCloseProjectModal}>
              <img src={crssrImage} alt="Edit Icon" />{" "}
            </a>
          </div>
          <div>
            <div className="modal-client-head">
              <h2 className="">New Project</h2>
            </div>
            <div className="form-field-client-outer">
              <form>
                <div className="form-field-client">
                  <label htmlFor="projectName" className="form-label">
                    Project Name
                  </label>
                  <input
                    type="text"
                    id="projectName"
                    name="projectName"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    className={projectNameIsValid ? "input-validation-error form-input" : "form-input"}
                    ref={projectNameRef}
                  />
                  {projectNameIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Project Name Field Cannot be empty
                    </span>
                  )}
                </div>

                <div className="form-field-client">
                  <label htmlFor="client" className="form-label">
                    Client Name
                  </label>

                  <div
                    className={`custom-dropdown-user-new-outer custom-dropdown-select ${clientDropdownOpen ? 'open' : ''
                      }`}
                    ref={clientDropdownRef}
                  >
                    <div
                      className="selected-option custom-dropdown-user-new"
                      onClick={handleToggleClientDropdown}
                      style={{ cursor: 'pointer' }}
                      ref={clientIdRef}
                    >
                      {clientId ? (
                        clientName.find((client) => client._id === clientId)?.client_name
                      ) : (
                        'Select'
                      )}
                    </div>
                    {clientDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <input
                          type="text"
                          className="search-bar"
                          placeholder="Search..."
                          value={clientSearchTerm}
                          onChange={handleClientSearchInputChange}
                        />
                        <ul className="client-list">
                          {filteredClients.map((client) => (
                            <li
                              key={client._id}
                              className={clientId === client._id ? 'selected-item' : ''}
                              onClick={() => {
                                setClientId(client._id);
                                setClientDropdownOpen(false);
                              }}
                            >
                              {client.client_name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {clientIdIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Client Name Field Cannot be empty
                    </span>
                  )}
                </div>

                <div className="form-field-client">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={discription}
                    onChange={(e) => setDiscription(e.target.value)}
                    className="form-textarea"
                    rows="3"
                  ></textarea>
                </div>
                {/* <div className="form-field-client">
                  <label htmlFor="tome" className="form-label">
                    Time Allocated(in hours)
                  </label>
                  <input
                    type="number"
                    id="time"
                    name="time"
                    value={totalHours}
                    onChange={(e) => setTotalHours(e.target.value)}
                    className="form-input"
                    onWheel={numberInputOnWheelPreventChange}
                  />
                </div> */}
                {/* <div className="form-field-client">
                    <label htmlFor="tome" className="form-label">
                      Budget
                    </label>
                    <input
                      type="number"
                      id="budget"
                      name="budget"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      className="form-input"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </div> */}
                <div className="form-field-client">
                  <label htmlFor="projectManager" className="form-label">
                    Assigned team
                  </label>
                  {isUserLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <Select
                      isMulti
                      options={projectManagerOptions}
                      value={selectedProjectManagers}
                      onChange={(selectedOptions) => setSelectedProjectManagers(selectedOptions)}
                    />
                  )}
                </div>
                <div className="form-field-client radio-sec">
                  <label htmlFor="billable" className="form-label">
                    Billable
                  </label>
                  <div className="radio-container">
                    <div className="radio-option yes">
                      <input
                        type="radio"
                        id="yes"
                        name="billable"
                        value={billable}
                        checked={billable === true}
                        onChange={(e) => setBillable(true)}
                      />
                      <label htmlFor="yes">
                        Yes <img src={Tick} alt="Tick" />
                      </label>
                    </div>
                    <div className="radio-option no">
                      <input
                        type="radio"
                        id="no"
                        name="billable"
                        value={billable}
                        checked={billable === false}
                        onChange={(e) => setBillable(false)}
                      />
                      <label htmlFor="no">
                        No <img src={Close} alt="Edit Icon" />
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="form-field-client">
                  <label htmlFor="projectTimeframe" className="form-label">
                    Project Timeframe
                  </label>
                  <div className="date-picker-outer date-picker-outer-all" id="projectTimeframe">
                    <div className="custom-picker-icon custom-picker-icon-all">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 14 14"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        className="rs-picker-toggle-caret rs-icon"
                        aria-label="calendar"
                        data-category="legacy"
                      >
                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                      </svg>
                    </div>
                    <div className="custoom-icon-calender custoom-icon-calender-all">
                      <DateRangePicker
                        showOneCalendar
                        id="date-range"
                        format="dd/MM/yyyy"
                        placeholder="Select a date range"
                        value={dateRange}
                        onChange={handleSelect}
                        placement="auto"
                        timeZone={global.config.getTimeZone()}
                        character="  to  " // This replaces "~" with "to"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-field-client">
                  <label htmlFor="projectTimeframe" className="form-label">
                    Project Alerts
                  </label>
                  <div className="project-alert-outer">
                    {/* Time Alert */}
                    {/* onChange={() => setTimeChecked(!timeChecked)} onChange={() => setBudgetChecked(!budgetChecked)}*/}
                    <div className="alert-section for-time-outer">
                      <label className="alert-label">
                        <input type="checkbox" checked={timeChecked} onChange={handleTimeCheckboxChange} />
                        <span>For time</span>
                      </label>

                      <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                        <div className="input-group">
                          <span>If project reaches</span>
                          {timeLoading ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <input
                              type="number"
                              value={timeThreshold}
                              // onChange={(e) => setTimeThreshold(e.target.value)}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                  setTimeThreshold(value);
                                }
                              }}
                              onWheel={numberInputOnWheelPreventChange}
                              min="0"
                              max="100"
                              step="1"
                            />
                          )}
                          <span>% of estimate</span>
                        </div>

                        <div className="dropdown-group">
                          <span>Alert</span>
                          <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                            <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                              <div className="selected-options1">
                                {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                  <div className="placeholder">Select</div>
                                ) : (
                                  <>
                                    <div className="selected-option1">
                                      {timeLoading ? (
                                        <div className="outter-load-table">
                                          <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      ) : (() => {
                                        const roles = {
                                          Super_Admin: "All Super Admin",
                                          Admin: "All Admin",
                                          Project_Manager: "All Project Manager",
                                          Team_Manager: "All Team Manager",
                                          User: "All Users",
                                        };

                                        // Check if all users of a specific role are selected
                                        for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                          const roleUsers = teams
                                            .filter((team) => team.role_id === userType[roleKey])
                                            .map((team) => team._id);

                                          if (
                                            roleUsers.length > 0 &&
                                            roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                            selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                          ) {
                                            return roleLabel;
                                          }
                                        }

                                        // If all team members are selected
                                        if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                          return "All Team";
                                        }

                                        // Get the first selected user name
                                        const firstSelectedUser =
                                          teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                            ? (() => {
                                              const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                              return user.first_name
                                                ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                            })()
                                            : "No Users selected";

                                        return (
                                          <>
                                            {firstSelectedUser}
                                            {selectedTimeThresholdTeamsEdit.length > 1 && (
                                              <div className="selected-option-count">
                                                {selectedTimeThresholdTeamsEdit.length - 1}+
                                              </div>
                                            )}
                                          </>
                                        );
                                      })()}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {isTimeThresholdTeamDropdownOpen && (
                              <div className="dropdown-list1">
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectTimeThresholdAllTeam}
                                    onChange={handleSelectTimeThresholdAllTeamChange}
                                  />
                                  All Teams
                                </label>
                                {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                  .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                  .map((role) => (
                                    <label className="checkbox-label1" key={role}>
                                      <input
                                        type="checkbox"
                                        checked={teams
                                          .filter((team) => team.role_id === userType[role])
                                          .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                        onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                      />
                                      All {role.replace("_", " ")}
                                    </label>
                                  ))}
                                {teams.map((team) => (
                                  <label className="checkbox-label1" key={team._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                      onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                    />
                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Budget Alert */}
                    <div className="alert-section for-budget-outer">
                      <label className="alert-label">
                        <input type="checkbox" checked={budgetChecked} onChange={handleBudgetCheckboxChange} />
                        <span>For budget</span>
                      </label>

                      <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                        <div className="input-group">
                          <span>If project reaches</span>
                          {budgetLoading ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <input
                              type="number"
                              value={budgetThreshold}
                              // onChange={(e) => setBudgetThreshold(e.target.value)}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                  setBudgetThreshold(value);
                                }
                              }}
                              onWheel={numberInputOnWheelPreventChange}
                              min="0"
                              max="100"
                              step="1"
                            />
                          )}
                          <span>% of estimate</span>
                        </div>

                        <div className="dropdown-group">
                          <span>Alert</span>
                          <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                            <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                              <div className="selected-options1">
                                {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                  <div className="placeholder">Select</div>
                                ) : (
                                  <>
                                    <div className="selected-option1">
                                      {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                        <div className="placeholder">Select</div>
                                      ) : (
                                        <>
                                          <div className="selected-option1">
                                            {/* {selectedBudgetThresholdTeamsEdit.length === teams.length ? (
                                              "All"
                                            ) : (
                                              <>
                                                {teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])?.first_name || "User"}
                                                {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                  <div className="selected-option-count">
                                                    {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                  </div>
                                                )}
                                              </>
                                            )} */}
                                            {budgetLoading ? (
                                              <div className="outter-load-table">
                                                <ThreeDots
                                                  height="18"
                                                  width="20"
                                                  radius="9"
                                                  color="#6479f9"
                                                  ariaLabel="three-dots-loading"
                                                  wrapperStyle={{}}
                                                  wrapperClassName=""
                                                  visible={true}
                                                />
                                              </div>
                                            ) : (() => {
                                              const roles = {
                                                Super_Admin: "All Super Admin",
                                                Admin: "All Admin",
                                                Project_Manager: "All Project Manager",
                                                Team_Manager: "All Team Manager",
                                                User: "All Users",
                                              };

                                              // Check if all users of a specific role are selected
                                              for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                const roleUsers = teams
                                                  .filter((team) => team.role_id === userType[roleKey])
                                                  .map((team) => team._id);

                                                if (
                                                  roleUsers.length > 0 &&
                                                  roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                  selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                ) {
                                                  return roleLabel;
                                                }
                                              }

                                              // If all team members are selected
                                              if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                                return "All Team";
                                              }

                                              // Get the first selected user name
                                              const firstSelectedUser =
                                                teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                  ? (() => {
                                                    const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                    return user.first_name
                                                      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                      : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                  })()
                                                  : "No Users selected";

                                              return (
                                                <>
                                                  {firstSelectedUser}
                                                  {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                    <div className="selected-option-count">
                                                      {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })()}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {isBudgetThresholdTeamDropdownOpen && (
                              <div className="dropdown-list1">
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectBudgetThresholdAllTeam}
                                    onChange={handleSelectBudgetThresholdAllTeamChange}
                                  />
                                  All Teams
                                </label>
                                {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                  .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                  .map((role) => (
                                    <label className="checkbox-label1" key={role}>
                                      <input
                                        type="checkbox"
                                        checked={teams
                                          .filter((team) => team.role_id === userType[role])
                                          .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                        onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                      />
                                      All {role.replace("_", " ")}
                                    </label>
                                  ))}
                                {teams.map((team) => (
                                  <label className="checkbox-label1" key={team._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                      onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                    />
                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isSubmitLoading ?
                  (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <button type="button" onClick={handleProjectSubmit} className="submit-client">
                      Create Project
                    </button>
                  )}
              </form>
            </div>
          </div>

        </div>
      </Modal>

    </>
  )
}

export default NewProjectsModal;