import React, { useState, useEffect, useRef } from "react";
import "./ClientOverviewListingSingleActivities.css";
import { ThreeDots } from "react-loader-spinner";
import { DateRangePicker } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import TimeIccon from "../../../images/icons/clock.svg";
import DollarIccon from "../../../images/icons/billable.svg";
import CloseDollarIccon from "../../../images/icons/non-billable.svg";
import PurseMmoney from "../../../images/icons/purses-money.svg";
import NotepadIcon from "../../../images/icons/notepad.svg";
import DollarReverse from "../../../images/icons/dollar-reverse.svg";
import {
  useTotalBudgetByClient,
  useTotalCostByClient,
  useTrackedClientHours,
} from "../../../features/projects/ProjectList";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setClientDashboardDate } from "../../../features/client/clientSlice";
import { useClientView } from "../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import Calendar from "../../../helpers/dateComponents/Calendar";

const ClientActivities = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  const id = decryptedBytes.toString(enc.Utf8);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const calendarRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const dashboradDate = useSelector((state) => state.client.dashboardDateRange);
  const { appSettings } = useSelector((state) => state.appSettings);
  const tz = useSelector((state) => state.appSettings.appSettings.timeZone);

  const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError: totalBudgetFetchError, } = useTotalBudgetByClient(id, token);
  const { data: trackedHours = [], isLoading: trackedHoursLoading, refetch: refetchTrackedlHours, isError: trackedHoursFetchError, } = useTrackedClientHours(id, selectedRange ? selectedRange[0] : null, selectedRange ? selectedRange[1] : null, tz, token);
  const { data: clientView = [], isLoading: clientDataLoading, refetch: refetchClientData, isError: clientViewError, } = useClientView(id, token);
  const {
    data: totalCost = [],
    isLoading: totalCostLoading,
    refetch: refetchTotalCost,
    isError,
  } = useTotalCostByClient(
    id,
    token,
    selectedRange ? selectedRange[0] : null,
    selectedRange ? selectedRange[1] : null,
    tz,
    forex,
    clientView?.preferred_currency || 'USD',
  );
  useEffect(() => {
    if (clientViewError) {
      if (clientViewError.response?.data?.status === "deleted") {
        // Handle the case where the user is deleted
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This client is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle other errors
      alert(`Error fetching users`);
    }
    refetchTotalCost();
    refetchTrackedlHours();
    refetchTotalBudget();
    refetchClientData();
  }, [id, dashboradDate, clientView]);

  useEffect(() => {
    console.log(selectedRange)
    setSelectedRange(null);
  }, [id, clientView]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCalendarDropdown =
      calendarRef.current && calendarRef.current.contains(event.target);
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    setSelectedRange(null); // Reset when the route changes
  }, [location.pathname]);

  // const handleDateRangeChange = async (value) => {
  //     if (value === null) {
  //         var newDate = [
  //             moment().tz(global.config.getTimeZone()).startOf('year').format('YYYY/MM/DD HH:mm'),
  //             moment().tz(global.config.getTimeZone()).endOf('year').format('YYYY/MM/DD HH:mm')
  //         ];
  //         setSelectedRange(newDate);
  //     } else {
  //         setSelectedRange(value);
  //         await dispatch(setClientDashboardDate(value));
  //     }
  // };

  // const onDateChange = async (ranges) => {
  //   // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
  //   //const dateRangeArray = [ranges.startDate, ranges.endDate];
  //   const dateRangeArray = [
  //     new Date(ranges.startDate),
  //     new Date(ranges.endDate)
  //   ];

  //   setSelectedRange(dateRangeArray);
  //   await dispatch(setClientDashboardDate(dateRangeArray));
  //   setShowCalendar(false);
  // };

  const onDateChange = async (ranges) => {

    const startOfDayUTC = moment(ranges.startDate).startOf("day").toDate();
    const endOfDayUTC = moment(ranges.endDate).endOf("day").toDate();
    const dateRangeArray = [startOfDayUTC, endOfDayUTC];
    setSelectedRange(dateRangeArray);
    await dispatch(setClientDashboardDate(dateRangeArray));
    setShowCalendar(false);

  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const changeDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDuration;
  };

  const Roi = (Cbudget, cCost) => {
    const budget = Cbudget;
    const cost = cCost;
    if (cost === 0 && budget === 0) {
      return "0%";
    } else if (cost === 0 && budget !== 0) {
      return "N/A";
    } else {
      const rev = budget - cost;
      const Rvalue = (rev / cost) * 100;
      return isNaN(Rvalue) ? "0%" : Rvalue.toFixed(2) + "%";
    }
  };

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }
  const convertedBudget = ConvertHelper(
    clientView?.preferred_currency,
    clientView?.preferred_currency || 'USD',
    totalBudget,
    forex
  );

  return (
    <>
      <div className="client-activities-outer">
        <div className="client-profile-and-time">
          <div
            className="date-picker-outer date-picker-outer-all"
            ref={calendarRef}
          >
            <div className="custom-picker-icon custom-picker-icon-all">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 14 14"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                class="rs-picker-toggle-caret rs-icon"
                aria-label="calendar"
                data-category="legacy"
              >
                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
              </svg>
            </div>
            <div className="custoom-icon-calender custoom-icon-calender-all">
              {/* <DateRangePicker
                    showOneCalendar
                    format="dd/MM/yyyy"
                    placeholder={global.config.locate(appSettings && appSettings.language, 'All Time')}
                    value={selectedRange}
                    onChange={handleDateRangeChange}
                    placement="topEnd"
                    isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                    timeZone={global.config.getTimeZone()}
                    className="project-calender time-off-cal"
                    panelStyles={{ width: "100%" }}
                /> */}

              <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                <input
                  type="text"
                  value={
                    selectedRange && selectedRange.length === 2
                      ? `${new Date(selectedRange[0]).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })} to ${new Date(selectedRange[1]).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}`
                      : ""
                  }
                  id="date-range"
                  name="date-range"
                  placeholder="All Time"
                  onClick={toggleCalendar}
                  autoComplete="off"
                  readOnly
                />
              </div>
              <div className="date-range-new right-align">
                {showCalendar && (
                  // <Calendar
                  //   onCancel={cancelCalendar}
                  //   onChange={onDateChange}
                  //   initialRange={{
                  //     startDate: selectedRange ? selectedRange[0] : null,
                  //     endDate: selectedRange ? selectedRange[1] : null,
                  //   }}
                  // />
                  <Calendar
                    onCancel={cancelCalendar}
                    onChange={(range) => {
                      // const adjustedStartDate = range.startDate ? new Date(range.startDate) : null;
                      // const adjustedEndDate = range.endDate ? new Date(range.endDate) : null;

                      const adjustedStartDate = range.startDate ? moment.tz(range.startDate, global.config.getTimeZone()).utc().toDate() : null;
                      const adjustedEndDate = range.endDate ? moment.tz(range.endDate, global.config.getTimeZone()).utc().toDate() : null;
                      onDateChange({
                        startDate: adjustedStartDate,
                        endDate: adjustedEndDate,
                      });
                    }}
                    initialRange={{
                      startDate: selectedRange ? new Date(selectedRange[0]) : null,
                      endDate: selectedRange ? new Date(selectedRange[1]) : null,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-activity-columns">
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">Total Hours</div>
              <img src={TimeIccon} alt="time-iccon" />
            </div>
            <div className="activity-count">
              {trackedHoursLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <span>
                  {trackedHours.totalClientDuration
                    ? changeDurationFormat(trackedHours.totalClientDuration)
                    : "00:00:00"}
                </span>
              )}
            </div>
          </div>
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">Total Billable</div>
              <img src={DollarIccon} alt="dollar-iccon" />
            </div>
            <div className="activity-count">
              {trackedHoursLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <span>
                  {trackedHours.totalBillableDuration
                    ? changeDurationFormat(trackedHours.totalBillableDuration)
                    : "00:00:00"}
                </span>
              )}
            </div>
          </div>
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">Total Non-Billable</div>
              <img src={CloseDollarIccon} alt="close-dollar-iccon" />
            </div>
            <div className="activity-count">
              {trackedHoursLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <span>
                  {trackedHours.totalNonBillableDuration
                    ? changeDurationFormat(
                      trackedHours.totalNonBillableDuration
                    )
                    : "00:00:00"}
                </span>
              )}
            </div>
          </div>
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">Total Budget</div>
              <img src={PurseMmoney} alt="purses-of-money" />
            </div>
            <div className="activity-count">
              {totalBudgetLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : // totalBudget ? totalBudget.toFixed(2) : '0.00'
                clientView?.preferred_currency ? (
                  totalBudget ? (
                    // totalBudget.toFixed(2)
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: clientView?.preferred_currency || 'USD',
                    }).format(
                      ConvertHelper(
                        clientView?.preferred_currency,
                        clientView?.preferred_currency || 'USD',
                        totalBudget,
                        forex
                      )
                    )
                  ) : (
                    // '0.00'
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: clientView?.preferred_currency || 'USD',
                    }).format("0.00")
                  )
                ) : totalBudget ? (
                  totalBudget.toFixed(2)
                ) : (
                  "0.00"
                )}
            </div>
          </div>
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">Total Cost</div>
              <img src={NotepadIcon} alt="notepad-iccon" />
            </div>
            <div className="activity-count">
              {totalCostLoading || clientDataLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : clientView?.preferred_currency ? (
                totalCost ? (
                  new Intl.NumberFormat("en-US", { style: "currency", currency: clientView?.preferred_currency || 'USD', }).format(totalCost)
                ) : (
                  new Intl.NumberFormat("en-US", { style: "currency", currency: clientView?.preferred_currency || 'USD', }).format("0.00")
                )
              ) : totalCost ? (
                totalCost.toFixed(2)
              ) : (
                "0.00"
              )}
            </div>
          </div>
          <div className="activity-column">
            <div className="activity-with-image">
              <div className="activity-name">ROI</div>
              <img src={DollarReverse} alt="time-iccon" />
            </div>
            <div className="activity-count">
              {/* 23% */}
              {totalCostLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                Roi(
                  totalBudget ? convertedBudget : 0,
                  totalCost ? totalCost : 0
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientActivities;
