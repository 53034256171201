import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import "./TimeTrackerProjectTable.css";
import { toast } from "react-toastify";
import subDays from "date-fns/subDays";
import { DateRangePicker } from "rsuite";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Delete from "../../../images/icons/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import Close from "../../../images/icons/black-close.svg";
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DateConverter from "../../../helpers/dateFormatChange.js";
import editImage from "../../../images/singleinvoice/edit.svg";
import Tick from "../../../images/singleinvoice/tick-green.svg";
import { logout, reset } from "../../../features/auth/authSlice.js";
import profile_image from "../../../images/user/Default_pfp.png";
import UpArrowImage from "../../../images/icons/up-down-arrow.svg";
import LeftArrow from "../../../images/icons/right-blue-arrow.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import DownArrowImage from "../../../images/icons/up-down-arrow.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import {
  useFetchClientDetails,
  useFetchProjectDetails,
  useFetchProjectManager,
} from "./useProjectDetails.jsx";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import TruncateProjectView from "../../helpers/truncateProjectTableView.js";
import TruncateFieldView from "../../helpers/truncateFieldView.js";

const TimeProjectTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [maxPageItems] = useState(4);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const [updatedProject, setUpdatedProject] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectDiscription, setProjectDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [totalHours, setTotalHours] = useState("");
  const [projectNameView, setProjectNameView] = useState("");
  const [clientNameView, setClientNameView] = useState("");
  const [projectDiscriptionView, setProjectDiscriptionView] = useState("");
  const [billableView, setBillableView] = useState("");
  // const [totalHoursView, setTotalHoursView] = useState("");
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [projectManagerView, setProjectManagerView] = useState([]);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const clientNameRef = useRef(null);
  const [isProjectDeleteModalOpen, setIsProjectDeleteModalOpen] =
    useState(false);
  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [sprintList, setSprintList] = useState([]);
  const [isSprintLoading, setIsSprintLoading] = useState(false);
  const [loadingRowIndex, setLoadingRowIndex] = useState(-1);
  const [isSprintsModalOpen, setIsSprintsModalOpen] = useState(false);
  const [selectedSprintId, setSelectedSprintId] = useState("");
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");
  const [sprintStartDate, setSprintStartDate] = useState("");
  const [sprintEndDate, setSprintEndDate] = useState("");
  const [isSprintDeleteModalOpen, setIsSprintDeleteModalOpen] = useState(false);
  const [isSprintEdit, setIsSprintEdit] = useState(false);
  const [sprintIsValid, setSprintIsValid] = useState(false);
  const sprintRef = useRef(null);
  const [sprintView, setSprintView] = useState("");
  const [sprintDiscriptionView, setSprintDiscriptionView] = useState("");
  const showingDropdownRef = useRef(null);
  const clientDropdownRef = useRef(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const {
    data: project = [],
    isLoading,
    refetch: refetchProjects,
  } = useFetchProjectDetails(token);
  const {
    data: clientId = [],
    isLoading: clientLoading,
    refetch: refetchClients,
  } = useFetchClientDetails(token);
  const {
    data: userId = [],
    isLoading: projectManagerLoading,
    refetch: refetchProjectManager,
  } = useFetchProjectManager(token);

  useEffect(() => {
    setUpdatedProject(project);
    refetchProjects();
    if (props.shouldFetchProject) {
      refetchProjects();
    }
    refetchClients();
    refetchProjectManager();
  }, [props.shouldFetchProject, project]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.filter]);
  const filteredData = updatedProject.filter((item) => {
    const searchTerm = props.filter.trim().toLowerCase();
    const hasMatch = item.project_name.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (key === "project_name" || key === "client_name.client_name") {
        const valueA =
          key === "project_name" ? a.project_name : a.client_name?.client_name;
        const valueB =
          key === "project_name" ? b.project_name : b.client_name?.client_name;
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    setUpdatedProject(sortedData);
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset current page to the first page
  };

  useEffect(() => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let startPage;
    let endPage;
    let visiblePageItems = [];

    if (totalPages <= maxPageItems) {
      // If the total number of pages is less than or equal to the maximum page items, show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // Otherwise, determine the start and end pages based on the current page
      const middlePage = Math.ceil(maxPageItems / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = maxPageItems;
      } else if (currentPage + middlePage - 1 >= totalPages) {
        startPage = totalPages - maxPageItems + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage + 1;
        endPage = currentPage + middlePage - 1;
      }
    }

    if (startPage > 1) {
      visiblePageItems.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePageItems.push(i);
    }

    if (endPage < totalPages) {
      visiblePageItems.push("...");
    }

    setVisiblePages(visiblePageItems);
  }, [
    project,
    updatedProject,
    currentPage,
    itemsPerPage,
    maxPageItems,
    props.filter,
  ]);

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${
            a.company_first_name
              ? a.company_first_name +
                (a.company_last_name && a.company_last_name)
              : a.company_name
          }`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${
            b.company_first_name
              ? b.company_first_name +
                (b.company_last_name && b.company_last_name)
              : b.company_name
          }`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name
        ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}`
        : item.company_first_name
        ? `${
            item.company_first_name +
            (item.company_last_name && item.company_last_name)
          }`
        : `${item.company_name}(Company)`,
      value: item._id,
    }));

  const handleToggleClientDropdown = () => {
    setClientSearchTerm("");
    setClientDropdownOpen(!clientDropdownOpen);
  };

  const handleClientSearchInputChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const filteredClients = clientId
    .filter((client) =>
      client.client_name.toLowerCase().includes(clientSearchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
    );

  const handleOpenDetailsModal = async (item, index) => {
    setSelectedId(item._id);
    if (item.project_name) {
      setProjectName(item.project_name);
      setProjectNameView(item.project_name);
    }
    if (item.client_name) {
      setClientName(item.client_name._id);
      setClientNameView(item.client_name.client_name);
    }
    if (item.discription) {
      setProjectDiscription(item.discription);
      setProjectDiscriptionView(item.discription);
    }
    // if (item.total_hours) {
    //   setTotalHours(item.total_hours);
    //   setTotalHoursView(item.total_hours);
    // }
    if (item.project_manager) {
      const findProjectManagerOptions = (
        selectedProjectManagers,
        projectManagerOptions
      ) => {
        return selectedProjectManagers.map((manager) => {
          const option = projectManagerOptions.find(
            (opt) => opt.value === manager._id
          );
          return option
            ? option
            : {
                label: manager.first_name
                  ? `${manager.first_name} ${manager.middle_name || ""} ${
                      manager.last_name
                    }`
                  : manager.company_first_name
                  ? `${
                      manager.company_first_name +
                      (manager.company_last_name && manager.company_last_name)
                    }`
                  : `${manager.company_name}(Company)`,
                value: manager._id,
              };
        });
      };
      setSelectedProjectManagers(
        findProjectManagerOptions(item.project_manager, projectManagerOptions)
      );
      setProjectManagerView(item.project_manager);
    }
    setBillable(item.is_billable);
    setBillableView(item.is_billable);

    try {
      setLoadingRowIndex(index);
      setIsSprintLoading(true);
      const response = await axios.get(
        `/api/sprintProject-details/${item._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSprintList(response.data);
      setLoadingRowIndex(-1);
      setIsSprintLoading(false);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setLoadingRowIndex(-1);
      setIsSprintLoading(false);
    }
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setSelectedId("");
    setProjectName("");
    setClientName("");
    setProjectDiscription("");
    // setTotalHours("");
    setBillable(true);
    setProjectNameView("");
    setClientNameView("");
    setProjectDiscriptionView("");
    // setTotalHoursView("");
    setBillableView("");
    setIsProjectEdit(false);
    setSprintList([]);
    handleCloseSprintsModal();
    setIsDetailsModalOpen(false);
    setIsSprintEdit(false);
  };

  const handleSetProjectEdit = () => {
    setIsProjectEdit(true);
  };

  const handleResetProjectEdit = () => {
    setIsProjectEdit(false);
  };

  const handleProjectDelete = () => {
    setIsProjectDeleteModalOpen(true);
  };

  const handleConfirmProjectDelete = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(
        `/api/project-details/delete/${selectedId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Project removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project (${selectedId}) deleted successfully`
      );
      handleCloseDetailsModal();
      refetchProjects();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsProjectDeleteModalOpen(false);
  };

  const handleCancelProjectDelete = () => {
    setIsProjectDeleteModalOpen(false);
  };

  const handleProjectEdit = async (event) => {
    event.preventDefault();
    setProjectNameIsValid(false);
    setClientNameIsValid(false);
    if (projectName === "" || clientName === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        return;
      } else if (clientName === "") {
        setClientNameIsValid(true);
        clientNameRef.current.focus();
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const requestData = {
      project_name: projectName,
      client_name: clientName,
      discription: projectDiscription,
      is_billable: billable,
      // total_hours: totalHours,
      project_manager: selectedProjectManagers.map((manager) => manager.value),
    };
    try {
      setIsSubmitLoading(true);
      const response = await axios.put(
        `/api/project-details/update/${selectedId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.message;
      setUpdatedProject((prevState) => {
        const updatedProjectList = prevState.map((item) => {
          if (item._id === selectedId) {
            return {
              ...item,
              project_name: projectName,
              discription: projectDiscription,
              is_billable: billable,
              // total_hours: totalHours,
              project_manager: selectedProjectManagers.map(
                (manager) => manager.value
              ),
            };
          }
          return item;
        });
        return updatedProjectList;
      });
      toast.success("Project updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project "${projectName}" updated successfully`
      );
      handleCloseDetailsModal();
      refetchProjects();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const handleOpenSprintsModal = async (item) => {
    setSelectedSprintId(item._id);
    if (item.sprint_name) {
      setSprint(item.sprint_name);
      setSprintView(item.sprint_name);
    }
    if (item.start_date && item.end_date) {
      setDateRange([new Date(item.start_date), new Date(item.end_date)]);
      setSprintStartDate(
        moment(item.start_date)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY")
      );
      setSprintEndDate(
        moment(item.end_date)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY")
      );
    } else {
      setDateRange(null);
    }
    if (item.discription) {
      setSprintDiscription(item.discription);
      setSprintDiscriptionView(item.discription);
    }
    setIsSprintsModalOpen(true);
  };

  const handleCloseSprintsModal = () => {
    setSelectedSprintId("");
    setSprint("");
    setDateRange(null);
    setSprintDiscription("");
    setSprintView("");
    setSprintDiscriptionView("");
    setSprintStartDate("");
    setSprintEndDate("");
    setIsSprintEdit(false);
    setIsSprintsModalOpen(false);
  };

  const handleSetSprintEdit = () => {
    setIsSprintEdit(true);
  };

  const handleResetSprintEdit = () => {
    setIsSprintEdit(false);
  };

  const handleSprintDelete = () => {
    setIsSprintDeleteModalOpen(true);
  };

  const handleConfirmSprintDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/sprint-details/delete/${selectedSprintId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Sprint removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Sprint",
        `Sprint (${selectedId}) deleted successfully`
      );
      handleCloseDetailsModal();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsSprintDeleteModalOpen(false);
  };

  const handleCancelSprintDelete = () => {
    setIsSprintDeleteModalOpen(false);
  };

  const handleSprintEdit = async (event) => {
    event.preventDefault();
    setSprintIsValid(false);

    if (sprint === "") {
      if (sprint === "") {
        setSprintIsValid(true);
        sprintRef.current.focus();
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let startdate, enddate;
    if (dateRange === null) {
      startdate = null;
      enddate = null;
    } else {
      startdate = dateRange[0];
      enddate = dateRange[1];
    }

    const requestData = {
      sprint_name: sprint,
      discription: sprintDiscription,
      start_date: startdate,
      end_date: enddate,
    };
    try {
      setIsSubmitLoading(true);
      const response = await axios.put(
        `/api/sprint-details/update/${selectedSprintId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Sprint updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Sprint",
          `Sprint "${sprint}" Updated Successfully`
        );
        handleCloseDetailsModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Sprint",
        `Something went wrong: Sprint update`
      );
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const ranges = [
    {
      label: "today",
      value: [new Date(), new Date()],
    },
    {
      label: "yesterday",
      value: [subDays(new Date(), 1), subDays(new Date(), 1)],
    },
  ];

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideClienDropdown =
      clientDropdownRef.current &&
      clientDropdownRef.current.contains(event.target);
    const isClickInsideShowingDropdown =
      showingDropdownRef.current &&
      showingDropdownRef.current.contains(event.target);
    if (!isClickInsideClienDropdown) {
      setClientDropdownOpen(false);
    }
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const [visiblePages, setVisiblePages] = useState([1, 2, 3, "..."]);
  const totalResults = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    // updateVisiblePages(currentPage);
  }, [filteredData]);

  const updateVisiblePages = (pageNumber) => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const maxVisiblePages = 5; // Adjust as needed

    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to max visible pages, show all pages
      const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      setVisiblePages(pages);
    } else if (pageNumber <= Math.ceil(maxVisiblePages / 2)) {
      // If the current page is within the first half, show the first maxVisiblePages pages
      const pages = Array.from({ length: maxVisiblePages }, (_, i) => i + 1);
      setVisiblePages([...pages, "...", totalPages]);
    } else if (pageNumber >= totalPages - Math.floor(maxVisiblePages / 2)) {
      // If the current page is within the last half, show the last maxVisiblePages pages
      const pages = Array.from(
        { length: maxVisiblePages },
        (_, i) => totalPages - maxVisiblePages + i + 1
      );
      setVisiblePages([1, "...", ...pages]);
    } else {
      // If the current page is somewhere in the middle, show pages around the current page
      const pages = Array.from(
        { length: maxVisiblePages - 2 },
        (_, i) => pageNumber - Math.floor(maxVisiblePages / 2) + i + 1
      );
      setVisiblePages([1, "...", ...pages, "...", totalPages]);
    }
  };

  const paginate = (pageNumber) => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    if (pageNumber < 1 || pageNumber > totalPages) {
      return; // Return early if the clicked page is out of range
    }

    if (typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    } else if (pageNumber === "...") {
      const currentPageIndex = visiblePages.indexOf(currentPage);
      if (currentPageIndex === 1 && visiblePages[0] !== 1) {
        // If the ellipsis near the "back" button is clicked, show the previous maxVisiblePages - 2 page numbers
        const prevPages = Array.from(
          { length: visiblePages.length - 2 },
          (_, i) => currentPage - i - 1
        ).reverse();
        setVisiblePages([1, "...", ...prevPages, "...", totalPages]);
      } else if (
        currentPageIndex === visiblePages.length - 2 &&
        visiblePages[visiblePages.length - 1] !== totalPages
      ) {
        // If the ellipsis near the "next" button is clicked, show the next maxVisiblePages - 2 page numbers
        const nextPages = Array.from(
          { length: visiblePages.length - 2 },
          (_, i) => currentPage + i + 1
        );
        setVisiblePages([1, "...", ...nextPages, "...", totalPages]);
      }
    }
  };

  const isFirstPage = currentPage === 1;
  const isLastPage =
    currentPage === Math.ceil(filteredData.length / itemsPerPage);
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="outer-time-table">
      <div className="show-count">
        <div className="filter-inner showing-result show-result-team-fill project-tba-show">
          <label>Showing</label>

          <div
            ref={showingDropdownRef}
            className="select-modal-mainM custom-dropdown-user-new-outer"
          >
            <div
              className="selected-option custom-dropdown-user-new"
              onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
            >
              {totalResults < 10
                ? totalResults.toString()
                : itemsPerPage.toString()}
            </div>
            {showingDropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  {totalResults >= 10 && (
                    <li
                      className={itemsPerPage === 10 ? "selected-item" : ""}
                      onClick={() => {
                        setItemsPerPage(10);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      10
                    </li>
                  )}
                  {totalResults >= 20 && (
                    <li
                      className={itemsPerPage === 20 ? "selected-item" : ""}
                      onClick={() => {
                        setItemsPerPage(20);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      20
                    </li>
                  )}
                  {totalResults >= 30 && (
                    <li
                      className={itemsPerPage === 30 ? "selected-item" : ""}
                      onClick={() => {
                        setItemsPerPage(30);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      30
                    </li>
                  )}
                  {totalResults >= 40 && (
                    <li
                      className={itemsPerPage === 40 ? "selected-item" : ""}
                      onClick={() => {
                        setItemsPerPage(40);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      40
                    </li>
                  )}
                  {totalResults >= 10 && (
                    <li
                      className={
                        totalResults >= 10 && itemsPerPage === totalResults
                          ? "selected-item"
                          : ""
                      }
                      onClick={() => {
                        setItemsPerPage(totalResults);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      ALL
                    </li>
                  )}
                  {/* Add more conditions as needed */}
                  {totalResults < 10 ? (
                    <li
                      className={totalResults < 10 ? "selected-item" : ""}
                      onClick={() => {
                        setItemsPerPage(totalResults);
                        setCurrentPage(1);
                        setShowingDropdownOpen(false);
                      }}
                    >
                      {totalResults}
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
          <label>of {totalResults} Results</label>
        </div>
      </div>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal project-modal"
          isOpen={isDetailsModalOpen}
          onRequestClose={handleCloseDetailsModal}
          contentLabel="Project Details"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseDetailsModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            {!isSprintsModalOpen ? (
              <div className="project-detail-modal">
                <div className="modal-client-head ">
                  <h2 className="">Project Details</h2>
                </div>
                <div className="form-field-client-outer">
                  <form>
                    <div className="form-field-client">
                      {isProjectEdit || projectNameView ? (
                        <label htmlFor="projectName" className="form-label">
                          Project Name
                        </label>
                      ) : null}
                      {isProjectEdit ? (
                        <>
                          <input
                            type="text"
                            id="projectName"
                            name="projectName"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            className={
                              projectNameIsValid
                                ? "input-validation-error form-input"
                                : "form-input"
                            }
                            ref={projectNameRef}
                          />
                          {projectNameIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Project Name Field Cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-input">{projectNameView}</p>
                      )}
                    </div>
                    <div className="form-field-client">
                      {isProjectEdit || clientNameView ? (
                        <label htmlFor="client" className="form-label">
                          Client Name
                        </label>
                      ) : null}
                      {isProjectEdit ? (
                        <>
                          <div
                            className={`custom-dropdown-user-new-outer custom-dropdown-select ${
                              clientDropdownOpen ? "open" : ""
                            }`}
                            ref={clientDropdownRef}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              onClick={handleToggleClientDropdown}
                              style={{ cursor: "pointer" }}
                            >
                              {clientName
                                ? clientId.find(
                                    (client) => client._id === clientName
                                  )?.client_name
                                : "Select"}
                            </div>
                            {clientDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={clientSearchTerm}
                                  onChange={handleClientSearchInputChange}
                                />
                                <ul className="client-list">
                                  {filteredClients.map((client) => (
                                    <li
                                      key={client._id}
                                      className={
                                        clientName === client._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() => {
                                        setClientName(client._id);
                                        setClientDropdownOpen(false);
                                      }}
                                    >
                                      {client.client_name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {clientNameIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Client Name Field Cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-input">{clientNameView}</p>
                      )}
                    </div>

                    {isProjectEdit || projectDiscriptionView ? (
                      <div className="form-field-client">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        {isProjectEdit ? (
                          <textarea
                            id="description"
                            name="description"
                            value={projectDiscription}
                            onChange={(e) =>
                              setProjectDiscription(e.target.value)
                            }
                            className="form-textarea"
                            rows="3"
                          ></textarea>
                        ) : (
                          <p className="form-textarea">
                            {projectDiscriptionView}
                          </p>
                        )}
                      </div>
                    ) : null}

                    {/* {isProjectEdit || totalHoursView ? (
                      <div className="form-field-client">
                        <label htmlFor="time" className="form-label">
                          Time Allocated (in hours)
                        </label>
                        {isProjectEdit ? (
                          <input
                            type="number"
                            id="time"
                            name="time"
                            value={totalHours}
                            onChange={(e) => setTotalHours(e.target.value)}
                            className="form-input"
                            onWheel={numberInputOnWheelPreventChange}
                          />
                        ) : (
                          <p className="form-input">{totalHoursView}</p>
                        )}
                      </div>
                    ) : null} */}

                    {isProjectEdit || projectManagerView ? (
                      <div className="form-field-client">
                        <label htmlFor="projectManager" className="form-label">
                          Project Manager
                        </label>
                        {isProjectEdit ? (
                          <Select
                            isMulti
                            options={projectManagerOptions}
                            value={selectedProjectManagers}
                            onChange={(selectedOptions) =>
                              setSelectedProjectManagers(selectedOptions)
                            }
                          />
                        ) : (
                          <p className="form-textarea project-manager-outer">
                            {projectManagerView
                              .map((manager) => (
                                <div key={manager._id}>
                                  <span className="user-image-listingteam">
                                    {manager.profile_image ? (
                                      <img
                                        src={`/uploads/lowProfileImage/${manager.profile_image}`}
                                        alt={manager.first_name}
                                        className="member-image"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "/uploads/lowProfileImage/user_default.png";
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={profile_image}
                                        alt={manager.first_name}
                                        className="member-image"
                                      />
                                    )}
                                  </span>
                                  {manager.first_name
                                    ? `${manager.first_name} ${
                                        manager.middle_name || ""
                                      } ${manager.last_name}`
                                    : manager.company_first_name
                                    ? `${
                                        manager.company_first_name +
                                        (manager.company_last_name &&
                                          manager.company_last_name)
                                      }`
                                    : `${manager.company_name}(Company)`}
                                </div>
                              ))
                              .map((element, index, array) => (
                                <React.Fragment key={index}>
                                  {element}
                                  {index < array.length - 1 && " "}
                                </React.Fragment>
                              ))}
                          </p>
                        )}
                      </div>
                    ) : null}

                    {isProjectEdit || billableView ? (
                      <div
                        className={`form-field-client ${
                          isProjectEdit ? "radio-sec" : ""
                        }`}
                      >
                        <label htmlFor="billable" className="form-label">
                          Billable
                        </label>
                        {isProjectEdit ? (
                          <div className="radio-container">
                            <div className="radio-option yes">
                              <input
                                type="radio"
                                id="yes"
                                name="billable"
                                value={billable}
                                checked={billable === true}
                                onChange={(e) => setBillable(true)}
                              />
                              <label htmlFor="yes">
                                Yes <img src={Tick} alt="Tick" />
                              </label>
                            </div>
                            <div className="radio-option no">
                              <input
                                type="radio"
                                id="no"
                                name="billable"
                                value={billable}
                                checked={billable === false}
                                onChange={(e) => setBillable(false)}
                              />
                              <label htmlFor="no">
                                No <img src={Close} alt="Edit Icon" />
                              </label>
                            </div>
                          </div>
                        ) : (
                          <>
                            {billableView === true ? (
                              <p className="form-input">Yes</p>
                            ) : (
                              <p className="form-input">No</p>
                            )}
                          </>
                        )}
                      </div>
                    ) : null}

                    {!isProjectEdit && (
                      <div className="form-field-client">
                        <label htmlFor="sprints" className="form-label">
                          Sprints
                        </label>
                        {isSprintLoading ? (
                          <p className="form-input form-input-sprint">
                            {/* {totalHours} */}
                          </p>
                        ) : (
                          <>
                            {sprintList.length > 0 ? (
                              sprintList.map((item, index) => (
                                <p
                                  key={index}
                                  className="form-input form-input-sprint"
                                  onClick={() => handleOpenSprintsModal(item)}
                                >
                                  {item.sprint_name}
                                </p>
                              ))
                            ) : (
                              <>
                                {!isSprintLoading && (
                                  <div>No Sprints in this Project</div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {isProjectEdit ? (
                      <>
                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="sprint-btn-outer">
                            <button
                              type="button"
                              onClick={handleResetProjectEdit}
                              className="submit-client cancel-sprint"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={handleProjectEdit}
                              className="submit-client save-sprint"
                            >
                              Save Changes
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="sprint-btn-outer">
                        <button
                          type="button"
                          className="submit-client cancel-sprint"
                          onClick={handleProjectDelete}
                          disabled={isSubmitLoading}
                        >
                          <img src={Delete} alt="Delete" /> Delete
                        </button>
                        <Modal
                          className="delete-modal"
                          isOpen={isProjectDeleteModalOpen}
                          onRequestClose={handleCancelProjectDelete}
                          contentLabel="Confirm Delete"
                          style={{
                            content: {
                              height: "41%",
                              width: "30%",
                              position: "fixed",
                              top: "36%",
                              left: "50%",
                              transform: "translate(-19.75%, 0%)",
                              parent: document.querySelector(
                                ".admin-outer.time.tracker"
                              ),
                            },
                          }}
                        >
                          <div className="delete-modal-content">
                            <h2>Confirm Delete</h2>
                            <p>Are you sure you want to delete this Project?</p>
                            <div className="delete-modal-buttons">
                              <button
                                className="buttons-rejected"
                                onClick={handleConfirmProjectDelete}
                                disabled={isSubmitLoading}
                              >
                                {isSubmitLoading ? (
                                  <div className="outter-load-table">
                                    <ThreeDots
                                      height="18"
                                      width="20"
                                      radius="9"
                                      color="white"
                                      ariaLabel="three-dots-loading"
                                      wrapperStyle={{}}
                                      wrapperClassName=""
                                      visible={true}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                              <button
                                onClick={handleCancelProjectDelete}
                                disabled={isSubmitLoading}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Modal>
                        <button
                          type="button"
                          onClick={handleSetProjectEdit}
                          className="submit-client save-sprint"
                        >
                          <img alt="" src={editImage} /> Edit Project
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            ) : (
              <div className="modal-sprint-detail">
                <div className="modal-client-head back-to-projec-top">
                  <div className="back-to-project">
                    <button onClick={handleCloseSprintsModal}>
                      <span class="back-to-project-img">
                        <img alt="" src={LeftArrow} />
                      </span>
                      <span className="back-to-projec-text">
                        Back to Project
                      </span>
                    </button>
                  </div>
                </div>
                <div className="form-field-client-outer">
                  <form>
                    <div className="form-field-client">
                      {isSprintEdit ? (
                        <label htmlFor="projectName" className="form-label">
                          Sprint Name
                        </label>
                      ) : (
                        sprintView && (
                          <label htmlFor="projectName" className="form-label">
                            Sprint Name
                          </label>
                        )
                      )}
                      {isSprintEdit ? (
                        <>
                          <input
                            type="text"
                            id="sprintName"
                            name="sprintName"
                            value={sprint}
                            onChange={(e) => setSprint(e.target.value)}
                            className={
                              sprintIsValid
                                ? "input-validation-error form-input"
                                : "form-input"
                            }
                            ref={sprintRef}
                          />
                          {sprintIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Sprint Field Cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-textarea">{sprintView}</p>
                      )}
                    </div>
                    <div className="form-field-client">
                      {isSprintEdit ? (
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                      ) : (
                        sprintDiscriptionView && (
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                        )
                      )}
                      {isSprintEdit ? (
                        <>
                          <textarea
                            id="sprintDiscription"
                            name="sprintDiscription"
                            className="form-textarea"
                            rows="3"
                            value={sprintDiscription}
                            onChange={(e) =>
                              setSprintDiscription(e.target.value)
                            }
                          ></textarea>
                        </>
                      ) : (
                        <p className="form-textarea">{sprintDiscriptionView}</p>
                      )}
                    </div>
                    <div className="form-field-client">
                      {isSprintEdit ? (
                        <label htmlFor="projectName" className="form-label">
                          Date Frames
                        </label>
                      ) : (
                        sprintStartDate &&
                        sprintEndDate && (
                          <label htmlFor="projectName" className="form-label">
                            Date Frames
                          </label>
                        )
                      )}
                      {isSprintEdit ? (
                        <DateRangePicker
                          showOneCalendar
                          format="dd/MM/yyyy"
                          placeholder="From - to"
                          placement="auto"
                          ranges={ranges}
                          className="project-calender"
                          value={dateRange}
                          onChange={handleSelect}
                        />
                      ) : (
                        sprintStartDate &&
                        sprintEndDate && (
                          <p className="form-textarea">
                            {sprintStartDate} to {sprintEndDate}
                          </p>
                        )
                      )}
                    </div>
                    {isSprintEdit ? (
                      <>
                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="sprint-btn-outer">
                            <button
                              type="button"
                              onClick={handleResetSprintEdit}
                              className="submit-client cancel-sprint"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="submit-client save-sprint"
                              onClick={handleSprintEdit}
                            >
                              Save Changes
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="sprint-btn-outer">
                        <button
                          type="button"
                          className="submit-client cancel-sprint"
                          onClick={handleSprintDelete}
                          disabled={isSubmitLoading}
                        >
                          <img src={Delete} alt="Delete" /> Delete
                        </button>

                        <Modal
                          className="delete-modal"
                          isOpen={isSprintDeleteModalOpen}
                          onRequestClose={handleCancelSprintDelete}
                          contentLabel="Confirm Delete"
                          style={{
                            content: {
                              height: "41%",
                              width: "30%",
                              position: "fixed",
                              top: "36%",
                              left: "50%",
                              transform: "translate(-19.75%, 0%)",
                              parent: document.querySelector(
                                ".admin-outer.time.tracker"
                              ),
                            },
                          }}
                        >
                          <div className="delete-modal-content">
                            <h2>Confirm Delete</h2>
                            <p>Are you sure you want to delete this Sprint?</p>
                            <div className="delete-modal-buttons">
                              <button
                                className="buttons-rejected"
                                onClick={handleConfirmSprintDelete}
                                disabled={isSubmitLoading}
                              >
                                {isSubmitLoading ? (
                                  <div className="outter-load-table">
                                    <ThreeDots
                                      height="18"
                                      width="20"
                                      radius="9"
                                      color="white"
                                      ariaLabel="three-dots-loading"
                                      wrapperStyle={{}}
                                      wrapperClassName=""
                                      visible={true}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                              <button
                                onClick={handleCancelSprintDelete}
                                disabled={isSubmitLoading}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Modal>
                        <button
                          type="button"
                          className="submit-client save-sprint"
                          onClick={handleSetSprintEdit}
                        >
                          <img alt="" src={editImage} />
                          Edit Sprint
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <div className="time-project-table">
        <table>
          <thead>
            <tr>
              <th
                onClick={() => sortTable("project_name")}
                style={{ minWidth: "180px", paddingRight: "30px" }}
              >
                Project{" "}
                {sortConfig.key === "project_name" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("client_name.client_name")}>
                Client{" "}
                {sortConfig.key === "client_name.client_name" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("created_at")}>
                Date Created{" "}
                {sortConfig.key === "created_at" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("total_hours")}>
                Allotted Hours{" "}
                {sortConfig.key === "total_hours" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("is_billable")}>
                Billable{" "}
                {sortConfig.key === "is_billable" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentItems.length > 0 ? (
                  <>
                    {currentItems.map((row, index) => (
                      <tr key={index}>
                        <td className="truncate-td">
                          <TruncateProjectView textContent={row.project_name} />
                        </td>
                        <td>
                          {row.client_name && (
                            <>
                              <TruncateFieldView
                                textContent={row.client_name.client_name}
                              />
                            </>
                          )}
                        </td>
                        <td>
                          <DateConverter date={row.created_at} />
                        </td>
                        <td>{row.total_hours}</td>
                        <td>
                          <span
                            className={row.is_billable === true ? "yes" : "no"}
                          >
                            {row.is_billable === true ? "Yes" : "No"}
                          </span>
                        </td>
                        <td>
                          <a
                            class="cta-link"
                            onClick={() => handleOpenDetailsModal(row, index)}
                            disabled={isSprintLoading}
                          >
                            {loadingRowIndex === index ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="white"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Details"
                            )}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="7">
                      {!isLoading && (
                        <div className="outter-load-table">
                          No results found
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        {filteredData.length > itemsPerPage && (
          <ul>
            {!isFirstPage && (
              <li
                onClick={() => paginate(currentPage - 1)}
                className="pagination-item back"
              >
                Back
              </li>
            )}

            {visiblePages.map((pageNumber, index) => (
              <li
                key={index}
                onClick={() => paginate(pageNumber)}
                className={`pagination-item ${
                  currentPage === pageNumber ? "active" : ""
                } ${pageNumber === "..." ? "ellipsis" : ""}`}
              >
                {pageNumber === "..." ? <span>...</span> : pageNumber}
              </li>
            ))}

            {!isLastPage && (
              <li
                onClick={() => paginate(currentPage + 1)}
                className="pagination-item next"
              >
                Next
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TimeProjectTable;
