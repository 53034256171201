import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const VendorItemDescription = (invoiceData) => {
    var total = 0;
    var totalCost = 0;
    return (
        <>
            <div className="table-inner-outmain22">
                <table className="invo-single-tb1">
                    <tr class=" mainborder-bottom" key={(Math.random() + 1).toString(36).substring(7)}>
                        <td>Item</td>
                        <td style={{ width: '40%',paddingRight: "20px" }}>Description</td>
                        <td>Cost</td>
                        <td>VAT %</td>
                        <td>Price</td>

                    </tr>
                    {invoiceData.invoiceData.length > 0 ?
                        invoiceData.invoiceData[0].Invoice_Details.map((item, index) => {
                            total = total + parseFloat(item.Price);
                            totalCost = totalCost + parseFloat(item.Cost);
                            return (<><tr valign="top" class="border-bottomiteam infotablem" key={(Math.random() + 1).toString(36).substring(7)}>
                                <td>{index + 1}</td>
                                <td style={{ paddingRight: "20px" }} >{item.Description}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: invoiceData.invoiceData[0].ven_inv_currency, }).format(item.Cost)}</td>

                                <td>{item.VAT}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: invoiceData.invoiceData[0].ven_inv_currency, }).format(item.Price)}</td>
                            </tr></>)
                        })
                        :
                        (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)
                    }
                    <tr class="sub-bordertotal reborder" key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td>Subtotal</td>
                        <td className="tab-totalsubvat">{invoiceData.invoiceData.length > 0 ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: invoiceData.invoiceData[0].ven_inv_currency, }).format(totalCost)) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</td>
                    </tr>
                    <tr class="vat-bordertotal reborder" key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td>VAT</td>
                        <td className="tab-totalvat">{invoiceData.invoiceData.length > 0 ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: invoiceData.invoiceData[0].ven_inv_currency, }).format(parseFloat(total) - parseFloat(totalCost))) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</td>
                    </tr>
                    <tr class="total-bordertotal reborder" key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td className="mob-no-need"></td>
                        <td className="invo-totalfd">Invoice Total</td>
                        <td className="tab-totalamt">{invoiceData.invoiceData.length > 0 ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: invoiceData.invoiceData[0].ven_inv_currency, }).format(total)) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default VendorItemDescription