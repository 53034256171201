import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/financial-number-deal.svg";

// Functional component
function RevenueNumberDeal({ clientInvoices }) {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);

  const filteredInvoices = clientInvoices && clientInvoices.filter(invoice => {
    const preferredInvoiceCurrency = invoice?.cli_inv_currency || 'USD';
    const isClientDetailMatch = !reportingRevenue?.revenueClient || reportingRevenue?.revenueClient.includes(invoice?.cli_inv_for?._id);
    const isNotDeleted = !invoice.is_delete;
    const isYearMatch = !reportingRevenue?.revenueYear ||
      new Date(invoice?.cli_inv_created_at).getFullYear() === reportingRevenue?.revenueYear;
    const isCurrencyMatch = reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredInvoiceCurrency)
    return isClientDetailMatch && isNotDeleted && isYearMatch && isCurrencyMatch;
  });

  const dealCount = filteredInvoices.length;
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Number of Invoices
          </div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {dealCount}
        </div>
      </div>

    </div>
  );
}

export default RevenueNumberDeal;
