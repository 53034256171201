import axios from "axios";
import Modal from "react-modal";
import "../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import subDays from 'date-fns/subDays';
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
// import "../../Dashboard/FilterCalendar.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import PlusIcon from "../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
// import TimeTrackerProjectTable from "./TimeTrackerProjectTable";
import { logout, reset } from "../../../features/auth/authSlice.js";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../features/projects/projectSlice.js";
import { useGetClients, useGetTeams } from "../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import userType from "../../../../src/constants/userType.js";


const NewSprintModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state.auth.token
  )
  const [isSprintModalOpen, setIsSprintModalOpen] = useState(false);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");
  const [totalHours, setTotalHours] = useState("");
  const [timeChecked, setTimeChecked] = useState(false);

  const [timeThreshold, setTimeThreshold] = useState(0);
  const timeThresholdTeamDropdownRef = useRef(null);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [budgetThreshold, setBudgetThreshold] = useState(0);
  const budgetThresholdTeamDropdownRef = useRef(null);
  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);


  const [sprintProjectName, setSprintProjectName] = useState(props.proId);
  const [budget, setBudget] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [sprintIsValid, setSprintIsValid] = useState(false);
  const sprintRef = useRef(null);
  const [sprintProjectNameIsValid, setSprintProjectNameIsValid] = useState(false);
  const sprintProjectDropdownRef = useRef(null);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);


  const { data: allTeams = [], isLoading: teamLoading, isError: teamError } = useGetTeams(token);

  // Filter teams where `is_active: true` and have at least one active user
  const teams = allTeams.filter(team => team.is_active === true);


  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };
  const [projectId, setProjectId] = useState([]);

  const settings = JSON.parse(localStorage.getItem("settings"));
  const handleOpenSprintModal = () => {
    setIsSprintModalOpen(true);
  };
  const handleCloseSprintModal = () => {
    setIsSprintModalOpen(false);
    setSprint("");
    setSprintDiscription("");
    setSprintProjectName("");
    setTotalHours("");
    setBudget("");
    setDateRange(null);
    setSprintIsValid(false);
    setSprintProjectNameIsValid(false);
    setTimeThreshold(0);
    setSelectedTimeThresholdTeamsEdit([]);
    setBudgetThreshold(0);
    setSelectedBudgetThresholdTeamsEdit([]);
    setTimeChecked(false);
    setBudgetChecked(false);
  };

  useEffect(() => {
    async function fetchProjectId() {
      try {
        const response = await axios.get("/api/project-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjectId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    fetchProjectId();
    const radioLabels = document.querySelectorAll(".radio-option label");
    radioLabels.forEach((label) => {
      label.addEventListener("click", (e) => {
        const radioInput = label.previousElementSibling;
        radioInput.checked = true;
      });
    });
  }, []);

  const ranges = [
    {
      label: 'today',
      value: [new Date(), new Date()]
    },
    {
      label: 'yesterday',
      value: [subDays(new Date(), 1), subDays(new Date(), 1)]
    }
  ];

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    // const isClickInsideSprintProjectDropdown = sprintProjectDropdownRef.current && sprintProjectDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintProjectDropdown) {
    //   setSprintProjectDropdownOpen(false);
    // }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSprintSubmit = async (event) => {
    event.preventDefault();
    setSprintIsValid(false);
    setSprintProjectNameIsValid(false);
    setIsSubmitLoading(true);
    if (sprint === "") {
      if (sprint === "") {
        setSprintIsValid(true);
        sprintRef.current.focus();
        setIsSubmitLoading(false);
        return;
      } else if (sprintProjectName === "") {
        setSprintProjectNameIsValid(true);
        // sprintProjectNameRef.current.focus();
        setIsSubmitLoading(false);
        return;
      }
      toast.error("Please fill all the required fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }
    if (timeChecked) {
      if (!totalHours || totalHours <= 0) {
        toast.error("Total hours are required when time threshold is enabled", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    // Validate budget if budgetChecked is true
    if (budgetChecked) {
      if (!budget || budget <= 0) {
        toast.error("Budget is required when budget threshold is enabled", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }
    let startdate, enddate;
    if (dateRange === null) {
      startdate = null;
      enddate = null;
    } else {
      startdate = dateRange[0];
      enddate = dateRange[1];
    }

    const requestData = {
      sprint_name: sprint,
      discription: sprintDiscription,
      project: props.proId,
      budget,
      totalHours,
      start_date: startdate,
      end_date: enddate,
    };
    try {
      const response = await axios.post(
        "/api/sprint-details/create",
        requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      );
      const data = response.data;
      if (data.status === "error") {

        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("response id", response.data?._id);
        const sprintId = response.data[0]?._id;
        if (timeChecked) {
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "sprints",
            recordId: sprintId,
            clientId: props.project?.client_name?._id,
            thresholdType: "time",
            thresholdPercentage: timeThreshold,
            alertTo: selectedTimeThresholdTeamsEdit,
            thresholdTimeStatus: timeChecked,
          }, config);
        }

        if (budgetChecked) {
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "sprints",
            recordId: sprintId,
            clientId: props.project?.client_name?._id,
            thresholdType: "budget",
            thresholdPercentage: budgetThreshold,
            alertTo: selectedBudgetThresholdTeamsEdit,
            thresholdBudgetStatus: budgetChecked,
          }, config);
        }

        toast.success("Sprint added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        global.config.activityLog(window.location.href, 'Sprint', `Sprint "${sprint}" added successfully`);
        handleCloseSprintModal();
        dispatch(setProjectAdded());

      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const formatBudget = (value) => {
    const currency = props.preferredCurrency || settings.currency || 'USD';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(value);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Remove non-numeric characters except for the decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');
    setBudget(numericValue);
  };


  // Threshold Alert section


  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };


  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };



  // Threshold Alert section

  return (

    <div className="button-sec-invoice">
      <a className="new-client" onClick={handleOpenSprintModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Sprint
      </a>

      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal sprint-modal"
          isOpen={isSprintModalOpen}
          onRequestClose={handleCloseSprintModal}
          contentLabel="Create a Sprint"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseSprintModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">Create a Sprint</h2>
            </div>
            <div className="form-field-client-outer">
              <form onSubmit={handleSprintSubmit}>
                <div className="form-field-client">
                  <label htmlFor="projectName" className="form-label">
                    Sprint Name
                  </label>
                  <input
                    type="text"
                    id="sprintName"
                    name="sprintName"
                    value={sprint}
                    onChange={(e) => setSprint(e.target.value)}
                    className={sprintIsValid ? "input-validation-error form-input" : "form-input"}
                    ref={sprintRef}
                  />
                  {sprintIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Sprint field cannot be empty
                    </span>
                  )}
                </div>
                <div className="form-field-client">
                  <label htmlFor="client" className="form-label">
                    Project Name
                  </label>

                  <div
                    className={`custom-dropdown-user-new-outer custom-dropdown-select`}
                    ref={sprintProjectDropdownRef}
                  >
                    <div className="form-input readonly" >
                      {
                        projectId.find((project) => project._id === props.proId)?.project_name
                      }
                    </div>
                  </div>
                  {sprintProjectNameIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Project Field Cannot be empty
                    </span>
                  )}
                </div>
                <div className="form-field-client">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    id="sprintDiscription"
                    name="sprintDiscription"
                    className="form-textarea"
                    rows="3"
                    value={sprintDiscription}
                    onChange={(e) => setSprintDiscription(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-field-client">
                  <label htmlFor="time" className="form-label">
                    Time Allocated (in hours)
                  </label>
                  <input
                    type="number"
                    id="time"
                    name="time"
                    value={totalHours}
                    onChange={(e) => setTotalHours(e.target.value)}
                    className="form-input"
                    onWheel={numberInputOnWheelPreventChange}
                  />
                </div>
                <div className="form-field-client">
                  <label htmlFor="tome" className="form-label">
                    Budget
                  </label>
                  <div className="form-field-client-currency">
                    <div className="currency-input">
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.preferredCurrency || settings.currency || 'USD' }).format(0).replace(/[\d.,]/g, '')}
                    </div>
                    <input
                      type="number"
                      id="budget"
                      name="budget"
                      value={budget}
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={(e) => setBudget(e.target.value)}
                      // onBlur={(e) => setBudget(formatBudget(e.target.value))}
                      className="form-input"
                    />
                  </div>
                </div>
                <div className="form-field-client">
                  <label htmlFor="projectName" className="form-label">
                    Date Frames
                  </label>
                  <div className="date-picker-outer date-picker-outer-all">
                    <div className="custom-picker-icon custom-picker-icon-all">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 14 14"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class="rs-picker-toggle-caret rs-icon"
                        aria-label="calendar"
                        data-category="legacy"
                      >
                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                      </svg>
                    </div>
                    <div className="custoom-icon-calender custoom-icon-calender-all">
                      <DateRangePicker
                        showOneCalendar
                        format="dd/MM/yyyy"
                        placeholder="From - to"
                        placement="topEnd"
                        ranges={ranges}
                        className="project-calender"
                        value={dateRange}
                        onChange={handleSelect}
                      />
                    </div>
                  </div>
                </div>
                {/* alert section */}
                <div className="form-field-client">
                  <label htmlFor="projectTimeframe" className="form-label">
                    Sprint Alerts
                  </label>
                  <div className="project-alert-outer">
                    {/* Time Alert */}
                    <div className="alert-section for-time-outer">
                      <label className="alert-label">
                        <input type="checkbox" checked={timeChecked} onChange={() => setTimeChecked(!timeChecked)} />
                        <span>For time</span>
                      </label>

                      <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                        <div className="input-group">
                          <span>If sprint reaches</span>
                          <input
                            type="number"
                            value={timeThreshold}
                            // onChange={(e) => setTimeThreshold(e.target.value)}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                setTimeThreshold(value);
                              }
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                            min="0"
                            max="100"
                            step="1"
                          />
                          <span>% of estimate</span>
                        </div>

                        <div className="dropdown-group">
                          <span>Alert</span>
                          <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                            <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                              <div className="selected-options1">
                                {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                  <div className="placeholder">Select</div>
                                ) : (
                                  <>
                                    <div className="selected-option1">
                                      {(() => {
                                        const roles = {
                                          Super_Admin: "All Super Admin",
                                          Admin: "All Admin",
                                          Project_Manager: "All Project Manager",
                                          Team_Manager: "All Team Manager",
                                          User: "All Users",
                                        };

                                        // Check if all users of a specific role are selected
                                        for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                          const roleUsers = teams
                                            .filter((team) => team.role_id === userType[roleKey])
                                            .map((team) => team._id);

                                          if (
                                            roleUsers.length > 0 &&
                                            roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                            selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                          ) {
                                            return roleLabel;
                                          }
                                        }

                                        // If all team members are selected
                                        if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                          return "All Team";
                                        }

                                        // Get the first selected user name
                                        const firstSelectedUser =
                                          teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                            ? (() => {
                                              const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                              return user.first_name
                                                ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                            })()
                                            : "No Users selected";

                                        return (
                                          <>
                                            {firstSelectedUser}
                                            {selectedTimeThresholdTeamsEdit.length > 1 && (
                                              <div className="selected-option-count">
                                                {selectedTimeThresholdTeamsEdit.length - 1}+
                                              </div>
                                            )}
                                          </>
                                        );
                                      })()}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {isTimeThresholdTeamDropdownOpen && (
                              <div className="dropdown-list1">
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectTimeThresholdAllTeam}
                                    onChange={handleSelectTimeThresholdAllTeamChange}
                                  />
                                  All Teams
                                </label>
                                {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                  .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                  .map((role) => (
                                    <label className="checkbox-label1" key={role}>
                                      <input
                                        type="checkbox"
                                        checked={teams
                                          .filter((team) => team.role_id === userType[role])
                                          .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                        onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                      />
                                      All {role.replace("_", " ")}
                                    </label>
                                  ))}
                                {teams.map((team) => (
                                  <label className="checkbox-label1" key={team._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                      onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                    />
                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Budget Alert */}
                    <div className="alert-section for-budget-outer">
                      <label className="alert-label">
                        <input type="checkbox" checked={budgetChecked} onChange={() => setBudgetChecked(!budgetChecked)} />
                        <span>For budget</span>
                      </label>

                      <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                        <div className="input-group">
                          <span>If sprint reaches</span>
                          <input
                            type="number"
                            value={budgetThreshold}
                            // onChange={(e) => setBudgetThreshold(e.target.value)}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                setBudgetThreshold(value);
                              }
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                            min="0"
                            max="100"
                            step="1"
                          />
                          <span>% of estimate</span>
                        </div>

                        <div className="dropdown-group">
                          <span>Alert</span>
                          <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                            <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                              <div className="selected-options1">
                                {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                  <div className="placeholder">Select</div>
                                ) : (
                                  <>
                                    <div className="selected-option1">
                                      {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                        <div className="placeholder">Select</div>
                                      ) : (
                                        <>
                                          <div className="selected-option1">
                                            {(() => {
                                              const roles = {
                                                Super_Admin: "All Super Admin",
                                                Admin: "All Admin",
                                                Project_Manager: "All Project Manager",
                                                Team_Manager: "All Team Manager",
                                                User: "All Users",
                                              };

                                              // Check if all users of a specific role are selected
                                              for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                const roleUsers = teams
                                                  .filter((team) => team.role_id === userType[roleKey])
                                                  .map((team) => team._id);

                                                if (
                                                  roleUsers.length > 0 &&
                                                  roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                  selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                ) {
                                                  return roleLabel;
                                                }
                                              }

                                              // If all team members are selected
                                              if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                                return "All Team";
                                              }

                                              // Get the first selected user name
                                              const firstSelectedUser =
                                                teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                  ? (() => {
                                                    const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                    return user.first_name
                                                      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                      : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                  })()
                                                  : "No Users selected";

                                              return (
                                                <>
                                                  {firstSelectedUser}
                                                  {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                    <div className="selected-option-count">
                                                      {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })()}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {isBudgetThresholdTeamDropdownOpen && (
                              <div className="dropdown-list1">
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectBudgetThresholdAllTeam}
                                    onChange={handleSelectBudgetThresholdAllTeamChange}
                                  />
                                  All Teams
                                </label>
                                {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                  .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                  .map((role) => (
                                    <label className="checkbox-label1" key={role}>
                                      <input
                                        type="checkbox"
                                        checked={teams
                                          .filter((team) => team.role_id === userType[role])
                                          .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                        onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                      />
                                      All {role.replace("_", " ")}
                                    </label>
                                  ))}
                                {teams.map((team) => (
                                  <label className="checkbox-label1" key={team._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                      onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                    />
                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* alert section */}
                {isSubmitLoading ?
                  (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <button type="submit" className="submit-client">
                      Create Sprint
                    </button>
                  )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default NewSprintModal;