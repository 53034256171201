import "./masterProfileSetting.css";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import MasterProfileDetails from "../../../components/Master/MasterUsers/MasterUserProfile/MasterUserProfile";
import UserAccount from '../../../components/User/UserDetails/UserAccount/UserAccount';
import UserSecurity from '../../../components/User/UserDetails/UserSecurity/UserSecurity';
import UserNotification from '../../../components/User/UserDetails/UserNotification/UserNotification';
import UserNotificationCenter from "../../../components/User/UserDetails/UserNotificationCenter/UserNotificationCenter";
import userCategory from "../../../constants/userCategory";
import VendorUserProfile from "../../../components/Vendors/VendorUserProfile/VendorUserProfile";
import ClientUserProfile from "../../../components/Clients/ClientUserProfile/ClientUserProfile";

function MasterProfileSettings() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(
    (state) => state.masterAuth.masterUser
  );
  const id = user._id;

  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem('activeTab', String(index));
  };
  useEffect(() => {
    const storedActiveTab = localStorage.getItem('activeTab');
    if (storedActiveTab) {
      setActiveTab(Number(storedActiveTab));
    }
    dispatch(setHeaderText("Profile Settings"));
  }, [id, dispatch]);

  return (
    <div>
      <div className='tab-outer-tab-account'>
        <ul className="tabs">
          <li
            className={activeTab === 0 ? 'active-tab' : ''}
            onClick={() => handleTabClick(0)}
          >
            Profile
          </li>
        </ul>

        <div className="tabs-content account-settings-tab">
          <div className='company-profile' style={{ display: activeTab === 0 ? 'block' : 'none' }}>
            <MasterProfileDetails id={id} />
          </div>
        </div>
      </div>
    </div >
  );
}
export default MasterProfileSettings;