import "./GeneralSettings.css"
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment-timezone';
import Modal from "react-modal";
import { getAllISOCodes } from 'iso-country-currency';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import currencyCodes from "currency-codes";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { useGetTeams } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import userType from "../../../constants/userType";
import { useNavigate } from "react-router-dom";
import { useGetBudgetThreshold, useGetTimeThreshold } from "../../../features/generalSettings/useGeneralSettings.jsx";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";


const GeneralSettings = (props) => {
    const navigate = useNavigate();
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const currencyDropdownRef = useRef(null);
    const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const timezoneDropdownRef = useRef(null);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const languageDropdownRef = useRef(null);
    const [weekStartDropdownOpen, setWeekStartDropdownOpen] = useState(false);
    const [selectedWeekStart, setSelectedWeekStart] = useState(null);
    const weekStartDropdownRef = useRef(null);
    const [lockTimeDropdownOpen, setLockTimeDropdownOpen] = useState(false);
    const [selectedLockTime, setSelectedLockTime] = useState(null);
    const lockTimeDropdownRef = useRef(null);
    const [timeZoneSearchTerm, setTimeZoneSearchTerm] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [currencySearchTerm, setCurrencySearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [locktime, setLocktime] = useState(2);
    const [lockTimertimeDropdownOpen, setLockTimertimeDropdownOpen] = useState(false);
    const [selectedTime, setSelectedTime] = useState('11:00 p.m');
    const [userCostVisibility, setUserCostVisibility] = useState(false);
    const [isUserCostVisibilityStatusLoading, setIsUserCostVisibilityStatusLoading] = useState(false);
    const [showTimeThresholdModal, setShowTimeThresholdModal] = useState(false);
    const [timeThreshold, setTimeThreshold] = useState("70");
    const [timeThresholdEdit, setTimeThresholdEdit] = useState("");
    const [showTimeThresholdUserModal, setShowTimeThresholdUserModal] = useState(false);
    const [selectedTimeThresholdTeams, setSelectedTimeThresholdTeams] = useState([]);
    const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
    const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
    const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
    const timeThresholdTeamDropdownRef = useRef(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [showBudgetThresholdModal, setShowBudgetThresholdModal] = useState(false);
    // const [threshold, setThreshold] = useState("70");
    const [budgetThreshold, setBudgetThreshold] = useState("");
    const [budgetThresholdEdit, setBudgetThresholdEdit] = useState("");
    const [showBudgetThresholdUserModal, setShowBudgetThresholdUserModal] = useState(false);
    const [selectedBudgetThresholdTeams, setSelectedBudgetThresholdTeams] = useState([]);
    const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
    const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
    const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
    const budgetThresholdTeamDropdownRef = useRef(null);
    // const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [seoTitle, setAppSeoTitle] = useState(null);
    const [seoDescription, setAppSeoDescription] = useState(null);
    const dispatch = useDispatch();
    const lockTimerTimeDropdownRef = useRef(null);
    // const locale = global.config.locate();

    const timeList = [
        {
            time_value: 0,
            time: "12:00 AM",
        },
        {
            time_value: 0.5,
            time: "12:30 AM",
        },
        {
            time_value: 1,
            time: "01:00 AM",
        },
        {
            time_value: 1.5,
            time: "01:30 AM",
        },
        {
            time_value: 2,
            time: "02:00 AM",
        },
        {
            time_value: 2.5,
            time: "02:30 AM",
        },
        {
            time_value: 3,
            time: "03:00 AM",
        },
        {
            time_value: 3.5,
            time: "03:30 AM",
        },
        {
            time_value: 4,
            time: "04:00 AM",
        },
        {
            time_value: 4.5,
            time: "04:30 AM",
        },
        {
            time_value: 5,
            time: "05:00 AM",
        },
        {
            time_value: 5.5,
            time: "05:30 AM",
        },
        {
            time_value: 6,
            time: "06:00 AM",
        },
        {
            time_value: 6.5,
            time: "06:30 AM",
        },
        {
            time_value: 7,
            time: "07:00 AM",
        },
        {
            time_value: 7.5,
            time: "07:30 AM",
        },
        {
            time_value: 8,
            time: "08:00 AM",
        },
        {
            time_value: 8.5,
            time: "08:30 AM",
        },
        {
            time_value: 9,
            time: "09:00 AM",
        },
        {
            time_value: 9.5,
            time: "09:30 AM",
        },
        {
            time_value: 10,
            time: "10:00 AM",
        },
        {
            time_value: 10.5,
            time: "10:30 AM",
        },
        {
            time_value: 11,
            time: "11:00 AM",
        },
        {
            time_value: 11.5,
            time: "11:30 AM",
        },
        {
            time_value: 12,
            time: "12:00 PM",
        },
        {
            time_value: 12.5,
            time: "12:30 PM",
        },
        {
            time_value: 13,
            time: "01:00 PM",
        },
        {
            time_value: 13.5,
            time: "01:30 PM",
        },
        {
            time_value: 14,
            time: "02:00 PM",
        },
        {
            time_value: 14.5,
            time: "02:30 PM",
        },
        {
            time_value: 15,
            time: "03:00 PM",
        },
        {
            time_value: 15.5,
            time: "03:30 PM",
        },
        {
            time_value: 16,
            time: "04:00 PM",
        },
        {
            time_value: 16.5,
            time: "04:30 PM",
        },
        {
            time_value: 17,
            time: "05:00 PM",
        },
        {
            time_value: 17.5,
            time: "05:30 PM",
        },
        {
            time_value: 18,
            time: "06:00 PM",
        },
        {
            time_value: 18.5,
            time: "06:30 PM",
        },
        {
            time_value: 19,
            time: "07:00 PM",
        },
        {
            time_value: 19.5,
            time: "07:30 PM",
        },
        {
            time_value: 20,
            time: "08:00 PM",
        },
        {
            time_value: 20.5,
            time: "08:30 PM",
        },
        {
            time_value: 21,
            time: "09:00 PM",
        },
        {
            time_value: 21.5,
            time: "09:30 PM",
        },
        {
            time_value: 22,
            time: "10:00 PM",
        },
        {
            time_value: 22.5,
            time: "10:30 PM",
        },
        {
            time_value: 23,
            time: "11:00 PM",
        },
        {
            time_value: 23.5,
            time: "11:30 PM",
        },
    ];
    let token = null;
    const { user } = useSelector((state) => state.auth);
    const { appSettings } = useSelector((state) => state.appSettings);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const fetchData = async () => {
        const response = await axios.get(
            `/api/get/app-settings/${appSettings && appSettings.appId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    };

    const { data, isLoading, error, refetch } = useQuery('exampleQueryKey', fetchData);
    const { data: allTeams = [], isLoading: teamLoading, isError: teamError } = useGetTeams(token);
    // Filter only active teams
    const teams = allTeams.filter(team => team.is_active === true);

    const { data: timeThresholdData = [], isLoading: timeThresholdLoading, refetch: refetchTimeThreshold, isError: timeThresholdError, } = useGetTimeThreshold(token);
    const { data: budgetThresholdData = [], isLoading: budgetThresholdLoading, refetch: refetchBudgetThreshold, isError: budgetThresholdError, } = useGetBudgetThreshold(token);

    useEffect(() => {
        setTimeThreshold(timeThresholdData?.data?.thresholdPercentage || 0);
        setSelectedTimeThresholdTeams(timeThresholdData?.data?.alertTo || []);
        setBudgetThreshold(budgetThresholdData?.data?.thresholdPercentage || 0);
        setSelectedBudgetThresholdTeams(budgetThresholdData?.data?.alertTo || []);
    }, [timeThresholdData, budgetThresholdData, dispatch]);
    useEffect(() => {
        // Using setTimeout to defer the state updates and make the effect non-blocking
        setTimeout(() => {
            setSelectedCurrency(data && data.currency);
            setSelectedTimezone(data && data.timeZone);
            setSelectedLanguage(data && data.language);
            setSelectedLockTime(data && data.timerLockOption);
            setAppSeoTitle(data && data.seoTitle);
            setAppSeoDescription(data && data.metaDescription);
            setLocktime(data && data.timerLock);
            setSelectedTime(data && data.timerLockTime);
            setSelectedWeekStart(data && data.weekStartDay);
            setUserCostVisibility(data && data.app_user_cost_visibility);
            // Refetch the data after state updates
            refetch();
        }, 0); // Non-blocking

    }, [isLoading, dispatch, data]); // Effect runs when isLoading, dispatch, or data changes

    useEffect(() => {
        setTimeout(() => {
            refetch();
        }, 0); // Non-blocking
    }, [selectedCurrency, selectedTimezone, selectedLanguage, selectedLockTime, seoTitle, seoDescription, locktime]);

    const toggleCurrencyDropdown = () => {
        setCurrencyDropdownOpen(!currencyDropdownOpen);
    };

    const selectCurrencyOption = async (selectedCurrency) => {
        setSelectedCurrency(selectedCurrency);
        // const updatedSettings = { ...appSettings, currency: selectedCurrency };
        // await dispatch(setAppSettings(updatedSettings));
        setCurrencyDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: selectedCurrency,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

        toast.success("Settings updated successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const toggleTimezoneDropdown = () => {
        setTimezoneDropdownOpen(!timezoneDropdownOpen);
    };

    const selectTimezoneOption = async (selectedTimezone) => {
        setSelectedTimezone(selectedTimezone);
        // const updatedSettings = { ...appSettings, timeZone: selectedTimezone };
        // await dispatch(setAppSettings(updatedSettings));
        setTimezoneDropdownOpen(false);
        try {
            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: selectedTimezone,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
            window.location.reload();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }


    };

    const toggleLanguageDropdown = () => {
        setLanguageDropdownOpen(!languageDropdownOpen);
    };

    const toggleWeekStartDropdown = () => {
        setWeekStartDropdownOpen(!weekStartDropdownOpen);
    };

    const selectLanguageOption = async (selectedLanguage) => {
        setSelectedLanguage(selectedLanguage);
        const updatedSettings = { ...appSettings, language: selectedLanguage };
        await dispatch(setAppSettings(updatedSettings));
        setLanguageDropdownOpen(false);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: selectedLanguage,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };

    const selectWeekStartOption = async (selectedWeekStart) => {
        setSelectedWeekStart(selectedWeekStart);
        const updatedSettings = { ...appSettings, weekStartDay: selectedWeekStart };
        await dispatch(setAppSettings(updatedSettings));
        setWeekStartDropdownOpen(false);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: null,
                    weekStartDay: selectedWeekStart,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };

    const toggleLockTimeDropdown = () => {
        setLockTimeDropdownOpen(!lockTimeDropdownOpen);
    };

    const selectLockTimeOption = async (selectedLockTime) => {
        setSelectedLockTime(selectedLockTime);
        const updatedSettings = { ...appSettings, timerLockOption: selectedLockTime };
        await dispatch(setAppSettings(updatedSettings));
        setLockTimeDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: selectedLockTime,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };


    // useEffect(() => {
    //     const fetchTimeZones = () => {
    //         // Get the time zone names using the Intl.DateTimeFormat object
    //         const allTimeZones = moment.tz.names();
    //         const allCurrencies = getAllISOCodes();

    //         // Set the time zones in the state
    //         setTimeZones(allTimeZones);
    //         setCurrencies(allCurrencies);
    //     };

    //     // Call the fetchTimeZones function
    //     fetchTimeZones();
    // }, []); // Empty dependency array to run the effect only once on mount

    useEffect(() => {
        const fetchTimeZones = () => {
            // Get the time zone names using the Intl.DateTimeFormat object
            const allTimeZones = moment.tz.names();
            const allCurrencies = getAllISOCodes();

            // Use setTimeout to make the state updates non-blocking
            setTimeout(() => {
                setTimeZones(allTimeZones);
                setCurrencies(allCurrencies);
            }, 0);
        };

        // Call the fetchTimeZones function
        fetchTimeZones();
    }, []);

    const handleCurrencyFilterChange = (e) => {
        setCurrencySearchTerm(e.target.value);
    };

    const codeToCurrency = (code) => {
        var currencyCode = code ? code : 'USD';
        try {
            var currencyCode1 = currencyCodes.code(currencyCode).currency;
            return currencyCode1.toString(); // Ensure it's a string
        } catch (error) {
            return code.toString(); // Ensure it's a string
        }
    }

    const filteredCurrencies = currencies && currencies.filter((currency) => {
        const currencyName = codeToCurrency(currency.currency && currency.currency);
        return currencyName?.toLowerCase().includes(currencySearchTerm?.toLowerCase());
    });

    const handleTimeZoneFilterChange = (e) => {
        setTimeZoneSearchTerm(e.target.value);
    };

    const filteredTimeZone = timeZones && timeZones.filter((timeZone) => {
        return timeZone?.toLowerCase().includes(timeZoneSearchTerm?.toLowerCase());
    });

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideTimezoneDropdown = timezoneDropdownRef.current && timezoneDropdownRef.current.contains(event.target);
        const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
        const isClickInsideLanguageDropdown = languageDropdownRef.current && languageDropdownRef.current.contains(event.target);
        const isClickInsideWeekStartDropdown = weekStartDropdownRef.current && weekStartDropdownRef.current.contains(event.target);
        const isClickInsideLockTimeDropdown = lockTimeDropdownRef.current && lockTimeDropdownRef.current.contains(event.target);
        const isClickInsideLockTimerTimeDropdown = lockTimerTimeDropdownRef.current && lockTimerTimeDropdownRef.current.contains(event.target);
        const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
        const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
        if (!isClickInsideTimezoneDropdown) {
            setTimezoneDropdownOpen(false);
        }
        if (!isClickInsideCurrencyDropdown) {
            setCurrencyDropdownOpen(false);
        }
        if (!isClickInsideLanguageDropdown) {
            setLanguageDropdownOpen(false);
        }
        if (!isClickInsideWeekStartDropdown) {
            setWeekStartDropdownOpen(false);
        }
        if (!isClickInsideLockTimeDropdown) {
            setLockTimeDropdownOpen(false);
        }
        if (!isClickInsideLockTimerTimeDropdown) {
            setLockTimertimeDropdownOpen(false);
        }
        if (!isClickInsideTimeThresholdTeamDropdown) {
            setIsTimeThresholdTeamDropdownOpen(false);
        }
        if (!isClickInsideBudgetThresholdTeamDropdown) {
            setIsBudgetThresholdTeamDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);



    const setSeoTitle = async (seoTitle) => {

        setAppSeoTitle(seoTitle)
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: seoTitle,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }
    }

    const setSeoDescription = async (seoDescription) => {
        setAppSeoDescription(seoDescription);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: seoDescription,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }
    }

    const setLockTimeUpdate = async (lockTime) => {
        setLocktime(lockTime);
        if (lockTime) {
            const updatedSettings = { ...appSettings, timerLock: lockTime };
            await dispatch(setAppSettings(updatedSettings));
            try {
                const response = await axios.post(
                    `/api/post/app-settings`,
                    {
                        timeZone: null,
                        currency: null,
                        language: null,
                        timerLock: lockTime,
                        timerLockTime: null,
                        seoTitle: null,
                        metaDescription: null,
                        weekStartDay: null,
                        appId: appSettings && appSettings.appId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("Time lock has been updated successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                refetch();
            } catch (error) {
                console.log(error)
                global.config.slackMessage(error.toString());
            }
        }
    }

    const setLockTimerTimeUpdate = async (lockTime) => {
        setSelectedTime(lockTime);
        const updatedSettings = { ...appSettings, timerLockTime: selectedTime };
        await dispatch(setAppSettings(updatedSettings));
        if (lockTime) {

            try {
                const response = await axios.post(
                    `/api/post/app-settings`,
                    {
                        timeZone: null,
                        currency: null,
                        language: null,
                        timerLock: null,
                        timerLockTime: lockTime,
                        seoTitle: null,
                        metaDescription: null,
                        weekStartDay: null,
                        appId: appSettings && appSettings.appId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("Time lock has been updated successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                refetch();
            } catch (error) {
                console.log(error)
                global.config.slackMessage(error.toString());
            }
        }
    }

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
        setLockTimertimeDropdownOpen(false); // Exit edit mode after selecting a time
        // Call function to update the time
        setLockTimerTimeUpdate(time); // This function should be defined elsewhere
    };

    const toggleLockTimer = () => {
        setLockTimertimeDropdownOpen(!lockTimertimeDropdownOpen);
    };

    const handleEnableUserCostVisibilityStatusChange = async (id) => {
        try {
            setIsUserCostVisibilityStatusLoading(true);

            const response = await axios.get(`/api/app-settings/user/cost/visibility/status-change/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const updatedVisibility = !userCostVisibility;
            setUserCostVisibility(updatedVisibility);

            const updatedSettings = { ...appSettings, app_user_cost_visibility: updatedVisibility };
            await dispatch(setAppSettings(updatedSettings));

            toast.success("App user cost visibility updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            refetch();
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                handleDeletedUser();
                return;
            }

            handleError(error);
        } finally {
            setIsUserCostVisibilityStatusLoading(false);
        }
    };

    const handleDeletedUser = () => {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());

        toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        });

        navigate("/");
    };

    const handleError = (error) => {
        global.config.slackMessage(error.toString());

        toast.error("Something went wrong in changing user cost visibility status!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        global.config.activityLog(
            window.location.href,
            "User",
            `Something went wrong while changing app "${appSettings?.appId}'s" user cost visibility status!!`
        );

        console.error("Error changing cost visibility status:", error);
    };

    const handleTimeThresholdShow = () => {
        setTimeThresholdEdit(timeThreshold);
        setShowTimeThresholdModal(true);
    }

    const handleTimeThresholdClose = () => {
        setTimeThresholdEdit("");
        setShowTimeThresholdModal(false);
    };

    const handleTimeThresholdSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);

        try {
            const response = await axios.put(`/api/general/thresholds/update/${timeThresholdData?.data?._id}`, {
                thresholdType: "time",
                thresholdPercentage: timeThresholdEdit,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                setTimeThreshold(timeThresholdEdit);
                refetchTimeThreshold();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleTimeThresholdClose();
        }
    };

    const handleTimeThresholdUserModalShow = () => {
        setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeams);
        setShowTimeThresholdUserModal(true);
    }

    const handleTimeThresholdUserModalClose = () => {
        setSelectedTimeThresholdTeamsEdit([]);
        setShowTimeThresholdUserModal(false);
    }

    const toggleTimeThresholdTeamDropdown = () => {
        setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
    };

    const handleCheckboxTimeThresholdTeamChange = (teamId) => {
        const updatedSelection = [...selectedTimeThresholdTeamsEdit];
        const index = updatedSelection.indexOf(teamId);

        if (index === -1) {
            // Add to selection
            updatedSelection.push(teamId);
        } else {
            // Remove from selection
            updatedSelection.splice(index, 1);
        }
        setSelectedTimeThresholdTeamsEdit(updatedSelection);
    };

    const handleSelectTimeThresholdAllTeamChange = () => {
        if (!selectTimeThresholdAllTeam) {
            setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
        } else {
            setSelectedTimeThresholdTeamsEdit([]);
        }
        setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
    };

    const handleTimeThresholdSelectByRole = (role) => {
        const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
        setSelectedTimeThresholdTeamsEdit((prev) => {
            if (filteredUsers.every((id) => prev.includes(id))) {
                return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
            } else {
                return [...new Set([...prev, ...filteredUsers])];
            }
        });
    };

    useEffect(() => {
        const updateSelectionStatus = () => {
            setSelectTimeThresholdAllTeam(selectedTimeThresholdTeamsEdit.length === teams.length);
            // if (selectedTimeThresholdTeamsEdit.length === teams.length) {
            //     setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
            // } else {
            //     setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
            // }
        };

        // Call the updateSelectionStatus asynchronously
        Promise.resolve().then(() => updateSelectionStatus());
    }, [selectedTimeThresholdTeamsEdit, teams]);

    const handleTimeThresholdUserSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);

        try {
            const response = await axios.put(`/api/general/thresholds/update/${timeThresholdData?.data?._id}`, {
                thresholdType: "time",
                alertTo: selectedTimeThresholdTeamsEdit,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                setSelectedTimeThresholdTeams(selectedTimeThresholdTeamsEdit);
                refetchTimeThreshold();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleTimeThresholdUserModalClose();
        }
    };

    const handleBudgetThresholdShow = () => {
        setBudgetThresholdEdit(budgetThreshold);
        setShowBudgetThresholdModal(true);
    }

    const handleBudgetThresholdClose = () => {
        setBudgetThresholdEdit("");
        setShowBudgetThresholdModal(false);
    };

    const handleBudgetThresholdSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);

        try {
            const response = await axios.put(`/api/general/thresholds/update/${budgetThresholdData?.data?._id}`, {
                thresholdType: "budget",
                thresholdPercentage: budgetThresholdEdit,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                setBudgetThreshold(budgetThresholdEdit);
                refetchBudgetThreshold();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleBudgetThresholdClose();
        }
    };

    const handleBudgetThresholdUserModalShow = () => {
        setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeams);
        setShowBudgetThresholdUserModal(true);
    }

    const handleBudgetThresholdUserModalClose = () => {
        setSelectedBudgetThresholdTeamsEdit([]);
        setShowBudgetThresholdUserModal(false);
    }

    const toggleBudgetThresholdTeamDropdown = () => {
        setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
    };

    const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
        const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
        const index = updatedSelection.indexOf(teamId);

        if (index === -1) {
            // Add to selection
            updatedSelection.push(teamId);
        } else {
            // Remove from selection
            updatedSelection.splice(index, 1);
        }
        setSelectedBudgetThresholdTeamsEdit(updatedSelection);
    };

    const handleSelectBudgetThresholdAllTeamChange = () => {
        if (!selectBudgetThresholdAllTeam) {
            setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
        } else {
            setSelectedBudgetThresholdTeamsEdit([]);
        }
        setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
    };

    const handleBudgetThresholdSelectByRole = (role) => {
        const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
        setSelectedBudgetThresholdTeamsEdit((prev) => {
            if (filteredUsers.every((id) => prev.includes(id))) {
                return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
            } else {
                return [...new Set([...prev, ...filteredUsers])];
            }
        });
    };

    useEffect(() => {
        const updateSelectionStatus = () => {
            setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
            // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
            //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
            // } else {
            //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
            // }
        };

        // Call the updateSelectionStatus asynchronously
        Promise.resolve().then(() => updateSelectionStatus());
    }, [selectedBudgetThresholdTeamsEdit, teams]);

    const handleBudgetThresholdUserSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);

        try {
            const response = await axios.put(`/api/general/thresholds/update/${budgetThresholdData?.data?._id}`, {
                thresholdType: "budget",
                alertTo: selectedBudgetThresholdTeamsEdit,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                setSelectedBudgetThresholdTeams(selectedBudgetThresholdTeamsEdit);
                refetchBudgetThreshold();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleBudgetThresholdUserModalClose();
        }
    };

    const CodeToCurrency = (code) => {

        var currencyCode = code ? code : 'USD';

        if (currencyCode !== "undefined") {

            var currencyCode = currencyCodes.code(currencyCode);

        }

        return currencyCode;
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (
        <div>
            <div>
                <div>
                    <div className="security-outer noti-outer">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'Timezone_and_Language')}</span>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Timezone')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={timezoneDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${timezoneDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleTimezoneDropdown}>
                                            {selectedTimezone ? selectedTimezone : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Timezone') : null)}
                                        </div>
                                        {timezoneDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search_Timezone')}
                                                    value={timeZoneSearchTerm}
                                                    onChange={handleTimeZoneFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredTimeZone.sort((a, b) => a.localeCompare(b)).map((timeZone, index) => (
                                                        <li onClick={() => selectTimezoneOption(timeZone)} key={index}>{timeZone}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Currency')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={currencyDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${currencyDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleCurrencyDropdown}>
                                            {selectedCurrency ? selectedCurrency : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Currency') : null)}
                                        </div>
                                        {currencyDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search_Currency')}
                                                    value={currencySearchTerm}
                                                    onChange={handleCurrencyFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredCurrencies
                                                        .reduce((uniqueCurrencies, currency) => {
                                                            const currencyName = codeToCurrency(currency.currency && currency.currency);
                                                            if (!uniqueCurrencies.find(item => item.name === currencyName)) {
                                                                uniqueCurrencies.push({ name: currencyName, currency: currency.currency }); // Push the currency object along with its name
                                                            }
                                                            return uniqueCurrencies;
                                                        }, [])
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((currency, index) => (
                                                            <li onClick={() => selectCurrencyOption(currency.currency)} key={index}>
                                                                {currency.name}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Language')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={languageDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${languageDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleLanguageDropdown}>
                                            {selectedLanguage ? selectedLanguage : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Language') : null)}
                                        </div>
                                        {languageDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <ul className="type-list">
                                                    <li onClick={() => selectLanguageOption('en')}>{global.config.locate(appSettings && appSettings.language, 'English')}</li>
                                                    <li onClick={() => selectLanguageOption('es')}>{global.config.locate(appSettings && appSettings.language, 'Spanish')}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                                <span className="custom-arrow"></span>
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Work week start day')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={weekStartDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${weekStartDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleWeekStartDropdown}>
                                            {selectedWeekStart ? selectedWeekStart : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Week_Start_Day') : null)}
                                        </div>
                                        {weekStartDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <ul className="type-list">
                                                    <li onClick={() => selectWeekStartOption('Sunday')}>{global.config.locate(appSettings && appSettings.language, 'Sunday')}</li>
                                                    <li onClick={() => selectWeekStartOption('Monday')}>{global.config.locate(appSettings && appSettings.language, 'Monday')}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                                <span className="custom-arrow"></span>
                            </div>
                        </div>
                    </div>

                    <div className="security-outer noti-outer tracker-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'Time_Tracking')}</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Lock_Time')}
                            </span>
                            <div className="tracker-setting-outer">
                                <div className="custom-dropdown">
                                    <div ref={lockTimeDropdownRef} className="custom-dropdown-user-new-outer custom-dropdown-select default-time-lesser">
                                        <div className="selected-option custom-dropdown-user-new default-time-lesser-inner">
                                            {global.config.locate(appSettings && appSettings.language, 'Older_Than')}
                                        </div>
                                    </div>
                                </div>

                                <div className="number-of">
                                    <div className="number-of-input" >
                                        <input type="number" min="0" onChange={(e) => setLockTimeUpdate(e.target.value)} value={locktime} onWheel={numberInputOnWheelPreventChange} />
                                        <div className="day-count">{locktime > 1 ? global.config.locate((appSettings && appSettings.language), 'Days') : global.config.locate((appSettings && appSettings.language), 'Day')}</div>
                                        <div className="at">{global.config.locate(appSettings && appSettings.language, 'at')}</div>
                                        <div className="lock-time-hour">
                                            <div ref={lockTimerTimeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${lockTimertimeDropdownOpen ? 'open' : ''}`}>
                                                <div className="selected-option custom-dropdown-user-new" onClick={toggleLockTimer}>
                                                    {selectedTime ? selectedTime : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Lock_Time') : null)}
                                                </div>
                                                {lockTimertimeDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <ul className="time-list">
                                                            {timeList.map((time) => (
                                                                <li
                                                                    key={time.time_value}
                                                                    className={selectedTime === time.time ? 'selected-item' : ''}
                                                                    onClick={() => handleTimeSelection(time.time)}
                                                                >
                                                                    {time.time}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="security-outer noti-outer tracker-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'User_Cost')}</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Allow project managers to see cost per user')}
                            </span>
                            <div className="accordion-right">
                                {isUserCostVisibilityStatusLoading === true && (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}
                                {isLoading === true ? (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                ) : (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="userCostVisibility"
                                            name="userCostVisibility"
                                            value="1"
                                            checked={userCostVisibility === true}
                                            onChange={() => handleEnableUserCostVisibilityStatusChange(appSettings.appId)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="security-outer noti-outer tracker-settings" >
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">Alerts</span>
                        </div>


                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Default alert for time
                            </span>
                            <div className="tracker-setting-outer">
                                <div className="custom-dropdown">
                                    {/* <div ref={lockTimeDropdownRef} className="custom-dropdown-user-new-outer custom-dropdown-select default-time-lesser">
                                        <div className="selected-option custom-dropdown-user-new default-time-lesser-inner">
                                            {global.config.locate(appSettings && appSettings.language, 'Older_Than')}
                                        </div>
                                    </div> */}
                                </div>

                                <div className="number-of">
                                    <div className="number-of-input" >
                                        <span className="custom-switch-description threashold-count-user-outer">
                                            If project reaches{" "}
                                            <a onClick={handleTimeThresholdShow}>
                                                {timeThresholdLoading ? (
                                                    <div className="outter-load-table">
                                                        <ThreeDots
                                                            height="18"
                                                            width="20"
                                                            radius="9"
                                                            color="#6479f9"
                                                            ariaLabel="three-dots-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (timeThreshold)}%
                                            </a>{" "}
                                            of estimate, alert{" "}
                                            <a className="threashold-count-user" onClick={handleTimeThresholdUserModalShow}>
                                                {timeThresholdLoading ? (
                                                    <div className="outter-load-table">
                                                        <ThreeDots
                                                            height="18"
                                                            width="20"
                                                            radius="9"
                                                            color="#6479f9"
                                                            ariaLabel="three-dots-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (() => {
                                                    const roles = {
                                                        Super_Admin: "All Super Admin",
                                                        Admin: "All Admin",
                                                        Project_Manager: "All Project Manager",
                                                        Team_Manager: "All Team Manager",
                                                        User: "All Users"
                                                    };

                                                    // Determine if all users of a specific role are selected
                                                    for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                        const roleUsers = teams
                                                            .filter((team) => team.role_id === userType[roleKey])
                                                            .map((team) => team._id);

                                                        if (
                                                            roleUsers.length > 0 &&
                                                            roleUsers.every((id) => selectedTimeThresholdTeams.includes(id)) &&
                                                            selectedTimeThresholdTeams.length === roleUsers.length
                                                        ) {
                                                            return roleLabel;
                                                        }
                                                    }

                                                    // If all team members are selected
                                                    if (selectedTimeThresholdTeams.length === teams.length) {
                                                        return "All Team";
                                                    }

                                                    // Display first selected user and count if more users are selected
                                                    const firstSelectedUser =
                                                        teams.find((item) => item._id === selectedTimeThresholdTeams[0])
                                                            ? (() => {
                                                                const user = teams.find((item) => item._id === selectedTimeThresholdTeams[0]);

                                                                return user.first_name
                                                                    ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                    : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                            })()
                                                            : "No Users selected";

                                                    return (
                                                        <>
                                                            {firstSelectedUser}
                                                            {selectedTimeThresholdTeams.length > 1 && (
                                                                <div className="selected-option-count">
                                                                    {selectedTimeThresholdTeams.length - 1}+
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                })()}
                                            </a>
                                        </span>
                                        <Modal
                                            className="select-users-alert"
                                            isOpen={showTimeThresholdModal}
                                            onRequestClose={handleTimeThresholdClose}
                                            contentLabel="Set Threshold"
                                            style={{
                                                content: {
                                                    height: "41%",
                                                    width: "30%",
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-25%, -50%)",
                                                    parent: document.querySelector(".admin-outer.time.tracker"),
                                                },
                                            }}
                                        >
                                            <div className="modal">
                                                <div className="modal-close-invite outter-modal-main">
                                                    <a className="" onClick={handleTimeThresholdClose}>
                                                        <img src={crssrImage} alt="Edit Icon" />{" "}
                                                    </a>
                                                </div>
                                                <div className="modal-client-head">
                                                    <h2 className="">
                                                        Set Threshold
                                                    </h2>
                                                </div>
                                                <div className="form-field-client-outer">
                                                    <form onSubmit={handleTimeThresholdSubmit}>
                                                        <div className="form-field-client">
                                                            <label htmlFor="clientName" className="form-label">
                                                                Threshold Percentage
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id="clientName"
                                                                name="clientName"
                                                                className="form-input"
                                                                value={timeThresholdEdit}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                                                        setTimeThresholdEdit(value);
                                                                    }
                                                                }}
                                                                onWheel={numberInputOnWheelPreventChange}
                                                                min="0"
                                                                max="100"
                                                                step="1"
                                                                required
                                                            />
                                                        </div>
                                                        {isSubmitLoading ? (
                                                            <div className="outter-load-table">
                                                                <ThreeDots
                                                                    height="38"
                                                                    width="40"
                                                                    radius="9"
                                                                    color="#6479f9"
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClassName=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="form-field-client-btns">
                                                                <button
                                                                    type="submit"
                                                                    className="save-changes"
                                                                    disabled={isSubmitLoading}
                                                                >
                                                                    {global.config.locate(
                                                                        appSettings && appSettings.language,
                                                                        "Save_changes"
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            className="select-users-alert"
                                            isOpen={showTimeThresholdUserModal}
                                            onRequestClose={handleTimeThresholdUserModalClose}
                                            contentLabel="Select Users"
                                            style={{
                                                content: {
                                                    height: "41%",
                                                    width: "30%",
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-25%, -50%)",
                                                },
                                            }}
                                        >
                                            <div className="modal">
                                                <div className="modal-close-invite outter-modal-main">
                                                    <a className="" onClick={handleTimeThresholdUserModalClose}>
                                                        <img src={crssrImage} alt="Edit Icon" />{" "}
                                                    </a>
                                                </div>
                                                <div className="modal-client-head">
                                                    <h2>Select Users</h2>
                                                </div>
                                                <div className="form-field-client-outer">
                                                    <form onSubmit={handleTimeThresholdUserSubmit}>
                                                        <div className="filter-inner invoice-status">
                                                            <label htmlFor="user">User</label>
                                                            <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                                                                <div className="drop-down-inner">
                                                                    <div className="selected-options1" onClick={toggleTimeThresholdTeamDropdown}>
                                                                        {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                                                            <div className="placeholder">Select</div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="selected-option1">
                                                                                    {selectedTimeThresholdTeamsEdit.length === teams.length ? (
                                                                                        "All"
                                                                                    ) : (
                                                                                        <>
                                                                                            {/* {teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])?.first_name || "User"}
                                                                                            {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                                                                <div className="selected-option-count">
                                                                                                    {selectedTimeThresholdTeamsEdit.length - 1}+
                                                                                                </div>
                                                                                            )} */}
                                                                                            {(() => {
                                                                                                const roles = {
                                                                                                    Super_Admin: "All Super Admin",
                                                                                                    Admin: "All Admin",
                                                                                                    Project_Manager: "All Project Manager",
                                                                                                    Team_Manager: "All Team Manager",
                                                                                                    User: "All Users"
                                                                                                };

                                                                                                for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                                                                    const roleUsers = teams
                                                                                                        .filter((team) => team.role_id === userType[roleKey])
                                                                                                        .map((team) => team._id);

                                                                                                    if (
                                                                                                        roleUsers?.length > 0 &&
                                                                                                        roleUsers?.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                                                                                        selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                                                                                    ) {
                                                                                                        return roleLabel;
                                                                                                    }
                                                                                                }

                                                                                                if (selectedTimeThresholdTeamsEdit?.length === teams.length) {
                                                                                                    return "All Team";
                                                                                                }

                                                                                                const firstSelectedUser = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                                                                                    ? (() => {
                                                                                                        const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);
                                                                                                        return user.first_name
                                                                                                            ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                                                            : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                                                                    })()
                                                                                                    : "No Users selected";

                                                                                                return (
                                                                                                    <>
                                                                                                        {firstSelectedUser}
                                                                                                        {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                                                                            <div className="selected-option-count">
                                                                                                                {selectedTimeThresholdTeamsEdit.length - 1}+
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                );
                                                                                            })()}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    {isTimeThresholdTeamDropdownOpen && (
                                                                        <div className="dropdown-list1">
                                                                            <label className="checkbox-label1" key="selectAll">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={selectTimeThresholdAllTeam}
                                                                                    onChange={handleSelectTimeThresholdAllTeamChange}
                                                                                />
                                                                                All Teams
                                                                            </label>
                                                                            {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                                                                .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                                                                .map((role) => (
                                                                                    <label className="checkbox-label1" key={role}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={teams
                                                                                                .filter((team) => team.role_id === userType[role])
                                                                                                .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                                                                            onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                                                                        />
                                                                                        All {role.replace("_", " ")}
                                                                                    </label>
                                                                                ))}
                                                                            {teams.map((team) => (
                                                                                <label className="checkbox-label1" key={team._id}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                                                                        onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                                                                    />
                                                                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                                                                </label>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-field-client-btns">
                                                            <button type="submit" className="save-changes">
                                                                {isSubmitLoading ? (
                                                                    <div className="outter-load-table">
                                                                        <ThreeDots
                                                                            height="25"
                                                                            width="25"
                                                                            radius="9"
                                                                            color="white"
                                                                            ariaLabel="three-dots-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClassName=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    "Save Changes"
                                                                )}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Default alert for budget
                            </span>
                            <div className="tracker-setting-outer">
                                <div className="custom-dropdown">
                                    {/* <div ref={lockTimeDropdownRef} className="custom-dropdown-user-new-outer custom-dropdown-select default-time-lesser">
                                        <div className="selected-option custom-dropdown-user-new default-time-lesser-inner">
                                            {global.config.locate(appSettings && appSettings.language, 'Older_Than')}
                                        </div>
                                    </div> */}
                                </div>

                                <div className="number-of">
                                    <div className="number-of-input" >
                                        <span className="custom-switch-description threashold-count-user-outer">
                                            If project reaches{" "}
                                            <a onClick={handleBudgetThresholdShow}>
                                                {budgetThresholdLoading ? (
                                                    <div className="outter-load-table">
                                                        <ThreeDots
                                                            height="18"
                                                            width="20"
                                                            radius="9"
                                                            color="#6479f9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (budgetThreshold)}%
                                            </a>{" "}
                                            of estimate, alert {" "}
                                            <a className="threashold-count-user" onClick={handleBudgetThresholdUserModalShow}>
                                                {budgetThresholdLoading ? (
                                                    <div className="outter-load-table">
                                                        <ThreeDots
                                                            height="18"
                                                            width="20"
                                                            radius="9"
                                                            color="#6479f9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (() => {
                                                    const roles = {
                                                        Super_Admin: "All Super Admin",
                                                        Admin: "All Admin",
                                                        Project_Manager: "All Project Manager",
                                                        Team_Manager: "All Team Manager",
                                                        User: "All Users"
                                                    };

                                                    // Check if all users of a specific role are selected
                                                    for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                        const roleUsers = teams.filter((team) => team.role_id === userType[roleKey]).map((team) => team._id);
                                                        if (roleUsers.length > 0 && roleUsers.every((id) => selectedBudgetThresholdTeams.includes(id)) && selectedBudgetThresholdTeams.length === roleUsers.length) {
                                                            return roleLabel;
                                                        }
                                                    }

                                                    if (selectedBudgetThresholdTeams.length === teams.length) {
                                                        return "All Team";
                                                    }

                                                    const firstSelectedUser = teams.find((item) => item._id === selectedBudgetThresholdTeams[0])
                                                        ? (() => {
                                                            const user = teams.find((item) => item._id === selectedBudgetThresholdTeams[0]);
                                                            return user.first_name
                                                                ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                        })()
                                                        : "No Users selected";

                                                    return (
                                                        <>
                                                            {firstSelectedUser}
                                                            {selectedBudgetThresholdTeams.length > 1 && (
                                                                <div className="selected-option-count">
                                                                    {selectedBudgetThresholdTeams.length - 1}+
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                })()}
                                            </a>
                                        </span>
                                        <Modal
                                            className="select-users-alert"
                                            isOpen={showBudgetThresholdModal}
                                            onRequestClose={handleBudgetThresholdClose}
                                            contentLabel="Set Threshold"
                                            style={{
                                                content: {
                                                    height: "41%",
                                                    width: "30%",
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-25%, -50%)",
                                                    parent: document.querySelector(".admin-outer.time.tracker"),
                                                },
                                            }}
                                        >
                                            <div className="modal">
                                                <div className="modal-close-invite outter-modal-main">
                                                    <a className="" onClick={handleBudgetThresholdClose}>
                                                        <img src={crssrImage} alt="Edit Icon" />{" "}
                                                    </a>
                                                </div>
                                                <div className="modal-client-head">
                                                    <h2 className="">
                                                        Set Threshold
                                                    </h2>
                                                </div>
                                                <div className="form-field-client-outer">
                                                    <form onSubmit={handleBudgetThresholdSubmit}>
                                                        <div className="form-field-client">
                                                            <label htmlFor="clientName" className="form-label">
                                                                Threshold Percentage
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id="clientName"
                                                                name="clientName"
                                                                className="form-input"
                                                                value={budgetThresholdEdit}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                                                        setBudgetThresholdEdit(value);
                                                                    }
                                                                }}
                                                                onWheel={numberInputOnWheelPreventChange}
                                                                min="0"
                                                                max="100"
                                                                step="1"
                                                                required
                                                            />
                                                        </div>
                                                        {isSubmitLoading ? (
                                                            <div className="outter-load-table">
                                                                <ThreeDots
                                                                    height="38"
                                                                    width="40"
                                                                    radius="9"
                                                                    color="#6479f9"
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClassName=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="form-field-client-btns">
                                                                <button
                                                                    type="submit"
                                                                    className="save-changes"
                                                                    disabled={isSubmitLoading}
                                                                >
                                                                    {global.config.locate(
                                                                        appSettings && appSettings.language,
                                                                        "Save_changes"
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            className="select-users-alert"
                                            isOpen={showBudgetThresholdUserModal}
                                            onRequestClose={handleBudgetThresholdUserModalClose}
                                            contentLabel="Select Users"
                                            style={{
                                                content: {
                                                    height: "41%",
                                                    width: "30%",
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-25%, -50%)",
                                                },
                                            }}
                                        >
                                            <div className="modal">
                                                <div className="modal-close-invite outter-modal-main">
                                                    <a className="" onClick={handleBudgetThresholdUserModalClose}>
                                                        <img src={crssrImage} alt="Edit Icon" />{" "}
                                                    </a>
                                                </div>
                                                <div className="modal-client-head">
                                                    <h2>Select Users</h2>
                                                </div>
                                                <div className="form-field-client-outer">
                                                    <form onSubmit={handleBudgetThresholdUserSubmit}>
                                                        <div className="filter-inner invoice-status">
                                                            <label htmlFor="user">User</label>
                                                            <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                                                                <div className="drop-down-inner">
                                                                    <div className="selected-options1" onClick={toggleBudgetThresholdTeamDropdown}>
                                                                        {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                                                            <div className="placeholder">Select</div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="selected-option1">
                                                                                    {selectedBudgetThresholdTeamsEdit.length === teams.length ? (
                                                                                        "All"
                                                                                    ) : (
                                                                                        <>
                                                                                            {/* {teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])?.first_name || "User"}
                                                                                            {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                                                                <div className="selected-option-count">
                                                                                                    {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                                                                </div>
                                                                                            )} */}
                                                                                            {(() => {
                                                                                                const roles = {
                                                                                                    Super_Admin: "All Super Admin",
                                                                                                    Admin: "All Admin",
                                                                                                    Project_Manager: "All Project Manager",
                                                                                                    Team_Manager: "All Team Manager",
                                                                                                    User: "All Users"
                                                                                                };

                                                                                                for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                                                                    const roleUsers = teams
                                                                                                        .filter((team) => team.role_id === userType[roleKey])
                                                                                                        .map((team) => team._id);

                                                                                                    if (
                                                                                                        roleUsers?.length > 0 &&
                                                                                                        roleUsers?.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                                                                        selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                                                                    ) {
                                                                                                        return roleLabel;
                                                                                                    }
                                                                                                }

                                                                                                if (selectedBudgetThresholdTeamsEdit?.length === teams.length) {
                                                                                                    return "All Team";
                                                                                                }

                                                                                                const firstSelectedUser = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                                                                    ? (() => {
                                                                                                        const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);
                                                                                                        return user.first_name
                                                                                                            ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                                                            : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                                                                    })()
                                                                                                    : "No Users selected";

                                                                                                return (
                                                                                                    <>
                                                                                                        {firstSelectedUser}
                                                                                                        {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                                                                            <div className="selected-option-count">
                                                                                                                {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                );
                                                                                            })()}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    {isBudgetThresholdTeamDropdownOpen && (
                                                                        <div className="dropdown-list1">
                                                                            <label className="checkbox-label1" key="selectAll">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={selectBudgetThresholdAllTeam}
                                                                                    onChange={handleSelectBudgetThresholdAllTeamChange}
                                                                                />
                                                                                All Teams
                                                                            </label>
                                                                            {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                                                                .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                                                                .map((role) => (
                                                                                    <label className="checkbox-label1" key={role}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={teams
                                                                                                .filter((team) => team.role_id === userType[role])
                                                                                                .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                                                                            onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                                                                        />
                                                                                        All {role.replace("_", " ")}
                                                                                    </label>
                                                                                ))}
                                                                            {teams.map((team) => (
                                                                                <label className="checkbox-label1" key={team._id}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                                                                        onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                                                                    />
                                                                                    {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                                                                </label>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-field-client-btns">
                                                            <button type="submit" className="save-changes">
                                                                {isSubmitLoading ? (
                                                                    <div className="outter-load-table">
                                                                        <ThreeDots
                                                                            height="25"
                                                                            width="25"
                                                                            radius="9"
                                                                            color="white"
                                                                            ariaLabel="three-dots-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClassName=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    "Save Changes"
                                                                )}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="security-outer noti-outer seo-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'SEO')}</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'SEO_Title')}
                            </span>
                            <div className="seo-input" >
                                <input type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="Text here" value={seoTitle} onPaste={(e) => setSeoTitle(e.clipboardData.getData('text'))} onChange={(e) => setSeoTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Meta_Description')}
                            </span>
                            <div className="seo-input">
                                <textarea type="text" style={{ border: seoDescription === "" ? "1px solid red" : "", backgroundColor: seoDescription === "" ? "#fff6f6" : "" }} value={seoDescription} onPaste={(e) => setSeoDescription(e.clipboardData.getData('text'))} onChange={(e) => setSeoDescription(e.target.value)} placeholder="Text here" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralSettings;