import React, { useState, createRef, useEffect } from "react";
import { useQuery } from "react-query";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import axios from "axios";
import Calendar from "./components/Calendar";
import GradualNumberLoader from "./GradualNumberLoader";
import SmoothLoadMap from "./SmoothLoadMap";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import Modal from "react-modal";
import Config from "./config";

const items = Array.from({ length: 100 }, (_, i) => `Item ${i + 1}`); // Sample data

Modal.setAppElement("#root"); 

const fetchLeaves = async () => {
    try {
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZDA5NzJjMmEzZDVkMGNiNTY1ZGRkNCIsImlhdCI6MTc0MDk3OTIzOCwiZXhwIjoxNzQzNTcxMjM4fQ.Gl4O-DB6fj080wekYvWX7j7A9br4siB4HGn9-tuCRFw";
        if (!token) throw new Error("No token found in localStorage");

        const response = await axios.get("http://localhost:8090/api/time-off-request/view", {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

        console.log("API Response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching leave data:", error.response ? error.response.data : error.message);
        throw new Error("Failed to fetch leave data");
    }
};

const groupEventsByDate = (events) => {
    const groupedEvents = {};

    events.forEach((event) => {
        if (!groupedEvents[event.date]) {
            groupedEvents[event.date] = [];
        }
        groupedEvents[event.date].push(event);
    });

    return Object.keys(groupedEvents).map((date) => ({
        title: groupedEvents[date].length > 1 ? `+${groupedEvents[date].length} leaves` : groupedEvents[date][0].title,
        date,
        backgroundColor: groupedEvents[date][0].color || "red",
        textColor: "white",
        extendedProps: { allEvents: groupedEvents[date] }, // Store all events in extendedProps
    }));
};

const Test = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [expandedEventIndex, setExpandedEventIndex] = useState(null);
    const dummyImage = "http://test.localhost:3000/uploads/lowProfileImage/user_default.png"; // Replace with your dummy image URL

    const { data: leaves, isLoading, error } = useQuery("leaves", fetchLeaves);

    const uniqueBirthdays = new Set(); // Moved outside

    const events = leaves ? leaves.map(leave => {
        const dob = new Date(leave.user.dob);
        const currentYear = new Date().getFullYear();
        const birthday = leave.user.dob ? new Date(Date.UTC(currentYear, dob.getMonth(), dob.getDate())) : null;
        const birthdayInIST = birthday ? new Date(birthday.toLocaleString("en-US", { timeZone: "Asia/Calcutta" })).toISOString().split("T")[0] : null;

        let birthdayEvent = null;

        if (birthdayInIST && !uniqueBirthdays.has(birthdayInIST)) {
            birthdayEvent = {
                title: `${leave.user.first_name} ${leave.user.middle_name} ${leave.user.last_name}'s Birthday 🎂`,
                date: birthdayInIST,
                color: "blue",
                textColor: "white",
                profileImage: leave.user.profile_image ? `./uploads/lowProfileImage/${leave.user.profile_image}` : dummyImage,
                description: "Happy Birthday!"
            };
            uniqueBirthdays.add(birthdayInIST);
        }

        const leaveEvents = [];
        let currentDate = new Date(leave.start_date);
        const endDate = new Date(leave.end_date);

        while (currentDate <= endDate) {
            leaveEvents.push({
                title: `${leave.user.first_name} ${leave.user.middle_name} ${leave.user.last_name} - ${leave.type_of_leave.leave_type}`,
                date: new Date(currentDate.toLocaleString("en-US", { timeZone: "Asia/Calcutta" })).toISOString().split("T")[0], // Extracting only date
                color: leave.status === "Pending" ? "orange" : "green",
                profileImage: leave.user.profile_image ? `./uploads/lowProfileImage/${leave.user.profile_image}` : dummyImage,
                description: leave.note
            });
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return [...(birthdayEvent ? [birthdayEvent] : []), ...leaveEvents];
    }).flat() : [];

    const handleEventClick = (clickInfo) => {
        setSelectedEvents([]);
        setModalIsOpen(true);
        setTimeout(() => {
            setSelectedEvents(clickInfo.event.extendedProps.allEvents || []);
        }, 200); // 2-second delay before showing the data
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching data: {error.message}</div>;

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={groupEventsByDate(events)} // This will include both leave and birthday events
                eventClick={handleEventClick}
            />

            {/* Modal for showing event details */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        width: "300px",
                        maxWidth: "90%",
                        margin: "auto",
                        padding: "10px",
                        textAlign: "center",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        opacity: modalIsOpen ? 1 : 0,
                        transform: modalIsOpen ? "translateY(0)" : "translateY(-20px)",
                        transition: "opacity 0.3s ease, transform 0.3s ease"
                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: modalIsOpen ? 1 : 0,
                        transition: "opacity 0.3s ease"
                    }
                }}
            >
                {selectedEvents.length > 0 && (
                    <>
                        <h2 style={{ fontSize: "16px", marginBottom: "15px" }}>
                            Events on {selectedEvents[0].date}
                        </h2>
                        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                            {selectedEvents.map((event, index) => (
                                <div key={index} style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    gap: "5px",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    backgroundColor: "#f5f5f5",
                                    width: "100%"
                                }}>
                                    <div 
                                        style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", cursor: event.title.includes("🎂") ? "default" : "pointer" }}
                                        onClick={() => event.title.includes("🎂") ? null : setExpandedEventIndex(expandedEventIndex === index ? null : index)}
                                    >
                                        <img 
                                            src={event.profileImage} 
                                            alt="Profile" 
                                            style={{ width: 40, height: 40, borderRadius: "50%" }}
                                            onError={(e) => { e.target.onerror = null; e.target.src = dummyImage; }}
                                        />
                                        <p style={{ fontSize: "14px", margin: 0, flexGrow: 1 }}>{event.title}</p>
                                        {!event.title.includes("🎂") && <span>{expandedEventIndex === index ? "▲" : "▼"}</span>}
                                    </div>
                                    {!event.title.includes("🎂") && expandedEventIndex === index && (
                                        <div style={{
                                            marginTop: "5px",
                                            padding: "5px",
                                            backgroundColor: "#e0e0e0",
                                            borderRadius: "5px",
                                            width: "100%"
                                        }}>
                                            <p style={{ fontSize: "12px", margin: 0 }}>{event.description || "No description available"}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                            <button 
                                onClick={() => setModalIsOpen(false)} 
                                style={{
                                    padding: "6px 12px",
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Test;