import React from 'react';
import moment from 'moment';

const TimeTotal = (item) => {

    console.log("item",item);
    var tot = 0;
    item['item'][1].map((item) => {
        tot = tot + item.duration;
    })
    const formattedTime = moment.utc(parseInt(tot) * 1000).format("HH:mm:ss");
    return formattedTime;
};

export default TimeTotal;