import "./GeneralSettings.css"
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment-timezone';
import { getAllISOCodes } from 'iso-country-currency';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from "react-toastify";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import currencyCodes from "currency-codes";
import { ThreeDots } from "react-loader-spinner";

const Integrations = (props) => {
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const currencyDropdownRef = useRef(null);
    const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const timezoneDropdownRef = useRef(null);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const languageDropdownRef = useRef(null);
    const [weekStartDropdownOpen, setWeekStartDropdownOpen] = useState(false);
    const [selectedWeekStart, setSelectedWeekStart] = useState(null);
    const weekStartDropdownRef = useRef(null);
    const [lockTimeDropdownOpen, setLockTimeDropdownOpen] = useState(false);
    const [selectedLockTime, setSelectedLockTime] = useState(null);
    const lockTimeDropdownRef = useRef(null);
    const [timeZoneSearchTerm, setTimeZoneSearchTerm] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [currencySearchTerm, setCurrencySearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [locktime, setLocktime] = useState(2);
    const [lockTimertimeDropdownOpen, setLockTimertimeDropdownOpen] = useState(false);
    const [selectedTime, setSelectedTime] = useState('11:00 p.m');
    const [seoTitle, setAppSeoTitle] = useState(null);

    const [googleDriveClientId, setGoogleDriveClientId] = useState(null);
    const [googleDriveDeveloperKey, setGoogleDriveDeveloperKey] = useState(null);
    const [dropBoxClientId, setDropBoxClientId] = useState(null);

    const [seoDescription, setAppSeoDescription] = useState(null);
    const [apiToken,setApiToken] = useState(null);
    const dispatch = useDispatch();
    const lockTimerTimeDropdownRef = useRef(null);
    const [buttonText, setButtonText] = useState("Copy");
    // const locale = global.config.locate();

    const timeList = [
        {
            time_value: 0,
            time: "12:00 AM",
        },
        {
            time_value: 0.5,
            time: "12:30 AM",
        },
        {
            time_value: 1,
            time: "01:00 AM",
        },
        {
            time_value: 1.5,
            time: "01:30 AM",
        },
        {
            time_value: 2,
            time: "02:00 AM",
        },
        {
            time_value: 2.5,
            time: "02:30 AM",
        },
        {
            time_value: 3,
            time: "03:00 AM",
        },
        {
            time_value: 3.5,
            time: "03:30 AM",
        },
        {
            time_value: 4,
            time: "04:00 AM",
        },
        {
            time_value: 4.5,
            time: "04:30 AM",
        },
        {
            time_value: 5,
            time: "05:00 AM",
        },
        {
            time_value: 5.5,
            time: "05:30 AM",
        },
        {
            time_value: 6,
            time: "06:00 AM",
        },
        {
            time_value: 6.5,
            time: "06:30 AM",
        },
        {
            time_value: 7,
            time: "07:00 AM",
        },
        {
            time_value: 7.5,
            time: "07:30 AM",
        },
        {
            time_value: 8,
            time: "08:00 AM",
        },
        {
            time_value: 8.5,
            time: "08:30 AM",
        },
        {
            time_value: 9,
            time: "09:00 AM",
        },
        {
            time_value: 9.5,
            time: "09:30 AM",
        },
        {
            time_value: 10,
            time: "10:00 AM",
        },
        {
            time_value: 10.5,
            time: "10:30 AM",
        },
        {
            time_value: 11,
            time: "11:00 AM",
        },
        {
            time_value: 11.5,
            time: "11:30 AM",
        },
        {
            time_value: 12,
            time: "12:00 PM",
        },
        {
            time_value: 12.5,
            time: "12:30 PM",
        },
        {
            time_value: 13,
            time: "01:00 PM",
        },
        {
            time_value: 13.5,
            time: "01:30 PM",
        },
        {
            time_value: 14,
            time: "02:00 PM",
        },
        {
            time_value: 14.5,
            time: "02:30 PM",
        },
        {
            time_value: 15,
            time: "03:00 PM",
        },
        {
            time_value: 15.5,
            time: "03:30 PM",
        },
        {
            time_value: 16,
            time: "04:00 PM",
        },
        {
            time_value: 16.5,
            time: "04:30 PM",
        },
        {
            time_value: 17,
            time: "05:00 PM",
        },
        {
            time_value: 17.5,
            time: "05:30 PM",
        },
        {
            time_value: 18,
            time: "06:00 PM",
        },
        {
            time_value: 18.5,
            time: "06:30 PM",
        },
        {
            time_value: 19,
            time: "07:00 PM",
        },
        {
            time_value: 19.5,
            time: "07:30 PM",
        },
        {
            time_value: 20,
            time: "08:00 PM",
        },
        {
            time_value: 20.5,
            time: "08:30 PM",
        },
        {
            time_value: 21,
            time: "09:00 PM",
        },
        {
            time_value: 21.5,
            time: "09:30 PM",
        },
        {
            time_value: 22,
            time: "10:00 PM",
        },
        {
            time_value: 22.5,
            time: "10:30 PM",
        },
        {
            time_value: 23,
            time: "11:00 PM",
        },
        {
            time_value: 23.5,
            time: "11:30 PM",
        },
    ];
    let token = null;
    const { user } = useSelector((state) => state.auth);
    const { appSettings } = useSelector((state) => state.appSettings);
    if (user) {
        if (user && user.token) {
            token = user.token;
           

        }
    }

    useEffect(() => {
        setApiToken(token);
    }, [token]);

    const fetchData = async () => {
        const response = await axios.get(
            `/api/get/app-settings/${appSettings && appSettings.appId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    };

    // const { data, isLoading, error, refetch } = useQuery('exampleQueryKey', fetchData);

    // useEffect(() => {
    //     setSelectedCurrency(data && data.currency);
    //     setSelectedTimezone(data && data.timeZone);
    //     setSelectedLanguage(data && data.language);
    //     setSelectedLockTime(data && data.timerLockOption);
    //     setAppSeoTitle(data && data.seoTitle);
    //     setAppSeoDescription(data && data.metaDescription);
    //     setLocktime(data && data.timerLock);
    //     setSelectedTime(data && data.timerLockTime);
    //     setSelectedWeekStart(data && data.weekStartDay);
    //     setGoogleDriveClientId(data && data.googleDriveClientId);
    //     setGoogleDriveDeveloperKey(data && data.googleDriveDeveloperKey);
    //     setDropBoxClientId(data && data.dropBoxClientId);
    //     refetch();
    // }, [isLoading, dispatch, data]);

    // useEffect(() => {
    //     refetch();
    // }, [selectedCurrency, selectedTimezone, selectedLanguage, selectedLockTime, seoTitle, seoDescription, locktime])



    // useEffect(() => {

    //     const fetchData = async () => {
    //         const response = await axios.get(
    //             `/api/get/app-settings/${appSettings && appSettings.appId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );

    //         setGoogleDriveClientId(response.data.googleDriveClientId);
    //         setGoogleDriveDeveloperKey(response.data.googleDriveDeveloperKey);
    //         setDropBoxClientId(response.data.dropBoxClientId);
    //         return response.data;
    //     };
    //     fetchData();
    // },[]);

    

    const handleCopy = () => {
        navigator.clipboard.writeText(apiToken).then(() => {
            setButtonText("Copied!");
            toast.success("Content copied to clipboard!", {
                position: "bottom-right",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => setButtonText("Copy"), 2000); // Reset after 2 seconds
        }).catch((err) => {
            console.error("Failed to copy: ", err);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `/api/get/app-settings/${appSettings && appSettings.appId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Using setTimeout to defer state updates and prevent blocking
                setTimeout(() => {
                    setGoogleDriveClientId(response.data.googleDriveClientId);
                    setGoogleDriveDeveloperKey(response.data.googleDriveDeveloperKey);
                    setDropBoxClientId(response.data.dropBoxClientId);
                }, 0);

                return response.data;
            } catch (error) {
                console.error("Error fetching app settings:", error);
            }
        };

        fetchData();
    }, [appSettings, token]);  // Including dependencies if needed


    const handleClickOutsideDropdown = (event) => {
        const isClickInsideTimezoneDropdown = timezoneDropdownRef.current && timezoneDropdownRef.current.contains(event.target);
        const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
        const isClickInsideLanguageDropdown = languageDropdownRef.current && languageDropdownRef.current.contains(event.target);
        const isClickInsideWeekStartDropdown = weekStartDropdownRef.current && weekStartDropdownRef.current.contains(event.target);
        const isClickInsideLockTimeDropdown = lockTimeDropdownRef.current && lockTimeDropdownRef.current.contains(event.target);
        const isClickInsideLockTimerTimeDropdown = lockTimerTimeDropdownRef.current && lockTimerTimeDropdownRef.current.contains(event.target);
        if (!isClickInsideTimezoneDropdown) {
            setTimezoneDropdownOpen(false);
        }
        if (!isClickInsideCurrencyDropdown) {
            setCurrencyDropdownOpen(false);
        }
        if (!isClickInsideLanguageDropdown) {
            setLanguageDropdownOpen(false);
        }
        if (!isClickInsideWeekStartDropdown) {
            setWeekStartDropdownOpen(false);
        }
        if (!isClickInsideLockTimeDropdown) {
            setLockTimeDropdownOpen(false);
        }
        if (!isClickInsideLockTimerTimeDropdown) {
            setLockTimertimeDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);



    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const integrationsUpdate = async (seoTitle) => {


        try {
            setIsSubmitLoading(true);
            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,
                    googleDriveClientId: googleDriveClientId,
                    googleDriveDeveloperKey: googleDriveDeveloperKey,
                    dropBoxClientId: dropBoxClientId

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Integration settings has been updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });

            setIsSubmitLoading(false);

        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
            setIsSubmitLoading(false);
        }
    }

    return (
        <div>
            <div>
                <div>



                    <div className="security-outer noti-outer seo-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">Google Drive</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Client Id
                            </span>
                            <div className="seo-input" >
                                <input type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="xxxxxxxxxxxxxxxx" value={googleDriveClientId} onPaste={(e) => setGoogleDriveClientId(e.clipboardData.getData('text'))} onChange={(e) => setGoogleDriveClientId(e.target.value)} />
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Developer Key
                            </span>
                            <div className="seo-input" >
                                <input type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="xxxxxxxxxxxxxxxx" value={googleDriveDeveloperKey} onPaste={(e) => setGoogleDriveDeveloperKey(e.clipboardData.getData('text'))} onChange={(e) => setGoogleDriveDeveloperKey(e.target.value)} />
                            </div>

                        </div>

                    </div>

                    <div className="security-outer noti-outer seo-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">Dropbox</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                API Key
                            </span>
                            <div className="seo-input" >
                                <input type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="xxxxxxxxxxxxxxxx" value={dropBoxClientId} onPaste={(e) => setDropBoxClientId(e.clipboardData.getData('text'))} onChange={(e) => setDropBoxClientId(e.target.value)} />
                            </div>


                        </div>



                    </div>

                    <div className="security-outer noti-outer seo-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">TalentOn API Token</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                               Token
                            </span>
                            <div className="seo-input api-token-copy" >
                                <textarea disabled type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="xxxxxxxxxxxxxxxx" value={apiToken} onPaste={(e) => setDropBoxClientId(e.clipboardData.getData('text'))} onChange={(e) => setDropBoxClientId(e.target.value)} />
                                <button onClick={handleCopy}>{buttonText}</button>
                            </div>
                            


                        </div>



                    </div>

                    <div className="outer-integration-btn">
                        <button class="cta-link account-payment" onClick={() => integrationsUpdate()}>
                            {isSubmitLoading ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="50"
                                        radius="5"
                                        color="#FFFFFF"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                "Update"
                            )}
                        </button>
                    </div>





                </div>
            </div>
        </div>
    );
};

export default Integrations;