import React, { useEffect } from "react";
import "./ReportExpense.css";
import ReportRevenueFilter from "./ReportRevenue/ReportRevenueFilter";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import RevenueNumberDeal from "./ReportRevenue/RevenueNumberDeal";
import ReportRevenueTotal from "./ReportRevenue/ReportRevenueTotal";
import ReportHighestRevenue from "./ReportRevenue/ReportHighestRevenue";
import ReportGrapRevenueHistoryFilter from "./ReportRevenue/ReportRevenueHistoryGraphFilter";
import RevenueOverviewPerClient from "./ReportRevenue/ReportingRevenuePerClient";
import RevenueOverviewPerProject from "./ReportRevenue/ReportingRevenuePerProject";
import ReportTotalRevenueCompariosnGraph from "./ReportRevenue/ReportTotalRevenueCompariosnGraph";
import ReportRevenueHistory from "./ReportRevenue/ReportRevenueHistoryGraph";
import ReportRevenuePerLocationGraph from "./ReportRevenue/ReportRevenuePerLocationGraph";
import ReportRevenuePerLocationGraphFilter from "./ReportRevenue/ReportRevenuePerLocationGraphFilter";
import ReportRevenuePerClientGraph from "./ReportRevenue/ReportRevenuePerClientGraph";
import ReportRevenuePerClientGraphFilter from "./ReportRevenue/ReportRevenuePerClientGraphFilter";
import ReportInvoicedOutstandingGraph from "./ReportRevenue/ReportInvoicedVsOutstandingGraph";
import ReportInvoicedOutstandingGraphFilter from "./ReportRevenue/ReportInvoicedVsOutstandingGraphFilter";
import { useFetchProjectDetails } from "../../TimeTracker/TimeTrackerProject/useProjectDetails";
import { useGetClients } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker";
import { useFetchClientInvoices, useFetchExpenses, useFetchProjectCosts, useFetchSprints, useFetchTrackReportDetails } from "../../../features/reporting/reportingOverview/useReportingOverview";
import { Link } from "react-router-dom";
import ReportTotalExpenseGraphFilter from "./ReportRevenue/ReportTotalRevenueCompariosnGraphFilter";
import ReportRevenuePerServiceGraphFilter from "./ReportRevenue/ReportRevenuePerServiceGraphFilter";
import ReportRevenuePerServiceGraph from "./ReportRevenue/ReportRevenuePerServiceGraph";
import RevenueOverviewTotalInvoice from "./ReportRevenue/ReportingRevenueTotalInvoice";
import RevenueHistoryGraphFilter from "./ReportRevenue/GraphFilters/ReportRevenueHistory/ReportRevenueHistoryFilter";
import TotalRevenueFilter from "./ReportRevenue/GraphFilters/ReportTotalRevenue/ReportTotalRevenueFilter";
import RevenuePerServiceGraphFilter from "./ReportRevenue/GraphFilters/ReportRevenuePerService/ReportRevenuePerServiceFilter";
import RevenuePerClientGraphFilter from "./ReportRevenue/GraphFilters/ReportRevenuePerClient/ReportRevenuePerClientFilter";
import RevenuePerLocationGraphFilter from "./ReportRevenue/GraphFilters/ReportRevenuePerLocation/ReportRevenuePerLocationFilter";
import InvoicedOutstandingGraphFilter from "./ReportRevenue/GraphFilters/ReportInvoicedVsOutstanding/ReportInvoicedVsOutstandingFilter";
import { setAppLoading } from "../../../features/appSettings/appSettingsSlice";


function RevenueReport() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: project = [], isLoading, refetch: refetchProjects } = useFetchProjectDetails(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: clientInvoices = [], isLoading: clientInvoicesLoading, isError: clientInvoicesError } = useFetchClientInvoices(token);
  const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);
  const { data: projectCost = [], isLoading: projectCostLoading, isError: projectCostError } = useFetchProjectCosts(token);
  const { data: sprints = [], isLoading: sprintLoading, isError: sprintError } = useFetchSprints(token);
  const { data: expenses = [], isLoading: expensesLoading, isError: expensesError } = useFetchExpenses(token);

  useEffect(() => {
    const handleLoading = async () => {
      await dispatch(setAppLoading(isLoading));
      await dispatch(setAppLoading(clientLoading));
      await dispatch(setAppLoading(clientInvoicesLoading));
      await dispatch(setAppLoading(trackReportLoading));
      await dispatch(setAppLoading(projectCostLoading));
      await dispatch(setAppLoading(sprintLoading));
      await dispatch(setAppLoading(expensesLoading));
    };
    handleLoading();
  }, [isLoading, clientLoading, clientInvoicesLoading, trackReportLoading, projectCostLoading, sprintLoading, expensesLoading]);

  // useEffect(() => {
  //   dispatch(setHeaderText("Financial Reports"));
  // }, []);
  return (
    <div className="react-outer report-expense client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportRevenueFilter project={project} clients={clients} trackReport={trackReport} projectCost={projectCost} sprints={sprints} clientInvoices={clientInvoices} />
      </div>
      <div className="repot-tatal-box-sec">
        <RevenueNumberDeal clientInvoices={clientInvoices} />
        <ReportRevenueTotal trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
        <ReportHighestRevenue trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
          <div className="top-graph">
            <RevenueHistoryGraphFilter
              clientInvoices={clientInvoices}
              clientInvoicesLoading={clientInvoicesLoading}
              clientInvoicesError={clientInvoicesError}
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
              projectCost={projectCost}
              expenses={expenses}
            />
          </div>
          <ReportRevenueHistory
            clientInvoices={clientInvoices}
            clientInvoicesLoading={clientInvoicesLoading}
            clientInvoicesError={clientInvoicesError}
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
            projectCost={projectCost}
            expenses={expenses}
          />
        </div>
        <div className="pie-chart-right">
          <RevenueOverviewPerProject trackReport={trackReport} projectCost={projectCost} sprints={sprints} clientInvoices={clientInvoices} expenses={expenses} />
        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <TotalRevenueFilter
              clientInvoices={clientInvoices}
              clientInvoicesLoading={clientInvoicesLoading}
              clientInvoicesError={clientInvoicesError}
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
              projectCost={projectCost}
              expenses={expenses}
            />
          </div>
          <ReportTotalRevenueCompariosnGraph
            clientInvoices={clientInvoices}
            clientInvoicesLoading={clientInvoicesLoading}
            clientInvoicesError={clientInvoicesError}
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
            projectCost={projectCost}
            expenses={expenses}
          />
        </div>
        <div className="pie-chart-right">
          <RevenueOverviewPerClient trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <RevenuePerServiceGraphFilter
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
              sprints={sprints}
              projectCost={projectCost}
            />
          </div>
          <ReportRevenuePerServiceGraph
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
            sprints={sprints}
            projectCost={projectCost}
          />
        </div>
        <div className="pie-chart-right">
          <RevenueOverviewTotalInvoice clientInvoices={clientInvoices} clientInvoicesLoading={clientInvoicesLoading} clientInvoicesError={clientInvoicesError} />
        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <RevenuePerLocationGraphFilter
              clientInvoices={clientInvoices}
              clientInvoicesLoading={clientInvoicesLoading}
              clientInvoicesError={clientInvoicesError}
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
              projectCost={projectCost}
              expenses={expenses}
            />
          </div>
          <ReportRevenuePerLocationGraph
            clientInvoices={clientInvoices}
            clientInvoicesLoading={clientInvoicesLoading}
            clientInvoicesError={clientInvoicesError}
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
            projectCost={projectCost}
            expenses={expenses}
          />
        </div>
        <div className="pie-chart-right-no">

        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <RevenuePerClientGraphFilter
              clientInvoices={clientInvoices}
              clientInvoicesLoading={clientInvoicesLoading}
              clientInvoicesError={clientInvoicesError}
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
              projectCost={projectCost}
              expenses={expenses}
            />
          </div>
          <ReportRevenuePerClientGraph
            clientInvoices={clientInvoices}
            clientInvoicesLoading={clientInvoicesLoading}
            clientInvoicesError={clientInvoicesError}
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
            projectCost={projectCost}
            expenses={expenses}
          />
        </div>
        <div className="pie-chart-right-no">

        </div>
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <InvoicedOutstandingGraphFilter clientInvoices={clientInvoices} clientInvoicesLoading={clientInvoicesLoading} clientInvoicesError={clientInvoicesError} />
          </div>
          <ReportInvoicedOutstandingGraph clientInvoices={clientInvoices} clientInvoicesLoading={clientInvoicesLoading} clientInvoicesError={clientInvoicesError} />
        </div>
        <div className="pie-chart-right-no">

        </div>
      </div>
    </div>
  );
}

export default RevenueReport;
