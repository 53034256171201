import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts';
import typeOfContract from '../../../../constants/typeOfContract';
import { clientTotalRevenueHistory } from './Services/reportRevenue';

// const data = [
//   { name: 'Rent', revenue: 44 },
//   { name: 'Utilities', revenue: 55 },
//   { name: 'Groceries', revenue: 13 },
//   { name: 'Transport', revenue: 43 },
//   { name: 'Entertainment', revenue: 46 },
//   { name: 'Healthcare', revenue: 55 },
//   { name: 'Miscellaneous', revenue: 32 },
// ];

const RevenueHistory = ({ clientInvoices, clientInvoicesLoading, clientInvoicesError, trackReport, trackReportLoading, trackReportError, projectCost, expenses }) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return clientTotalRevenueHistory(
      trackReport,
      projectCost,
      clientInvoices,
      reportingRevenue,
      currencies,
      forex,
      typeOfContract,
      expenses
    );
  }, [trackReport, projectCost, clientInvoices, reportingRevenue, currencies, forex, typeOfContract, expenses]);

  const calculateYTicks = () => {
    const dataValues = processedData.map(item => item.revenue);
    const maxDataValue = Math.max(...dataValues);
    const minDataValue = Math.min(...dataValues);

    const hasNegativeValues = minDataValue < 0;
    const hasPositiveValues = maxDataValue > 0;

    // Determine tick interval
    const tickInterval = Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / 10);
    const numOfTicks = Math.min(Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / tickInterval), 10);
    const ticks = [];

    // Generate ticks for both positive and negative ranges
    if (hasNegativeValues && hasPositiveValues) {
      for (let i = -numOfTicks; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
    } else if (hasNegativeValues) {
      // Generate negative ticks only
      for (let i = -numOfTicks; i <= 0; i++) {
        ticks.push(i * tickInterval);
      }
    } else if (hasPositiveValues) {
      // Generate positive ticks only
      for (let i = 0; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
    }

    return ticks;
  }

  const yAxisTicks = calculateYTicks();

  if (clientInvoicesLoading || trackReportLoading) {
    return (
      <div className="outter-load-table">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
    );
  }

  if (clientInvoicesError || trackReportError) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="horizontal" // Change to horizontal
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: Math.max(
              0,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currencies,
              })
                .format(Math.max(...processedData.map((item) => item.revenue)))
                .length
            ),
            bottom: 8,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <XAxis
            type="category"
            dataKey="name"
            angle={-55}
            // width={150} // Ensure enough width for client names
            tickMargin={10}
          />
          <YAxis
            type="number"
            domain={['auto', 'auto']} // Allow automatic domain calculation
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(tick)}`}
            // ticks={yAxisTicks}
            ticks={[
              ...(yAxisTicks.includes(Math.min(...yAxisTicks))
                ? [Math.min(...yAxisTicks) * 1.2] // Add an extra tick slightly below the min negative value
                : []),
              ...yAxisTicks,
            ]}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies }).format(value)}`} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="revenue" name="Closed amount in default currency" fill="#96A8BA" barSize={13}>
            <LabelList dataKey="revenue" position="top" fill="#282828" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RevenueHistory;