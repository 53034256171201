import css from "./User.css";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import addicon from "../../images/icons/addicon.svg";
import BankAccountVerification from "./elements/Bank";
import InviteIcon from "../../images/icons/invite.svg";
import { useUserProfiles, useUserType, useWiseBanks, fetchTeamsInvite, } from "./userProfileQueries";
import { useSelector, useDispatch } from "react-redux";
import UserIcon from "../../images/icons/user-icon.svg";
import { RenderUserCTA, RenderUserInviteCTA } from "../../helpers/invoiceHelpers";
import profile_image from "../../images/user/Default_pfp.png";
import dletImage from "../../images/singleinvoice/delete.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import Plus from "../../images/icons/plus-black.svg";
import UpArrowImage from "../../images/icons/up-down-arrow.svg";
import DownArrowImage from "../../images/icons/up-down-arrow.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { setNotification } from "../../features/notification/notificationSlice.js";
import userCategory from "../../constants/userCategory";
import userInviteStatus from "../../constants/userInviteStatus";
import userTypeName from "../../constants/userType";
import DownloadImage from "../../images/icons/download-icon.svg";
import { motion } from "framer-motion";
import moment from "moment-timezone";
import { useContractTypes } from "./UserDetails/UserProfile/useUserView.jsx";
import TruncateFilterFieldView from "../helpers/truncateFilterFieldView.js";
import { RotatingLines } from 'react-loader-spinner';
import { useLocation } from "react-router-dom";
import typeofAccount from "../../constants/typeofAccount.js";
import Calendar from "../../helpers/dateComponents/Calendar.jsx";

const UseRenderMemberName = ({ rowData }) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData.first_name
    ? `${rowData.first_name} ${rowData.last_name || ""}`.trim()
    : rowData.company_first_name
      ? `${rowData.company_first_name} ${rowData.company_last_name || ""}`.trim()
      : `${rowData.company_name} (Company)`;

  const displayName = showFullname
    ? fullName
    : fullName && fullName.length <= 21
      ? fullName
      : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={toggleShowFullname}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.profile_image !== null && rowData.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const UserInviteHistory = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [filter, setFilter] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [emails, setEmails] = useState([]);
  const [userType, setUserType] = useState("");
  const [typeOfAccount, setTypeOfAccount] = useState("");
  const roleDropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const userTypeDropdownRef = useRef(null);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("All");
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [userInviteStatusFilter, setUserInviteStatusFilter] = useState("All");
  const [selectAllCategory, setSelectAllCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isCategoryModalDropdownOpen, setIsCategoryModalDropdownOpen] = useState(false);
  const [userInviteStatusSearchTerm, setUserInviteStatusSearchTerm] = useState('');
  const [accountTypeFilter, setAccountTypeFilter] = useState("All");
  const [selectAllAccountType, setSelectAllAccountType] = useState(true);
  const [selectedAccountType, setSelectedAccountType] = useState([]);
  const [isAccountTypeDropdownOpen, setIsAccountTypeDropdownOpen] = useState(false);
  const [isAccountTypeModalDropdownOpen, setIsAccountTypeModalDropdownOpen] = useState(false);
  const [accountTypeSearchTerm, setAccountTypeSearchTerm] = useState('');
  const [statusModalDropdownOpen, setStatusModalDropdownOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [alreadySentEmailsList, setAlreadySentEmailsList] = useState([]);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const userInviteStatusDropdownRef = useRef(null);
  const userInviteStatusModalDropdownRef = useRef(null);
  const accountTypeDropdownRef = useRef(null);
  const accountTypeModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);
  const [paginatedTeams, setPaginatedTeams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const location = useLocation();

  const [invitations, setInvitations] = useState([]);

  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const tz = useSelector((state) => state.appSettings.appSettings.timeZone);

  const { data: userProfiles = [], refetch: refetchUserProfiles, isLoading: userProfileLoading, isError, error, } = useUserProfiles(token);
  // get invitations
  const { data: roleId = [], isLoading: roleLoading, } = useUserType(token);


  const enabled = selectedTeams.length > 0 && selectedAccountType.length > 0 && selectedCategory.length > 0;

  const [teams, setTeams] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAllTeamInvites = async () => {
    try {
      setIsUpdating(true); // Indicates data is being updated
      const allTeamInvites = await fetchTeamsInvite(
        token,
        currentPage,
        rowsPerPage,
        filter,
        selectedAccountType,
        selectedCategory,
        selectedRange ? selectedRange[0] : null,
        selectedRange ? selectedRange[1] : null,
        tz,
        sortBy,
        sortOrder,
        dispatch,
      );
      setTeams(allTeamInvites);
      setPaginatedTeams(allTeamInvites.invitedUsers);
      setTotal(allTeamInvites.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(async () => {
      try {
        await Promise.all([
          setFilter(debouncedValue),
        ]);
      } catch (error) {
        console.error("Error in async operations:", error);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue]);

  const memoizedDependencies = useMemo(() => {
    return {
      selectedTeams,
      selectedAccountType,
      selectedCategory,
      selectedStatus,
      selectedRange,
      tz,
      sortBy,
      sortOrder,
      currentPage,
      rowsPerPage,
    };
  }, [selectedTeams, selectedAccountType, selectedCategory, selectedStatus, selectedRange, tz, sortBy, sortOrder, currentPage, rowsPerPage]);

  useEffect(() => {
    if (
      memoizedDependencies.selectedTeams.length > 0 &&
      memoizedDependencies.selectedAccountType.length > 0 &&
      memoizedDependencies.selectedCategory.length > 0
    ) {
      getAllTeamInvites();
    }
  }, [
    memoizedDependencies.selectedTeams,
    memoizedDependencies.selectedAccountType,
    memoizedDependencies.selectedCategory,
    memoizedDependencies.selectedStatus,
    memoizedDependencies.selectedRange,
    memoizedDependencies.tz,
    memoizedDependencies.sortBy,
    memoizedDependencies.sortOrder,
    memoizedDependencies.currentPage,
    memoizedDependencies.rowsPerPage,
    filter,
  ]);


  useEffect(() => {
    const updateSelectedTeams = async () => {
      // Assuming you might have additional async operations in the future
      await Promise.all([
        // Perform all async operations concurrently
        new Promise((resolve) => {
          setSelectedTeams(userProfiles.map((team) => team._id));
          resolve();
        }),
        // You can add other async calls here
      ]);
    };

    updateSelectedTeams();
  }, [userProfiles]);

  useEffect(() => {
    const updateSelectedAccountType = async () => {
      await Promise.all([
        new Promise((resolve) => {
          setSelectedAccountType(typeofAccount.type_of_account.map((team) => team.account_type));
          resolve();
        }),
        // Add other async tasks here in the future if needed
      ]);
    };

    updateSelectedAccountType();
  }, [typeofAccount]);

  useEffect(() => {
    const updateSelectedCategory = async () => {
      await Promise.all([
        new Promise((resolve) => {
          setSelectedCategory(userInviteStatusList.map((item) => item.value));
          resolve();
        }),
        // You can add other asynchronous tasks here if needed in the future
      ]);
    };

    updateSelectedCategory();
  }, []);

  useEffect(() => {
    const updateIsUpdating = async () => {
      await Promise.all([
        new Promise((resolve) => {
          setIsUpdating(true);
          resolve();
        }),
        // You can add other asynchronous tasks here if needed in the future
      ]);
    };

    updateIsUpdating();
  }, [userProfileLoading]);


  const {
    data: typeContract = [],
    isLoading: contractTypesLoading,
    isError: contractTypesError,
  } = useContractTypes();

  const {
    data: wiseBanks = [],
    refetch: refetchWiseBanks,
    isLoading: wiseBanksLoading,
    isError: wiseBanksError,
    error: wiseBanksErrorMessage,
  } = useWiseBanks(token);

  // roleId = roleId.filter(
  //   (role) => !(user.userType === 'ADMIN' && role._id === userTypeName.Super_Admin)
  // );

  useEffect(() => {
    if (userProfiles) {
      // setUsers(prevUsers => [...prevUsers, ...userProfiles]);
      setUsers(userProfiles);
    }
  }, [userProfiles]);

  const handleSort = (sortBy, sortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  const renderUserInviteStatus = (rowData) => {
    // Determine the class based on the payment_ssdstatus value
    let statusClass;
    let statusName;
    if (rowData.invite_status === "Pending") {
      statusClass = "pending";
      statusName = "Pending";
    } else if (rowData.invite_status === "Accepted") {
      statusClass = "manual";
      statusName = "Accepted";
    } else if (rowData.invite_status === "Expired") {
      statusClass = "overdue";
      statusName = "Expired";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{rowData.invite_status}</span>
    );
  };

  const renderUserInviteCTA = (rowData) => {
    const handleOpenModal = () => {
      setIsModalOpen(true);
      setTypeOfAccount(rowData.type_of_account);
      // setEmail(rowData.email);
      // setUserType(rowData.user_role_id);
      setEmails([{ id: 1, email: rowData.email, userType: rowData.user_role_id }]);
    };
    return (
      <Link className="cta-link resend-icon" onClick={handleOpenModal}>
        <span className="resend-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
            <path d="M1.34791 1.00037C1.18428 1.01562 1.04838 1.13217 1.01038 1.29075C0.972386 1.44898 1.04085 1.61399 1.18017 1.70005L7.23648 5.51864L5.43931 9.96576C5.38522 10.105 5.41843 10.2629 5.52454 10.3686C5.63032 10.4747 5.78916 10.5096 5.93053 10.4577L9.07576 9.24812L10.2379 11.7802C10.2961 11.9042 10.417 11.9876 10.5546 11.9987C10.6922 12.0099 10.8254 11.9466 10.9027 11.8337L17.8042 1.58756C17.8809 1.47134 17.8874 1.32327 17.8213 1.20096C17.7553 1.07864 17.6269 1.00172 17.4869 1.00071H1.38385C1.37187 1.00003 1.35989 0.999692 1.34791 1.00037ZM2.69596 1.75934H15.5398L7.74003 4.93755L2.69596 1.75934ZM16.4681 2.19813L10.6511 10.8491L9.63888 8.64942C9.63272 8.62875 9.6245 8.60909 9.61491 8.59012L9.60875 8.57826V8.57216L8.22476 5.56608L16.4681 2.19813ZM7.73387 6.32505L8.75843 8.56064L6.47003 9.43821L7.73387 6.32505Z" fill="#405BFF" stroke="#405BFF" stroke-width="0.3" />
          </svg>
        </span>
        <span>Resend</span>
      </Link>
    );
  };
  const [emailValidation, setEmailValodation] = useState(false);
  const [selectValidation, setSelectValodation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );
    if (email === "") {
      setEmailValodation(true);
    } else {
      setEmailValodation(false);
    }
    if (userType === "") {
      setSelectValodation(true);
    } else {
      setSelectValodation(false);
    }
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;
    if (email !== "") {
      allEmails = [
        ...emails,
        { email: email, id: Date.now(), userType: userType },
      ]; // combine all emails including the last added one
    }
    // Validate email and userType fields
    if (!email || !userType) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allEmails.some((obj) => !obj.email || !obj.userType)) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/user-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: allEmails.map((obj) => ({
            email: obj.email,
            userType: obj.userType,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      handleCloseModal();
    }
  };

  const handleResendSubmit = () => {
    switch (typeOfAccount) {
      case "Team":
        return handleTeamResendSingleSubmit;
      case "Client":
        return handleClientResendSingleSubmit;
      case "Vendor":
        return handleVendorResendSingleSubmit;
      default:
        return;
    }
  };

  const handleTeamResendSingleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );

    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;

    if (allEmails.some((obj) => !obj.email || !obj.userType)) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/resend-user-invite/single", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: allEmails.map((obj) => ({
            email: obj.email,
            userType: obj.userType,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      getAllTeamInvites();
      handleCloseModal();
    }
  };

  const handleClientResendSingleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );

    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;

    if (allEmails.some((obj) => !obj.email)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/resend-client-invite/single", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          cliInvEmails: allEmails.map((obj) => ({
            email: obj.email,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      getAllTeamInvites();
      handleCloseModal();
    }
  };

  const handleVendorResendSingleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );

    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;

    if (allEmails.some((obj) => !obj.email)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/resend-vendor-invite/single", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          venInvEmails: allEmails.map((obj) => ({
            email: obj.email,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error(`Something went wrong!! ${error}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsMailLoading(false);
      getAllTeamInvites();
      handleCloseModal();
    }
  };

  const handleResendEmails = async () => {
    try {
      setIsMailLoading(true);
      const response = await fetch("/api/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: alreadySentEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      // Handle network or server error
      // Show an error message
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsMailLoading(false);
      handleCloseResendModal();
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emailRegex.test(email);
    if (email && !isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newEmailEntry = { email: email, id: Date.now(), userType: userType };
    setEmails([...emails, newEmailEntry]);
    // }
    setEmail("");
    setUserType("");
    // }
  };

  // const handleToggleRoleDropdown = () => {
  //   setRoleDropdownOpen(!roleDropdownOpen);
  // };

  const handleToggleRoleDropdown = (id) => {
    setRoleDropdownOpen((prev) => (prev === id ? null : id));
  };

  const handleToggleUserTypeDropdown = () => {
    setUserTypeDropdownOpen(!userTypeDropdownOpen);
  };

  const handleUserTypeOptionClick = (selectedValue) => {
    setUserType(selectedValue);
    setSelectValodation(false);
    setUserTypeDropdownOpen(false);
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setFirst(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(userProfiles.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  const filteredUsers = userProfiles.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleUserSearchChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  // useEffect(() => {
  //   setSelectAllTeam(selectedTeams.length === userProfiles.length);
  //   if (selectedTeams.length === userProfiles.length) {
  //     setUserFilter("All");
  //   } else {
  //     setUserFilter(selectedTeams);
  //   }
  // }, [selectedTeams, userProfiles]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
    setUserSearchTerm("");
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
    setUserSearchTerm("");
  };

  const userInviteStatusList = Object.entries(userInviteStatus)
    .map(([name, value]) => ({
      name,
      value,
    }));

  const filteredUserInviteStatusList = userInviteStatusList && userInviteStatusList.filter((userInviteStatus) =>
    userInviteStatus && userInviteStatus.name
      ?.toLowerCase()
      .includes(userInviteStatusSearchTerm?.toLowerCase())
  );

  const handleUserInviteStatusSearchChange = (e) => {
    setUserInviteStatusSearchTerm(e.target.value);
  };

  const toggleCategoryDropdown = () => {
    setUserInviteStatusSearchTerm("");
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const toggleCategoryModalDropdown = () => {
    setUserInviteStatusSearchTerm("");
    setIsCategoryModalDropdownOpen(!isCategoryModalDropdownOpen);
  };

  const handleCheckboxCategoryChange = (value) => {
    setSelectedCategory((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const handleSelectAllCategoryChange = () => {
    setSelectAllCategory(!selectAllCategory);
    if (!selectAllCategory) {
      setSelectedCategory(userInviteStatusList.map((item) => item.value));
    } else {
      setSelectedCategory([]);
    }
  };

  // useEffect(() => {
  //   setSelectAllCategory(selectedCategory.length === userInviteStatusList.length);
  //   if (selectedCategory.length === userInviteStatusList.length) {
  //     setUserInviteStatusFilter("All");
  //   } else {
  //     setUserInviteStatusFilter(selectedTeams);
  //   }
  // }, [selectedCategory, userInviteStatusList]);

  useEffect(() => {
    const updateCategorySelection = async () => {
      await Promise.all([
        new Promise((resolve) => {
          setSelectAllCategory(selectedCategory.length === userInviteStatusList.length);
          resolve();
        }),
        new Promise((resolve) => {
          if (selectedCategory.length === userInviteStatusList.length) {
            setUserInviteStatusFilter("All");
          } else {
            setUserInviteStatusFilter(selectedTeams);
          }
          resolve();
        }),
      ]);
    };

    updateCategorySelection();
  }, [selectedCategory, userInviteStatusList]);

  const filteredAccountType = typeofAccount.type_of_account;
  const filteredAccountTypeList = typeofAccount && typeofAccount.type_of_account.filter((accountType) =>
    accountType && accountType.account_type
      ?.toLowerCase()
      .includes(accountTypeSearchTerm?.toLowerCase())
  );

  const handleRoleIdSearchChange = (e) => {
    setAccountTypeSearchTerm(e.target.value);
  };


  const toggleAccountTypeDropdown = () => {
    setIsAccountTypeDropdownOpen(!isAccountTypeDropdownOpen);
  };

  const toggleAccountTypeModalDropdown = () => {
    setIsAccountTypeModalDropdownOpen(!isAccountTypeModalDropdownOpen);
  };

  const handleCheckboxAccountTypeChange = (value) => {
    const updatedSelection = [...selectedAccountType];
    const index = updatedSelection.indexOf(value);

    if (index === -1) {
      updatedSelection.push(value);
    } else {
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(1);
    setSelectedAccountType(updatedSelection);
  };

  const handleSelectAllAccountTypeChange = () => {
    setSelectAllAccountType(!selectAllAccountType);
    if (!selectAllAccountType) {
      setSelectedAccountType(filteredAccountType.map((item) => item.account_type));
    } else {
      setSelectedAccountType([]);
    }
  };

  useEffect(() => {
    setSelectAllAccountType(
      selectedAccountType.length === filteredAccountType.length
    );
    if (selectedAccountType.length === filteredAccountType.length) {
      setAccountTypeFilter("All");
    } else {
      setAccountTypeFilter(selectedTeams);
    }
  }, [selectedAccountType, filteredAccountType]);

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setStatusModalDropdownOpen(!statusModalDropdownOpen);
  };

  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedTeams(userProfiles.map((team) => team._id));
    setSelectedCategory(userInviteStatusList.map((item) => item.value));
    setSelectedAccountType(filteredAccountType.map((type) => type.account_type));
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isTeamsModified = !isEqual(
      selectedTeams,
      userProfiles.map((team) => team._id)
    );
    const isUserInviteStatusModified = !isEqual(
      selectedStatus,
      userInviteStatusList.map((item) => item.value)
    );
    const isAccountTypeModified = !isEqual(
      selectedAccountType,
      filteredAccountType.map((type) => type.account_type)
    );

    if (isTeamsModified || isUserInviteStatusModified || isAccountTypeModified) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  // useEffect(() => {
  //   setSelectedTeams(userProfiles.map((team) => team._id));
  //   setSelectedCategory(userInviteStatusList.map((item) => item.value));
  //   setSelectedAccountType(filteredAccountType.map((type) => type.account_type));
  // }, [userProfiles, roleId]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideRoleDropdown =
      roleDropdownRef.current && roleDropdownRef.current.contains(event.target);
    const isClickInsideUserTypeDropdown =
      userTypeDropdownRef.current &&
      userTypeDropdownRef.current.contains(event.target);
    const isClickInsideTeamDropdown =
      teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown =
      teamModalDropdownRef.current &&
      teamModalDropdownRef.current.contains(event.target);
    const isClickInsideUserInviteStatusDropdown = userInviteStatusDropdownRef.current && userInviteStatusDropdownRef.current.contains(event.target);
    const isClickInsideUserInviteStatusModalDropdown = userInviteStatusModalDropdownRef.current && userInviteStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeDropdown = accountTypeDropdownRef.current && accountTypeDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeModalDropdown = accountTypeModalDropdownRef.current && accountTypeModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown = statusDropdownRef.current && statusDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown =
      statusModalDropdownRef.current &&
      statusModalDropdownRef.current.contains(event.target);
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);

    if (!isClickInsideRoleDropdown) {
      setRoleDropdownOpen(false);
    }
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideUserInviteStatusDropdown) {
      setIsCategoryDropdownOpen(false);
    }
    if (!isClickInsideUserInviteStatusModalDropdown) {
      setIsCategoryModalDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeDropdown) {
      setIsAccountTypeDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeModalDropdown) {
      setIsAccountTypeModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setStatusModalDropdownOpen(false);
    }
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutsideDropdown);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutsideDropdown);
  //   };
  // }, []);

  useEffect(() => {
    const handleAsyncClickOutsideDropdown = async (event) => {
      try {
        // If you plan to do async operations on click outside, you can await them here.
        await Promise.all([
          // Example async tasks (can be replaced with actual async logic)
          new Promise((resolve) => {
            handleClickOutsideDropdown(event);
            resolve();  // Resolve the promise after executing the function
          }),
          // Another async task can be added here
          new Promise((resolve) => {
            // console.log("Click outside detected");  // Example async task
            resolve();
          }),
        ]);
      } catch (error) {
        console.error("Error handling click outside", error);
      }
    };

    // Adding the event listener
    document.addEventListener("click", handleAsyncClickOutsideDropdown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleAsyncClickOutsideDropdown);
    };
  }, []);

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(total / rowsPerPage);

  // const handleEditEmail = (id, field, value) => {
  //   // Find the index of the email entry with the given id
  //   const index = emails.findIndex((obj) => obj.id === id);
  //   if (index !== -1) {
  //     // Create a copy of the emails array to avoid mutating state directly
  //     const updatedEmails = [...emails];
  //     // Update the specified field of the email entry
  //     updatedEmails[index][field] = value;
  //     // Update the state with the new array
  //     setEmails(updatedEmails);
  //   }
  // };

  const handleEditEmail = (id, field, value) => {
    setEmails((prevEmails) =>
      prevEmails.map((obj) =>
        obj.id === id ? { ...obj, [field]: value } : obj
      )
    );
    setRoleDropdownOpen(null); // Close dropdown after selection
  };
  const handleRemoveEmail = (id) => {
    const filteredEmails = emails.filter((obj) => obj.id !== id);
    setEmails(filteredEmails);
  };

  const handleRemoveLatestEmail = () => {
    const updatedEmails = [...emails];
    const lastEmail = updatedEmails.pop(); // Remove the last element
    setEmails(updatedEmails);

    // Set state variables with the latest values
    setEmail(lastEmail.email);
    setUserType(lastEmail.userType);
  };

  // Function to remove an email from the list
  const handleRemoveResendEmail = (emailToRemove) => {
    const updatedEmails = alreadySentEmailsList.filter(
      (email) => email !== emailToRemove
    );
    setAlreadySentEmailsList(updatedEmails);
  };

  const onDateChange = async (ranges) => {
    const startOfDayUTC = moment(ranges.startDate).startOf("day").toDate();
    const endOfDayUTC = moment(ranges.endDate).endOf("day").toDate();
    const dateRangeArray = [startOfDayUTC, endOfDayUTC];
    setSelectedRange(dateRangeArray);
    setCurrentPage(1);
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const handleOpenResendModal = () => {
    // Set the list of already sent emails here
    setIsResendModalOpen(true);
  };

  const handleCloseResendModal = () => {
    setIsResendModalOpen(false);
    setAlreadySentEmailsList([]);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmails([]);
    setEmail("");
    setUserType("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    if (bulkImportFile.size > maxFileSize) {
      toast.error("File size exceeds the 5 MB limit.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/import/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (
          response.data.existingUserEmails &&
          response.data.existingUserEmails.length > 0
        ) {
          toast.error(
            `The following emails are already registered: ${response.data.existingUserEmails.join(
              ", "
            )}`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          if (
            response.data.filteredUsers &&
            response.data.filteredUsers.length > 0
          ) {
            toast.success(
              `Inserted ${response.data.filteredUsers.length} records`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        } else {
          toast.success("File uploaded successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        refetchUserProfiles();
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false); // Reset loading state after upload process completes
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null);
    setBulkImportFileName("");
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0]);
      setBulkImportFileName(file.name);
    } else {
      setBulkImportFile(null);
      setBulkImportFileName("");
    }
  };

  const filteredData = users.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name ||
        (item.company_first_name
          ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ""
          }`
          : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;

    const hasMatch =
      (fullName?.toLowerCase().includes(searchTerm) ||
        item.job_title?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm) ||
        item.user_wx_comapny?.wx_company?.toLowerCase().includes(searchTerm)) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);

    const isUserFilterMatched =
      userFilter === "All" || selectedTeams.includes(item._id);
    const isUserInviteStatusFilterMatched =
      userInviteStatusFilter === "All" ||
      selectedCategory.includes(item.user_category);
    const isAccountTypeFilterMatched =
      accountTypeFilter === "All" ||
      selectedAccountType.includes(item?.role_id?._id);

    if (user.role_id === userTypeName.Admin) {
      return (
        hasMatch &&
        item?.role_id?._id !== userTypeName.Super_Admin &&
        (item.user_category === userCategory.Individual ||
          item.user_category === userCategory.Business) &&
        isUserFilterMatched &&
        isUserInviteStatusFilterMatched &&
        isAccountTypeFilterMatched
      );
    }

    return (
      hasMatch &&
      isUserFilterMatched &&
      isUserInviteStatusFilterMatched &&
      isAccountTypeFilterMatched
    );
  });

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "User Name": item.first_name
          ? `${item.first_name} ${item.middle_name && item.middle_name !== "null"
            ? item.middle_name + " "
            : ""
          }${item.last_name}`
          : item.company_first_name
            ? `${item.company_first_name} ${item.company_last_name || ""}`
            : `${item.company_name || ""} (Company)`,
        Email: item.email,
        "Company Name": item.company_name,
        "User Category":
          item.user_category === userCategory.Individual
            ? "Individual"
            : item.user_category === userCategory.Business
              ? "Business"
              : item.user_category === userCategory.Vendor
                ? "Vendor"
                : item.user_category === userCategory.Client
                  ? "Client"
                  : "",
        Company: item?.user_wx_comapny?.wx_company
          ? item.user_wx_comapny.wx_company
          : "",
        Role: item.role_id?.user_type,
        "Job Title": item.job_title,
        Department: item?.department?.user_department,
        "Date of Birth": item?.dob
          ? moment(item.dob)
            .tz(global.config.getTimeZone())
            .format("DD/MM/YYYY")
          : "",
        "Passport Number": item.passport_no,
        "Registration Number": item.register_number,
        Mobile: item.mobile_no,
        Address: item.address,
        Pincode: item.pincode,
        Country: item.country,
        State: item.state,
        City: item.city,
        Currency: item.payment_currency,
        "Type of contract": item.typeOfContract
          ? item.typeOfContract.typeOfContract
          : " ",
        "Monthly Hours": `${item.monthly_hours || 168} hrs`,
        Compensation: item.cost
          ? item.cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "Overhead Cost per Hour": item.overhead_cost
          ? item.overhead_cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "VAT (%)": `${item.vat} %`,
        "Vat Id": item.vat_number ? item.vat_number : " ",
        "Is Client Active": item.is_active ? "Yes" : "No",
        "Created at": moment(parseInt(item.created_at)).format(
          "DD/MM/YYYY, hh:mm:ss A"
        ),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);

      // Calculate column widths based on the maximum length of the data in each column
      const getMaxLength = (arr, field) =>
        Math.max(
          ...arr.map((item) => (item[field] || "").toString().length),
          field.length
        );

      const headers = Object.keys(modifiedLog[0]);
      const colWidths = headers.map((header) => ({
        wch: getMaxLength(modifiedLog, header) + 5, // Add padding to each column
      }));

      worksheet["!cols"] = colWidths;

      // Add alignment and font styling for header
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < headers.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center";
        style.alignment.vertical = "center";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size for data
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      const fileName = `Users_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Clients Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="user-outer-overview invoice-outer-invoice invite-history">
        <div className="invoice-top">
          
          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all">
            <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Date Range
                </label>
                <div
                  className="date-picker-outer date-picker-outer-all"
                  ref={calendarRef}
                >
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range ">
                      <input
                        type="text"
                        value={
                          selectedRange
                            ? `${new Date(selectedRange[0]).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )} to ${new Date(selectedRange[1]).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}`
                            : null
                        }
                        id="date-range"
                        name="date-range"
                        placeholder="All Time"
                        onClick={toggleCalendar}
                        autocomplete="off"
                        readOnly
                      />
                    </div>
                    <div className="date-range-new left-align">
                      {showCalendar && (
                        <Calendar
                          onCancel={cancelCalendar}
                          onChange={(range) => {
                            const adjustedStartDate = range.startDate ? moment.tz(range.startDate, global.config.getTimeZone()).utc().toDate() : null;
                            const adjustedEndDate = range.endDate ? moment.tz(range.endDate, global.config.getTimeZone()).utc().toDate() : null;
                            onDateChange({
                              startDate: adjustedStartDate,
                              endDate: adjustedEndDate,
                            });
                          }}
                          initialRange={{
                            startDate: selectedRange ? new Date(selectedRange[0]) : null,
                            endDate: selectedRange ? new Date(selectedRange[1]) : null,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>    
              <div className="filter-inner account-type">
                <label htmlFor="accountType">Type of Account</label>
                <div className="custom-dropdown1" ref={accountTypeDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleAccountTypeDropdown}>
                      {selectedAccountType.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedAccountType[0]}>
                            {selectedAccountType.length === filteredAccountType.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    filteredAccountType.find(
                                      (item) => item.account_type === selectedAccountType[0]
                                    )?.account_type
                                  }
                                />
                                {selectedAccountType.length > 1 &&
                                  selectedAccountType.length !== filteredAccountType.length && (
                                    <div className="selected-option-count">
                                      {selectedAccountType.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isAccountTypeDropdownOpen && (
                      <div className="dropdown-list1">
                        <input
                          type="text"
                          placeholder="Search role"
                          value={accountTypeSearchTerm}
                          onChange={handleRoleIdSearchChange}
                          className="search-bar"
                        />
                        {filteredAccountTypeList.length > 0 && (
                          <>
                            {accountTypeSearchTerm === "" && (
                              <label className="checkbox-label1" key="selectAll">
                                <input
                                  type="checkbox"
                                  checked={selectAllAccountType}
                                  onChange={handleSelectAllAccountTypeChange}
                                />
                                All
                              </label>
                            )}
                            {filteredAccountTypeList
                              .sort((a, b) => (a.account_type || '').localeCompare(b.account_type || '')) // Sort by user_type alphabetically
                              .map((accountType) => (
                                <label className="checkbox-label1" key={accountType.account_type}>
                                  <input
                                    type="checkbox"
                                    checked={selectedAccountType.includes(accountType.account_type)}
                                    onChange={() => handleCheckboxAccountTypeChange(accountType.account_type)}
                                  />
                                  {accountType.account_type && `${accountType.account_type}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner user-category">
                <label htmlFor="userCategory">Invite Status</label>
                <div className="custom-dropdown1" ref={userInviteStatusDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleCategoryDropdown}>
                      {selectedCategory.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedCategory[0]}>
                            {selectedCategory.length === userInviteStatusList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    userInviteStatusList.find(
                                      (item) => item.value === selectedCategory[0]
                                    )?.name
                                  }
                                />
                                {selectedCategory.length > 1 &&
                                  selectedCategory.length !== userInviteStatusList.length && (
                                    <div className="selected-option-count">
                                      {selectedCategory.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isCategoryDropdownOpen && (
                      <div className="dropdown-list1">
                        <input
                          type="text"
                          placeholder="Search category"
                          value={userInviteStatusSearchTerm}
                          onChange={handleUserInviteStatusSearchChange}
                          className="search-bar"
                        />
                        {filteredUserInviteStatusList.length > 0 && (
                          <>
                            {userInviteStatusSearchTerm === "" && (
                              <label className="checkbox-label1" key="selectAll">
                                <input
                                  type="checkbox"
                                  checked={selectAllCategory}
                                  onChange={handleSelectAllCategoryChange}
                                />
                                All
                              </label>
                            )}
                            {filteredUserInviteStatusList
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by category name alphabetically
                              .map((category) => (
                                <label className="checkbox-label1" key={category.value}>
                                  <input
                                    type="checkbox"
                                    checked={selectedCategory.includes(category.value)}
                                    onChange={() => {
                                      handleCheckboxCategoryChange(category.value);
                                      setCurrentPage(1);
                                    }}
                                  />
                                  {category.name}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
            </div>
            <div className="filter-invoice-right apply-fliter-right">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filters
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={checkAndShowConfirmationModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">

                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                <div className="filter-inner due-date">
                    <label htmlFor="dueDate" className="calendar-icon">
                      Date Range
                    </label>
                    <div
                      className="date-picker-outer date-picker-outer-all"
                      ref={calendarModalRef}
                    >
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>

                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">

                          <input
                            type="text"
                            // onChange={}
                            value={
                              selectedRange
                                ? `${new Date(selectedRange[0]).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )} to ${new Date(selectedRange[1]).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}`
                                : null
                            }
                            id="invoiceId"
                            name="invoiceId"
                            placeholder="All Time"
                            onClick={toggleModalCalendar}
                            autocomplete="off"
                          />
                        </div>

                        {/* Date range selector */}

                        <div className="date-range-new">
                          {showModalCalendar && (
                            <Calendar
                              onCancel={cancelModalCalendar}
                              onChange={(range) => {
                                const adjustedStartDate = range.startDate ? moment.tz(range.startDate, global.config.getTimeZone()).utc().toDate() : null;
                                const adjustedEndDate = range.endDate ? moment.tz(range.endDate, global.config.getTimeZone()).utc().toDate() : null;
                                onDateChange({
                                  startDate: adjustedStartDate,
                                  endDate: adjustedEndDate,
                                });
                              }}
                              initialRange={{
                                startDate: selectedRange ? new Date(selectedRange[0]) : null,
                                endDate: selectedRange ? new Date(selectedRange[1]) : null,
                              }}
                            />
                          )}
                        </div>
                        {/* Date range selector */}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner payment-status">
                    <label htmlFor="typeofAccount">Type of Account</label>
                    <div className="custom-dropdown1" ref={accountTypeModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleAccountTypeModalDropdown}>
                          {selectedAccountType.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedAccountType[0]}>
                                {selectedAccountType.length === filteredAccountType.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        filteredAccountType.find(
                                          (item) => item.account_type === selectedAccountType[0]
                                        )?.user_type
                                      }
                                    />
                                    {selectedAccountType.length > 1 &&
                                      selectedAccountType.length !== filteredAccountType.length && (
                                        <div className="selected-option-count">
                                          {selectedAccountType.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {isAccountTypeModalDropdownOpen && (
                          <div className="dropdown-list1">
                            <input
                              type="text"
                              placeholder="Search role"
                              value={accountTypeSearchTerm}
                              onChange={handleRoleIdSearchChange}
                              className="search-bar"
                            />
                            {filteredAccountTypeList.length > 0 && (
                              <>
                                {accountTypeSearchTerm === "" && (
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectAllAccountType}
                                      onChange={handleSelectAllAccountTypeChange}
                                    />
                                    All
                                  </label>
                                )}
                                {filteredAccountTypeList.map((accountType) => (
                                  <label className="checkbox-label1" key={accountType.account_type}>
                                    <input
                                      type="checkbox"
                                      checked={selectedAccountType.includes(accountType.account_type)}
                                      onChange={() =>
                                        handleCheckboxAccountTypeChange(accountType.account_type)
                                      }
                                    />
                                    {accountType.account_type && `${accountType.account_type}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner user-category">
                    <label htmlFor="userCategory">Invite Status</label>
                    <div className="custom-dropdown1" ref={userInviteStatusModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleCategoryModalDropdown}
                        >
                          {selectedCategory.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedCategory[0]}>
                                {selectedCategory.length === userInviteStatusList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        userInviteStatusList.find(
                                          (item) => item.value === selectedCategory[0]
                                        )?.name
                                      }
                                    />
                                    {selectedCategory.length > 1 &&
                                      selectedCategory.length !== userInviteStatusList.length && (
                                        <div className="selected-option-count">
                                          {selectedCategory.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {isCategoryModalDropdownOpen && (
                          <div className="dropdown-list1">
                            <input
                              type="text"
                              placeholder="Search Category"
                              value={userInviteStatusSearchTerm}
                              onChange={handleUserInviteStatusSearchChange}
                              className="search-bar"
                            />
                            {filteredUserInviteStatusList.length > 0 && (
                              <>
                                {userInviteStatusSearchTerm === "" && (
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectAllCategory}
                                      onChange={handleSelectAllCategoryChange}
                                    />
                                    All
                                  </label>
                                )}
                                {filteredUserInviteStatusList.map((category) => (
                                  <label className="checkbox-label1" key={category.value}>
                                    <input
                                      type="checkbox"
                                      checked={selectedCategory.includes(category.value)}
                                      onChange={() => {
                                        handleCheckboxCategoryChange(category.value);
                                        setCurrentPage(1);
                                      }}
                                    />
                                    {category.name}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  <Modal
                    className="delete-modal"
                    isOpen={showConfirmationModal}
                    onRequestClose={handleCancel}
                    contentLabel="Confirm Delete"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "36%",
                        left: "50%",
                        transform: "translate(-24.75%, 0%)",
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <h2>Apply or Discard changes?</h2>
                      <p>
                        Do you want to Apply or Discard filter changes?
                      </p>

                      <div className="delete-modal-buttons">
                        <button className="delete" onClick={handleApplyConfirm} >
                          Apply Changes
                        </button>
                        <button onClick={clearFilters}>
                          Discard Changes
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              {/* <Link to="/user/register">
                <span className="icon-invoice">
                  <img
                    src={UserIcon}
                    alt="Create Invoice"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/uploads/lowProfileImage/user_default.png";
                    }}
                  />
                </span>
                Create User
              </Link> */}
              {/* <a className="btn-forpop-initem" onClick={handleOpenModal}>
                <span className="icon-invoice">
                  <img src={InviteIcon} alt="Export" />
                </span>
                Invite Users
              </a> */}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={!isMailLoading ? handleCloseModal : undefined}
                contentLabel="Invite a User"
                className="user-invite-modal-main-outer resend-modal-outer"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal resend-modal">
                  <div className="modal-top-section-user-invite">
                    <div className="modal-invite-head">
                      <h2 className="">Resend invitation</h2>
                    </div>
                    <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                      <a
                        className=""
                        onClick={handleCloseModal}
                        disabled={isMailLoading}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <path d="M1 13L13 1" stroke="black" />
                          <path d="M1 0.999999L13 13" stroke="black" />
                        </svg>
                      </a>
                    </div>
                  </div>

                  <form
                    onSubmit={
                      typeOfAccount === "Team"
                        ? handleTeamResendSingleSubmit
                        : typeOfAccount === "Client"
                          ? handleClientResendSingleSubmit
                          : handleVendorResendSingleSubmit
                    }
                  >
                    <div>
                      {emails.map((obj) => (
                        <div key={obj.id} className="the-modal-inuser">
                          <div className="user-add-outer-innner">
                            <div className="invite-user">
                              <label htmlFor="grid-password" className="">
                                Email Address
                              </label>
                              <input
                                type="email"
                                id={`email-${obj.id}`}
                                className=""
                                name="email"
                                required
                                placeholder="Enter Email ID"
                                value={obj.email}
                                disabled
                              />
                              {typeOfAccount === "Team" && (
                                <>
                                  <label htmlFor="grid-password" className="type-of-acc" >
                                    Role
                                  </label>

                                  {/* <div className={`custom-dropdown-user-new-outer`} >
                                        <div className="selected-option custom-dropdown-user-new" >
                                          {roleId.find((option) => option._id === obj.userType)?.user_type}
                                        </div>
                                      </div> */}
                                  <div
                                    className={`custom-dropdown-user-new-outer ${roleDropdownOpen === obj.id ? "open" : ""}`}
                                    ref={roleDropdownRef}
                                  >
                                    <div
                                      className="selected-option custom-dropdown-user-new"
                                      onClick={() => handleToggleRoleDropdown(obj.id)}
                                    >
                                      {obj.userType
                                        ? roleId.find((option) => option._id === obj.userType)?.user_type
                                        : "Select Role"}
                                    </div>
                                    {roleDropdownOpen === obj.id && (
                                      <div className="custom-dropdown-new">
                                        <ul className="role-list">
                                          {roleId.map((option) => (
                                            <li
                                              className={obj.userType === option._id ? "selected-item" : ""}
                                              key={option._id}
                                              onClick={() => {
                                                handleEditEmail(obj.id, "userType", option._id);
                                                setRoleDropdownOpen(null);
                                              }}
                                            >
                                              {option.user_type}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="invite-mail-popup-btn">
                      <button type="submit" className="" disabled={isMailLoading}>
                        {isMailLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Send Invite"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>



              {/* <a
                className="btn-forpop-initem bulk-import"
                onClick={handleBulkImportOpenModal}
              >
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Bulk Import
              </a> */}

              {/* <a
                className="btn-forpop-initem bulk-export"
                onClick={exportToExcel}
              >
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a> */}

              <Modal
                isOpen={isBulkImportModalOpen}
                onRequestClose={
                  !isBulkImportLoading ? handleBulkImportCloseModal : undefined
                }
                contentLabel="Invite a User"
                className="bulk-import-popup"
                style={{
                  content: {
                    height: "50%",
                    width: "50%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-17.5%, -46%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="outter-modal-main">
                    <a
                      className="close-image-clr1"
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>

                  <div className="outter-modal-main">
                    <p>Bulk Import</p>


                    <form>
                      <div className="relative w-full mb-3">
                        <label className="gray-label">Select client list</label>
                        <div className="file-input-wrapper">
                          <input
                            type="text"
                            className="form-input form-input-file"
                            value={bulkImportFileName}
                            readOnly
                          />
                          <input
                            type="file"
                            id="expFileAttchEdit"
                            name="expFileAttchEdit"
                            className="form-input hidden-input"
                            onChange={handleBulkImportUpload}
                          // required
                          />
                          <label
                            htmlFor="expFileAttch"
                            className="file-input-label"
                          >
                            <img src={Plus} alt="Browse" />
                            Browse
                          </label>
                        </div>
                        <div className="text-sm bulk-text">
                          Demo File: {"    "}
                          <a
                            href="https://docs.google.com/spreadsheets/d/1rCCZmtrRoUs2wL-58OzTunfEYZdiVRQnvKYrTYQIhoQ/edit?usp=sharing"
                            className="form-textarea"
                            target="_blank"
                          >
                            Import_demo.xlsx
                          </a>
                        </div>
                      </div>

                      <div className="bulk-btn-section sprint-btn-outer">
                        <button
                          className="submit-client cancel-sprint"
                          type="button"
                          onClick={handleBulkImportCloseModal}
                          disabled={isBulkImportLoading}
                        >
                          Close
                        </button>
                        <button
                          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={handleUpload}
                          disabled={isBulkImportLoading}
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>

            <div className="search-sec search-sec-user-overview">
              <div class="search-container">
                <input
                  type="text"
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                    setDebouncedValue(e.target.value);
                    setCurrentPage(1);
                  }}
                  value={searchFilter}
                  class="search-input"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          

        </div>
        <div className="filter-invoice-right">
            <div className="filter-inner showing-result">
              <label>Showing</label>
              <div
                ref={showingDropdownRef}
                className="select-modal-mainM custom-dropdown-user-new-outer"
              >
                <div
                  className="selected-option custom-dropdown-user-new"
                  onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                >
                  {total < 10 ? total.toString() : rowsPerPage.toString()}
                </div>
                {showingDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <ul className="showing-options-list">
                      {total >= 10 && (
                        <li
                          className={rowsPerPage === 10 ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({ target: { value: 10 } });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          10
                        </li>
                      )}
                      {total >= 20 && (
                        <li
                          className={rowsPerPage === 20 ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({ target: { value: 20 } });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          20
                        </li>
                      )}
                      {total >= 30 && (
                        <li
                          className={rowsPerPage === 30 ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({ target: { value: 30 } });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          30
                        </li>
                      )}
                      {total >= 40 && (
                        <li
                          className={rowsPerPage === 40 ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({ target: { value: 40 } });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          40
                        </li>
                      )}
                      {total > 10 && (
                        <li
                          className={total > 10 && rowsPerPage === total ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({ target: { value: total }, });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          ALL
                        </li>
                      )}
                      {total < 10 ? (
                        <li
                          className={total < 10 ? "selected-item" : ""}
                          onClick={() => {
                            handleRowsPerPageChange({
                              target: { value: total },
                            });
                            setShowingDropdownOpen(false);
                          }}
                        >
                          {total}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                )}
              </div>
              <label>of {total} Results</label>
            </div>
          </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card-outer-user">
              <h3>Invite History</h3>
            </div>
            <div className="time-table">
              <table style={{ minWidth: "50rem", width: "100%", textAlign: "left" }} className={`${isUpdating ? "loading-fade" : ""}`} >
                <thead>
                  <tr>
                    <th>
                      Email
                      {sortBy === 'email' && sortOrder === "asc" ? (
                        <span onClick={() => handleSort('email', 'desc')}>
                          <img src={DownArrowImage} alt="Down Arrow" />
                        </span>
                      ) : (
                        <span onClick={() => handleSort('email', 'asc')}>
                          <img src={UpArrowImage} alt="Up Arrow" />
                        </span>
                      )}
                    </th>
                    <th>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Type of Account
                        {sortBy === 'type_of_account' && sortOrder === "asc" ? (
                          <span onClick={() => handleSort('type_of_account', 'desc')}>
                            <img src={DownArrowImage} alt="Down Arrow" />
                          </span>
                        ) : (
                          <span onClick={() => handleSort('type_of_account', 'asc')}>
                            <img src={UpArrowImage} alt="Up Arrow" />
                          </span>
                        )}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Date Sent
                        {sortBy === 'created_at' && sortOrder === "asc" ? (
                          <span onClick={() => handleSort('created_at', 'desc')}>
                            <img src={DownArrowImage} alt="Down Arrow" />
                          </span>
                        ) : (
                          <span onClick={() => handleSort('created_at', 'asc')}>
                            <img src={UpArrowImage} alt="Up Arrow" />
                          </span>
                        )}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Status
                        {sortBy === 'invite_status' && sortOrder === "asc" ? (
                          <span onClick={() => handleSort('invite_status', 'desc')}>
                            <img src={DownArrowImage} alt="Down Arrow" />
                          </span>
                        ) : (
                          <span onClick={() => handleSort('invite_status', 'asc')}>
                            <img src={UpArrowImage} alt="Up Arrow" />
                          </span>
                        )}
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isUpdating && paginatedTeams?.length === 0 ? (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
                    </tr>
                  ) : (
                    paginatedTeams.map((row, index) => (
                      <tr key={row._id || index}>
                        <td>{row.email}</td>
                        <td>{row.type_of_account}</td>
                        <td>{new Date(row.created_at).toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' })}</td>
                        <td>{renderUserInviteStatus(row)}</td>
                        <td>
                          {row.invite_status !== "Accepted" && renderUserInviteCTA(row)}
                        </td>
                      </tr>
                    ))
                  )}

                  {isUpdating && (
                    <>
                      {paginatedTeams.length === 0 && (<tr className="loader-height"><td></td></tr>)}

                      <div className="loasding-spinner-fade">
                        <div className="loadsing-spinner">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="40"
                            visible={true}
                          />

                        </div>
                      </div>
                    </>
                  )}

                </tbody>
              </table>

            </div>

            {/* Pagination */}
            {paginatedTeams.length > 0 && (
              <ul className="pagination">
                <li className="back">
                  <button
                    className={currentPage === 1 ? "disabled" : ""}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Back
                  </button>
                </li>
                {/* {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <li key={page} className={currentPage === page ? "active" : ""}>
                    <button onClick={() => handlePageChange(page)}>{page}</button>
                  </li>
                ))} */}
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter((page) => {
                    if (
                      page === 1 ||
                      page === totalPages ||
                      (page >= currentPage - 2 && page <= currentPage + 2)
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .reduce((acc, page, index, array) => {
                    if (
                      index > 0 &&
                      page !== array[index - 1] + 1
                    ) {
                      acc.push("...");
                    }
                    acc.push(page);
                    return acc;
                  }, [])
                  .map((item, index) =>
                    item === "..." ? (
                      <li key={`ellipsis-${index}`} className="ellipsis">
                        <button
                          onClick={() => handlePageChange(index + (index === 1 ? 1 : 2))}
                        >
                          ...
                        </button>
                      </li>
                    ) : (
                      <li key={item} className={currentPage === item ? "active" : ""} >
                        <button onClick={() => handlePageChange(item)}>{item}</button>
                      </li>
                    )
                  )}
                <li className="next">
                  <button
                    className={currentPage === totalPages ? "disabled" : ""}
                    disabled={currentPage === totalPages || paginatedTeams.length < rowsPerPage}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            )}




          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default UserInviteHistory;
