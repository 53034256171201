import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';

export const useGetTimeThreshold = (token) => {
    return useQuery(['generalTimeThreshold'], async () => {
        try {
            const response = await axios.post('/api/general/thresholds/type', { thresholdType: "time" }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total duration');
        }
    });
};

export const useGetBudgetThreshold = (token) => {
    return useQuery(['generalBudgetThreshold'], async () => {
        try {
            const response = await axios.post('/api/general/thresholds/type', { thresholdType: "budget" }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching total duration');
        }
    });
};