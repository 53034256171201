import React, { useState, useRef, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import TimestampConverter from "../helpers/TimestampConverter.jsx";
import {
  useClientProjectDetails,
  useTagDetails,
  useTimerData,
} from "../TimerDataApi.jsx";
import { DatePicker } from "rsuite";

import TopArrow from "../../../../../images/icons/top-small-arrow.svg";
import DownArrow from "../../../../../images/icons/bottom-small-arrow.svg";
import Play from "../../../../../images/icons/play-btn.svg";
import Update from "../../../../../images/icons/upate-icon1.png";
import { setTimerData } from "../../../../../features/timerData/timerDataSlice.js";
import zoneConverter from "../helpers/zoneConverter.jsx";
import TimeEntryPopup from "./TimeEntryLockModal.jsx";
import MyComponent from "./EllipsisComponent.jsx";
import {
  convertTo24HourFormatback,
  formatChange,
  convertTo24HourFormat,
  formatTimeTo24Hr,
  convertTo12,
  isEndTimeGreater,
  isValidTimeFormat,
  convertTo24Hour,
  validateNoAlphabets,
} from "../services/timeFormat.js";
import userTypeConsts from "../../../../../constants/userTypeConsts.js";
const CardBody = ({ item, resumeTimeTracking, resumeLoading, customeTime, timeFormat, selectedUser }) => {
  const [deleteId, setDeleteId] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [taskNameEdit, setTaskNameEdit] = useState("");
  const [dateEdit, setDateEdit] = useState(new Date());
  const [startTimeEdit, setStartTimeEdit] = useState("");
  const [startTimeTemp, setStartTimeTemp] = useState("");
  const [startTimeEdit24, setStartTimeEdit24] = useState("");
  const [endTimeEdit, setEndTimeEdit] = useState("");
  const [endTimeTemp, setEndTimeTemp] = useState("");
  const [endTimeEdit24, setEndTimeEdit24] = useState("");
  const [clientEdit, setClientEdit] = useState(null);
  const [clientIdEdit, setClientIdEdit] = useState(null);
  const [projectNameEdit, setProjectNameEdit] = useState("");
  const [projectIdEdit, setProjectIdEdit] = useState("");
  const [dateOldEdit, setDateOldEdit] = useState(null);
  const [tagIdEdit, setTagIdEdit] = useState(null);
  const [billableEdit, setBillableEdit] = useState(false);
  const [uDuration, setUDuration] = useState(null);
  const [sprintIdEdit, setSprintIdEdit] = useState("");
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  // const [timerUpdate, setTimerUpdate] = useState(null);
  // const [isRunning, setIsRunning] = useState(false);
  // const [customeTime, setCustomeTime] = useState(false);
  // const [stopRunning, setStopRunning] = useState(null);
  const [uEndTimeError, setUEndTimeError] = useState(false);
  const [uStartTimeError, setUStartTimeError] = useState(false);
  // const [uEndTimeError24, setUEndTimeError24] = useState(false);
  // const [uStartTimeError24, setUStartTimeError24] = useState(false);
  const [sprintNameEdit, setSprintNameEdit] = useState("");
  // const [sprintName, setSprintName] = useState("");
  // const [sprintId, setSprintId] = useState("");
  // const [hrEdit, setHrEdit] = useState(0);
  // const [minEdit, setMinEdit] = useState(0);
  // const [secEdit, setSecEdit] = useState(0);
  // const [tagId, setTagId] = useState(null);
  // const [eClient, setEClient] = useState(null);
  const [projectEditDropdownOpen, setProjectEditDropdownOpen] = useState(false);
  const [tagEditDropdownOpen, setTagEditDropdownOpen] = useState(false);
  const [uFormatedTime, setUFormatedTime] = useState(0);
  const [uFormatedTimeError, setUFormatedTimeError] = useState(false);
  const [isTimeInputActive, setIsTimeInputActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedClients, setSelectedClients] = useState(new Set());
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectStatusFilter, setProjectStatusFilter] = useState("Active");
  const [isProjectStatusDropdownOpen, setIsProjectStatusDropdownOpen] = useState(false);
  const [isProjectStatusMobileDropdownOpen, setIsProjectStatusMobileDropdownOpen] = useState(false);
  const [isProjectStatusMergeDropdownOpen, setIsProjectStatusMergeDropdownOpen] = useState(false);
  const [isProjectStatusMobileMergeDropdownOpen, setIsProjectStatusMobileMergeDropdownOpen] = useState(false);
  const projectName = "";
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [tagEdit, setTagEdit] = useState(null);
  const [tagQuery, setTagQuery] = useState("");
  // const [tag, setTag] = useState(null);
  const datePickerRef = useRef();
  const dispatch = useDispatch();
  // const [isTableLoading, setIsTableLoading] = useState(false);
  const [groupedData, setGroupedData] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const projectEditDropdownRef = useRef(null);
  const projectEditdropdownRefs = useRef({});
  const tagEditDropdownRef = useRef(null);
  const editRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [clickId, setClickId] = useState(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  const currentTextCount = (text1, text2, text3) => {
    const text = `${text1} ${text2 ? "-" + text2 : ""} ${text3 ? text3 : ""}`;
    return text.length;
  };
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const startRowInputRef = useRef(null);
  const endRowInputRef = useRef(null);
  const start12TimeInputRef = useRef(null);
  const end12TimeInputRef = useRef(null);
  const start12TimeRowInputRef = useRef(null);
  const end12TimeRowInputRef = useRef(null);
  const activedropdownRefs = useRef({});
  const projectStatusDropdownRef = useRef(null);
  const projectStatusMobileDropdownRef = useRef(null);
  const projectStatusMergeDropdownRef = useRef(null);
  const projectStatusMobileMergeDropdownRef = useRef(null);
  const toggleGroup = (groupIndex) => {
    setEditedId(null);
    setExpandedGroups((prevGroups) => {
      if (prevGroups.includes(groupIndex)) {
        // Group is expanded, so collapse it
        return prevGroups.filter((index) => index !== groupIndex);
      } else {
        // Group is collapsed, so expand it
        return [...prevGroups, groupIndex];
      }
    });
  };

  const duplicate = async (
    task,
    project_id,
    tag_id,
    client,
    clientId,
    date,
    startTime,
    dupendTime,
    duration,
    sprint_name,
    sprint_id,
    _id,
    billable
  ) => {
    const postData = {
      task: task,
      project: project_id,
      date: dupendTime,
      tag: tag_id,
      billable: billable,
      startTime: startTime,
      endTime: dupendTime,
      duration: duration,
      client: client,
      clientId: clientId,
      user: selectedUser,
      id: _id,
      tz: global.config.getTimeZone(),
    };
    if (sprint_id) {
      postData.sprint = sprint_id;
    }
    setDuplicateLoading(true);
    await axios
      .post("/api/time-track/duplicate", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (response) => {
        // setTimerUpdate((prevState) => !prevState);
        setDuplicateLoading(false);
        await dispatch(setTimerData(Date.now()));

        global.config.activityLog(
          window.location.href,
          "Timer",
          `Duplicate created Successfully`
        );
        toast("Duplicate created Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIsRunning(false);
        // setCustomeTime(false);
        // setStopRunning((prevState) => !prevState);
        setIsUpdateLoading(false);
        // setSprintName("");
        // setSprintId("");
      });
    await dispatch(setTimerData(Date.now()));
    setActiveDropdownId(null);
  };

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const timerData = useSelector((state) => state.timerData.timerDataState);
  const timeTrackerLog = useSelector((state) => state.timeTrackerLog);

  const {
    data: timer = [],
    // isLoading: isTimerDataLoading,
    refetch: refetchTimerData,
  } = useTimerData(token, selectedUser);
  const { data: clientData = [], isLoading: isLoadingClientProjectDetails } =
    useClientProjectDetails(token);
  const { data: tagOptions = [], isLoading: isLoadingTagDetails } =
    useTagDetails(token);

  //expandedGroups
  useEffect(() => {
    refetchTimerData();
  }, [expandedGroups, selectedUser]);

  useEffect(() => {
    // setIsRunning(timeTrackerLog && timeTrackerLog.isRunning);
  }, [timeTrackerLog]);
  // functions
  const covertTimeSatmp = (dateEdit, sT) => {
    const date = new Date(dateEdit);
    const timeString = sT;
    const datePart = date.toLocaleDateString("en-US");
    const timePart = timeString;

    // Combine the date and time strings
    const combinedDateTimeString = `${datePart} ${timePart}`;
    return moment(combinedDateTimeString).format();
  };

  // function formatTime(value) {
  //   return value < 10 ? `0${value}` : value;
  // }

  // const formatTime = (time) => {
  //   return time.toString().padStart(2, '0');
  // };

  const handleTagInputChange = (event) => {
    setTagQuery(event.target.value);
    // setTag(event.target.value);
    setTagIdEdit(event.target.value);
  };

  const handleResetClick = async (taskId, group) => {
    setIsDeleteLoading(true);
    setDeleteId(taskId);
    // var userId = user._id;
    var userId = selectedUser;
    await axios
      .delete(`/api/time-track/delete-timer/${taskId}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (response) => {
        // setTimerUpdate((prevState) => !prevState);
        toast("Deleted Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsDeleteLoading(false);
        await dispatch(setTimerData(Date.now()));
        // Check if group entries length is 2 and reset expandedGroups
        if (group.entries.length === 2) {
          setExpandedGroups([]);
        }
      })
      .catch((error) => {
        console.log(error);
        global.config.slackMessage(error.toString());
        setIsDeleteLoading(false);
      });

    handleDropdownToggle();
  };

  // const filteredClientData = clientData
  //   .filter(
  //     (client) =>
  //       client.client_name.toLowerCase().includes(filter.toLowerCase()) ||
  //       client.projects.some((project) =>
  //         project.project_name.toLowerCase().includes(filter.toLowerCase())
  //       )
  //   )
  //   .sort((a, b) => a.client_name.localeCompare(b.client_name));

  const filteredClientData = clientData
    .filter((client) => {
      const clientMatches = client.client_name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const filteredProjects = client.projects.filter(
        (project) =>
          project.project_name
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
          (projectStatusFilter === "All"
            ? true
            : projectStatusFilter === "Active"
              ? project.is_active === true
              : project.is_active === false)
      );

      return filteredProjects.length > 0;
    })
    .sort((a, b) => a.client_name.localeCompare(b.client_name));

  const handleProjectStatusChange = (status) => {
    setProjectStatusFilter(status);
    setSelectedClients(new Set());
  };

  const toggleProjectStatusDropdown = () => {
    setIsProjectStatusDropdownOpen(!isProjectStatusDropdownOpen);
  };

  const toggleProjectStatusMobileDropdown = () => {
    setIsProjectStatusMobileDropdownOpen(!isProjectStatusMobileDropdownOpen);
  };

  const toggleProjectStatusMergeDropdown = () => {
    setIsProjectStatusMergeDropdownOpen(!isProjectStatusMergeDropdownOpen);
  };

  const toggleProjectStatusMobileMergeDropdown = () => {
    setIsProjectStatusMobileMergeDropdownOpen(!isProjectStatusMobileMergeDropdownOpen);
  };

  const handleDropdownToggle = (itemId) => {
    setActiveDropdownId((prevId) => (prevId === itemId ? null : itemId));
  };

  const updateTimer2 = async (event) => {
    event.preventDefault();
    if (!editedId) {
      toast("Missing Timer ID", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!taskNameEdit) {
      toast("Task name is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!projectIdEdit) {
      toast("Project is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!dateEdit) {
      toast("Date is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!startTimeEdit || !startTimeEdit.trim()) {
      toast("Start time is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!endTimeEdit || !endTimeEdit.trim()) {
      toast("End time is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!clientEdit) {
      toast("Client is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    if (!sprintIdEdit) {
      toast("Sprint is required", { position: "bottom-right", autoClose: 2000 });
      return;
    }
    const id = editedId;
    const task = taskNameEdit;
    const date = new Date(dateEdit);
    const startTimeFormated = covertTimeSatmp(
      date,
      startTimeEdit && startTimeEdit.trim()
    );
    const endTimeFormated = covertTimeSatmp(
      date,
      endTimeEdit && endTimeEdit.trim()
    );
    const client = clientEdit;
    const clientId = clientIdEdit;
    const startdate = moment
      .tz(dateEdit, global.config.getTimeZone())
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const postData = {
      task: task,
      project: projectIdEdit,
      date: startdate,
      oldDate: dateOldEdit,
      tag: tagIdEdit,
      billable: billableEdit,
      startTime: startTimeFormated,
      endTime: endTimeFormated,
      duration: uDuration,
      client: client,
      clientId: clientId,
      user: selectedUser,
      id: id,
      tz: global.config.getTimeZone(),
    };

    if (sprintIdEdit) {
      postData.sprint = sprintIdEdit;
    } else {
      postData.sprint = null;
    }

    setIsUpdateLoading(true);
    await axios
      .post("/api/time-track/custome-time-update", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (response) => {
        await dispatch(setTimerData(Date.now()));
        // setTimerUpdate((prevState) => !prevState);
        dispatch(setTimerData(Date.now()));
        setExpandedGroups([]);
        global.config.activityLog(
          window.location.href,
          "Timer",
          `Time Updated Successfully`
        );
        toast("Time Updated Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIsRunning(false);
        // setCustomeTime(false);
        // setStopRunning((prevState) => !prevState);
        setUEndTimeError(false);
        setUStartTimeError(false);
        setIsUpdateLoading(false);
        // setSprintName("");
        // setSprintId("");
        setEditedId(null);
        setTaskNameEdit(null);
        setProjectIdEdit(null);
        setProjectNameEdit(null);
        setSprintIdEdit(null);
        setSprintNameEdit(null);
        // setHrEdit(null);
        // setMinEdit(null);
        // setSecEdit(null);
        setDateEdit(null);
        // setTagId(null);
        // setTagIdEdit(null);
        setStartTimeEdit(TimestampConverter(null));
        setEndTimeEdit(TimestampConverter(null));
        setStartTimeEdit24(TimestampConverter(null));
        setEndTimeEdit24(TimestampConverter(null));
        // setEClient(null);
        setClientEdit(null);
        setClientIdEdit(null);
        setBillableEdit(null);
        refetchTimerData();
        setEditedId(null);
      });
  };

  const filteredTagItems = tagOptions
    .filter((item) =>
      item.tag_name.toLowerCase().includes(tagQuery.toLowerCase())
    )
    .sort((a, b) => a.tag_name.localeCompare(b.tag_name));

  const parseTimeInput = (input) => {
    input = input.trim().toLowerCase();
    let timeRegex = /^(\d{1,2}):?(\d{2})?\s*([ap]m?)?$/;
    let match = input.match(timeRegex);

    if (!match) {
      timeRegex = /^(\d{1,2}):?(\d{1,2})?\s*([ap]m?)?$/;
      match = input.match(timeRegex);
      if (!match) return false;
    }

    let [_, hour, minutes, period] = match;
    hour = hour.padStart(2, "0");
    minutes = minutes ? minutes.padStart(2, "0") : "00";
    if (
      parseInt(hour, 10) > 12 ||
      parseInt(hour, 10) < 1 ||
      parseInt(minutes, 10) > 59
    ) {
      return false;
    }
    period = period
      ? period?.toUpperCase()
      : parseInt(hour, 10) < 12
        ? "AM"
        : "PM";

    return `${hour}:${minutes} ${period}`;
  };



  const timeFormatChange = (time) => {
    const input = parseTimeInput(time);
    if (input) {
      const momentTime = moment(input, "hh:mm A");
      if (!momentTime.isValid()) return false;
      return momentTime.format("hh:mm A");
    }
    return false;
  };

  const isValidCustomTimeFormat = (timeString) => {
    const customTimeRegex = /^(0?[1-9]|1[0-2])[apAP]$/;
    return customTimeRegex.test(timeString);
  };

  const addOneHour = (startTime) => {
    const time = moment(startTime, "hh:mm A");
    const newTime = time.add(1, "hour");
    return newTime.format("hh:mm A");
  };

  const subOneHour = (startTime) => {
    const time = moment(startTime, "hh:mm A");
    const newTime = time.subtract(1, "hour");
    return newTime.format("hh:mm A");
  };

  const timeValidate = (timeString) => {
    return moment(timeString, "h:mm A", true).isValid();
  };

  const formatTime = (time) => {
    return time.toString().padStart(2, "0");
  };

  function convertToStandardTime(timeString) {
    const isPM = timeString.toLowerCase().includes("p");
    const hours = parseInt(timeString, 10);
    if (isNaN(hours)) {
      return "Invalid format";
    }
    const standardTime = moment({ hour: hours % 12, minute: 0 }).format(
      "hh:mm A"
    );
    return isPM ? standardTime.replace("AM", "PM") : standardTime;
  }


  const timeFomatChange2 = (time) => {
    const input = time.trim().toLowerCase();
    let converted = "";
    // Check if the input matches the supported time format

    if (/^\d{1,2}:\d{2}[ap]$/i.test(input)) {
      const [hour, minutes] = input.match(/\d+/g);
      const isPM = input.includes("p");
      let formattedHour = parseInt(hour, 10);
      if (isPM && formattedHour !== 12) {
        formattedHour += 12;
      }
      if (!isPM && formattedHour === 12) {
        formattedHour = 0;
      }
      formattedHour = formattedHour % 12 || 12;
      converted = `${formattedHour.toString().padStart(2, "0")}:${minutes} ${isPM ? "PM" : "AM"
        }`;
    } else {
      converted = false;
    }
    return converted;
  };

  useEffect(() => {
    if (startTimeEdit && endTimeEdit) {
      const timeString1 = startTimeEdit.trim();
      const timeString2 = endTimeEdit.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTime(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUStartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      } else {
        setUStartTimeError(true);
        setUEndTimeError(true);
      }
    }
  }, [startTimeTemp, endTimeTemp]);

  const changeStartTime = (startTime) => {
    let formattedTime = timeFormatChange(startTime);
    if (formattedTime === false) {
      setUStartTimeError(true);
      return;
    }

    setStartTimeEdit(formattedTime);
    setStartTimeTemp(formattedTime);
  };

  const changeEndTime = (endTime) => {
    let formattedTime = timeFormatChange(endTime);
    if (formattedTime === false) {
      setUEndTimeError(true);
      return;
    }
    setEndTimeEdit(formattedTime);
    setEndTimeTemp(formattedTime);
  };

  const changeStartTime24 = (startTime) => {
    setStartTimeEdit24(startTime);
    if (formatChange(startTime)) {
      var convertedTim = convertTo24HourFormat(startTime);

      setStartTimeEdit24(convertedTim);
      setStartTimeEdit(convertTo12(convertedTim));
      setUStartTimeError(false);
    } else {
      setUStartTimeError(true);
    }

    var oldTime = null;
    if (
      !isValidTimeFormat(formatTimeTo24Hr(startTime)) ||
      !isValidTimeFormat(formatTimeTo24Hr(endTimeEdit24))
    ) {
      setUFormatedTimeError(true);
    }

    if (
      isEndTimeGreater(
        formatTimeTo24Hr(startTime),
        formatTimeTo24Hr(endTimeEdit24)
      )
    ) {
      const SrtTime = convertTo12(formatTimeTo24Hr(startTime));
      const endTime = convertTo12(formatTimeTo24Hr(endTimeEdit24));
      const format = "h:mm A";
      const time1 = moment(SrtTime, format).tz(global.config.getTimeZone());
      const time2 = moment(endTime, format).tz(global.config.getTimeZone());

      const timeDifferenceInSeconds = time2.diff(time1, "seconds");
      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTime(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUStartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      }
    } else {
      setUStartTimeError(true);
      setUFormatedTimeError(true);
    }
  };


  const changeEndTime24 = (endTime) => {
    setEndTimeEdit24(endTime);
    if (formatChange(endTime)) {
      var convertedTim = convertTo24HourFormat(endTime);

      setEndTimeEdit24(convertedTim);
      setEndTimeEdit(convertTo12(convertedTim));
      setUStartTimeError(false);
    } else {
      setUStartTimeError(true);
    }

    if (
      !isValidTimeFormat(startTimeEdit24) ||
      !isValidTimeFormat(convertTo24HourFormat(endTime))
    ) {
      setUFormatedTimeError(true);
    }
    if (isEndTimeGreater(startTimeEdit24, convertTo24HourFormat(endTime))) {
      const SrtTime = convertTo12(startTimeEdit24);
      const edTime = convertTo12(convertTo24HourFormat(endTime));
      const format = "h:mm A";
      const time1 = moment(SrtTime, format).tz(global.config.getTimeZone());
      const time2 = moment(edTime, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");
      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTime(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUStartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      }
    } else {
      setUEndTimeError(true);
      setUFormatedTimeError(true);
    }

  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startInputRef.current &&
        !startInputRef.current.contains(event.target)
      ) {
        // Click occurred outside the input field, trigger changeStartTime24
        if (formatChange(startInputRef.current.value)) {
          changeStartTime24(startInputRef.current.value);
        } else {
          changeStartTime24(convertTo24Hour(startTimeEdit));
        }
      }
      if (endInputRef.current && !endInputRef.current.contains(event.target)) {
        if (formatChange(endInputRef.current.value)) {
          changeEndTime24(endInputRef.current.value);
        } else {
          changeEndTime24(convertTo24Hour(endTimeEdit));
        }
      }
      if (
        startRowInputRef.current &&
        !startRowInputRef.current.contains(event.target)
      ) {
        // Click occurred outside the input field, trigger changeStartTime24
        if (formatChange(startRowInputRef.current.value)) {
          changeStartTime24(startRowInputRef.current.value);
        } else {
          changeStartTime24(convertTo24Hour(startTimeEdit));
        }
      }
      if (
        endRowInputRef.current &&
        !endRowInputRef.current.contains(event.target)
      ) {
        if (formatChange(endRowInputRef.current.value)) {
          changeEndTime24(endRowInputRef.current.value);
        } else {
          changeEndTime24(convertTo24Hour(endTimeEdit));
        }
      }
      if (
        start12TimeInputRef.current &&
        !start12TimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(start12TimeInputRef.current.value);
        if (formattedTime === false) {
          changeStartTime(startTimeTemp);
        } else {
          changeStartTime(start12TimeInputRef.current.value);
        }
      }
      if (
        end12TimeInputRef.current &&
        !end12TimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(end12TimeInputRef.current.value);
        if (formattedTime === false) {
          changeEndTime(endTimeTemp);
        } else {
          changeEndTime(end12TimeInputRef.current.value);
        }
      }
      if (
        start12TimeRowInputRef.current &&
        !start12TimeRowInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(
          start12TimeRowInputRef.current.value
        );
        if (formattedTime === false) {
          changeStartTime(startTimeTemp);
        } else {
          changeStartTime(start12TimeRowInputRef.current.value);
        }
      }
      if (
        end12TimeRowInputRef.current &&
        !end12TimeRowInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(
          end12TimeRowInputRef.current.value
        );
        if (formattedTime === false) {
          changeEndTime(endTimeTemp);
        } else {
          changeEndTime(end12TimeRowInputRef.current.value);
        }
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [changeStartTime24, changeEndTime24, changeStartTime, changeEndTime]);

  const handleDateIconClick = () => {
    datePickerRef.current.open(); // Open the date picker programmatically
  };

  function validateTimeFormat(timeString) {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return timeRegex.test(timeString);
  }

  const timeShowEdit = (timeSec) => {
    if (validateTimeFormat(timeSec)) {
      const timeString = timeSec;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
      const date = dateEdit;
      const formattedDates = covertTimeSatmp(
        date,
        startTimeEdit && startTimeEdit.trim()
      );
      const timestampInSeconds = Math.floor(
        new Date(formattedDates).getTime() / 1000
      );
      setUDuration(parseInt(totalSeconds));
      const upcomingTimeInSeconds =
        parseInt(timestampInSeconds) + parseInt(totalSeconds);
      const upcomingTime = new Date(parseInt(upcomingTimeInSeconds) * 1000);
      const formattedTime =
        upcomingTime &&
        upcomingTime
          .toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace("am", "AM")
          .replace("pm", "PM");
      setUFormatedTimeError(false);
      setEndTimeEdit(formattedTime);
      setUFormatedTimeError(false);
      setUStartTimeError(false);
      setUEndTimeError(false);
    } else {
      setUFormatedTimeError(true);
    }
    setUFormatedTime(timeSec);
  };

  useEffect(() => {
    const getTableData = async () => {
      try {
        let filtered = item[1];
      } catch (err) {
        global.config.slackMessage(err.toString());
        console.log(err);
      }
    };
    getTableData();

    const updatedGroupedData = groupAndSumDurations(item[1]);
    setGroupedData(updatedGroupedData);
  }, [item, isUpdateLoading, timerData]);

  const groupAndSumDurations = (data) => {
    const groupedData = [];
    let currentGroup = null;
    let totalDuration = 0;
    let totalHr = 0;
    let totalMin = 0;
    let totalSec = 0;
    const formatDate = (dateString) => {
      if (!dateString) {
        return null;
      }
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toISOString().split("T")[0];
      return formattedDate;
    };

    data.sort((a, b) => {
      if (a.task !== b.task) return a.task.localeCompare(b.task);
      if (a?.project?._id !== b?.project?._id)
        return a?.project?._id.localeCompare(b?.project?._id);
      if (a.tag && b.tag && a.tag?._id !== b.tag?._id)
        return a.tag?._id.localeCompare(b.tag?._id);
      return 0;
    });

    data.forEach((row) => {
      if (
        currentGroup &&
        currentGroup.user.trim() === row.user.trim() &&
        currentGroup.task.trim() === row.task.trim() &&
        currentGroup.project._id === row.project._id &&
        currentGroup.tag?._id === row.tag?._id &&
        formatDate(currentGroup.date) === formatDate(row.date)
      ) {
        // If the current row belongs to the same group, update the duration

        totalDuration += row.duration;
        totalHr += row.hr;
        totalMin += row.min;
        totalSec += row.sec;
        // Update startTime and endTime within the group
        if (row.startTime < currentGroup.startTime) {
          currentGroup.startTime = row.startTime;
        }

        if (row.endTime > currentGroup.endTime) {
          currentGroup.endTime = row.endTime;
        }

        currentGroup.entries.push(row);
      } else {
        // If the row starts a new group, push the previous group (if any) and start a new one
        if (currentGroup) {
          currentGroup.totalDuration = totalDuration;
          currentGroup.totalHr = totalHr;
          currentGroup.totalMin = totalMin;
          currentGroup.totalSec = totalSec;
          groupedData.push(currentGroup);
        }
        // Start a new group
        currentGroup = {
          _id: row._id,
          user: row.user,
          task: row.task,
          project: row.project,
          sprint: row.sprint,
          tag: row.tag,
          date: row.date,
          startTime: row.startTime,
          endTime: row.endTime,
          totalHr: row.hr,
          totalMin: row.min,
          totalSec: row.sec,
          totalDuration: row.duration,
          billable: row.billable,
          client: row.client,
          clientId: row.clientId,
          date: row.date,
          entries: [row],
        };
        totalHr = row.hr;
        totalMin = row.min;
        totalSec = row.sec;
        totalDuration = row.duration;
      }
    });

    // Push the last group (if any)
    if (currentGroup) {
      currentGroup.totalDuration = totalDuration;
      currentGroup.totalHr = totalHr;
      currentGroup.totalMin = totalMin;
      currentGroup.totalSec = totalSec;
      groupedData.push(currentGroup);
    }

    groupedData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    return groupedData;
  };

  // end functions timerData
  useEffect(() => {
    const refetch = async () => {
      await refetchTimerData();
    };
    refetch();
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
        setEditedId(null);
        setClickId(null);
        setProjectEditDropdownOpen(false);
        setTagEditDropdownOpen(false);
        setUEndTimeError(false);
        setUStartTimeError(false);
        setUFormatedTime(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [timerData]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideProjectDropdown = projectEditDropdownRef.current && projectEditDropdownRef.current.contains(event.target);
    const isClickInsideTagDropdown = tagEditDropdownRef.current && tagEditDropdownRef.current.contains(event.target);
    const isClickInsideEdit = editRef.current && editRef.current.contains(event.target);
    const isClickInsideActiveDropdown = activeDropdownId && activedropdownRefs.current[activeDropdownId] && !activedropdownRefs.current[activeDropdownId].contains(event.target)
    const isClickInsideProjectStatusDropdown = projectStatusDropdownRef.current && projectStatusDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusMobileDropdown = projectStatusMobileDropdownRef.current && projectStatusMobileDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusMergeDropdown = projectStatusMergeDropdownRef.current && projectStatusMergeDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusMobileMergeDropdown = projectStatusMobileMergeDropdownRef.current && projectStatusMobileMergeDropdownRef.current.contains(event.target);

    // if (!isClickInsideProjectDropdown) {
    //   setProjectEditDropdownOpen(false);
    // }
    if (isClickInsideActiveDropdown) {
      setActiveDropdownId(null);
    }
    if (!isClickInsideProjectStatusDropdown) {
      setIsProjectStatusDropdownOpen(false);
    }
    if (!isClickInsideProjectStatusMobileDropdown) {
      setIsProjectStatusMobileDropdownOpen(false);
    }
    if (!isClickInsideProjectStatusMergeDropdown) {
      setIsProjectStatusMergeDropdownOpen(false);
    }
    if (!isClickInsideProjectStatusMobileMergeDropdown) {
      setIsProjectStatusMobileMergeDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, [activeDropdownId]);

  function getTimeComponents(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  const getLockHours = (date, timeZone, format) => {
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      return true;
    }
    const lockData = global.config.timerLock();
    const tz = global.config.getTimeZone();
    const currentDate = moment().tz(tz);
    const days = lockData.timerLock;
    const time = lockData.timerLockTime;
    const time24Hour = moment(time, "h:mm A").format("HH:mm:ss");
    const timeComponents = getTimeComponents(time24Hour);
    const oneDayBefore = currentDate.subtract(days, "days");
    const updatedDate = oneDayBefore.set({
      hour: timeComponents.hours,
      minute: timeComponents.minutes,
      second: timeComponents.seconds,
    });
    const specificDate = moment(updatedDate)
      .tz(tz)
      .format("YYYY-MM-DD HH:mm:ss");
    const currentTime = moment(date);
    const specificDate2 = moment(specificDate);
    if (Date.parse(specificDate2) < Date.parse(currentTime)) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (startTimeEdit && endTimeEdit) {
      const timeString1 = startTimeEdit.trim();
      const timeString2 = endTimeEdit.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUStartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      } else {
        setUStartTimeError(true);
        setUEndTimeError(true);
      }
    }
    startTimeEdit && setStartTimeEdit24(convertTo24Hour(startTimeEdit));
    endTimeEdit && setEndTimeEdit24(convertTo24Hour(endTimeEdit));
  }, [startTimeEdit, endTimeEdit]);

  return (
    <Card.Body className="body-mg">
      {item[0] === Date.now() ? (
        <p>sdsd</p>
      ) : (
        <>
          {groupedData
            .sort((a, b) => {
              const taskComparison = a.task.trim().localeCompare(b.task.trim());
              return new Date(b.date) - new Date(a.date);
            })
            .map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                <form onSubmit={updateTimer2}>
                  <>
                    <input type="hidden" name="id" value={group._id} />
                    {/* start */}
                    <div
                      className={`timetracker-inner data-time-tracker overview-sec-timer date-${group._id
                        } ${group._id === editedId && group.entries.length === 1
                          ? "editer"
                          : ""
                        }`}
                      style={{
                        backgroundColor:
                          group._id === editedId && group.entries.length === 1
                            ? "#f0f7f9"
                            : getLockHours(
                              moment(group.endTime)
                                .tz(global.config.getTimeZone())
                                .format("YYYY-MM-DD HH:mm:ss"),
                              global.config.getTimeZone(),
                              "YYYY-MM-DD HH:mm:ss"
                            ) === false
                              ? "#fff"
                              : "",
                        opacity:
                          group._id === editedId && group.entries.length === 1
                            ? "1"
                            : getLockHours(
                              moment(group.endTime)
                                .tz(global.config.getTimeZone())
                                .format("YYYY-MM-DD HH:mm:ss"),
                              global.config.getTimeZone(),
                              "YYYY-MM-DD HH:mm:ss"
                            ) === false
                              ? "1"
                              : "",
                      }}
                      onClick={() => {
                        if (
                          getLockHours(
                            moment(group.endTime)
                              .tz(global.config.getTimeZone())
                              .format("YYYY-MM-DD HH:mm:ss"),
                            global.config.getTimeZone(),
                            "YYYY-MM-DD HH:mm:ss"
                          ) === false
                        ) {
                          handleOpenPopup();
                        } else {
                          if (group.entries.length === 1) {
                            setClickId(group._id);
                          }
                        }
                      }}
                    >
                      <div className="merge-tracker">
                        <div className="overview-sec-timer-inner">
                          <div className="project-name">
                            {group.entries.length > 1 && (
                              <div
                                onClick={() => toggleGroup(groupIndex)}
                                style={{ cursor: "pointer" }}
                                className="count-merge"
                              >
                                <span>{group.entries.length}</span>
                              </div>
                            )}

                            <Col
                              className={
                                getLockHours(
                                  moment(group.endTime)
                                    .tz(global.config.getTimeZone())
                                    .format("YYYY-MM-DD HH:mm:ss"),
                                  global.config.getTimeZone(),
                                  "YYYY-MM-DD HH:mm:ss"
                                ) === true
                                  ? "cursor"
                                  : "cursor"
                              }
                              onClick={() => {
                                if (group.entries.length === 1) {
                                  setClickId(group._id);
                                } else {
                                  setClickId(null);
                                }
                                if (
                                  getLockHours(
                                    moment(group.endTime)
                                      .tz(global.config.getTimeZone())
                                      .format("YYYY-MM-DD HH:mm:ss"),
                                    global.config.getTimeZone(),
                                    "YYYY-MM-DD HH:mm:ss"
                                  ) === true
                                ) {
                                  if (group.entries.length === 1) {
                                    setEditedId(group._id);
                                    setProjectEditDropdownOpen(false);
                                    setTagEditDropdownOpen(false);
                                    setTaskNameEdit(group.task);
                                    setProjectIdEdit(group.project._id);
                                    setProjectNameEdit(group.project.project_name);
                                    if (group.sprint) {
                                      setSprintNameEdit(group.sprint.sprint_name);
                                      setSprintIdEdit(group.sprint._id);
                                    } else {
                                      setSprintIdEdit("");
                                      setSprintNameEdit("");
                                    }
                                    setDateEdit(
                                      zoneConverter(
                                        group.date,
                                        global.config.getTimeZone(),
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    );
                                    setTagIdEdit(group.tag && group.tag._id);
                                    setStartTimeEdit(
                                      TimestampConverter(group.startTime)
                                    );
                                    setEndTimeEdit(
                                      TimestampConverter(group.endTime)
                                    );
                                    setStartTimeTemp(
                                      TimestampConverter(group.startTime)
                                    );
                                    setEndTimeTemp(
                                      TimestampConverter(group.endTime)
                                    );
                                    setStartTimeEdit24(
                                      convertTo24HourFormatback(
                                        TimestampConverter(group.startTime)
                                      )
                                    );
                                    setEndTimeEdit24(
                                      convertTo24HourFormatback(
                                        TimestampConverter(group.endTime)
                                      )
                                    );
                                    setClientEdit(group.client);
                                    setClientIdEdit(group.clientId);
                                    setBillableEdit(group.billable);
                                    setUFormatedTime(
                                      `${formatTime(
                                        Math.floor(group.totalDuration / 3600)
                                      )}:${formatTime(
                                        Math.floor(
                                          parseInt(
                                            (group.totalDuration % 3600) / 60
                                          )
                                        )
                                      )}:${formatTime(
                                        Math.floor(group.totalDuration % 60)
                                      )}`
                                    );
                                    setUDuration(group.totalDuration);
                                    setUStartTimeError(false);
                                    setUEndTimeError(false);
                                    setUFormatedTimeError(false);
                                  } else {
                                    toggleGroup(groupIndex);
                                  }
                                } else {
                                  if (group.entries.length === 1) {
                                    setClickId(group._id);
                                  } else {
                                    toggleGroup(groupIndex);
                                    setClickId(null);
                                  }
                                }
                              }}
                            >
                              {group._id === editedId &&
                                group.entries.length === 1 ? (
                                <input
                                  type="text"
                                  name="task"
                                  value={taskNameEdit}
                                  onChange={(e) =>
                                    setTaskNameEdit(e.target.value)
                                  }
                                  placeholder="Regular Input"
                                  className={
                                    group._id === editedId &&
                                      group.entries.length === 1
                                      ? "input-edit-project"
                                      : "input-bg input-edit-project"
                                  }
                                  style={{ textOverflow: "ellipsis" }}
                                  autoComplete="off"
                                />
                              ) : group._id === editedId &&
                                group.entries.length > 1 ? (
                                <React.Fragment>
                                  {group.task.length > 20 && (
                                    <span className="tooltip-tracker testq">
                                      {group.task}
                                    </span>
                                  )}

                                  <input
                                    type="text"
                                    name="task"
                                    value={group.task}
                                    placeholder="Regular Input"
                                    style={{ textOverflow: "ellipsis" }}
                                    autoComplete="off"
                                  />
                                </React.Fragment>
                              ) : null}

                              {group._id !== editedId && (
                                <>
                                  <MyComponent
                                    group={group}
                                    editedId={editedId}
                                  />
                                </>
                              )}
                            </Col>
                          </div>

                          <div
                            className="project-sprint-outer col"
                            ref={projectEditDropdownRef}
                          >
                            {group._id === editedId &&
                              group.entries.length === 1 ? (
                              <>
                                <Col
                                  className="cursor 111"
                                  onClick={() => {
                                    if (group.entries.length === 1) {
                                      setClickId(group._id);
                                    } else {
                                      setClickId(null);
                                    }
                                    setProjectEditDropdownOpen(!projectEditDropdownOpen);
                                    setProjectStatusFilter("Active");
                                    setFilter("");
                                    setSelectedClients(new Set());
                                    setSelectedProject(null);
                                    setTagEditDropdownOpen(false);
                                    setUStartTimeError(false);
                                    setUEndTimeError(false);
                                    setUFormatedTimeError(false);
                                  }}
                                >
                                  <span
                                    className={`tooltip-tracker ${currentTextCount(
                                      projectNameEdit,
                                      sprintNameEdit && sprintNameEdit,
                                      clientEdit && clientEdit
                                    ) < 21 ? "small" : "large"}`}
                                  >
                                    {projectName ? projectNameEdit : projectNameEdit}
                                    {`${sprintNameEdit ? ` - ${sprintNameEdit}` : ""}`}
                                    <input
                                      type="hidden"
                                      name="project"
                                      value={projectIdEdit}
                                    />
                                    <div class="project-name-tracker">
                                      {clientEdit}
                                    </div>
                                  </span>
                                  <span
                                    className={`project-tracker-inner ${currentTextCount(projectNameEdit, sprintNameEdit && sprintNameEdit, clientEdit && clientEdit) < 21 ? "small" : "large"}`}
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      height: "22px",
                                    }}
                                  >
                                    {projectName ? projectNameEdit : projectNameEdit}
                                    {`${sprintNameEdit ? ` - ${sprintNameEdit}` : ""}`}
                                    <input
                                      type="hidden"
                                      name="project"
                                      value={projectIdEdit}
                                    />
                                    <div class="project-name-tracker">
                                      {clientEdit}
                                    </div>
                                  </span>
                                </Col>
                                {projectEditDropdownOpen && (
                                  <div className="custom-dropdown new-active-inactive">
                                    {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                      <>
                                        <div className="user-status-dropdown">
                                          <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                          <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                            <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                              {projectStatusFilter ? projectStatusFilter : "Active"}
                                            </div>
                                          </div>
                                        </div>
                                        {isProjectStatusDropdownOpen && (
                                          <ul className="status-list">
                                            <li
                                              onClick={() => handleProjectStatusChange("All")}
                                              className={projectStatusFilter === "All" ? "selected-item" : ""}
                                            >
                                              All
                                            </li>
                                            <li
                                              onClick={() => handleProjectStatusChange("Active")}
                                              className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                            >
                                              Active
                                            </li>
                                            <li
                                              onClick={() => handleProjectStatusChange("Inactive")}
                                              className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                            >
                                              Inactive
                                            </li>
                                          </ul>
                                        )}
                                      </>
                                    )}
                                    <div className="top-search-tracker-for">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Find Project or Client"
                                        value={filter}
                                        onChange={(e) => {
                                          const filterValue = e.target.value;
                                          setFilter(filterValue);

                                          if (filterValue === "") {
                                            setSelectedClients(new Set());
                                          } else {
                                            // Check if the filtered project exists under any client
                                            const matchingClients =
                                              filteredClientData.filter(
                                                (client) =>
                                                  client.projects.some(
                                                    (project) =>
                                                      project.project_name
                                                        .toLowerCase()
                                                        .includes(filterValue.toLowerCase()) &&
                                                      (projectStatusFilter === "All" ? true
                                                        : projectStatusFilter === "Active"
                                                          ? project.is_active === true
                                                          : project.is_active === false)
                                                  )
                                              );
                                            setSelectedClients(
                                              new Set(matchingClients)
                                            );
                                          }
                                        }}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <ul className={`tracker-who-list client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                                      {filteredClientData.map((client) => (
                                        <div key={client._id} className="main-outer-item-dd" >
                                          <div
                                            className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                            onClick={() => {
                                              if (group.entries.length === 1) {
                                                setClickId(group._id);
                                              } else {
                                                setClickId(null);
                                              }
                                              if (client.projects.filter(
                                                (project) =>
                                                  project.project_name
                                                    .toLowerCase()
                                                    .includes(filter.toLowerCase()) &&
                                                  (projectStatusFilter === "All"
                                                    ? true
                                                    : projectStatusFilter === "Active"
                                                      ? project.is_active === true
                                                      : project.is_active === false)
                                              ).length > 0) {
                                                // Toggle the client in the Set
                                                const newSelectedClients =
                                                  new Set(selectedClients);
                                                if (
                                                  selectedClients.has(client)
                                                ) {
                                                  newSelectedClients.delete(client);
                                                } else {
                                                  newSelectedClients.add(client);
                                                }
                                                setSelectedClients(newSelectedClients);
                                              }
                                            }}
                                          >
                                            <span className="main-project">
                                              {client.client_name}{" "}
                                            </span>
                                            {client.projects.filter(
                                              (project) =>
                                                project.project_name
                                                  .toLowerCase()
                                                  .includes(filter.toLowerCase()) &&
                                                (projectStatusFilter === "All"
                                                  ? true
                                                  : projectStatusFilter === "Active"
                                                    ? project.is_active === true
                                                    : project.is_active === false)
                                            ).length > 0 && (
                                                <span className={`arrow ${selectedClients.has(client) ? "open" : ""}`} >
                                                  <span className="main-project-title">
                                                    {client.projects.filter(
                                                      (project) =>
                                                        project.project_name
                                                          .toLowerCase()
                                                          .includes(filter.toLowerCase()) &&
                                                        (projectStatusFilter === "All"
                                                          ? true
                                                          : projectStatusFilter === "Active"
                                                            ? project.is_active === true
                                                            : project.is_active === false)
                                                    ).length}{" "}
                                                    Projects
                                                  </span>
                                                  {selectedClients.has(client) ? (
                                                    <img src={TopArrow} alt="Open" />
                                                  ) : (
                                                    <img src={DownArrow} alt="Close" />
                                                  )}
                                                </span>
                                              )}
                                          </div>
                                          {selectedClients.has(client) && (
                                            <div className="project-name1-outer">
                                              {/* Render projects under the selected client */}
                                              {client.projects.filter(
                                                (project) =>
                                                  project.project_name
                                                    .toLowerCase()
                                                    .includes(filter.toLowerCase()) &&
                                                  (projectStatusFilter === "All"
                                                    ? true
                                                    : projectStatusFilter === "Active"
                                                      ? project.is_active === true
                                                      : project.is_active === false)
                                              ).length === 0 ? (
                                                <div>
                                                  {" "} No projects under this client{" "}
                                                </div>
                                              ) : (
                                                client.projects
                                                  .filter((project) =>
                                                    project.project_name
                                                      .toLowerCase()
                                                      .includes(filter.toLowerCase()) &&
                                                    (projectStatusFilter === "All" ? true
                                                      : projectStatusFilter === "Active"
                                                        ? project.is_active === true
                                                        : project.is_active === false)
                                                  )
                                                  .sort((a, b) =>
                                                    a.project_name.localeCompare(b.project_name)
                                                  )
                                                  .map((project) => (
                                                    <div key={project._id}>
                                                      <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                                        <div
                                                          onClick={(e) => {
                                                            setSprintIdEdit("");
                                                            setSprintNameEdit("");
                                                            setProjectIdEdit(project._id);
                                                            setProjectNameEdit(project.project_name);
                                                            setClientEdit(client.client_name);
                                                            setClientIdEdit(client._id);
                                                            setBillableEdit(project.is_billable);
                                                            setProjectEditDropdownOpen(false);
                                                          }}
                                                        >
                                                          <span className="project-dd-title">
                                                            {project.project_name}{" "}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          {project.sprints.length > 0 && (
                                                            <span
                                                              className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                              onClick={() =>
                                                                setSelectedProject(selectedProject === project ? null : project)
                                                              }
                                                            >
                                                              <span className="main-project-title">
                                                                {project.sprints.length}{" "} Sprints
                                                              </span>
                                                              {selectedProject === project ? (
                                                                <img src={TopArrow} alt="Open" />
                                                              ) : (
                                                                <img src={DownArrow} alt="Close" />
                                                              )}
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                      {selectedProject === project && (
                                                        <div>
                                                          {/* Render sprints under the selected project */}
                                                          {project.sprints.length === 0 ? (
                                                            <div>
                                                              No sprints under this project
                                                            </div>
                                                          ) : (
                                                            <ul className="sprint-list">
                                                              {project.sprints
                                                                .sort((a, b) =>
                                                                  a.sprint_name.localeCompare(b.sprint_name)
                                                                )
                                                                .map(
                                                                  (sprint) => (
                                                                    <li key={sprint._id}
                                                                      onClick={(e) => {
                                                                        setSprintIdEdit(sprint._id);
                                                                        setSprintNameEdit(sprint.sprint_name);
                                                                        setProjectIdEdit(project._id);
                                                                        setProjectNameEdit(project.project_name);
                                                                        setClientEdit(client.client_name);
                                                                        setClientIdEdit(client._id);
                                                                        setBillableEdit(project.is_billable);
                                                                        setProjectEditDropdownOpen(false);
                                                                      }}
                                                                    >
                                                                      {sprint.sprint_name}
                                                                    </li>
                                                                  )
                                                                )}
                                                            </ul>
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  ))
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </>
                            ) : group._id === editedId &&
                              group.entries.length > 1 ? (
                              <React.Fragment>
                                <Col
                                  className={
                                    getLockHours(
                                      moment(group.endTime)
                                        .tz(global.config.getTimeZone())
                                        .format("YYYY-MM-DD HH:mm:ss"),
                                      global.config.getTimeZone(),
                                      "YYYY-MM-DD HH:mm:ss"
                                    ) === true
                                      ? "cursor"
                                      : "cursor"
                                  }
                                  onClick={() => {
                                    if (
                                      getLockHours(
                                        moment(group.endTime)
                                          .tz(global.config.getTimeZone())
                                          .format("YYYY-MM-DD HH:mm:ss"),
                                        global.config.getTimeZone(),
                                        "YYYY-MM-DD HH:mm:ss"
                                      ) === true
                                    ) {
                                      if (group.entries.length === 1) {
                                        setEditedId(group._id);
                                        setProjectEditDropdownOpen(false);
                                        setTagEditDropdownOpen(false);
                                        setTaskNameEdit(group.task);
                                        setProjectIdEdit(group.project._id);
                                        setProjectNameEdit(group.project.project_name);
                                        if (group.sprint) {
                                          setSprintNameEdit(group.sprint.sprint_name);
                                          setSprintIdEdit(group.sprint._id);
                                        } else {
                                          setSprintIdEdit("");
                                          setSprintNameEdit("");
                                        }
                                        setDateEdit(
                                          moment
                                            .tz(group.date, "YYYY-MM-DD HH:mm:ss", global.config.getTimeZone())
                                            .format()
                                        );
                                        // setTagId(group.tag && group.tag._id);
                                        setTagIdEdit(group.tag && group.tag._id);
                                        setStartTimeEdit(TimestampConverter(group.startTime));
                                        setEndTimeEdit(TimestampConverter(group.endTime));
                                        setStartTimeTemp(TimestampConverter(group.startTime));
                                        setEndTimeTemp(TimestampConverter(group.endTime));
                                        setStartTimeEdit24(
                                          convertTo24HourFormatback(TimestampConverter(group.startTime))
                                        );
                                        setEndTimeEdit24(
                                          convertTo24HourFormatback(TimestampConverter(group.endTime))
                                        );
                                        setClientEdit(group.client);
                                        setClientIdEdit(group.clientId);
                                        setBillableEdit(group.billable);
                                        setUFormatedTime(
                                          `${formatTime(
                                            Math.floor(group.totalDuration / 3600)
                                          )}:${formatTime(
                                            Math.floor(parseInt((group.totalDuration % 3600) / 60))
                                          )}:${formatTime(
                                            Math.floor(group.totalDuration % 60)
                                          )}`
                                        );
                                        setUDuration(group.totalDuration);
                                        setUStartTimeError(false);
                                        setUEndTimeError(false);
                                        setUFormatedTimeError(false);
                                      } else {
                                        toggleGroup(groupIndex);
                                      }
                                    } else {
                                      if (group.entries.length === 1) {
                                        setClickId(group._id);
                                      } else {
                                        setClickId(null);
                                      }
                                    }
                                  }}
                                >
                                  <span
                                    className={`tooltip-tracker ${currentTextCount(
                                      group.project.project_name,
                                      group.sprint &&
                                      group.sprint.sprint_name,
                                      group.client && group.client
                                    ) < 21 ? "small" : "large"}`}
                                  >
                                    {group.project.project_name}{" "}
                                    {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                    <div class="project-name-tracker">
                                      {group.client}
                                    </div>
                                  </span>
                                  <span
                                    className={`project-tracker-inner 11  ${currentTextCount(
                                      group.project.project_name,
                                      group.sprint &&
                                      group.sprint.sprint_name,
                                      group.client && group.client
                                    ) < 21 ? "small" : "large"}`}
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      height: "22px",
                                    }}
                                  >
                                    {group.project.project_name}{" "}
                                    {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                    <div class="project-name-tracker">
                                      {group.client}
                                    </div>
                                  </span>
                                </Col>
                              </React.Fragment>
                            ) : null}

                            {group._id !== editedId && (
                              <Col
                                className={
                                  getLockHours(
                                    moment(group.endTime)
                                      .tz(global.config.getTimeZone())
                                      .format("YYYY-MM-DD HH:mm:ss"),
                                    global.config.getTimeZone(),
                                    "YYYY-MM-DD HH:mm:ss"
                                  ) === true ? "cursor" : "cursor"
                                }
                                onClick={() => {
                                  if (
                                    getLockHours(
                                      moment(group.endTime)
                                        .tz(global.config.getTimeZone())
                                        .format("YYYY-MM-DD HH:mm:ss"),
                                      global.config.getTimeZone(),
                                      "YYYY-MM-DD HH:mm:ss"
                                    ) === true
                                  ) {
                                    if (group.entries.length === 1) {
                                      setEditedId(group._id);
                                      setProjectEditDropdownOpen(false);
                                      setTagEditDropdownOpen(false);
                                      setTaskNameEdit(group.task);
                                      setProjectIdEdit(group.project._id);
                                      setProjectNameEdit(group.project.project_name);
                                      if (group.sprint) {
                                        setSprintNameEdit(group.sprint.sprint_name);
                                        setSprintIdEdit(group.sprint._id);
                                      } else {
                                        setSprintIdEdit("");
                                        setSprintNameEdit("");
                                      }
                                      setDateEdit(
                                        zoneConverter(group.date, global.config.getTimeZone(), "YYYY-MM-DD HH:mm:ss")
                                      );
                                      setTagIdEdit(group.tag && group.tag._id);
                                      setStartTimeEdit(TimestampConverter(group.startTime));
                                      setEndTimeEdit(TimestampConverter(group.endTime));
                                      setStartTimeTemp(TimestampConverter(group.startTime));
                                      setEndTimeTemp(TimestampConverter(group.endTime));
                                      setStartTimeEdit24(
                                        convertTo24HourFormatback(TimestampConverter(group.startTime))
                                      );
                                      setEndTimeEdit24(
                                        convertTo24HourFormatback(TimestampConverter(group.endTime))
                                      );
                                      setClientEdit(group.client);
                                      setClientIdEdit(group.clientId);
                                      setBillableEdit(group.billable);
                                      setUFormatedTime(
                                        `${formatTime(
                                          Math.floor(group.totalDuration / 3600)
                                        )}:${formatTime(
                                          Math.floor(
                                            parseInt((group.totalDuration % 3600) / 60)
                                          )
                                        )}:${formatTime(
                                          Math.floor(group.totalDuration % 60)
                                        )}`
                                      );
                                      setUDuration(group.totalDuration);
                                      setUStartTimeError(false);
                                      setUEndTimeError(false);
                                      setUFormatedTimeError(false);
                                    } else {
                                      toggleGroup(groupIndex);
                                    }
                                  } else {
                                    if (group.entries.length === 1) {
                                      setClickId(group._id);
                                    } else {
                                      setClickId(null);
                                    }
                                  }
                                }}
                              >
                                <span
                                  className={`tooltip-tracker ${currentTextCount(
                                    group.project.project_name,
                                    group.sprint && group.sprint.sprint_name,
                                    group.client && group.client
                                  ) < 21 ? "small" : "large"}`}
                                >
                                  {group.project.project_name}
                                  {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                  <div class="project-name-tracker">
                                    {group.client}
                                  </div>
                                </span>
                                <span
                                  className={`project-tracker-inner 11  ${currentTextCount(
                                    group.project.project_name,
                                    group.sprint && group.sprint.sprint_name,
                                    group.client && group.client
                                  ) < 21 ? "small" : "large"}`}
                                  style={{
                                    display: "inline-block",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    height: "22px",
                                  }}
                                >
                                  {group.project.project_name}
                                  {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                  <div class="project-name-tracker">
                                    {group.client}
                                  </div>
                                </span>
                              </Col>
                            )}
                          </div>

                          {/* mobile view */}
                          <div
                            className="custom-dropdown12"
                            style={{ display: "none" }}
                            ref={(el) => (activedropdownRefs.current[group._id] = el)}
                          >
                            <div
                              className="dropdown-toggle1"
                              onClick={() => {
                                if (
                                  getLockHours(
                                    moment(group.endTime)
                                      .tz(global.config.getTimeZone())
                                      .format("YYYY-MM-DD HH:mm:ss"),
                                    global.config.getTimeZone(),
                                    "YYYY-MM-DD HH:mm:ss"
                                  ) === true
                                ) {
                                  handleDropdownToggle(group._id);
                                } else {
                                  if (group.entries.length === 1) {
                                    setClickId(group._id);
                                  } else {
                                    setClickId(null);
                                  }
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4"
                                height="20"
                                viewBox="0 0 4 20"
                                fill="none"
                              >
                                <circle cx="2" cy="2" r="2" fill="#96A8BA" />
                                <circle cx="2" cy="10" r="2" fill="#96A8BA" />
                                <circle cx="2" cy="18" r="2" fill="#96A8BA" />
                              </svg>
                            </div>
                            {activeDropdownId === group._id && (
                              <ul className="dropdown-menu1">
                                <li
                                  onClick={() => {
                                    if (!duplicateLoading) {
                                      duplicate(
                                        group.task,
                                        group.project._id,
                                        group.tag._id,
                                        group.client,
                                        group.project.client_name,
                                        group.date,
                                        group.startTime,
                                        group.endTime,
                                        group.totalDuration,
                                        group.sprint
                                          ? group.sprint.sprint_name
                                          : null,
                                        group.sprint ? group.sprint._id : null,
                                        group._id,
                                        group.billable
                                      );
                                    }
                                  }}
                                >
                                  <span>
                                    {duplicateLoading ? (
                                      <div className="outter-load-table">
                                        <ThreeDots
                                          height="30"
                                          width="40"
                                          radius="9"
                                          color="#6479f9"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                      </div>
                                    ) : (
                                      "Duplicate"
                                    )}
                                  </span>
                                </li>
                                <li
                                  onClick={() =>
                                    !isDeleteLoading &&
                                    handleResetClick(group._id, group)
                                  }
                                >
                                  {group._id === deleteId && isDeleteLoading ? (
                                    <div className="outter-load-table">
                                      <ThreeDots
                                        height="30"
                                        width="40"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div>
                                  ) : (
                                    <span>Delete</span>
                                  )}
                                </li>
                              </ul>
                            )}
                            <div>
                              {showPopup &&
                                clickId === group._id &&
                                group.entries.length > 1 && (
                                  <TimeEntryPopup
                                    onClose={() => setShowPopup(false)}
                                  />
                                )}
                            </div>
                          </div>

                          {/* mobile view */}
                        </div>

                        <div className="outer-right-sec-overview">
                          <div className="overview-sec-timer-inner-right">
                            {/* Mobile view */}
                            <div
                              className="project-sprint-outer col project-sprint-outer-mobile"
                              style={{ display: "none" }}
                              ref={projectEditDropdownRef}
                            >
                              {group._id === editedId &&
                                group.entries.length === 1 ? (
                                <>
                                  <Col
                                    className="cursor"
                                    onClick={() => {
                                      setProjectEditDropdownOpen(!projectEditDropdownOpen);
                                      setProjectStatusFilter("Active");
                                      setFilter("");
                                      setSelectedClients(new Set());
                                      setSelectedProject(null);
                                      setTagEditDropdownOpen(false);
                                    }}
                                  >
                                    <span
                                      className={`tooltip-tracker ${currentTextCount(
                                        projectNameEdit,
                                        sprintNameEdit && sprintNameEdit,
                                        clientEdit && clientEdit
                                      ) < 21
                                        ? "small"
                                        : "large"
                                        }`}
                                    >
                                      {projectName
                                        ? projectNameEdit
                                        : projectNameEdit}
                                      {`${sprintNameEdit
                                        ? ` - ${sprintNameEdit}`
                                        : " "
                                        }`}
                                      <input
                                        type="hidden"
                                        name="project"
                                        value={projectIdEdit}
                                      />
                                      <div class="project-name-tracker">
                                        {clientEdit ? clientEdit : clientEdit}
                                      </div>
                                    </span>
                                    <span
                                      className={`project-name-edit-inner ${currentTextCount(
                                        projectNameEdit,
                                        sprintNameEdit && sprintNameEdit,
                                        clientEdit && clientEdit
                                      ) < 21
                                        ? "small"
                                        : "large"
                                        }`}
                                      style={{
                                        display: "inline-block",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        height: "22px",
                                        maxWidth: "140px",
                                      }}
                                    >
                                      {projectName
                                        ? projectNameEdit
                                        : projectNameEdit}
                                      {`${sprintNameEdit
                                        ? ` - ${sprintNameEdit}`
                                        : " "
                                        }`}
                                      <input
                                        type="hidden"
                                        name="project"
                                        value={projectIdEdit}
                                      />
                                      <div class="project-name-tracker">
                                        {clientEdit ? clientEdit : clientEdit}
                                      </div>
                                    </span>
                                  </Col>

                                  {projectEditDropdownOpen && (
                                    <div className="custom-dropdown">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Find Project or Client"
                                        value={filter}
                                        onChange={(e) => {
                                          const filterValue = e.target.value;
                                          setFilter(filterValue);

                                          if (filterValue === "") {
                                            setSelectedClients(new Set());
                                          } else {
                                            // Check if the filtered project exists under any client
                                            const matchingClients =
                                              filteredClientData.filter(
                                                (client) =>
                                                  client.projects.some(
                                                    (project) =>
                                                      project.project_name
                                                        .toLowerCase()
                                                        .includes(filterValue.toLowerCase()) &&
                                                      (projectStatusFilter === "All" ? true
                                                        : projectStatusFilter === "Active"
                                                          ? project.is_active === true
                                                          : project.is_active === false)
                                                  )
                                              );
                                            setSelectedClients(
                                              new Set(matchingClients)
                                            );
                                          }
                                        }}
                                        autoComplete="off"
                                      />
                                      {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                        <>
                                          <div className="user-status-dropdown">
                                            <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                            <div className="custom-dropdown-user" ref={projectStatusMobileDropdownRef}>
                                              <div className="showing-name" onClick={toggleProjectStatusMobileDropdown}>
                                                {projectStatusFilter ? projectStatusFilter : "Active"}
                                              </div>
                                            </div>
                                          </div>
                                          {isProjectStatusMobileDropdownOpen && (
                                            <ul className="status-list">
                                              <li
                                                onClick={() => handleProjectStatusChange("All")}
                                                className={projectStatusFilter === "All" ? "selected-item" : ""}
                                              >
                                                All
                                              </li>
                                              <li
                                                onClick={() => handleProjectStatusChange("Active")}
                                                className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                              >
                                                Active
                                              </li>
                                              <li
                                                onClick={() => handleProjectStatusChange("Inactive")}
                                                className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                              >
                                                Inactive
                                              </li>
                                            </ul>
                                          )}
                                        </>
                                      )}
                                      <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                                        {filteredClientData.map((client) => (
                                          <div
                                            key={client._id}
                                            className="main-outer-item-dd"
                                          >
                                            {/* Render the client name */}
                                            <div
                                              className={`client-name1 ${selectedClients.has(client)
                                                ? "selected"
                                                : ""
                                                }`}
                                              onClick={() => {
                                                if (
                                                  client.projects.filter(
                                                    (project) =>
                                                      project.project_name
                                                        .toLowerCase()
                                                        .includes(filter.toLowerCase()) &&
                                                      (projectStatusFilter === "All"
                                                        ? true
                                                        : projectStatusFilter === "Active"
                                                          ? project.is_active === true
                                                          : project.is_active === false)
                                                  ).length > 0
                                                ) {
                                                  // Toggle the client in the Set
                                                  const newSelectedClients =
                                                    new Set(selectedClients);
                                                  if (
                                                    selectedClients.has(client)
                                                  ) {
                                                    newSelectedClients.delete(
                                                      client
                                                    );
                                                  } else {
                                                    newSelectedClients.add(
                                                      client
                                                    );
                                                  }
                                                  setSelectedClients(
                                                    newSelectedClients
                                                  );
                                                }
                                              }}
                                            >
                                              <span className="main-project">
                                                {client.client_name}{" "}
                                              </span>
                                              {client.projects.filter(
                                                (project) =>
                                                  project.project_name
                                                    .toLowerCase()
                                                    .includes(filter.toLowerCase()) &&
                                                  (projectStatusFilter === "All"
                                                    ? true
                                                    : projectStatusFilter === "Active"
                                                      ? project.is_active === true
                                                      : project.is_active === false)
                                              ).length > 0 && (
                                                  <span
                                                    className={`arrow ${selectedClients.has(client)
                                                      ? "open"
                                                      : ""
                                                      }`}
                                                  >
                                                    <span className="main-project-title">
                                                      {client.projects.filter(
                                                        (project) =>
                                                          project.project_name
                                                            .toLowerCase()
                                                            .includes(filter.toLowerCase()) &&
                                                          (projectStatusFilter === "All"
                                                            ? true
                                                            : projectStatusFilter === "Active"
                                                              ? project.is_active === true
                                                              : project.is_active === false)
                                                      ).length}{" "}
                                                      Projects
                                                    </span>
                                                    {selectedClients.has(
                                                      client
                                                    ) ? (
                                                      <img
                                                        src={TopArrow}
                                                        alt="Open"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={DownArrow}
                                                        alt="Close"
                                                      />
                                                    )}
                                                  </span>
                                                )}
                                            </div>
                                            {selectedClients.has(client) && (
                                              <div className="project-name1-outer">
                                                {/* Render projects under the selected client */}
                                                {client.projects.filter(
                                                  (project) =>
                                                    project.project_name
                                                      .toLowerCase()
                                                      .includes(filter.toLowerCase()) &&
                                                    (projectStatusFilter === "All"
                                                      ? true
                                                      : projectStatusFilter === "Active"
                                                        ? project.is_active === true
                                                        : project.is_active === false)
                                                ).length ===
                                                  0 ? (
                                                  <div>
                                                    No projects under this client
                                                  </div>
                                                ) : (
                                                  client.projects
                                                    .filter((project) =>
                                                      project.project_name
                                                        .toLowerCase()
                                                        .includes(filter.toLowerCase()) &&
                                                      (projectStatusFilter === "All"
                                                        ? true
                                                        : projectStatusFilter === "Active"
                                                          ? project.is_active === true
                                                          : project.is_active === false)
                                                    )
                                                    .sort((a, b) =>
                                                      a.project_name.localeCompare(
                                                        b.project_name
                                                      )
                                                    )
                                                    .map((project) => (
                                                      <div key={project._id}>
                                                        <div
                                                          className={`project-name1 ${selectedProject ===
                                                            project
                                                            ? "selected"
                                                            : ""
                                                            }`}
                                                        >
                                                          <div
                                                            onClick={(e) => {
                                                              setSprintIdEdit("");
                                                              setSprintNameEdit("");
                                                              setProjectIdEdit(project._id);
                                                              setProjectNameEdit(project.project_name);
                                                              setClientEdit(client.client_name);
                                                              setClientIdEdit(client._id);
                                                              setBillableEdit(project.is_billable);
                                                              setProjectEditDropdownOpen(false);
                                                            }}
                                                          >
                                                            <span className="project-dd-title">
                                                              {project.project_name}{" "}
                                                            </span>
                                                          </div>
                                                          <div>
                                                            {project.sprints.length > 0 && (
                                                              <span
                                                                className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                                onClick={() =>
                                                                  setSelectedProject(selectedProject === project ? null : project)
                                                                }
                                                              >
                                                                <span className="main-project-title">
                                                                  {project.sprints.length}{" "} Sprints
                                                                </span>
                                                                {selectedProject === project ? (
                                                                  <img src={TopArrow} alt="Open" />
                                                                ) : (
                                                                  <img src={DownArrow} alt="Close" />
                                                                )}
                                                              </span>
                                                            )}
                                                          </div>
                                                        </div>
                                                        {selectedProject === project && (
                                                          <div>
                                                            {/* Render sprints under the selected project */}
                                                            {project.sprints.length === 0 ? (
                                                              <div>
                                                                {" "} No sprints under this project{" "}
                                                              </div>
                                                            ) : (
                                                              <ul className="sprint-list">
                                                                {project.sprints
                                                                  .sort(
                                                                    (a, b) =>
                                                                      a.sprint_name.localeCompare(b.sprint_name)
                                                                  )
                                                                  .map(
                                                                    (sprint) => (
                                                                      <li
                                                                        key={sprint._id}
                                                                        onClick={(e) => {
                                                                          setSprintIdEdit(sprint._id);
                                                                          setSprintNameEdit(sprint.sprint_name);
                                                                          setProjectIdEdit(project._id);
                                                                          setProjectNameEdit(project.project_name);
                                                                          setClientEdit(client.client_name);
                                                                          setClientIdEdit(client._id);
                                                                          setBillableEdit(project.is_billable);
                                                                          setProjectEditDropdownOpen(false);
                                                                        }}
                                                                      >
                                                                        {sprint.sprint_name}
                                                                      </li>
                                                                    )
                                                                  )}
                                                              </ul>
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    ))
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </>
                              ) : group._id === editedId &&
                                group.entries.length > 1 ? (
                                <React.Fragment>
                                  <Col
                                    className={
                                      getLockHours(
                                        moment(group.endTime)
                                          .tz(global.config.getTimeZone())
                                          .format("YYYY-MM-DD HH:mm:ss"),
                                        global.config.getTimeZone(),
                                        "YYYY-MM-DD HH:mm:ss"
                                      ) === true
                                        ? "cursor"
                                        : "cursor"
                                    }
                                    onClick={() => {
                                      if (
                                        getLockHours(
                                          moment(group.endTime)
                                            .tz(global.config.getTimeZone())
                                            .format("YYYY-MM-DD HH:mm:ss"),
                                          global.config.getTimeZone(),
                                          "YYYY-MM-DD HH:mm:ss"
                                        ) === true
                                      ) {
                                        if (group.entries.length === 1) {
                                          setEditedId(group._id);
                                          setProjectEditDropdownOpen(false);
                                          setTagEditDropdownOpen(false);
                                          setTaskNameEdit(group.task);
                                          setProjectIdEdit(group.project._id);
                                          setProjectNameEdit(group.project.project_nameF);
                                          if (group.sprint) {
                                            setSprintNameEdit(group.sprint.sprint_name);
                                            setSprintIdEdit(group.sprint._id);
                                          } else {
                                            setSprintIdEdit("");
                                            setSprintNameEdit("");
                                          }
                                          setDateEdit(moment.tz(group.date, "YYYY-MM-DD HH:mm:ss", global.config.getTimeZone()).format());
                                          setTagIdEdit(group.tag && group.tag._id);
                                          setStartTimeEdit(TimestampConverter(group.startTime));
                                          setEndTimeEdit(TimestampConverter(group.endTime));
                                          setStartTimeTemp(TimestampConverter(group.startTime));
                                          setEndTimeTemp(TimestampConverter(group.endTime));
                                          setStartTimeEdit24(
                                            convertTo24HourFormatback(TimestampConverter(group.startTime))
                                          );
                                          setEndTimeEdit24(
                                            convertTo24HourFormatback(TimestampConverter(group.endTime))
                                          );

                                          setClientEdit(group.client);
                                          setClientIdEdit(group.clientId);
                                          setBillableEdit(group.billable);
                                          setUFormatedTime(
                                            `${formatTime(
                                              Math.floor(group.totalDuration / 3600)
                                            )}:${formatTime(
                                              Math.floor(
                                                parseInt((group.totalDuration % 3600) / 60)
                                              )
                                            )}:${formatTime(
                                              Math.floor(group.totalDuration % 60)
                                            )}`
                                          );
                                          setUDuration(group.totalDuration);
                                          setUStartTimeError(false);
                                          setUEndTimeError(false);
                                          setUFormatedTimeError(false);
                                        } else {
                                          toggleGroup(groupIndex);
                                        }
                                      } else {
                                        if (group.entries.length === 1) {
                                          setClickId(group._id);
                                        } else {
                                          setClickId(null);
                                        }
                                      }
                                    }}
                                  >
                                    <span
                                      className={`tooltip-tracker ${currentTextCount(
                                        group.project.project_name,
                                        group.sprint &&
                                        group.sprint.sprint_name,
                                        group.client && group.client
                                      ) < 21 ? "small" : "large"}`}
                                    >
                                      {group.project.project_name}

                                      {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                      <div class="project-name-tracker">
                                        {group.client}{" "}
                                      </div>
                                    </span>
                                    <span
                                      className={`project-tracker-inner ${currentTextCount(
                                        group.project.project_name,
                                        group.sprint &&
                                        group.sprint.sprint_name,
                                        group.client && group.client
                                      ) < 21 ? "small" : "large"}`}
                                      style={{
                                        display: "inline-block",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        height: "22px",
                                        maxWidth: "140px",
                                      }}
                                    >
                                      {group.project.project_name}
                                      {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                      <div class="project-name-tracker">
                                        {group.client}{" "}
                                      </div>
                                    </span>
                                  </Col>
                                </React.Fragment>
                              ) : null}

                              {group._id !== editedId && (
                                <Col
                                  className={
                                    getLockHours(
                                      moment(group.endTime)
                                        .tz(global.config.getTimeZone())
                                        .format("YYYY-MM-DD HH:mm:ss"),
                                      global.config.getTimeZone(),
                                      "YYYY-MM-DD HH:mm:ss"
                                    ) === true
                                      ? "cursor"
                                      : "cursor"
                                  }
                                  onClick={() => {
                                    if (
                                      getLockHours(
                                        moment(group.endTime)
                                          .tz(global.config.getTimeZone())
                                          .format("YYYY-MM-DD HH:mm:ss"),
                                        global.config.getTimeZone(),
                                        "YYYY-MM-DD HH:mm:ss"
                                      ) === true
                                    ) {
                                      if (group.entries.length === 1) {
                                        setEditedId(group._id);
                                        setProjectEditDropdownOpen(false);
                                        setTagEditDropdownOpen(false);
                                        setTaskNameEdit(group.task);
                                        setProjectIdEdit(group.project._id);
                                        setProjectNameEdit(
                                          group.project.project_name
                                        );
                                        if (group.sprint) {
                                          setSprintNameEdit(group.sprint.sprint_name);
                                          setSprintIdEdit(group.sprint._id);
                                        } else {
                                          setSprintIdEdit("");
                                          setSprintNameEdit("");
                                        }
                                        setDateEdit(
                                          zoneConverter(
                                            group.date,
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        );
                                        setTagIdEdit(group.tag && group.tag._id);
                                        setStartTimeEdit(
                                          TimestampConverter(group.startTime)
                                        );
                                        setEndTimeEdit(
                                          TimestampConverter(group.endTime)
                                        );
                                        setStartTimeTemp(
                                          TimestampConverter(group.startTime)
                                        );
                                        setEndTimeTemp(
                                          TimestampConverter(group.endTime)
                                        );
                                        setStartTimeEdit24(
                                          convertTo24HourFormatback(
                                            TimestampConverter(group.startTime)
                                          )
                                        );
                                        setEndTimeEdit24(
                                          convertTo24HourFormatback(
                                            TimestampConverter(group.endTime)
                                          )
                                        );
                                        setClientEdit(group.client);
                                        setClientIdEdit(group.clientId);
                                        setBillableEdit(group.billable);
                                        setUFormatedTime(
                                          `${formatTime(
                                            Math.floor(group.totalDuration / 3600)
                                          )}:${formatTime(
                                            Math.floor(parseInt((group.totalDuration % 3600) / 60))
                                          )}:${formatTime(
                                            Math.floor(group.totalDuration % 60)
                                          )}`
                                        );
                                        setUDuration(group.totalDuration);
                                        setUStartTimeError(false);
                                        setUEndTimeError(false);
                                        setUFormatedTimeError(false);
                                      } else {
                                        toggleGroup(groupIndex);
                                      }
                                    } else {
                                      if (group.entries.length === 1) {
                                        setClickId(group._id);
                                      } else {
                                        setClickId(null);
                                      }
                                    }
                                  }}
                                >
                                  <span
                                    className={`tooltip-tracker ${currentTextCount(
                                      group.project.project_name,
                                      group.sprint &&
                                      group.sprint.sprint_name,
                                      group.client && group.client
                                    ) < 21 ? "small" : "large"}`}
                                  >
                                    {group.project.project_name}

                                    {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                    <div class="project-name-tracker">
                                      {group.client}{" "}
                                    </div>
                                  </span>
                                  <span
                                    className={`project-tracker-inner ${currentTextCount(
                                      group.project.project_name,
                                      group.sprint &&
                                      group.sprint.sprint_name,
                                      group.client && group.client
                                    ) < 21 ? "small" : "large"}`}
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      height: "22px",
                                      maxWidth: "140px",
                                    }}
                                  >
                                    {group.project.project_name}
                                    {`${group.sprint ? ` - ${group.sprint.sprint_name}` : ""}`}
                                    <div class="project-name-tracker">
                                      {group.client}{" "}
                                    </div>
                                  </span>
                                </Col>
                              )}
                            </div>

                            {/* end mobile */}

                            {/* tag , timer section */}
                            <div className="tag-section-mobile-section">
                              <div className="tag-section-overview-outer dddd">
                                {group._id === editedId &&
                                  group.entries.length === 1 ? (
                                  <div className="tag-section">
                                    <p
                                      className={`cursor ${group.tag ? "tag-available" : "tag-not-available"}`}
                                      onClick={() => {
                                        setTagEditDropdownOpen(!tagEditDropdownOpen);
                                        setProjectEditDropdownOpen(false);
                                      }}
                                    >
                                      <span className="tooltip-tracker">
                                        {" "}
                                        {tagEdit ? (
                                          tagEdit
                                        ) : group.tag ? (
                                          group.tag.tag_name
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                            <path
                                              d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                          </svg>
                                        )}
                                      </span>
                                      <span
                                        className="tag-edit-tag-name"
                                        style={{
                                          display: "inline-block",
                                          maxWidth: "180px",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {" "}
                                        {tagEdit ? (
                                          tagEdit
                                        ) : group.tag ? (
                                          group.tag.tag_name
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                            <path
                                              d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                          </svg>
                                        )}
                                      </span>
                                    </p>
                                    {tagEditDropdownOpen && (
                                      <div className="custom-dropdown">
                                        <input
                                          type="text"
                                          className="search-bar"
                                          placeholder="Search..."
                                          value={tagEdit ? tagEdit : tagEdit}
                                          onChange={handleTagInputChange}
                                          autoComplete="off"
                                        />
                                        <ul className="client-list">
                                          {filteredTagItems.map((group) => (
                                            <li
                                              className="cursor"
                                              key={group._id}
                                              onClick={(e) => {
                                                setTagEdit(group.tag_name);
                                                setTagIdEdit(group._id);
                                                setTagEditDropdownOpen(false);
                                              }}
                                            >
                                              {group.tag_name}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                ) : group._id === editedId &&
                                  group.entries.length > 1 ? (
                                  <React.Fragment>
                                    <div
                                      className="tag-section"
                                      onClick={() => {
                                        if (
                                          getLockHours(
                                            moment(group.endTime)
                                              .tz(global.config.getTimeZone())
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          ) === true
                                        ) {
                                          if (group.entries.length === 1) {
                                            setEditedId(group._id);
                                            setProjectEditDropdownOpen(false);
                                            setTagEditDropdownOpen(false);
                                            setTaskNameEdit(group.task);
                                            setProjectIdEdit(group.project._id);
                                            setProjectNameEdit(group.project.project_name);
                                            if (group.sprint) {
                                              setSprintNameEdit(group.sprint.sprint_name);
                                              setSprintIdEdit(group.sprint._id);
                                            } else {
                                              setSprintIdEdit("");
                                              setSprintNameEdit("");
                                            }

                                            setDateEdit(
                                              moment
                                                .tz(
                                                  group.date,
                                                  "YYYY-MM-DD HH:mm:ss",
                                                  global.config.getTimeZone()
                                                )
                                                .format()
                                            );

                                            setTagIdEdit(
                                              group.tag && group.tag._id
                                            );
                                            setStartTimeEdit(
                                              TimestampConverter(group.startTime)
                                            );
                                            setEndTimeEdit(
                                              TimestampConverter(group.endTime)
                                            );
                                            setStartTimeTemp(
                                              TimestampConverter(group.startTime)
                                            );
                                            setEndTimeTemp(
                                              TimestampConverter(group.endTime)
                                            );
                                            setStartTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              )
                                            );
                                            setEndTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(group.endTime)
                                              )
                                            );

                                            setClientEdit(group.client);
                                            setClientIdEdit(group.clientId);
                                            setBillableEdit(group.billable);
                                            setUFormatedTime(
                                              `${formatTime(
                                                Math.floor(
                                                  group.totalDuration / 3600
                                                )
                                              )}:${formatTime(
                                                Math.floor(
                                                  parseInt(
                                                    (group.totalDuration % 3600) /
                                                    60
                                                  )
                                                )
                                              )}:${formatTime(
                                                Math.floor(
                                                  group.totalDuration % 60
                                                )
                                              )}`
                                            );
                                            setUDuration(group.totalDuration);
                                            setUStartTimeError(false);
                                            setUEndTimeError(false);
                                            setUFormatedTimeError(false);
                                          } else {
                                            toggleGroup(groupIndex);
                                          }
                                        } else {
                                          if (group.entries.length === 1) {
                                            setClickId(group._id);
                                          } else {
                                            setClickId(null);
                                          }
                                        }
                                      }}
                                    >
                                      <p
                                        className={`cursor ${group.tag
                                          ? "tag-available"
                                          : "tag-not-available"
                                          }`}
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(group.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            if (group.entries.length === 1) {
                                              setEditedId(group._id);
                                              setProjectEditDropdownOpen(false);
                                              setTagEditDropdownOpen(false);
                                              setTaskNameEdit(group.task);
                                              setProjectIdEdit(group.project._id);
                                              setProjectNameEdit(group.project.project_name);
                                              if (group.sprint) {
                                                setSprintNameEdit(group.sprint.sprint_name);
                                                setSprintIdEdit(group.sprint._id);
                                              } else {
                                                setSprintIdEdit("");
                                                setSprintNameEdit("");
                                              }

                                              setDateEdit(
                                                moment
                                                  .tz(
                                                    group.date,
                                                    "YYYY-MM-DD HH:mm:ss",
                                                    global.config.getTimeZone()
                                                  )
                                                  .format()
                                              );

                                              setTagIdEdit(
                                                group.tag && group.tag._id
                                              );
                                              setStartTimeEdit(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeEdit(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeTemp(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeTemp(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                )
                                              );
                                              setEndTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                )
                                              );

                                              setClientEdit(group.client);
                                              setClientIdEdit(group.clientId);
                                              setBillableEdit(group.billable);
                                              setUFormatedTime(
                                                `${formatTime(
                                                  Math.floor(
                                                    group.totalDuration / 3600
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    parseInt(
                                                      (group.totalDuration %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    group.totalDuration % 60
                                                  )
                                                )}`
                                              );
                                              setUDuration(group.totalDuration);
                                              setUStartTimeError(false);
                                              setUEndTimeError(false);
                                              setUFormatedTimeError(false);
                                            } else {
                                              toggleGroup(groupIndex);
                                            }
                                          } else {
                                            if (group.entries.length === 1) {
                                              setClickId(group._id);
                                            } else {
                                              setClickId(null);
                                            }
                                          }
                                        }}
                                      >
                                        <span className="tooltip-tracker">
                                          {" "}
                                          {group.tag ? (
                                            group.tag.tag_name
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                fill="#96A8BA"
                                                stroke="white"
                                                strokeWidth="0.2"
                                              ></path>
                                              <path
                                                d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                fill="#96A8BA"
                                                stroke="white"
                                                strokeWidth="0.2"
                                              ></path>
                                            </svg>
                                          )}
                                        </span>
                                        <span
                                          style={{
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {" "}
                                          {group.tag ? (
                                            group.tag.tag_name
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                fill="#96A8BA"
                                                stroke="white"
                                                strokeWidth="0.2"
                                              ></path>
                                              <path
                                                d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                fill="#96A8BA"
                                                stroke="white"
                                                strokeWidth="0.2"
                                              ></path>
                                            </svg>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {group._id !== editedId && (
                                  <div
                                    className="tag-section"
                                    onClick={() => {
                                      if (
                                        getLockHours(
                                          moment(group.endTime)
                                            .tz(global.config.getTimeZone())
                                            .format("YYYY-MM-DD HH:mm:ss"),
                                          global.config.getTimeZone(),
                                          "YYYY-MM-DD HH:mm:ss"
                                        ) === true
                                      ) {
                                        if (group.entries.length === 1) {
                                          setEditedId(group._id);
                                          setProjectEditDropdownOpen(false);
                                          setTagEditDropdownOpen(false);
                                          setTaskNameEdit(group.task);
                                          setProjectIdEdit(group.project._id);
                                          setProjectNameEdit(group.project.project_name);
                                          if (group.sprint) {
                                            setSprintNameEdit(group.sprint.sprint_name);
                                            setSprintIdEdit(group.sprint._id);
                                          } else {
                                            setSprintIdEdit("");
                                            setSprintNameEdit("");
                                          }

                                          setDateEdit(
                                            zoneConverter(
                                              group.date,
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            )
                                          );

                                          setTagIdEdit(
                                            group.tag && group.tag._id
                                          );
                                          setStartTimeEdit(
                                            TimestampConverter(group.startTime)
                                          );
                                          setEndTimeEdit(
                                            TimestampConverter(group.endTime)
                                          );
                                          setStartTimeTemp(
                                            TimestampConverter(group.startTime)
                                          );
                                          setEndTimeTemp(
                                            TimestampConverter(group.endTime)
                                          );
                                          setStartTimeEdit24(
                                            convertTo24HourFormatback(
                                              TimestampConverter(group.startTime)
                                            )
                                          );
                                          setEndTimeEdit24(
                                            convertTo24HourFormatback(
                                              TimestampConverter(group.endTime)
                                            )
                                          );


                                          setClientEdit(group.client);
                                          setClientIdEdit(group.clientId);
                                          setBillableEdit(group.billable);
                                          setUFormatedTime(
                                            `${formatTime(
                                              Math.floor(
                                                group.totalDuration / 3600
                                              )
                                            )}:${formatTime(
                                              Math.floor(
                                                parseInt(
                                                  (group.totalDuration % 3600) /
                                                  60
                                                )
                                              )
                                            )}:${formatTime(
                                              Math.floor(group.totalDuration % 60)
                                            )}`
                                          );
                                          setUDuration(group.totalDuration);
                                          setUStartTimeError(false);
                                          setUEndTimeError(false);
                                          setUFormatedTimeError(false);
                                        } else {
                                          toggleGroup(groupIndex);
                                        }
                                      } else {
                                        if (group.entries.length === 1) {
                                          setClickId(group._id);
                                        } else {
                                          setClickId(null);
                                        }
                                      }
                                    }}
                                  >
                                    <p
                                      className={`cursor ${group.tag
                                        ? "tag-available"
                                        : "tag-not-available"
                                        }`}
                                      onClick={() => {
                                        if (
                                          getLockHours(
                                            moment(group.endTime)
                                              .tz(global.config.getTimeZone())
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          ) === true
                                        ) {
                                          if (group.entries.length === 1) {
                                            setEditedId(group._id);
                                            setProjectEditDropdownOpen(false);
                                            setTagEditDropdownOpen(false);
                                            setTaskNameEdit(group.task);
                                            setProjectIdEdit(group.project._id);
                                            setProjectNameEdit(group.project.project_name);
                                            if (group.sprint) {
                                              setSprintNameEdit(group.sprint.sprint_name);
                                              setSprintIdEdit(group.sprint._id);
                                            } else {
                                              setSprintIdEdit("");
                                              setSprintNameEdit("");
                                            }

                                            setDateEdit(
                                              zoneConverter(
                                                group.date,
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            );

                                            setTagIdEdit(
                                              group.tag && group.tag._id
                                            );
                                            setStartTimeEdit(
                                              TimestampConverter(group.startTime)
                                            );
                                            setEndTimeEdit(
                                              TimestampConverter(group.endTime)
                                            );
                                            setStartTimeTemp(
                                              TimestampConverter(group.startTime)
                                            );
                                            setEndTimeTemp(
                                              TimestampConverter(group.endTime)
                                            );
                                            setStartTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              )
                                            );
                                            setEndTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(group.endTime)
                                              )
                                            );

                                            setClientEdit(group.client);
                                            setClientIdEdit(group.clientId);
                                            setBillableEdit(group.billable);
                                            setUFormatedTime(
                                              `${formatTime(
                                                Math.floor(
                                                  group.totalDuration / 3600
                                                )
                                              )}:${formatTime(
                                                Math.floor(
                                                  parseInt(
                                                    (group.totalDuration % 3600) /
                                                    60
                                                  )
                                                )
                                              )}:${formatTime(
                                                Math.floor(
                                                  group.totalDuration % 60
                                                )
                                              )}`
                                            );
                                            setUDuration(group.totalDuration);
                                            setUStartTimeError(false);
                                            setUEndTimeError(false);
                                            setUFormatedTimeError(false);
                                          } else {
                                            toggleGroup(groupIndex);
                                          }
                                        } else {
                                          if (group.entries.length === 1) {
                                            setClickId(group._id);
                                          } else {
                                            setClickId(null);
                                          }
                                        }
                                      }}
                                    >
                                      <span className="tooltip-tracker">
                                        {" "}
                                        {group.tag ? (
                                          group.tag.tag_name
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                            <path
                                              d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                          </svg>
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {" "}
                                        {group.tag ? (
                                          group.tag.tag_name
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                            <path
                                              d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                              fill="#96A8BA"
                                              stroke="white"
                                              strokeWidth="0.2"
                                            ></path>
                                          </svg>
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                <input
                                  type="hidden"
                                  name="tag"
                                  value={tagIdEdit}
                                />
                              </div>

                              <div className="billable-section">
                                {editedId === group._id ? (
                                  billableEdit === true ? (
                                    <div className="billable-timing-sec billable-timing">
                                      <svg
                                        width="13"
                                        height="20"
                                        viewBox="0 0 13 20"
                                        fill="#405BFF"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                          fill="#96A8BA"
                                        />
                                      </svg>
                                    </div>
                                  ) : (
                                    <div className="billable-timing-sec non-billable-timing">
                                      <svg
                                        width="13"
                                        height="20"
                                        viewBox="0 0 13 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                          fill="#96A8BA"
                                        />
                                      </svg>
                                    </div>
                                  )
                                ) : group.billable === true ? (
                                  <div className="billable-timing-sec billable-timing">
                                    <svg
                                      width="13"
                                      height="20"
                                      viewBox="0 0 13 20"
                                      fill="#405BFF"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                        fill="#96A8BA"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <div className="billable-timing-sec non-billable-timing">
                                    <svg
                                      width="13"
                                      height="20"
                                      viewBox="0 0 13 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                        fill="#96A8BA"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`overview-sec-timer-date-time date-${group._id}`}
                                style={{ position: "relative" }}
                              >
                                <Col>
                                  {group._id === editedId &&
                                    group.entries.length === 1 ? (
                                    <>
                                      <div className="time-overview-sec">
                                        {timeFormat === 24 ? (
                                          <>
                                            <input
                                              ref={startInputRef}
                                              type="text"
                                              name="start_time"
                                              value={startTimeEdit24}
                                              style={{
                                                backgroundColor:
                                                  uStartTimeError && "#fddada",
                                              }}

                                              onChange={(e) => {
                                                if (
                                                  validateNoAlphabets(
                                                    e.target.value
                                                  )
                                                ) {
                                                  setStartTimeEdit24(
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                              onBlur={() => {
                                                if (!startTimeEdit)
                                                  setStartTimeEdit("7:46pm");
                                                setIsTimeInputActive(false);
                                              }}
                                              onFocus={() =>
                                                setIsTimeInputActive(true)
                                              }
                                              className={`${editedId === group._id
                                                ? "edit-time edit-time-start"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                            -
                                            <input
                                              ref={endInputRef}
                                              type="text"
                                              name="end_time"
                                              value={endTimeEdit24}
                                              style={{
                                                backgroundColor:
                                                  uEndTimeError && "#fddada",
                                              }}

                                              onChange={(e) => {
                                                if (
                                                  validateNoAlphabets(
                                                    e.target.value
                                                  )
                                                ) {
                                                  setEndTimeEdit24(
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                              onBlur={() => {
                                                if (!endTimeEdit)
                                                  setEndTimeEdit("8:50pm");
                                                setIsTimeInputActive(false);
                                              }}
                                              onFocus={() =>
                                                setIsTimeInputActive(true)
                                              }
                                              className={`${editedId === group._id
                                                ? "edit-time edit-time-end"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              ref={start12TimeInputRef}
                                              type="text"
                                              name="start_time"
                                              value={startTimeEdit}
                                              style={{
                                                backgroundColor:
                                                  uStartTimeError && "#fddada",
                                              }}

                                              onChange={(e) => {
                                                setStartTimeEdit(e.target.value);
                                              }}
                                              onBlur={() => {
                                                if (!startTimeEdit)
                                                  setStartTimeEdit("07:46 PM");
                                                setIsTimeInputActive(false);
                                              }}
                                              onFocus={() =>
                                                setIsTimeInputActive(true)
                                              }
                                              className={`${editedId === group._id
                                                ? "edit-time edit-time-start"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                            -
                                            <input
                                              ref={end12TimeInputRef}
                                              type="text"
                                              name="end_time"
                                              value={endTimeEdit}
                                              style={{
                                                backgroundColor:
                                                  uEndTimeError && "#fddada",
                                              }}

                                              onChange={(e) => {
                                                setEndTimeEdit(e.target.value);
                                              }}
                                              onBlur={() => {
                                                if (!endTimeEdit)
                                                  setEndTimeEdit("08:50 PM");
                                                setIsTimeInputActive(false);
                                              }}
                                              onFocus={() =>
                                                setIsTimeInputActive(true)
                                              }
                                              className={`${editedId === group._id
                                                ? "edit-time edit-time-end"
                                                : ""
                                                }`}
                                              autoComplete="off"
                                            />
                                          </>
                                        )}
                                      </div>

                                      <div className="date-overview-sec date-overview-edit">
                                        <div
                                          onClick={handleDateIconClick}
                                          className="custom-date-icon"
                                        >
                                          {/* Your custom date picker icon SVG or image here */}
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 14 14"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            focusable="false"
                                            className="rs-picker-toggle-caret rs-icon"
                                            aria-label="calendar"
                                            data-category="legacy"
                                          >
                                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                          </svg>
                                        </div>
                                        {/* <DatePicker
                                          block
                                          name="date"
                                          appearance="subtle"
                                          value={
                                            new Date(
                                              zoneConverter(
                                                dateEdit,
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            )
                                          }
                                          value={moment(new Date(dateEdit)).format("YYYY-MM-DD HH:mm:ss")}
                                          onChange={(value) => {
                                            setDateOldEdit(
                                              new Date(
                                                zoneConverter(
                                                  dateEdit,
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                )
                                              )
                                            );
                                            setDateEdit(value);
                                          }}
                                          ref={datePickerRef}
                                          placement="bottomEnd"
                                          oneTap
                                          timeZone={global.config.getTimeZone()}
                                          isoWeek={
                                            appSettings.weekStartDay &&
                                            appSettings.weekStartDay === "Monday"
                                          }
                                        /> */}
                                        <DatePicker
                                          block
                                          name="date"
                                          appearance="subtle"
                                          value={moment(dateEdit, "YYYY-MM-DD HH:mm:ss").toDate()}
                                          onChange={(value) => {
                                            setDateOldEdit(moment(dateEdit, "YYYY-MM-DD HH:mm:ss").toDate());
                                            setDateEdit(value);
                                          }}
                                          ref={datePickerRef}
                                          placement="bottomEnd"
                                          oneTap
                                          timeZone={global.config.getTimeZone()}
                                          isoWeek={
                                            appSettings.weekStartDay &&
                                            appSettings.weekStartDay === "Monday"
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="time-overview-sec"
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(group.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            if (group.entries.length === 1) {
                                              setEditedId(group._id);
                                              setTaskNameEdit(group.task);
                                              setProjectIdEdit(group.project._id);
                                              setProjectNameEdit(group.project.project_name);
                                              if (group.sprint) {
                                                setSprintNameEdit(group.sprint.sprint_name);
                                                setSprintIdEdit(group.sprint._id);
                                              } else {
                                                setSprintIdEdit("");
                                                setSprintNameEdit("");
                                              }

                                              setDateEdit(
                                                zoneConverter(
                                                  group.date,
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                )
                                              );

                                              setTagIdEdit(
                                                group.tag && group.tag._id
                                              );
                                              setStartTimeEdit(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeEdit(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeTemp(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeTemp(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                )
                                              );
                                              setEndTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                )
                                              );

                                              setClientEdit(group.client);
                                              setClientIdEdit(group.clientId);
                                              setBillableEdit(group.billable);
                                              setUFormatedTime(
                                                `${formatTime(
                                                  Math.floor(
                                                    group.totalDuration / 3600
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    parseInt(
                                                      (group.totalDuration %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    group.totalDuration % 60
                                                  )
                                                )}`
                                              );
                                              setUDuration(group.totalDuration);
                                              setIsTimeInputActive(true);
                                              setUStartTimeError(false);
                                              setUEndTimeError(false);
                                              setUFormatedTimeError(false);
                                            } else {
                                              toggleGroup(groupIndex);
                                            }
                                          } else {
                                            if (group.entries.length === 1) {
                                              setClickId(group._id);
                                            } else {
                                              setClickId(null);
                                            }
                                          }
                                        }}
                                      >
                                        {timeFormat === 24 ? (
                                          <>
                                            <input
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              name="start_time"
                                              autocomplete="off"
                                              value={convertTo24HourFormatback(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              )}
                                              className="time-show edit-time-start"
                                              autoComplete="off"
                                            />
                                            -
                                            <input
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              name="end_time"
                                              autocomplete="off"
                                              value={convertTo24HourFormatback(
                                                TimestampConverter(group.endTime)
                                              )}
                                              className="time-show edit-time-end"
                                              autoComplete="off"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              name="start_time"
                                              autocomplete="off"
                                              value={TimestampConverter(
                                                group.startTime
                                              )}
                                              className="time-show edit-time-start"
                                              autoComplete="off"
                                            />
                                            -
                                            <input
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              name="end_time"
                                              autocomplete="off"
                                              value={TimestampConverter(
                                                group.endTime
                                              )}
                                              className="time-show edit-time-end"
                                              autoComplete="off"
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className="date-overview-sec"
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(group.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            if (group.entries.length === 1) {
                                              setEditedId(group._id);
                                              setTaskNameEdit(group.task);
                                              setProjectIdEdit(group.project._id);
                                              setProjectNameEdit(group.project.project_name);
                                              if (group.sprint) {
                                                setSprintNameEdit(group.sprint.sprint_name);
                                                setSprintIdEdit(group.sprint._id);
                                              } else {
                                                setSprintIdEdit("");
                                                setSprintNameEdit("");
                                              }

                                              setDateEdit(
                                                zoneConverter(
                                                  group.date,
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                )
                                              );

                                              setTagIdEdit(
                                                group.tag && group.tag._id
                                              );
                                              setStartTimeEdit(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeEdit(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeTemp(
                                                TimestampConverter(
                                                  group.startTime
                                                )
                                              );
                                              setEndTimeTemp(
                                                TimestampConverter(group.endTime)
                                              );
                                              setStartTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                )
                                              );
                                              setEndTimeEdit24(
                                                convertTo24HourFormatback(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                )
                                              );

                                              setClientEdit(group.client);
                                              setClientIdEdit(group.clientId);
                                              setBillableEdit(group.billable);
                                              setUFormatedTime(
                                                `${formatTime(
                                                  Math.floor(
                                                    group.totalDuration / 3600
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    parseInt(
                                                      (group.totalDuration %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                )}:${formatTime(
                                                  Math.floor(
                                                    group.totalDuration % 60
                                                  )
                                                )}`
                                              );
                                              setUDuration(group.totalDuration);
                                              setUStartTimeError(false);
                                              setUEndTimeError(false);
                                              setUFormatedTimeError(false);
                                            } else {
                                              toggleGroup(groupIndex);
                                            }
                                          } else {
                                            if (group.entries.length === 1) {
                                              setClickId(group._id);
                                            } else {
                                              setClickId(null);
                                            }
                                          }
                                        }}
                                      >
                                        <div
                                          className="custom-date-icon"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {/* Your custom date picker icon SVG or image here */}
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 14 14"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            focusable="false"
                                            className="rs-picker-toggle-caret rs-icon"
                                            aria-label="calendar"
                                            data-category="legacy"
                                          >
                                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </div>

                              <div className="overview-time-edit">
                                <Col>
                                  <div className="overview-time-edit-inner">
                                    {/* duration edit section */}
                                    {group._id === editedId &&
                                      group.entries.length === 1 ? (
                                      <>
                                        <div
                                          className="timer-sec-input"
                                          style={{
                                            backgroundColor:
                                              uFormatedTimeError && "#fddada",
                                          }}
                                        >
                                          <input
                                            className={
                                              group._id === editedId &&
                                                group.entries.length === 1
                                                ? "edit-time"
                                                : "edit-time"
                                            }
                                            name="time"
                                            value={uFormatedTime}
                                            onChange={(e) => {
                                              // handleTimeChange
                                              timeShowEdit(e.target.value);
                                            }}
                                            placeholder="00:00:00"
                                            type="text"
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div className="play-button">
                                          {group._id !== editedId &&
                                            group.entries.length === 1(
                                              <>
                                                <span
                                                  style={{
                                                    cursor: (resumeLoading || customeTime)
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (!resumeLoading && !customeTime) {
                                                      resumeTimeTracking(
                                                        group.task,
                                                        group.project._id,
                                                        group.project
                                                          .project_name,
                                                        group.tag.tag_name,
                                                        group.tag._id,
                                                        group.client,
                                                        group.project.client_name,
                                                        group.date,
                                                        group.startTime,
                                                        group.endTime,
                                                        group.totalDuration,
                                                        group.sprint
                                                          ? group.sprint
                                                            .sprint_name
                                                          : null,
                                                        group.sprint
                                                          ? group.sprint._id
                                                          : null,
                                                        group._id,
                                                        group.billable
                                                      );
                                                    }
                                                    // }
                                                  }}
                                                >
                                                  <img src={Play} alt="Play" />
                                                </span>
                                              </>
                                            )}
                                        </div>
                                      </>
                                    ) : group._id === editedId &&
                                      group.entries.length > 1 ? (
                                      <React.Fragment>
                                        <div
                                          className="timer-sec-input"
                                          onClick={() => {
                                            if (
                                              getLockHours(
                                                moment(group.endTime)
                                                  .tz(global.config.getTimeZone())
                                                  .format("YYYY-MM-DD HH:mm:ss"),
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              ) === true
                                            ) {
                                              if (group.entries.length === 1) {
                                                setEditedId(group._id);
                                                setTaskNameEdit(group.task);
                                                setProjectIdEdit(group.project._id);
                                                setProjectNameEdit(group.project.project_name);
                                                if (group.sprint) {
                                                  setSprintNameEdit(group.sprint.sprint_name);
                                                  setSprintIdEdit(group.sprint._id);
                                                } else {
                                                  setSprintIdEdit("");
                                                  setSprintNameEdit("");
                                                }

                                                setDateEdit(
                                                  moment
                                                    .tz(
                                                      group.date,
                                                      "YYYY-MM-DD HH:mm:ss",
                                                      global.config.getTimeZone()
                                                    )
                                                    .format()
                                                );

                                                setTagIdEdit(
                                                  group.tag && group.tag._id
                                                );
                                                setStartTimeEdit(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                );
                                                setEndTimeEdit(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                );
                                                setStartTimeTemp(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                );
                                                setEndTimeTemp(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                );
                                                setStartTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      group.startTime
                                                    )
                                                  )
                                                );
                                                setEndTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      group.endTime
                                                    )
                                                  )
                                                );

                                                setClientEdit(group.client);
                                                setClientIdEdit(group.clientId);
                                                setBillableEdit(group.billable);
                                                setUFormatedTime(
                                                  `${formatTime(
                                                    Math.floor(
                                                      group.totalDuration / 3600
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      parseInt(
                                                        (group.totalDuration %
                                                          3600) /
                                                        60
                                                      )
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      group.totalDuration % 60
                                                    )
                                                  )}`
                                                );
                                                setUDuration(group.totalDuration);
                                                setUStartTimeError(false);
                                                setUEndTimeError(false);
                                                setUFormatedTimeError(false);
                                              } else {
                                                toggleGroup(groupIndex);
                                              }
                                            } else {
                                              if (group.entries.length === 1) {
                                                setClickId(group._id);
                                              } else {
                                                setClickId(null);
                                              }
                                            }
                                          }}
                                        >
                                          <input
                                            style={{ cursor: "pointer" }}
                                            className={
                                              group._id === editedId &&
                                                group.entries.length === 1
                                                ? "before-edit-input"
                                                : "before-edit-input"
                                            }
                                            name="time"
                                            value={`${formatTime(
                                              Math.floor(
                                                group.totalDuration / 3600
                                              )
                                            )}:${formatTime(
                                              Math.floor(
                                                parseInt(
                                                  (group.totalDuration % 3600) /
                                                  60
                                                )
                                              )
                                            )}:${formatTime(
                                              Math.floor(group.totalDuration % 60)
                                            )}`}
                                            placeholder="00:00:00"
                                            type="text"
                                            autoComplete="off"
                                          />
                                        </div>

                                        <div
                                          className="play-button"
                                          onClick={() => {
                                            if (!resumeLoading && !customeTime) {
                                              resumeTimeTracking(
                                                group.task,
                                                group.project._id,
                                                group.project.project_name,
                                                group.tag && group.tag.tag_name,
                                                group.tag && group.tag._id,
                                                group.client,
                                                group.project.client_name,
                                                group.date,
                                                group.startTime,
                                                group.endTime,
                                                group.totalDuration,
                                                group.sprint
                                                  ? group.sprint.sprint_name
                                                  : null,
                                                group.sprint
                                                  ? group.sprint._id
                                                  : null,
                                                group._id,
                                                group.billable
                                              );
                                              // }
                                            }
                                          }}
                                        >
                                          <span
                                            style={{
                                              cursor: (resumeLoading || customeTime)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                          >
                                            <img src={Play} alt="Play" />
                                          </span>
                                        </div>
                                      </React.Fragment>
                                    ) : null}

                                    {/* duration edit section end */}

                                    {group._id !== editedId && (
                                      <>
                                        <div
                                          className="timer-sec-input"
                                          onClick={() => {
                                            if (
                                              getLockHours(
                                                moment(group.endTime)
                                                  .tz(global.config.getTimeZone())
                                                  .format("YYYY-MM-DD HH:mm:ss"),
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              ) === true
                                            ) {
                                              if (group.entries.length === 1) {
                                                setEditedId(group._id);
                                                setTaskNameEdit(group.task);
                                                setProjectIdEdit(group.project._id);
                                                setProjectNameEdit(group.project.project_name);
                                                if (group.sprint) {
                                                  setSprintNameEdit(group.sprint.sprint_name);
                                                  setSprintIdEdit(group.sprint._id);
                                                } else {
                                                  setSprintIdEdit("");
                                                  setSprintNameEdit("");
                                                }

                                                setDateEdit(
                                                  zoneConverter(
                                                    group.date,
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )
                                                );

                                                setTagIdEdit(
                                                  group.tag && group.tag._id
                                                );
                                                setStartTimeEdit(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                );
                                                setEndTimeEdit(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                );
                                                setStartTimeTemp(
                                                  TimestampConverter(
                                                    group.startTime
                                                  )
                                                );
                                                setEndTimeTemp(
                                                  TimestampConverter(
                                                    group.endTime
                                                  )
                                                );
                                                setStartTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      group.startTime
                                                    )
                                                  )
                                                );
                                                setEndTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      group.endTime
                                                    )
                                                  )
                                                );

                                                setClientEdit(group.client);
                                                setClientIdEdit(group.clientId);
                                                setBillableEdit(group.billable);
                                                setUFormatedTime(
                                                  `${formatTime(
                                                    Math.floor(
                                                      group.totalDuration / 3600
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      parseInt(
                                                        (group.totalDuration %
                                                          3600) /
                                                        60
                                                      )
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      group.totalDuration % 60
                                                    )
                                                  )}`
                                                );
                                                setUDuration(group.totalDuration);
                                                setUStartTimeError(false);
                                                setUEndTimeError(false);
                                                setUFormatedTimeError(false);
                                              } else {
                                                toggleGroup(groupIndex);
                                              }
                                            } else {
                                              if (group.entries.length === 1) {
                                                setClickId(group._id);
                                              } else {
                                                setClickId(null);
                                              }
                                            }
                                          }}
                                        >
                                          <input
                                            style={{ cursor: "pointer" }}
                                            className={
                                              group._id === editedId &&
                                                group.entries.length === 1 ? "before-edit-input" : "before-edit-input"
                                            }
                                            name="time"
                                            value={`${formatTime(
                                              Math.floor(group.totalDuration / 3600)
                                            )}:${formatTime(
                                              Math.floor(parseInt((group.totalDuration % 3600) / 60))
                                            )}:${formatTime(
                                              Math.floor(group.totalDuration % 60)
                                            )}`}
                                            placeholder="00:00:00"
                                            type="text"
                                            autoComplete="off"
                                          />
                                        </div>

                                        <div
                                          className="play-button"
                                          onClick={() => {
                                            if (!resumeLoading && !customeTime) {
                                              resumeTimeTracking(
                                                group.task,
                                                group.project._id,
                                                group.project.project_name,
                                                group.tag && group.tag.tag_name,
                                                group.tag && group.tag._id,
                                                group.client,
                                                group.project.client_name,
                                                group.date,
                                                group.startTime,
                                                group.endTime,
                                                group.totalDuration,
                                                group.sprint
                                                  ? group.sprint.sprint_name
                                                  : null,
                                                group.sprint
                                                  ? group.sprint._id
                                                  : null,
                                                group._id,
                                                group.billable
                                              );

                                            }
                                          }}
                                        >
                                          <span
                                            style={{
                                              cursor: (resumeLoading || customeTime)
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                          >
                                            <img src={Play} alt="Play" />
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {group._id === editedId &&
                                    group.entries.length === 1 ? (
                                    <>
                                      <div className="set-btn-outer">
                                        {!isTimeInputActive && (
                                          <button
                                            type="submit"
                                            className="set-button"
                                            disabled={
                                              (isUpdateLoading ||
                                                uFormatedTimeError ||
                                                uEndTimeError ||
                                                uStartTimeError ||
                                                isTimeInputActive) &&
                                              "disabled"
                                            }
                                          >
                                            {isUpdateLoading ? (
                                              <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="white"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                              />
                                            ) : (
                                              <img
                                                width="18"
                                                src={Update}
                                                alt="update"
                                              />
                                            )}
                                          </button>
                                        )}
                                      </div>
                                    </>
                                  ) : null}

                                  {group._id !== editedId &&
                                    group.entries.length < 2 && (
                                      <>
                                        <div className="custom-dropdown12" ref={(el) => (activedropdownRefs.current[group._id] = el)}>
                                          <div
                                            className="dropdown-toggle1"
                                            onClick={() => {
                                              if (
                                                getLockHours(
                                                  moment(group.endTime)
                                                    .tz(
                                                      global.config.getTimeZone()
                                                    )
                                                    .format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ),
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ) === true
                                              ) {
                                                handleDropdownToggle(group._id);
                                              } else {
                                                if (group.entries.length === 1) {
                                                  setClickId(group._id);
                                                } else {
                                                  setClickId(null);
                                                }
                                              }
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="4"
                                              height="20"
                                              viewBox="0 0 4 20"
                                              fill="none"
                                            >
                                              <circle
                                                cx="2"
                                                cy="2"
                                                r="2"
                                                fill="#96A8BA"
                                              />
                                              <circle
                                                cx="2"
                                                cy="10"
                                                r="2"
                                                fill="#96A8BA"
                                              />
                                              <circle
                                                cx="2"
                                                cy="18"
                                                r="2"
                                                fill="#96A8BA"
                                              />
                                            </svg>
                                          </div>

                                          {activeDropdownId === group._id && (
                                            <ul className="dropdown-menu1">
                                              {duplicateLoading === false ? (
                                                <li
                                                  onClick={() => {
                                                    if (!duplicateLoading) {
                                                      duplicate(
                                                        group.task,
                                                        group.project &&
                                                        group.project._id,
                                                        group.tag &&
                                                        group.tag._id,
                                                        group.client,
                                                        group.project &&
                                                        group.project
                                                          .client_name,
                                                        group.date,
                                                        group.startTime,
                                                        group.endTime,
                                                        group.totalDuration,
                                                        group.sprint
                                                          ? group.sprint
                                                            .sprint_name
                                                          : null,
                                                        group.sprint
                                                          ? group.sprint._id
                                                          : null,
                                                        group._id,
                                                        group.billable
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <span>
                                                    {" "}
                                                    <span> Duplicate</span>
                                                  </span>
                                                </li>
                                              ) : (
                                                <div className="outter-load-table">
                                                  <ThreeDots
                                                    height="30"
                                                    width="40"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                  />
                                                </div>
                                              )}
                                              <li
                                                onClick={() =>
                                                  !isDeleteLoading &&
                                                  handleResetClick(
                                                    group._id,
                                                    group
                                                  )
                                                }
                                              >
                                                {group._id === deleteId &&
                                                  isDeleteLoading ? (
                                                  <div className="outter-load-table">
                                                    <ThreeDots
                                                      height="30"
                                                      width="40"
                                                      radius="9"
                                                      color="#6479f9"
                                                      ariaLabel="three-dots-loading"
                                                      wrapperStyle={{}}
                                                      wrapperClassName=""
                                                      visible={true}
                                                    />
                                                  </div>
                                                ) : (
                                                  <span>Delete</span>
                                                )}
                                              </li>
                                            </ul>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  <div>
                                    {showPopup &&
                                      clickId === group._id &&
                                      group.entries.length === 1 && (
                                        <TimeEntryPopup
                                          onClose={() => setShowPopup(false)}
                                        />
                                      )}
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {expandedGroups.includes(groupIndex) &&
                        group.entries.length > 1 &&
                        group.entries.map((row, index) => (
                          <>
                            <div
                              className={`merge-tracker merge-tracker-iner ${row._id === editedId ? "editer" : ""}`}
                              style={{
                                backgroundColor: row._id === editedId ? "#f0f7f9" : "#fff",
                                padding: row._id === editedId ? "10px" : "",
                              }}
                              onClick={() => { }}
                            >
                              <div className="overview-sec-timer-inner">
                                <div className="project-name">
                                  <div
                                    class="count-merge"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span></span>
                                  </div>
                                  <Col
                                    className={
                                      getLockHours(
                                        moment(row.endTime).tz(global.config.getTimeZone()).format("YYYY-MM-DD HH:mm:ss"),
                                        global.config.getTimeZone(),
                                        "YYYY-MM-DD HH:mm:ss"
                                      ) === true
                                        ? "cursor"
                                        : "cursor"
                                    }
                                    onClick={() => {
                                      setClickId(row._id);
                                      if (
                                        getLockHours(
                                          moment(row.endTime)
                                            .tz(global.config.getTimeZone())
                                            .format("YYYY-MM-DD HH:mm:ss"),
                                          global.config.getTimeZone(),
                                          "YYYY-MM-DD HH:mm:ss"
                                        ) === true
                                      ) {
                                        setClickId(row._id);
                                        setEditedId(row._id);
                                        setProjectEditDropdownOpen(false);
                                        setTagEditDropdownOpen(false);
                                        setTaskNameEdit(row.task);
                                        setProjectIdEdit(row.project._id);
                                        setProjectNameEdit(row.project.project_name);
                                        if (row.sprint) {
                                          setSprintNameEdit(row.sprint.sprint_name);
                                          setSprintIdEdit(row.sprint._id);
                                        } else {
                                          setSprintIdEdit("");
                                          setSprintNameEdit("");
                                        }

                                        setDateEdit(
                                          zoneConverter(
                                            row.date,
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        );

                                        setTagIdEdit(row.tag && row.tag._id);
                                        setStartTimeEdit(
                                          TimestampConverter(row.startTime)
                                        );
                                        setEndTimeEdit(
                                          TimestampConverter(row.endTime)
                                        );
                                        setStartTimeTemp(
                                          TimestampConverter(row.startTime)
                                        );
                                        setEndTimeTemp(
                                          TimestampConverter(row.endTime)
                                        );
                                        setStartTimeEdit24(
                                          convertTo24HourFormatback(
                                            TimestampConverter(row.startTime)
                                          )
                                        );
                                        setEndTimeEdit24(
                                          convertTo24HourFormatback(
                                            TimestampConverter(row.endTime)
                                          )
                                        );
                                        // setEClient(row.client);
                                        setClientEdit(row.client);
                                        setClientIdEdit(row.clientId);
                                        setBillableEdit(row.billable);
                                        setUFormatedTime(
                                          `${formatTime(
                                            Math.floor(row.duration / 3600)
                                          )}:${formatTime(
                                            Math.floor(
                                              parseInt(
                                                (row.duration % 3600) / 60
                                              )
                                            )
                                          )}:${formatTime(
                                            Math.floor(row.duration % 60)
                                          )}`
                                        );
                                        setUDuration(row.duration);
                                        setUStartTimeError(false);
                                        setUEndTimeError(false);
                                        setUFormatedTimeError(false);
                                      } else {
                                        handleOpenPopup();
                                        setClickId(row._id);
                                      }
                                    }}
                                  >
                                    {row._id === editedId && (
                                      <input
                                        type="text"
                                        name="task"
                                        value={taskNameEdit}
                                        onChange={(e) =>
                                          setTaskNameEdit(e.target.value)
                                        }
                                        onClick={(e) => setClickId(row._id)}
                                        placeholder="Regular Input"
                                        className={
                                          row._id === editedId
                                            ? "input-edit-project"
                                            : "input-bg input-edit-project"
                                        }
                                        style={{ textOverflow: "ellipsis" }}
                                        autoComplete="off"
                                      />
                                    )}

                                    {row._id !== editedId && (
                                      <>
                                        {/* </span> */}
                                        <MyComponent
                                          group={row}
                                          editedId={editedId}
                                        />
                                      </>
                                    )}
                                  </Col>
                                </div>

                                <div
                                  className="project-sprint-outer col 111"
                                  ref={projectEditDropdownRef}
                                >
                                  {row._id === editedId && (
                                    <>
                                      <Col
                                        className="cursor"
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(row.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            setProjectEditDropdownOpen(!projectEditDropdownOpen);
                                            setProjectStatusFilter("Active");
                                            setFilter("");
                                            setSelectedClients(new Set());
                                            setSelectedProject(null);
                                            setTagEditDropdownOpen(false);
                                          }
                                        }}
                                      >
                                        <span
                                          className={`tooltip-tracker ${currentTextCount(
                                            projectNameEdit,
                                            sprintNameEdit && sprintNameEdit,
                                            clientEdit && clientEdit
                                          ) < 21
                                            ? "small"
                                            : "large"
                                            }`}
                                        >
                                          {projectNameEdit && projectNameEdit}
                                          {`${sprintNameEdit
                                            ? ` - ${sprintNameEdit}`
                                            : ""
                                            }`}
                                          <input
                                            type="hidden"
                                            name="project"
                                            value={projectIdEdit}
                                          />
                                          <div class="project-name-tracker">
                                            {clientEdit}
                                          </div>
                                        </span>
                                        <div
                                          className={`project-tracker-inner ${currentTextCount(
                                            projectNameEdit,
                                            sprintNameEdit && sprintNameEdit,
                                            clientEdit && clientEdit
                                          ) < 21
                                            ? "small"
                                            : "large"
                                            }`}
                                          style={{
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            height: "22px",
                                          }}
                                        >
                                          {projectNameEdit && projectNameEdit}
                                          {`${sprintNameEdit
                                            ? ` - ${sprintNameEdit}`
                                            : ""
                                            }`}
                                          <input
                                            type="hidden"
                                            name="project"
                                            value={projectIdEdit}
                                          />
                                          <div class="project-name-tracker">
                                            {clientEdit}
                                          </div>
                                        </div>
                                      </Col>
                                      {projectEditDropdownOpen && (
                                        <div className="custom-dropdown">
                                          <input
                                            type="text"
                                            className="search-bar"
                                            placeholder="Find Project or Client"
                                            value={filter}
                                            onChange={(e) => {
                                              const filterValue =
                                                e.target.value;
                                              setFilter(filterValue);
                                              if (filterValue === "") {
                                                setSelectedClients(new Set());
                                              } else {
                                                // Check if the filtered project exists under any client
                                                const matchingClients =
                                                  filteredClientData.filter(
                                                    (client) =>
                                                      client.projects.some(
                                                        (project) =>
                                                          project.project_name
                                                            .toLowerCase()
                                                            .includes(filterValue.toLowerCase()) &&
                                                          (projectStatusFilter === "All" ? true
                                                            : projectStatusFilter === "Active"
                                                              ? project.is_active === true
                                                              : project.is_active === false)
                                                      )
                                                  );
                                                setSelectedClients(
                                                  new Set(matchingClients)
                                                );
                                              }
                                            }}
                                            autoComplete="off"
                                          />
                                          {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                            <>
                                              <div className="user-status-dropdown">
                                                <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                                <div className="custom-dropdown-user" ref={projectStatusMergeDropdownRef}>
                                                  <div className="showing-name" onClick={toggleProjectStatusMergeDropdown}>
                                                    {projectStatusFilter ? projectStatusFilter : "Active"}
                                                  </div>
                                                </div>
                                              </div>
                                              {isProjectStatusMergeDropdownOpen && (
                                                <ul className="status-list">
                                                  <li
                                                    onClick={() => handleProjectStatusChange("All")}
                                                    className={projectStatusFilter === "All" ? "selected-item" : ""}
                                                  >
                                                    All
                                                  </li>
                                                  <li
                                                    onClick={() => handleProjectStatusChange("Active")}
                                                    className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                                  >
                                                    Active
                                                  </li>
                                                  <li
                                                    onClick={() => handleProjectStatusChange("Inactive")}
                                                    className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                                  >
                                                    Inactive
                                                  </li>
                                                </ul>
                                              )}
                                            </>
                                          )}
                                          <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                                            {filteredClientData.map(
                                              (client) => (
                                                <div
                                                  key={client._id}
                                                  className="main-outer-item-dd"
                                                >
                                                  {/* Render the client name */}
                                                  <div
                                                    className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                                    onClick={() => {
                                                      if (
                                                        client.projects.filter(
                                                          (project) =>
                                                            project.project_name
                                                              .toLowerCase()
                                                              .includes(filter.toLowerCase()) &&
                                                            (projectStatusFilter === "All"
                                                              ? true
                                                              : projectStatusFilter === "Active"
                                                                ? project.is_active === true
                                                                : project.is_active === false)
                                                        ).length > 0
                                                      ) {
                                                        // Toggle the client in the Set
                                                        const newSelectedClients =
                                                          new Set(selectedClients);
                                                        if (
                                                          selectedClients.has(client)
                                                        ) {
                                                          newSelectedClients.delete(client);
                                                        } else {
                                                          newSelectedClients.add(client);
                                                        }
                                                        setSelectedClients(newSelectedClients);
                                                      }
                                                    }}
                                                  >
                                                    <span className="main-project">
                                                      {client.client_name}{" "}
                                                    </span>
                                                    {client.projects.filter(
                                                      (project) =>
                                                        project.project_name
                                                          .toLowerCase()
                                                          .includes(filter.toLowerCase()) &&
                                                        (projectStatusFilter === "All"
                                                          ? true
                                                          : projectStatusFilter === "Active"
                                                            ? project.is_active === true
                                                            : project.is_active === false)
                                                    ).length >
                                                      0 && (
                                                        <span className={`arrow ${selectedClients.has(client) ? "open" : ""}`} >
                                                          <span className="main-project-title">
                                                            {client.projects.filter(
                                                              (project) =>
                                                                project.project_name
                                                                  .toLowerCase()
                                                                  .includes(filter.toLowerCase()) &&
                                                                (projectStatusFilter === "All"
                                                                  ? true
                                                                  : projectStatusFilter === "Active"
                                                                    ? project.is_active === true
                                                                    : project.is_active === false)
                                                            ).length}{" "}
                                                            Projects
                                                          </span>
                                                          {selectedClients.has(client) ? (
                                                            <img src={TopArrow} alt="Open" />
                                                          ) : (
                                                            <img src={DownArrow} alt="Close" />
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                  {selectedClients.has(client) && (
                                                    <div className="project-name1-outer">
                                                      {/* Render projects under the selected client */}
                                                      {client.projects.filter(
                                                        (project) =>
                                                          project.project_name
                                                            .toLowerCase()
                                                            .includes(filter.toLowerCase()) &&
                                                          (projectStatusFilter === "All"
                                                            ? true
                                                            : projectStatusFilter === "Active"
                                                              ? project.is_active === true
                                                              : project.is_active === false)
                                                      ).length === 0 ? (
                                                        <div>
                                                          {" "} No projects under this client{" "}
                                                        </div>
                                                      ) : (
                                                        client.projects
                                                          .filter((project) =>
                                                            project.project_name
                                                              .toLowerCase()
                                                              .includes(filter.toLowerCase()) &&
                                                            (projectStatusFilter === "All"
                                                              ? true
                                                              : projectStatusFilter === "Active"
                                                                ? project.is_active === true
                                                                : project.is_active === false)
                                                          )
                                                          .sort((a, b) =>
                                                            a.project_name.localeCompare(b.project_name)
                                                          )
                                                          .map((project) => (
                                                            <div key={project._id} >
                                                              <div
                                                                className={`project-name1 ${selectedProject === project ? "selected" : ""}`}
                                                              >
                                                                <div
                                                                  onClick={(e) => {
                                                                    setSprintIdEdit("");
                                                                    setSprintNameEdit("");
                                                                    setProjectIdEdit(project._id);
                                                                    setProjectNameEdit(project.project_name);
                                                                    setClientEdit(client.client_name);
                                                                    setClientIdEdit(client._id);
                                                                    setBillableEdit(project.is_billable);
                                                                    setProjectEditDropdownOpen(false);
                                                                  }}
                                                                >
                                                                  <span className="project-dd-title">
                                                                    {project.project_name}{" "}
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  {project.sprints.length > 0 && (
                                                                    <span
                                                                      className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                                      onClick={() =>
                                                                        setSelectedProject(selectedProject === project ? null : project)
                                                                      }
                                                                    >
                                                                      <span className="main-project-title">
                                                                        {project.sprints.length}{" "} Sprints
                                                                      </span>
                                                                      {selectedProject === project ? (
                                                                        <img src={TopArrow} alt="Open" />
                                                                      ) : (
                                                                        <img src={DownArrow} alt="Close" />
                                                                      )}
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              </div>
                                                              {selectedProject ===
                                                                project && (
                                                                  <div>
                                                                    {/* Render sprints under the selected project */}
                                                                    {project.sprints.length === 0 ? (
                                                                      <div>
                                                                        {" "} No sprints under this project{" "}
                                                                      </div>
                                                                    ) : (
                                                                      <ul className="sprint-list">
                                                                        {project.sprints
                                                                          .sort(
                                                                            (a, b) =>
                                                                              a.sprint_name.localeCompare(b.sprint_name)
                                                                          )
                                                                          .map(
                                                                            (sprint) => (
                                                                              <li
                                                                                key={sprint._id}
                                                                                onClick={(e) => {
                                                                                  setSprintIdEdit(sprint._id);
                                                                                  setSprintNameEdit(sprint.sprint_name);
                                                                                  setProjectIdEdit(project._id);
                                                                                  setProjectNameEdit(project.project_name);
                                                                                  setClientEdit(client.client_name);
                                                                                  setClientIdEdit(client._id);
                                                                                  setBillableEdit(project.is_billable);
                                                                                  setProjectEditDropdownOpen(false);
                                                                                }}
                                                                              >
                                                                                {sprint.sprint_name}
                                                                              </li>
                                                                            )
                                                                          )}
                                                                      </ul>
                                                                    )}
                                                                  </div>
                                                                )}
                                                            </div>
                                                          ))
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {row._id !== editedId && (
                                    <Col
                                      className={
                                        getLockHours(
                                          moment(row.endTime)
                                            .tz(global.config.getTimeZone())
                                            .format("YYYY-MM-DD HH:mm:ss"),
                                          global.config.getTimeZone(),
                                          "YYYY-MM-DD HH:mm:ss"
                                        ) === true
                                          ? "cursor"
                                          : "cursor"
                                      }
                                      onClick={() => {
                                        if (
                                          getLockHours(
                                            moment(row.endTime)
                                              .tz(global.config.getTimeZone())
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          ) === true
                                        ) {
                                          setEditedId(row._id);
                                          setProjectEditDropdownOpen(false);
                                          setTagEditDropdownOpen(false);
                                          setTaskNameEdit(row.task);
                                          setProjectIdEdit(row.project._id);
                                          setProjectNameEdit(row.project.project_name);
                                          if (row.sprint) {
                                            setSprintNameEdit(row.sprint.sprint_name);
                                            setSprintIdEdit(row.sprint._id);
                                          } else {
                                            setSprintIdEdit("");
                                            setSprintNameEdit("");
                                          }
                                          // setHrEdit(row.hr);
                                          // setMinEdit(row.min);
                                          // setSecEdit(row.sec);
                                          setDateEdit(
                                            zoneConverter(
                                              row.date,
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            )
                                          );
                                          // setTagId(row.tag && row.tag._id);
                                          setTagIdEdit(row.tag && row.tag._id);
                                          setStartTimeEdit(
                                            TimestampConverter(row.startTime)
                                          );
                                          setEndTimeEdit(
                                            TimestampConverter(row.endTime)
                                          );
                                          setStartTimeTemp(
                                            TimestampConverter(row.startTime)
                                          );
                                          setEndTimeTemp(
                                            TimestampConverter(row.endTime)
                                          );
                                          setStartTimeEdit24(
                                            convertTo24HourFormatback(
                                              TimestampConverter(row.startTime)
                                            )
                                          );
                                          setEndTimeEdit24(
                                            convertTo24HourFormatback(
                                              TimestampConverter(row.endTime)
                                            )
                                          );

                                          setClientEdit(row.client);
                                          setClientIdEdit(row.clientId);
                                          setBillableEdit(row.billable);
                                          setUFormatedTime(
                                            `${formatTime(
                                              Math.floor(row.duration / 3600)
                                            )}:${formatTime(
                                              Math.floor(
                                                parseInt(
                                                  (row.duration % 3600) / 60
                                                )
                                              )
                                            )}:${formatTime(
                                              Math.floor(row.duration % 60)
                                            )}`
                                          );
                                          setUDuration(row.duration);
                                          setUStartTimeError(false);
                                          setUEndTimeError(false);
                                          setUFormatedTimeError(false);
                                        } else {
                                          handleOpenPopup();
                                          setClickId(row._id);
                                        }
                                      }}
                                    >
                                      <span
                                        className={`tooltip-tracker ${currentTextCount(
                                          row.project.project_name,
                                          row.sprint &&
                                          row.sprint.sprint_name,
                                          row.client && row.client
                                        ) < 21
                                          ? "small"
                                          : "large"
                                          }`}
                                      >
                                        {row.project.project_name}{" "}
                                        {`${row.sprint
                                          ? ` - ${row.sprint.sprint_name}`
                                          : ""
                                          }`}
                                        <div class="project-name-tracker">
                                          {row.client}
                                        </div>
                                      </span>
                                      <span
                                        className={`project-tracker-inner ${currentTextCount(
                                          row.project.project_name,
                                          row.sprint &&
                                          row.sprint.sprint_name,
                                          row.client && row.client
                                        ) < 21
                                          ? "small"
                                          : "large"
                                          }`}
                                        style={{
                                          display: "inline-block",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          height: "22px",
                                        }}
                                      >
                                        {row.project.project_name}

                                        {`${row.sprint
                                          ? ` - ${row.sprint.sprint_name}`
                                          : ""
                                          }`}
                                        <div class="project-name-tracker">
                                          {row.client}
                                        </div>
                                      </span>
                                    </Col>
                                  )}
                                </div>

                                {/* mobile view */}
                                <div
                                  className="custom-dropdown12"
                                  style={{ display: "none" }}
                                  ref={(el) => (activedropdownRefs.current[row._id] = el)}
                                >
                                  <div
                                    className="dropdown-toggle1"
                                    onClick={() => {
                                      if (
                                        getLockHours(
                                          moment(row.endTime)
                                            .tz(global.config.getTimeZone())
                                            .format("YYYY-MM-DD HH:mm:ss"),
                                          global.config.getTimeZone(),
                                          "YYYY-MM-DD HH:mm:ss"
                                        ) === true
                                      ) {
                                        handleDropdownToggle(row._id);
                                      } else {
                                        setClickId(row._id);
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="4"
                                      height="20"
                                      viewBox="0 0 4 20"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2"
                                        cy="2"
                                        r="2"
                                        fill="#96A8BA"
                                      />
                                      <circle
                                        cx="2"
                                        cy="10"
                                        r="2"
                                        fill="#96A8BA"
                                      />
                                      <circle
                                        cx="2"
                                        cy="18"
                                        r="2"
                                        fill="#96A8BA"
                                      />
                                    </svg>
                                  </div>

                                  {activeDropdownId === row._id && (
                                    <ul className="dropdown-menu1">
                                      <li
                                        onClick={() => {
                                          if (!duplicateLoading) {
                                            duplicate(
                                              row.task,
                                              row.project._id,
                                              row.tag._id,
                                              row.client,
                                              row.project.client_name,
                                              row.date,
                                              row.startTime,
                                              row.endTime,
                                              row.duration,
                                              row.sprint
                                                ? row.sprint.sprint_name
                                                : null,
                                              row.sprint
                                                ? row.sprint._id
                                                : null,
                                              row._id,
                                              row.billable
                                            );
                                          }
                                        }}
                                      >
                                        <span>
                                          {duplicateLoading ? (
                                            <div className="outter-load-table">
                                              <ThreeDots
                                                height="30"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                              />
                                            </div>
                                          ) : (
                                            "Duplicate"
                                          )}
                                        </span>
                                      </li>
                                      <li
                                        onClick={() =>
                                          !isDeleteLoading &&
                                          handleResetClick(row._id, group)
                                        }
                                      >
                                        {row._id === deleteId &&
                                          isDeleteLoading ? (
                                          <div className="outter-load-table">
                                            <ThreeDots
                                              height="30"
                                              width="40"
                                              radius="9"
                                              color="#6479f9"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </div>
                                        ) : (
                                          <span>Delete</span>
                                        )}
                                      </li>
                                    </ul>
                                  )}
                                  <div>
                                    {showPopup && clickId === row._id && (
                                      <TimeEntryPopup
                                        onClose={() => setShowPopup(false)}
                                      />
                                    )}
                                  </div>
                                </div>
                                {/* mobile view */}
                              </div>

                              <div className="outer-right-sec-overview">
                                <div className="overview-sec-timer-inner-right">
                                  {/* Mobile view */}
                                  <div
                                    className="project-sprint-outer col project-sprint-outer-mobile"
                                    style={{ display: "none" }}
                                    ref={projectEditDropdownRef}
                                  >
                                    {row._id === editedId && (
                                      <>
                                        <Col
                                          className="cursor"
                                          onClick={() => {
                                            setClickId(row._id);
                                            setProjectEditDropdownOpen(!projectEditDropdownOpen);
                                            setProjectStatusFilter("Active");
                                            setFilter("");
                                            setSelectedClients(new Set());
                                            setSelectedProject(null);
                                            setTagEditDropdownOpen(false);
                                          }}
                                        >
                                          <span
                                            className={`tooltip-tracker ${currentTextCount(
                                              projectNameEdit,
                                              sprintNameEdit &&
                                              sprintNameEdit,
                                              clientEdit && clientEdit
                                            ) < 21
                                              ? "small"
                                              : "large"
                                              }`}
                                          >
                                            {projectName
                                              ? projectNameEdit
                                              : projectNameEdit}
                                            {`${sprintNameEdit
                                              ? ` - ${sprintNameEdit}`
                                              : " "
                                              }`}
                                            <input
                                              type="hidden"
                                              name="project"
                                              value={projectIdEdit}
                                            />
                                            <div class="project-name-tracker">
                                              {clientEdit
                                                ? clientEdit
                                                : clientEdit}
                                            </div>
                                          </span>
                                          <span
                                            className={`project-name-edit-inner ${currentTextCount(
                                              projectNameEdit,
                                              sprintNameEdit &&
                                              sprintNameEdit,
                                              clientEdit && clientEdit
                                            ) < 21
                                              ? "small"
                                              : "large"
                                              }`}
                                            style={{
                                              display: "inline-block",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              height: "22px",
                                              maxWidth: "140px",
                                            }}
                                          >
                                            {projectNameEdit && projectNameEdit}
                                            {`${sprintNameEdit
                                              ? ` - ${sprintNameEdit}`
                                              : ""
                                              }`}
                                            <input
                                              type="hidden"
                                              name="project"
                                              value={projectIdEdit}
                                            />
                                            <div class="project-name-tracker">
                                              {clientEdit
                                                ? clientEdit
                                                : clientEdit}
                                            </div>
                                          </span>
                                        </Col>

                                        {projectEditDropdownOpen && (
                                          <div className="custom-dropdown">
                                            <input
                                              type="text"
                                              className="search-bar"
                                              placeholder="Find Project or Client"
                                              value={filter}
                                              onChange={(e) => {
                                                const filterValue = e.target.value;
                                                setFilter(filterValue);
                                                if (filterValue === "") {
                                                  setSelectedClients(new Set());
                                                } else {
                                                  // Check if the filtered project exists under any client
                                                  const matchingClients =
                                                    filteredClientData.filter(
                                                      (client) =>
                                                        client.projects.some(
                                                          (project) =>
                                                            project.project_name
                                                              .toLowerCase()
                                                              .includes(filterValue.toLowerCase()) &&
                                                            (projectStatusFilter === "All" ? true
                                                              : projectStatusFilter === "Active"
                                                                ? project.is_active === true
                                                                : project.is_active === false)
                                                        )
                                                    );
                                                  setSelectedClients(new Set(matchingClients));
                                                }
                                              }}
                                              autoComplete="off"
                                            />
                                            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                              <>
                                                <div className="user-status-dropdown">
                                                  <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                                  <div className="custom-dropdown-user" ref={projectStatusMobileMergeDropdownRef}>
                                                    <div className="showing-name" onClick={toggleProjectStatusMobileMergeDropdown}>
                                                      {projectStatusFilter ? projectStatusFilter : "Active"}
                                                    </div>
                                                  </div>
                                                </div>
                                                {isProjectStatusMobileMergeDropdownOpen && (
                                                  <ul className="status-list">
                                                    <li
                                                      onClick={() => handleProjectStatusChange("All")}
                                                      className={projectStatusFilter === "All" ? "selected-item" : ""}
                                                    >
                                                      All
                                                    </li>
                                                    <li
                                                      onClick={() => handleProjectStatusChange("Active")}
                                                      className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                                    >
                                                      Active
                                                    </li>
                                                    <li
                                                      onClick={() => handleProjectStatusChange("Inactive")}
                                                      className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                                    >
                                                      Inactive
                                                    </li>
                                                  </ul>
                                                )}
                                              </>
                                            )}
                                            <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                                              {filteredClientData.map(
                                                (client) => (
                                                  <div
                                                    key={client._id}
                                                    className="main-outer-item-dd"
                                                  >
                                                    {/* Render the client name */}
                                                    <div
                                                      className={`client-name1 ${selectedClients.has(
                                                        client
                                                      )
                                                        ? "selected"
                                                        : ""
                                                        }`}
                                                      onClick={() => {
                                                        if (
                                                          client.projects
                                                            .length > 0
                                                        ) {
                                                          // Toggle the client in the Set
                                                          const newSelectedClients =
                                                            new Set(
                                                              selectedClients
                                                            );
                                                          if (
                                                            selectedClients.has(
                                                              client
                                                            )
                                                          ) {
                                                            newSelectedClients.delete(
                                                              client
                                                            );
                                                          } else {
                                                            newSelectedClients.add(
                                                              client
                                                            );
                                                          }
                                                          setSelectedClients(
                                                            newSelectedClients
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <span className="main-project">
                                                        {client.client_name}{" "}
                                                      </span>
                                                      {client.projects.filter(
                                                        (project) =>
                                                          project.project_name
                                                            .toLowerCase()
                                                            .includes(filter.toLowerCase()) &&
                                                          (projectStatusFilter === "All"
                                                            ? true
                                                            : projectStatusFilter === "Active"
                                                              ? project.is_active === true
                                                              : project.is_active === false)
                                                      ).length >
                                                        0 && (
                                                          <span
                                                            className={`arrow ${selectedClients.has(
                                                              client
                                                            )
                                                              ? "open"
                                                              : ""
                                                              }`}
                                                          >
                                                            <span className="main-project-title">
                                                              {
                                                                client.projects
                                                                  .length
                                                              }{" "}
                                                              Projects
                                                            </span>
                                                            {selectedClients.has(
                                                              client
                                                            ) ? (
                                                              <img
                                                                src={TopArrow}
                                                                alt="Open"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={DownArrow}
                                                                alt="Close"
                                                              />
                                                            )}
                                                          </span>
                                                        )}
                                                    </div>
                                                    {selectedClients.has(
                                                      client
                                                    ) && (
                                                        <div className="project-name1-outer">
                                                          {/* Render projects under the selected client */}
                                                          {client.projects
                                                            .length === 0 ? (
                                                            <div>
                                                              No projects under
                                                              this client
                                                            </div>
                                                          ) : (
                                                            client.projects
                                                              .filter((project) =>
                                                                project.project_name
                                                                  .toLowerCase()
                                                                  .includes(filter.toLowerCase()) &&
                                                                (projectStatusFilter === "All" ? true
                                                                  : projectStatusFilter === "Active"
                                                                    ? project.is_active === true
                                                                    : project.is_active === false)
                                                              )
                                                              .sort((a, b) =>
                                                                a.project_name.localeCompare(b.project_name)
                                                              )
                                                              .map((project) => (
                                                                <div key={project._id} >
                                                                  <div
                                                                    className={`project-name1 ${selectedProject === project ? "selected" : ""}`}
                                                                  >
                                                                    <div
                                                                      onClick={(e) => {
                                                                        setSprintIdEdit("");
                                                                        setSprintNameEdit("");
                                                                        setProjectIdEdit(project._id);
                                                                        setProjectNameEdit(project.project_name);
                                                                        setClientEdit(client.client_name);
                                                                        setClientIdEdit(client._id);
                                                                        setBillableEdit(project.is_billable);
                                                                        setProjectEditDropdownOpen(false);
                                                                      }}
                                                                    >
                                                                      <span className="project-dd-title">
                                                                        {project.project_name}{" "}
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      {project.sprints.length > 0 && (
                                                                        <span
                                                                          className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                                          onClick={() =>
                                                                            setSelectedProject(selectedProject === project ? null : project)
                                                                          }
                                                                        >
                                                                          <span className="main-project-title">
                                                                            {project.sprints.length}{" "} Sprints
                                                                          </span>
                                                                          {selectedProject === project ? (
                                                                            <img src={TopArrow} alt="Open" />
                                                                          ) : (
                                                                            <img src={DownArrow} alt="Close" />
                                                                          )}
                                                                        </span>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                  {selectedProject === project && (
                                                                    <div>
                                                                      {/* Render sprints under the selected project */}
                                                                      {project.sprints.length === 0 ? (
                                                                        <div>
                                                                          No sprints under this project
                                                                        </div>
                                                                      ) : (
                                                                        <ul className="sprint-list">
                                                                          {project.sprints
                                                                            .sort((a, b) => a.sprint_name.localeCompare(b.sprint_name))
                                                                            .map(
                                                                              (sprint) => (
                                                                                <li key={sprint._id}
                                                                                  onClick={(e) => {
                                                                                    setSprintIdEdit(sprint._id);
                                                                                    setSprintNameEdit(sprint.sprint_name);
                                                                                    setProjectIdEdit(project._id);
                                                                                    setProjectNameEdit(project.project_name);
                                                                                    setClientEdit(client.client_name);
                                                                                    setClientIdEdit(client._id);
                                                                                    setBillableEdit(project.is_billable);
                                                                                    setProjectEditDropdownOpen(false);
                                                                                  }}
                                                                                >
                                                                                  {sprint.sprint_name}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                        </ul>
                                                                      )}
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              ))
                                                          )}
                                                        </div>
                                                      )}
                                                  </div>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </>
                                    )}

                                    {row._id !== editedId && (
                                      <Col
                                        className={
                                          getLockHours(
                                            moment(row.endTime)
                                              .tz(global.config.getTimeZone())
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            global.config.getTimeZone(),
                                            "YYYY-MM-DD HH:mm:ss"
                                          ) === true
                                            ? "cursor"
                                            : "cursor"
                                        }
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(row.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            setEditedId(row._id);
                                            setProjectEditDropdownOpen(false);
                                            setTagEditDropdownOpen(false);
                                            setTaskNameEdit(row.task);
                                            setProjectIdEdit(row.project._id);
                                            setProjectNameEdit(row.project.project_name);
                                            if (row.sprint) {
                                              setSprintNameEdit(row.sprint.sprint_name);
                                              setSprintIdEdit(row.sprint._id);
                                            } else {
                                              setSprintIdEdit("");
                                              setSprintNameEdit("");
                                            }

                                            setDateEdit(
                                              zoneConverter(
                                                row.date,
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            );

                                            setTagIdEdit(
                                              row.tag && row.tag._id
                                            );
                                            setStartTimeEdit(
                                              TimestampConverter(row.startTime)
                                            );
                                            setEndTimeEdit(
                                              TimestampConverter(row.endTime)
                                            );
                                            setStartTimeTemp(
                                              TimestampConverter(row.startTime)
                                            );
                                            setEndTimeTemp(
                                              TimestampConverter(row.endTime)
                                            );
                                            setStartTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(
                                                  row.startTime
                                                )
                                              )
                                            );
                                            setEndTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(row.endTime)
                                              )
                                            );

                                            setClientEdit(row.client);
                                            setClientIdEdit(row.clientId);
                                            setBillableEdit(row.billable);
                                            setUFormatedTime(
                                              `${formatTime(
                                                Math.floor(row.duration / 3600)
                                              )}:${formatTime(
                                                Math.floor(
                                                  parseInt(
                                                    (row.duration % 3600) / 60
                                                  )
                                                )
                                              )}:${formatTime(
                                                Math.floor(row.duration % 60)
                                              )}`
                                            );
                                            setUDuration(row.duration);
                                            setUStartTimeError(false);
                                            setUEndTimeError(false);
                                            setUFormatedTimeError(false);
                                          } else {
                                            handleOpenPopup();
                                            setClickId(row._id);
                                          }
                                        }}
                                      >
                                        <span
                                          className={`tooltip-tracker ${currentTextCount(
                                            row.project.project_name,
                                            row.sprint &&
                                            row.sprint.sprint_name,
                                            row.client && row.client
                                          ) < 21
                                            ? "small"
                                            : "large"
                                            }`}
                                        >
                                          {row.project.project_name}{" "}
                                          {`${row.sprint
                                            ? ` - ${row.sprint.sprint_name}`
                                            : ""
                                            }`}
                                          <div class="project-name-tracker">
                                            {row.client}{" "}
                                          </div>
                                        </span>
                                        <span
                                          className={`project-tracker-inner ${currentTextCount(
                                            row.project.project_name,
                                            row.sprint &&
                                            row.sprint.sprint_name,
                                            row.client && row.client
                                          ) < 21
                                            ? "small"
                                            : "large"
                                            }`}
                                          style={{
                                            display: "inline-block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            height: "22px",
                                            maxWidth: "140px",
                                          }}
                                        >
                                          {row.project.project_name}{" "}
                                          {`${row.sprint
                                            ? ` - ${row.sprint.sprint_name}`
                                            : ""
                                            }`}
                                          <div class="project-name-tracker">
                                            {row.client}{" "}
                                          </div>
                                        </span>
                                      </Col>
                                    )}
                                  </div>

                                  {/* end mobile */}

                                  {/* tag , timer section */}
                                  <div className="tag-section-overview-outer">
                                    {row._id === editedId && (
                                      <div className="tag-section">
                                        <p
                                          className={`cursor ${row.tag
                                            ? "tag-available"
                                            : "tag-not-available"
                                            }`}
                                          onClick={() => {
                                            setTagEditDropdownOpen(
                                              !tagEditDropdownOpen
                                            );
                                            setProjectEditDropdownOpen(false);
                                          }}
                                        >
                                          <span className="tooltip-tracker">
                                            {" "}
                                            {tagEdit ? (
                                              tagEdit
                                            ) : row.tag ? (
                                              row.tag.tag_name
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                                <path
                                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                              </svg>
                                            )}
                                          </span>
                                          <span
                                            className="tag-edit-tag-name"
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "180px", // Define a maxWidth or width based on your layout
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {" "}
                                            {tagEdit ? (
                                              tagEdit
                                            ) : row.tag ? (
                                              row.tag.tag_name
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                                <path
                                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                              </svg>
                                            )}
                                          </span>
                                        </p>
                                        {tagEditDropdownOpen && (
                                          <div className="custom-dropdown">
                                            <input
                                              type="text"
                                              className="search-bar"
                                              placeholder="Search..."
                                              value={
                                                tagEdit ? tagEdit : tagEdit
                                              }
                                              onChange={handleTagInputChange}
                                              autoComplete="off"
                                            />
                                            <ul className="client-list">
                                              {filteredTagItems.map((row) => (
                                                <li
                                                  className="cursor"
                                                  key={row._id}
                                                  onClick={(e) => {
                                                    setTagEdit(row.tag_name);
                                                    setTagIdEdit(row._id);
                                                    setTagEditDropdownOpen(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  {row.tag_name}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {row._id !== editedId && (
                                      <div
                                        className="tag-section"
                                        onClick={() => {
                                          if (
                                            getLockHours(
                                              moment(row.endTime)
                                                .tz(global.config.getTimeZone())
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              global.config.getTimeZone(),
                                              "YYYY-MM-DD HH:mm:ss"
                                            ) === true
                                          ) {
                                            setEditedId(row._id);
                                            setProjectEditDropdownOpen(false);
                                            setTagEditDropdownOpen(false);
                                            setTaskNameEdit(row.task);
                                            setProjectIdEdit(row.project._id);
                                            setProjectNameEdit(row.project.project_name);
                                            if (row.sprint) {
                                              setSprintNameEdit(row.sprint.sprint_name);
                                              setSprintIdEdit(row.sprint._id);
                                            } else {
                                              setSprintIdEdit("");
                                              setSprintNameEdit("");
                                            }
                                            setDateEdit(
                                              zoneConverter(
                                                row.date,
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            );
                                            setTagIdEdit(row.tag && row.tag._id);
                                            setStartTimeEdit(TimestampConverter(row.startTime));
                                            setEndTimeEdit(TimestampConverter(row.endTime));
                                            setStartTimeTemp(TimestampConverter(row.startTime));
                                            setEndTimeTemp(TimestampConverter(row.endTime));
                                            setStartTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(row.startTime)
                                              )
                                            );
                                            setEndTimeEdit24(
                                              convertTo24HourFormatback(
                                                TimestampConverter(row.endTime)
                                              )
                                            );

                                            setClientEdit(row.client);
                                            setClientIdEdit(row.clientId);
                                            setBillableEdit(row.billable);
                                            setUFormatedTime(
                                              `${formatTime(
                                                Math.floor(row.duration / 3600)
                                              )}:${formatTime(
                                                Math.floor(
                                                  parseInt((row.duration % 3600) / 60)
                                                )
                                              )}:${formatTime(
                                                Math.floor(row.duration % 60)
                                              )}`
                                            );
                                            setUDuration(row.duration);
                                            setUStartTimeError(false);
                                            setUEndTimeError(false);
                                            setUFormatedTimeError(false);
                                          } else {
                                            handleOpenPopup();
                                            setClickId(row._id);
                                          }
                                        }}
                                      >
                                        <p
                                          className={`cursor ${row.tag ? "tag-available" : "tag-not-available"}`}
                                          onClick={() => {
                                            if (
                                              getLockHours(
                                                moment(row.endTime)
                                                  .tz(global.config.getTimeZone())
                                                  .format("YYYY-MM-DD HH:mm:ss"),
                                                global.config.getTimeZone(),
                                                "YYYY-MM-DD HH:mm:ss"
                                              ) === true
                                            ) {
                                              setEditedId(row._id);
                                              setTaskNameEdit(row.task);
                                              setProjectIdEdit(row.project._id);
                                              setProjectNameEdit(row.project.project_name);
                                              if (row.sprint) {
                                                setSprintNameEdit(row.sprint.sprint_name);
                                                setSprintIdEdit(row.sprint._id);
                                              } else {
                                                setSprintIdEdit("");
                                                setSprintNameEdit("");
                                              }
                                              setStartTimeTemp(TimestampConverter(row.startTime));
                                              setEndTimeTemp(TimestampConverter(row.endTime));
                                              setStartTimeEdit24(convertTo24HourFormatback(TimestampConverter(row.startTime)));
                                              setEndTimeEdit24(convertTo24HourFormatback(TimestampConverter(row.endTime)));
                                              setDateEdit(zoneConverter(row.date, global.config.getTimeZone(), "YYYY-MM-DD HH:mm:ss"));
                                              setTagIdEdit(row.tag && row.tag._id);
                                            } else {
                                              handleOpenPopup();
                                              setClickId(row._id);
                                            }
                                          }}
                                        >
                                          <span className="tooltip-tracker">
                                            {" "}
                                            {row.tag ? (
                                              row.tag.tag_name
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                                <path
                                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                              </svg>
                                            )}
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {" "}
                                            {row.tag ? (
                                              row.tag.tag_name
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                                <path
                                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                                  fill="#96A8BA"
                                                  stroke="white"
                                                  strokeWidth="0.2"
                                                ></path>
                                              </svg>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                    <input
                                      type="hidden"
                                      name="tag"
                                      value={tagIdEdit}
                                    />
                                  </div>
                                  <div className="billable-section">
                                    {editedId === row._id ? (
                                      billableEdit === true ? (
                                        <div className="billable-timing-sec billable-timing">
                                          <svg
                                            width="13"
                                            height="20"
                                            viewBox="0 0 13 20"
                                            fill="#405BFF"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                              fill="#96A8BA"
                                            />
                                          </svg>
                                        </div>
                                      ) : (
                                        <div className="billable-timing-sec non-billable-timing">
                                          <svg
                                            width="13"
                                            height="20"
                                            viewBox="0 0 13 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                              fill="#96A8BA"
                                            />
                                          </svg>
                                        </div>
                                      )
                                    ) : row.billable === true ? (
                                      <div className="billable-timing-sec billable-timing">
                                        <svg
                                          width="13"
                                          height="20"
                                          viewBox="0 0 13 20"
                                          fill="#405BFF"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                            fill="#96A8BA"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <div className="billable-timing-sec non-billable-timing">
                                        <svg
                                          width="13"
                                          height="20"
                                          viewBox="0 0 13 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                            fill="#96A8BA"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={`overview-sec-timer-date-time date-${row._id}`}
                                    style={{ position: "relative" }}
                                  >
                                    <Col>
                                      {row._id === editedId ? (
                                        <>
                                          <div className="time-overview-sec">
                                            {timeFormat === 24 ? (
                                              <>
                                                <input
                                                  ref={startRowInputRef}
                                                  type="text"
                                                  name="start_time"
                                                  value={startTimeEdit24}
                                                  style={{
                                                    backgroundColor:
                                                      uStartTimeError &&
                                                      "#fddada",
                                                  }}

                                                  onChange={(e) => {
                                                    if (
                                                      validateNoAlphabets(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      setStartTimeEdit24(
                                                        e.target.value
                                                      );
                                                    }
                                                  }}
                                                  onBlur={() => {
                                                    if (!startTimeEdit) {
                                                      setStartTimeEdit(
                                                        "7:46pm"
                                                      );
                                                    }
                                                    setIsTimeInputActive(false);
                                                  }}
                                                  onFocus={() =>
                                                    setIsTimeInputActive(true)
                                                  }
                                                  className={`${editedId === row._id
                                                    ? "edit-time edit-time-start"
                                                    : ""
                                                    }`}
                                                  autoComplete="off"
                                                />
                                                -
                                                <input
                                                  ref={endRowInputRef}
                                                  type="text"
                                                  name="end_time"
                                                  value={endTimeEdit24}
                                                  style={{
                                                    backgroundColor:
                                                      uEndTimeError &&
                                                      "#fddada",
                                                  }}

                                                  onChange={(e) => {
                                                    if (
                                                      validateNoAlphabets(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      setEndTimeEdit24(
                                                        e.target.value
                                                      );
                                                    }
                                                  }}
                                                  onBlur={() => {
                                                    if (!endTimeEdit) {
                                                      setEndTimeEdit("8:50pm");
                                                    }
                                                    setIsTimeInputActive(false);
                                                  }}
                                                  onFocus={() =>
                                                    setIsTimeInputActive(true)
                                                  }
                                                  className={`${editedId === row._id
                                                    ? "edit-time edit-time-end"
                                                    : ""
                                                    }`}
                                                  autoComplete="off"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  ref={start12TimeRowInputRef}
                                                  type="text"
                                                  name="start_time"
                                                  value={startTimeEdit}
                                                  style={{
                                                    backgroundColor:
                                                      uStartTimeError &&
                                                      "#fddada",
                                                  }}

                                                  onChange={(e) => {
                                                    setStartTimeEdit(
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    if (!startTimeEdit)
                                                      setStartTimeEdit(
                                                        "07:46 PM"
                                                      );
                                                    setIsTimeInputActive(false);
                                                  }}
                                                  onFocus={() =>
                                                    setIsTimeInputActive(true)
                                                  }
                                                  className={`${editedId === row._id
                                                    ? "edit-time edit-time-start"
                                                    : ""
                                                    }`}
                                                  autoComplete="off"
                                                />
                                                -
                                                <input
                                                  ref={end12TimeRowInputRef}
                                                  type="text"
                                                  name="end_time"
                                                  value={endTimeEdit}
                                                  style={{
                                                    backgroundColor:
                                                      uEndTimeError &&
                                                      "#fddada",
                                                  }}

                                                  onChange={(e) => {
                                                    setEndTimeEdit(
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    if (!endTimeEdit)
                                                      setEndTimeEdit(
                                                        "08:50 PM"
                                                      );
                                                    setIsTimeInputActive(false);
                                                  }}
                                                  onFocus={() =>
                                                    setIsTimeInputActive(true)
                                                  }
                                                  className={`${editedId === row._id
                                                    ? "edit-time edit-time-end"
                                                    : ""
                                                    }`}
                                                  autoComplete="off"
                                                />
                                              </>
                                            )}
                                          </div>

                                          <div className="date-overview-sec date-overview-edit">
                                            <div
                                              onClick={handleDateIconClick}
                                              className="custom-date-icon"
                                            >
                                              {/* Your custom date picker icon SVG or image here */}
                                              <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 14 14"
                                                fill="currentColor"
                                                aria-hidden="true"
                                                focusable="false"
                                                className="rs-picker-toggle-caret rs-icon"
                                                aria-label="calendar"
                                                data-category="legacy"
                                              >
                                                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                              </svg>
                                            </div>

                                            {/* <DatePicker
                                              block
                                              name="date"
                                              appearance="subtle"
                                              value={
                                                new Date(
                                                  zoneConverter(
                                                    dateEdit,
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )
                                                )
                                              }
                                              onChange={(value) => {
                                                setDateOldEdit(
                                                  new Date(
                                                    zoneConverter(
                                                      dateEdit,
                                                      global.config.getTimeZone(),
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                  )
                                                );
                                                setDateEdit(value);
                                              }}
                                              ref={datePickerRef}
                                              placement="bottomEnd"
                                              oneTap
                                              timeZone={global.config.getTimeZone()}
                                              isoWeek={
                                                appSettings.weekStartDay &&
                                                appSettings.weekStartDay ===
                                                "Monday"
                                              }
                                            /> */}
                                            <DatePicker
                                              block
                                              name="date"
                                              appearance="subtle"
                                              value={moment(dateEdit, "YYYY-MM-DD HH:mm:ss").toDate()}
                                              onChange={(value) => {
                                                setDateOldEdit(moment(dateEdit, "YYYY-MM-DD HH:mm:ss").toDate());
                                                setDateEdit(value);
                                              }}
                                              ref={datePickerRef}
                                              placement="bottomEnd"
                                              oneTap
                                              timeZone={global.config.getTimeZone()}
                                              isoWeek={
                                                appSettings.weekStartDay &&
                                                appSettings.weekStartDay === "Monday"
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="time-overview-sec"
                                            onClick={() => {
                                              if (
                                                getLockHours(
                                                  moment(row.endTime)
                                                    .tz(
                                                      global.config.getTimeZone()
                                                    )
                                                    .format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ),
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ) === true
                                              ) {
                                                setEditedId(row._id);
                                                setTaskNameEdit(row.task);
                                                setProjectIdEdit(row.project._id);
                                                setProjectNameEdit(row.project.project_name);
                                                if (row.sprint) {
                                                  setSprintNameEdit(row.sprint.sprint_name);
                                                  setSprintIdEdit(row.sprint._id);
                                                } else {
                                                  setSprintIdEdit("");
                                                  setSprintNameEdit("");
                                                }

                                                setDateEdit(
                                                  zoneConverter(
                                                    row.date,
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )
                                                );

                                                setTagIdEdit(
                                                  row.tag && row.tag._id
                                                );
                                                setStartTimeEdit(
                                                  TimestampConverter(
                                                    row.startTime
                                                  )
                                                );
                                                setEndTimeEdit(
                                                  TimestampConverter(
                                                    row.endTime
                                                  )
                                                );
                                                setStartTimeTemp(
                                                  TimestampConverter(
                                                    row.startTime
                                                  )
                                                );
                                                setEndTimeTemp(
                                                  TimestampConverter(
                                                    row.endTime
                                                  )
                                                );
                                                setStartTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.startTime
                                                    )
                                                  )
                                                );
                                                setEndTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.endTime
                                                    )
                                                  )
                                                );

                                                setClientEdit(row.client);
                                                setClientIdEdit(row.clientId);
                                                setBillableEdit(row.billable);
                                                setUFormatedTime(
                                                  `${formatTime(
                                                    Math.floor(
                                                      row.duration / 3600
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      parseInt(
                                                        (row.duration % 3600) /
                                                        60
                                                      )
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      row.duration % 60
                                                    )
                                                  )}`
                                                );
                                                setUDuration(row.duration);
                                                setIsTimeInputActive(true);
                                                setUStartTimeError(false);
                                                setUEndTimeError(false);
                                                setUFormatedTimeError(false);
                                              } else {
                                                handleOpenPopup();
                                                setClickId(row._id);
                                              }
                                            }}
                                          >
                                            {timeFormat === 24 ? (
                                              <>
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  type="text"
                                                  name="start_time"
                                                  value={convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.startTime
                                                    )
                                                  )}

                                                  autocomplete="off"
                                                  className="time-show edit-time-start"
                                                />
                                                -
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  type="text"
                                                  name="end_time"
                                                  value={convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.endTime
                                                    )
                                                  )}

                                                  autocomplete="off"
                                                  className="time-show edit-time-end"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  type="text"
                                                  name="start_time"
                                                  value={TimestampConverter(
                                                    row.startTime
                                                  )}

                                                  autocomplete="off"
                                                  className="time-show edit-time-start"
                                                />
                                                -
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  type="text"
                                                  name="end_time"
                                                  value={TimestampConverter(
                                                    row.endTime
                                                  )}

                                                  autocomplete="off"
                                                  className="time-show edit-time-end"
                                                />
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className="date-overview-sec"
                                            onClick={() => {
                                              if (
                                                getLockHours(
                                                  moment(row.endTime).tz(global.config.getTimeZone()).format("YYYY-MM-DD HH:mm:ss"),
                                                  global.config.getTimeZone(),
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ) === true
                                              ) {
                                                setEditedId(row._id);
                                                setTaskNameEdit(row.task);
                                                setProjectIdEdit(row.project._id);
                                                setProjectNameEdit(row.project.project_name);
                                                if (row.sprint) {
                                                  setSprintNameEdit(row.sprint.sprint_name);
                                                  setSprintIdEdit(row.sprint._id);
                                                } else {
                                                  setSprintIdEdit("");
                                                  setSprintNameEdit("");
                                                }
                                                setDateEdit(
                                                  zoneConverter(
                                                    row.date,
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )
                                                );
                                                setTagIdEdit(
                                                  row.tag && row.tag._id
                                                );
                                                setStartTimeEdit(
                                                  TimestampConverter(
                                                    row.startTime
                                                  )
                                                );
                                                setEndTimeEdit(
                                                  TimestampConverter(
                                                    row.endTime
                                                  )
                                                );
                                                setStartTimeTemp(
                                                  TimestampConverter(
                                                    row.startTime
                                                  )
                                                );
                                                setEndTimeTemp(
                                                  TimestampConverter(
                                                    row.endTime
                                                  )
                                                );
                                                setStartTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.startTime
                                                    )
                                                  )
                                                );
                                                setEndTimeEdit24(
                                                  convertTo24HourFormatback(
                                                    TimestampConverter(
                                                      row.endTime
                                                    )
                                                  )
                                                );

                                                setClientEdit(row.client);
                                                setClientIdEdit(row.clientId);
                                                setBillableEdit(row.billable);
                                                setUFormatedTime(
                                                  `${formatTime(
                                                    Math.floor(
                                                      row.duration / 3600
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      parseInt(
                                                        (row.duration % 3600) /
                                                        60
                                                      )
                                                    )
                                                  )}:${formatTime(
                                                    Math.floor(
                                                      row.duration % 60
                                                    )
                                                  )}`
                                                );
                                                setUDuration(row.duration);
                                                setUStartTimeError(false);
                                                setUEndTimeError(false);
                                                setUFormatedTimeError(false);
                                              } else {
                                                handleOpenPopup();
                                                setClickId(row._id);
                                              }
                                            }}
                                          >
                                            <div
                                              className="custom-date-icon"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {/* Your custom date picker icon SVG or image here */}
                                              <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 14 14"
                                                fill="currentColor"
                                                aria-hidden="true"
                                                focusable="false"
                                                className="rs-picker-toggle-caret rs-icon"
                                                aria-label="calendar"
                                                data-category="legacy"
                                              >
                                                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                              </svg>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </div>

                                  <div className="overview-time-edit">
                                    <Col>
                                      <div className="overview-time-edit-inner">
                                        {/* duration edit section */}
                                        {row._id === editedId && (
                                          <>
                                            <div
                                              className="timer-sec-input"
                                              style={{
                                                backgroundColor:
                                                  uFormatedTimeError &&
                                                  "#fddada",
                                              }}
                                            >
                                              <input
                                                className={
                                                  row._id === editedId
                                                    ? "edit-time"
                                                    : "edit-time"
                                                }
                                                name="time"
                                                value={uFormatedTime}
                                                onChange={(e) => {

                                                  timeShowEdit(e.target.value);
                                                }}
                                                placeholder="00:00:00"
                                                type="text"
                                                autoComplete="off"
                                              />
                                            </div>

                                            <div className="play-button">
                                              <span
                                                style={{
                                                  cursor: (resumeLoading || customeTime)
                                                    ? "not-allowed"
                                                    : "pointer",
                                                }}
                                                onClick={() => {
                                                  if (!resumeLoading && !customeTime) {
                                                    resumeTimeTracking(
                                                      row.task,
                                                      row.project._id,
                                                      row.project.project_name,
                                                      row.tag.tag_name,
                                                      row.tag._id,
                                                      row.client,
                                                      row.project.client_name,
                                                      row.date,
                                                      row.startTime,
                                                      row.endTime,
                                                      row.duration,
                                                      row.sprint
                                                        ? row.sprint.sprint_name
                                                        : null,
                                                      row.sprint
                                                        ? row.sprint._id
                                                        : null,
                                                      row._id,
                                                      row.billable
                                                    );
                                                    // }
                                                  }
                                                }}
                                              >
                                                <img src={Play} alt="Play" />
                                              </span>
                                            </div>
                                          </>
                                        )}

                                        {/* duration edit section end */}

                                        {row._id !== editedId && (
                                          <>
                                            <div
                                              className="timer-sec-input"
                                              onClick={() => {
                                                if (
                                                  getLockHours(
                                                    moment(row.endTime).tz(global.config.getTimeZone()).format("YYYY-MM-DD HH:mm:ss"),
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ) === true
                                                ) {
                                                  setEditedId(row._id);
                                                  setTaskNameEdit(row.task);
                                                  setProjectIdEdit(row.project._id);
                                                  setProjectNameEdit(row.project.project_name);
                                                  if (row.sprint) {
                                                    setSprintNameEdit(row.sprint.sprint_name);
                                                    setSprintIdEdit(row.sprint._id);
                                                  } else {
                                                    setSprintIdEdit("");
                                                    setSprintNameEdit("");
                                                  }

                                                  setDateEdit(
                                                    zoneConverter(
                                                      row.date,
                                                      global.config.getTimeZone(),
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                  );

                                                  setTagIdEdit(
                                                    row.tag && row.tag._id
                                                  );
                                                  setStartTimeEdit(
                                                    TimestampConverter(
                                                      row.startTime
                                                    )
                                                  );
                                                  setEndTimeEdit(
                                                    TimestampConverter(
                                                      row.endTime
                                                    )
                                                  );
                                                  setStartTimeTemp(
                                                    TimestampConverter(
                                                      row.startTime
                                                    )
                                                  );
                                                  setEndTimeTemp(
                                                    TimestampConverter(
                                                      row.endTime
                                                    )
                                                  );
                                                  setStartTimeEdit24(
                                                    convertTo24HourFormatback(
                                                      TimestampConverter(
                                                        row.startTime
                                                      )
                                                    )
                                                  );
                                                  setEndTimeEdit24(
                                                    convertTo24HourFormatback(
                                                      TimestampConverter(
                                                        row.endTime
                                                      )
                                                    )
                                                  );

                                                  setClientEdit(row.client);
                                                  setClientIdEdit(row.clientId);
                                                  setBillableEdit(row.billable);
                                                  setUFormatedTime(
                                                    `${formatTime(
                                                      Math.floor(
                                                        row.duration / 3600
                                                      )
                                                    )}:${formatTime(
                                                      Math.floor(
                                                        parseInt(
                                                          (row.duration %
                                                            3600) /
                                                          60
                                                        )
                                                      )
                                                    )}:${formatTime(
                                                      Math.floor(
                                                        row.duration % 60
                                                      )
                                                    )}`
                                                  );
                                                  setUDuration(row.duration);
                                                  setUStartTimeError(false);
                                                  setUEndTimeError(false);
                                                  setUFormatedTimeError(false);
                                                } else {
                                                  handleOpenPopup();
                                                  setClickId(row._id);
                                                }
                                              }}
                                            >
                                              <input
                                                style={{ cursor: "pointer" }}
                                                className={
                                                  row._id === editedId
                                                    ? "before-edit-input"
                                                    : "before-edit-input"
                                                }
                                                name="time"
                                                value={`${formatTime(
                                                  row.hr
                                                )}:${formatTime(
                                                  row.min
                                                )}:${formatTime(row.sec)}`}
                                                placeholder="00:00:00"
                                                type="text"
                                                autoComplete="off"
                                              />
                                            </div>

                                            <div
                                              className="play-button"
                                              onClick={() => {
                                                if (!resumeLoading && !customeTime) {
                                                  resumeTimeTracking(
                                                    row.task,
                                                    row.project._id,
                                                    row.project.project_name,
                                                    row.tag && row.tag.tag_name,
                                                    row.tag && row.tag._id,
                                                    row.client,
                                                    row.project.client_name,
                                                    row.date,
                                                    row.startTime,
                                                    row.endTime,
                                                    row.duration,
                                                    row.sprint
                                                      ? row.sprint.sprint_name
                                                      : null,
                                                    row.sprint
                                                      ? row.sprint._id
                                                      : null,
                                                    row._id,
                                                    row.billable
                                                  );
                                                }
                                              }}
                                            >
                                              <span
                                                style={{
                                                  cursor: (resumeLoading || customeTime)
                                                    ? "not-allowed"
                                                    : "pointer",
                                                }}
                                              >
                                                <img src={Play} alt="Play" />
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </div>

                                      {row._id === editedId && (
                                        <>
                                          <div className="set-btn-outer">
                                            {!isTimeInputActive && (
                                              <button
                                                type="submit"
                                                className="set-button"
                                                disabled={
                                                  (isUpdateLoading ||
                                                    uFormatedTimeError ||
                                                    uEndTimeError ||
                                                    uStartTimeError ||
                                                    isTimeInputActive) &&
                                                  "disabled"
                                                }
                                              >
                                                {isUpdateLoading ? (
                                                  <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="white"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                  />
                                                ) : (
                                                  <img
                                                    width="18"
                                                    src={Update}
                                                    alt="update"
                                                  />
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </>
                                      )}

                                      {row._id !== editedId && (
                                        <>
                                          <div className="custom-dropdown12" ref={(el) => (activedropdownRefs.current[row._id] = el)}>
                                            <div
                                              className="dropdown-toggle1"
                                              onClick={() => {
                                                if (
                                                  getLockHours(
                                                    moment(row.endTime).tz(global.config.getTimeZone()).format("YYYY-MM-DD HH:mm:ss"),
                                                    global.config.getTimeZone(),
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ) === true
                                                ) {
                                                  handleDropdownToggle(row._id);
                                                } else {
                                                  setClickId(row._id);
                                                }
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="4"
                                                height="20"
                                                viewBox="0 0 4 20"
                                                fill="none"
                                              >
                                                <circle
                                                  cx="2"
                                                  cy="2"
                                                  r="2"
                                                  fill="#96A8BA"
                                                />
                                                <circle
                                                  cx="2"
                                                  cy="10"
                                                  r="2"
                                                  fill="#96A8BA"
                                                />
                                                <circle
                                                  cx="2"
                                                  cy="18"
                                                  r="2"
                                                  fill="#96A8BA"
                                                />
                                              </svg>
                                            </div>

                                            {activeDropdownId === row._id && (
                                              <ul className="dropdown-menu1">
                                                {duplicateLoading === false ? (
                                                  <li
                                                    onClick={() => {
                                                      if (!duplicateLoading) {
                                                        duplicate(
                                                          row.task,
                                                          row.project?._id,
                                                          row.tag &&
                                                          row.tag._id,
                                                          row.client,
                                                          row.project?.client_name,
                                                          row.date,
                                                          row.startTime,
                                                          row.endTime,
                                                          row.duration,
                                                          row.sprint ? row.sprint.sprint_name : null,
                                                          row.sprint ? row.sprint._id : null,
                                                          row._id,
                                                          row.billable
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      {" "}
                                                      <span> Duplicate</span>
                                                    </span>
                                                  </li>
                                                ) : (
                                                  <div className="outter-load-table">
                                                    <ThreeDots
                                                      height="30"
                                                      width="40"
                                                      radius="9"
                                                      color="#6479f9"
                                                      ariaLabel="three-dots-loading"
                                                      wrapperStyle={{}}
                                                      wrapperClassName=""
                                                      visible={true}
                                                    />
                                                  </div>
                                                )}
                                                <li
                                                  onClick={() =>
                                                    !isDeleteLoading &&
                                                    handleResetClick(
                                                      row._id,
                                                      group
                                                    )
                                                  }
                                                >
                                                  {row._id === deleteId &&
                                                    isDeleteLoading ? (
                                                    <div className="outter-load-table">
                                                      <ThreeDots
                                                        height="30"
                                                        width="40"
                                                        radius="9"
                                                        color="#6479f9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <span>Delete</span>
                                                  )}
                                                </li>
                                              </ul>
                                            )}
                                          </div>
                                        </>
                                      )}
                                      <div>
                                        {showPopup && clickId === row._id && (
                                          <TimeEntryPopup
                                            onClose={() => setShowPopup(false)}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                </form>
              </React.Fragment>
            ))}
        </>
      )}
    </Card.Body>
  );
};

export default CardBody;
