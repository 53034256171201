import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { AES } from 'crypto-js';
import { useTotalBudgetByClient, useTotalBudgetByProject, useTotalCostByClient, useTotalCostByClientReport, useTotalCostByProject, useTotalCostByProjectReport, useTotalInvoiceAmountByClient, useTrackedClientHours, useTrackedProjectHours } from "../../../features/projects/ProjectList";
import { convertCurrencyHelper } from "../../../helpers/forexConvertor";
import TruncateFieldView from '../../helpers/truncateFieldView';

export function clientTotalRevenue(trackReport, projectCost, invoices, projects, sprints, reportingFinancialOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    let totalBudget = 0;
    let totalCost = 0;
    let totalExpense = 0;
    // const processedProjects = new Set();

    const sprintsByProject = sprints.reduce((acc, sprint) => {
        const projectId = sprint.project?._id;
        if (projectId && !sprint.is_delete) {
            if (!acc[projectId]) {
                acc[projectId] = [];
            }
            acc[projectId].push(sprint);
        }
        return acc;
    }, {});

    // projects.forEach(project => {
    //     const projectId = project._id;
    //     const clientId = project?.client_name && project.client_name?._id;
    //     const projectYear = new Date(project.created_at).getFullYear();
    //     const preferredClientCurrency = project?.client_name?.preferred_currency || 'USD';

    //     if (!project.is_delete && projectYear === currentYear &&
    //         (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId))
    //         && (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(clientId))
    //         && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(preferredClientCurrency))
    //     ) {
    //         const preferredClientCurrency = project.clientId?.preferred_currency || 'USD';

    //         // Calculate total budget for the project including all its sprints
    //         const projectSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
    //             return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
    //         }, 0);

    //         // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget + projectSprintBudget, forex);
    //         totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, projectSprintBudget, forex);
    //     }
    // });

    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_created_at).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for._id;
        if (invoiceYear === currentYear && !invoice.is_delete && clientId
            && (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(clientId))
            && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(preferredClientCurrency))
        ) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            totalBudget += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (project && !project.is_delete
            && projectYear === currentYear
            && (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId))
            && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(clientId?.preferred_currency || 'USD'))
        ) {
            // const preferredClientCurrency = clientId.preferred_currency || 'USD';
            // console.log("OutSideProjectId", projectId);
            // if (!processedProjects.has(projectId)) {
            //     // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
            //     const projectSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
            //         console.log("ProjectId", projectId);
            //         console.log("Budget", convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex));
            //         return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
            //     }, 0);
            //     totalBudget += projectSprintBudget;
            //     processedProjects.add(projectId);
            // }

            // const userCost = user.cost || 0;
            const paymentCurrency = user?.payment_currency || 'USD';
            const preferredClientCurrency = clientId && clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }
            // if (projectYear === currentYear) {
            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
            // }
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingFinancialOverview.financialOverviewClient ? reportingFinancialOverview.financialOverviewClient.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });


    // Calculate total revenue
    const totalRevenue = totalBudget - (totalCost + totalExpense);

    return parseFloat(totalRevenue).toFixed(2);
}

export function clientRevenue(trackReport, cliId, projectCost, invoices, projects, sprints, reportingFinancialOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    let totalBudget = 0;
    let totalCost = 0;
    let totalExpense = 0;

    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_created_at).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const invoiceClientId = invoice.cli_inv_for._id;
        if (invoiceYear === currentYear && !invoice.is_delete && invoiceClientId
            && invoiceClientId === cliId
            && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(preferredClientCurrency))
        ) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            totalBudget += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (project && !project.is_delete
            && projectYear === currentYear
            && (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId))
            && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(clientId?.preferred_currency || 'USD'))
        ) {

            const paymentCurrency = user?.payment_currency || 'USD';
            const preferredClientCurrency = clientId && clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }
            // if (projectYear === currentYear) {
            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
            // }
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const expenseClientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && expenseClientId && expenseClientId === cliId) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });


    // Calculate total revenue
    const totalRevenue = totalBudget - (totalCost + totalExpense);

    return parseFloat(totalRevenue).toFixed(2);
}

export function clientTotalCost(trackReport, projectCost, reportingFinancialOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    let totalCost = 0;
    let totalExpense = 0;

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId)) && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(clientId?.preferred_currency || 'USD'))) {
            // const userCost = user.cost || 0;
            const paymentCurrency = user.payment_currency || 'USD';
            const preferredClientCurrency = clientId && clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingFinancialOverview.financialOverviewClient ? reportingFinancialOverview.financialOverviewClient.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });

    // Calculate total revenue
    const totalClientCost = (totalCost + totalExpense);

    return parseFloat(totalClientCost).toFixed(2);
}

export function clientCost(trackReport, cliId, projectCost, reportingFinancialOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    let totalCost = 0;
    let totalExpense = 0;

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && clientId && !clientId.is_delete && (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId)) && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(clientId?.preferred_currency || 'USD'))) {
            // const userCost = user.cost || 0;
            const paymentCurrency = user.payment_currency || 'USD';
            const preferredClientCurrency = clientId && clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const expenseClientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && expenseClientId && expenseClientId === cliId) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });

    // Calculate total revenue
    const totalClientCost = (totalCost + totalExpense);

    return parseFloat(totalClientCost).toFixed(2);
}

export function clientTotalRoi(trackReport, projectCost, invoices, projects, sprints, reportingFinancialOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    let totalBudget = 0;
    let totalCost = 0;
    let totalExpense = 0;
    // const processedProjects = new Set();

    const sprintsByProject = sprints.reduce((acc, sprint) => {
        const projectId = sprint.project?._id;
        if (projectId && !sprint.is_delete) {
            if (!acc[projectId]) {
                acc[projectId] = [];
            }
            acc[projectId].push(sprint);
        }
        return acc;
    }, {});

    // projects.forEach(project => {
    //     const projectId = project._id;
    //     const clientId = project?.client_name && project.client_name?._id;
    //     const projectYear = new Date(project.created_at).getFullYear();
    //     const preferredClientCurrency = project?.client_name?.preferred_currency || 'USD';

    //     if (!project.is_delete && projectYear === currentYear &&
    //         (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId))
    //         && (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(clientId))
    //         && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(preferredClientCurrency))
    //     ) {
    //         const preferredClientCurrency = project.clientId?.preferred_currency || 'USD';

    //         // Calculate total budget for the project including all its sprints
    //         const projectSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
    //             return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
    //         }, 0);

    //         // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget + projectSprintBudget, forex);
    //         totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, projectSprintBudget, forex);
    //     }
    // });

    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_created_at).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for._id;
        if (invoiceYear === currentYear && !invoice.is_delete && clientId
            && (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(clientId))
            && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(preferredClientCurrency))
        ) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            totalBudget += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(projectId)) && (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(clientId?.preferred_currency || 'USD'))) {
            // const preferredClientCurrency = clientId.preferred_currency || 'USD';
            // if (!processedProjects.has(projectId)) {
            //     // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
            //     const projectSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
            //         return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
            //     }, 0);
            //     totalBudget += projectSprintBudget;
            //     processedProjects.add(projectId);
            // }
            // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);

            // const userCost = user.cost || 0;
            const paymentCurrency = user.payment_currency || 'USD';
            const preferredClientCurrency = clientId && clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingFinancialOverview.financialOverviewClient ? reportingFinancialOverview.financialOverviewClient.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });

    // Calculate total ROI
    const totalCostAndExpense = totalCost + totalExpense;
    const totalRoi = totalCostAndExpense !== 0 ? (((totalBudget - totalCostAndExpense) / totalCostAndExpense) * 100) : 0;

    return totalRoi.toFixed(2);
}

export function ProjectColumn({ rowData }) {
    const encryptedCliId = AES.encrypt(rowData?.client_id, 'encryptionKey').toString();
    const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

    const handleClick = () => {
        window.location.href = `/client/projects/details/${rowData?.project_id}/${encodedId}`;
    };

    return (
        <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            <TruncateFieldView textContent={rowData?.project_name} />
        </div>
    );
};

export function ProjectTotalBudget(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalBudget = [], isLoading: budgetLoading, refetch: refetchTotalBudget, isError: budgetIsError } = useTotalBudgetByProject(rowData._id, token);
    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, settings.currency);
    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalBudget();
        }
    }, [rowData]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(rowData.client_name.preferred_currency || "USD", settings.currency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, settings.currency, rowData.client_name]);


    const totalRevenue = convertedBudget - totalCost;
    const formattedRevenue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: settings.currency,
    }).format(totalRevenue);

    const formattedBudget = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: settings.currency,
    }).format(convertedBudget);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                formattedBudget
            )}
        </div>
    );
}

export function ProjectLoggedHours(rowData) {
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalHours = [], isLoading: loading, refetch: refetchTotalHours, isError: totalHoursFetchError } = useTrackedProjectHours(rowData._id, token);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalHours();
        }
    }, [rowData]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }
    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span>
            )}
        </div>
    );
};

export function ProjectTotalCost(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProjectReport(rowData._id, token, forex, settings.currency);

    useEffect(() => {

        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0.00)
            )}
        </div>
    );
}

export function ProjectRoi(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProjectReport(rowData._id, token, forex, settings.currency);
    const { data: totalBudget = [], isLoading: budgetLoading, refetch: refetchTotalBudget, isError: budgetIsError } = useTotalBudgetByProject(rowData._id, token);
    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalBudget();
        }
    }, [rowData]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(rowData.client_name.preferred_currency || "USD", settings.currency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, settings.currency, rowData.client_name]);

    let Rvalue;
    if (totalCost === 0 && convertedBudget === 0) {
        Rvalue = "0%";
    } else if (totalCost === 0 && convertedBudget !== 0) {
        Rvalue = "N/A";
    } else {
        const rev = convertedBudget - totalCost;
        Rvalue = (rev / totalCost) * 100;
    }

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>{typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue}</>
            )}
        </div>
    );
}

export function TotalCostPerProject(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, settings.currency);

    useEffect(() => {

        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0.00)
            )}
        </div>
    );
}

export function ClientColumn({ rowData }) {
    const encryptedCliId = AES.encrypt(rowData?._id, 'encryptionKey').toString();
    const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');
    const clientName = rowData?.client_name || rowData?.legal_entity_name || 'Client Name';

    const handleClick = () => {
        window.location.href = `/client/details/${encodedId}`;
    };

    return (
        <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            <TruncateFieldView textContent={clientName} />
        </div>
    );
};

export function ClientTotalRevenue(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);

    const financialYear = reportingFinancialOverview.financialOverviewYear;

    const startDate = new Date(financialYear, 0, 1);
    const endDate = new Date(financialYear, 11, 31);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError: totalBudgetFetchError, } = useTotalBudgetByClient(rowData._id, token);
    const { data: totalInvoiceAmount = [], isLoading: totalInvoiceAmountLoading, refetch: refetchTotalInvoiceAmount, isError: totalInvoiceAmountFetchError, } = useTotalInvoiceAmountByClient(rowData._id, token);
    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByClientReport(rowData._id, token, startDate, endDate, forex, settings.currency);
    const convertedBudget = rowData?.preferred_currency ?
        ConvertHelper(rowData.preferred_currency, settings.currency, totalInvoiceAmount, forex) :
        ConvertHelper("USD", settings.currency, totalInvoiceAmount, forex);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalInvoiceAmount();
        }
    }, [rowData]);


    const totalRevenue = convertedBudget - totalCost;
    const formattedRevenue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: settings.currency,
    }).format(totalRevenue);

    return (
        <div>
            {(loading || totalInvoiceAmountLoading) ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                formattedRevenue
            )}
        </div>
    );
}

export function ClientLoggedHours(rowData) {
    const token = useSelector(
        (state) => state.auth.token
    )
    const tz = useSelector((state) => state.appSettings.appSettings.timeZone);
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
    const financialYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    const startDate = moment(`${financialYear}-01-01T00:00:00.000Z`).tz(tz).format();
    const endDate = moment(`${financialYear}-12-31T23:59:59.999Z`).tz(tz).format();

    const { data: totalHours = [], isLoading: loading, refetch: refetchTotalHours, isError: totalHoursFetchError } = useTrackedClientHours(rowData._id, startDate, endDate, tz, token);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalHours();
        }
    }, [rowData]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }
    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalHours ? changeDurationFormat(totalHours.totalClientDuration) : '00:00:00'}</span>
            )}
        </div>
    );
};

export function ClientTotalCost(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const tz = useSelector((state) => state.appSettings.appSettings.timeZone);
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);

    const financialYear = reportingFinancialOverview.financialOverviewYear || new Date().getFullYear();
    const startDate = moment(`${financialYear}-01-01T00:00:00.000Z`).tz(tz).format();
    const endDate = moment(`${financialYear}-12-31T23:59:59.999Z`).tz(tz).format();

    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByClientReport(rowData._id, token, startDate, endDate, forex, settings.currency);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0.00)
            )}
        </div>
    );
}

export function ClientRoi(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);

    const financialYear = reportingFinancialOverview.financialOverviewYear;

    const startDate = new Date(financialYear, 0, 1);
    const endDate = new Date(financialYear, 11, 31);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )
    const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError: totalBudgetFetchError, } = useTotalBudgetByClient(rowData._id, token);
    const { data: totalInvoiceAmount = [], isLoading: totalInvoiceAmountLoading, refetch: refetchTotalInvoiceAmount, isError: totalInvoiceAmountFetchError, } = useTotalInvoiceAmountByClient(rowData._id, token);
    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByClientReport(rowData._id, token, startDate, endDate, forex, settings.currency);
    const convertedBudget = rowData?.preferred_currency ?
        ConvertHelper(rowData.preferred_currency, settings.currency, totalInvoiceAmount, forex) :
        ConvertHelper("USD", settings.currency, totalInvoiceAmount, forex);


    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalInvoiceAmount();
        }
    }, [rowData]);


    let Rvalue;
    if (totalCost === 0 && convertedBudget === 0) {
        Rvalue = "0%";
    } else if (totalCost === 0 && convertedBudget !== 0) {
        Rvalue = "N/A";
    } else {
        const rev = convertedBudget - totalCost;
        Rvalue = (rev / totalCost) * 100;
    }

    return (
        <div>
            {(loading || totalInvoiceAmountLoading) ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>{typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue}</>
            )}
        </div>
    );
}