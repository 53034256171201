import React from "react";
import Moment from "react-moment";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TimeTotal from "../helpers/TimeTotal.jsx";
import zoneConverter from "../helpers/zoneConverter.jsx";

const CardHeader = ({ item }) => {

    console.log("item",item);
    // const formatDateWithLabel = (date) => {
    //     const today = new Date();
    //     const yesterday = new Date(today);
    //     yesterday.setDate(yesterday.getDate() - 1);

    //     if (date.toDateString() === today.toDateString()) {
    //         return "Today";
    //     } else if (date.toDateString() === yesterday.toDateString()) {
    //         return "Yesterday";
    //     } else {
    //         return <Moment format="ddd, MMM D" tz={global.config.getTimeZone()}>{date}</Moment>;
    //     }
    // };

    const formatDateWithLabel = (date) => {
        const timeZone = global.config.getTimeZone();
        // const today = new Date();
        // const yesterday = new Date(today);
        // yesterday.setDate(yesterday.getDate() - 1);

        // if (date.toDateString() === today.toDateString()) {
        //     return "Today";
        // } else if (date.toDateString() === yesterday.toDateString()) {
        //     return "Yesterday";
        // } else {
        //     const formattedDate = moment(date).format("ddd, MMM D");
        //     return formattedDate;
        // }

        const inputDate = moment(date).tz(timeZone, true).startOf("day");
        const today = moment().tz(timeZone).startOf("day");
        const yesterday = moment(today).subtract(1, "day");

        // Convert all dates to "YYYY-MM-DD" for safe comparison
        const todayStr = today.format("YYYY-MM-DD");
        const yesterdayStr = yesterday.format("YYYY-MM-DD");
        const inputDateStr = inputDate.format("YYYY-MM-DD");

        console.log("inputDateStr", inputDateStr,todayStr);

        if (inputDateStr === todayStr) {
            return "Today";
        } else if (inputDateStr === yesterdayStr) {
            return "Yesterday";
        } else {
            return inputDate.format("ddd, MMM D"); // e.g., "Tue, Jan 30"
        }
    };

    return (
        <Card.Header>
            <Row className="card-header-sec">
                <Col className="card-header-date">
                {/* {moment(item[0]).format("ddd,MMM D")} */}
                    {formatDateWithLabel(moment.tz(moment(item[0]).format("YYYY-MM-DD"),global.config.getTimeZone()).toDate())}
                </Col>
                <div className="separation-head"></div>
                {moment.tz(item[0], global.config.getTimeZone()).toDate() === moment.tz(Date.now(),global.config.getTimeZone()).toDate() ? (
                    <Col className="card-timer">
                        Total:{" "}
                        <Moment
                            interval={1000}
                            date={item[0]}
                            format="hh:mm:ss"
                            durationFromNow
                            tz={global.config.getTimeZone()}
                        />{" "}
                    </Col>
                ) : (
                    <Col className="card-timer">
                        <TimeTotal item={item} />
                    </Col>
                )}
            </Row>
        </Card.Header>
    );
};

export default CardHeader;