import moment from "moment";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { AES, enc } from 'crypto-js';
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DateConverter from "../../helpers/dateFormatChange.js";
import LeftArrow from "../../images/icons/blue-left-arrow.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice.js";
import { useProjectList } from "../../features/projects/ProjectList.jsx";
import { ProjectBudget, ProjectCost, Roi, TrackedProjectHours, TrackedProjectHoursByProjectId } from "../../helpers/ProjectHelpers.jsx";
import NewProjectModal from "./helpers/NewProjectModal.jsx";
import { renderClientProjectCTA } from "../../helpers/invoiceHelpers.jsx";
import { useFetchClientProjectDetails } from "../../components/Clients/ClientInvoice/useClientInvoice.jsx";
import { DatePicker } from "rsuite";
import subDays from "date-fns/subDays";
import "./Project.css";

const Project = () => {

    const { encryptedId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { forex } = useSelector((state) => state.forex);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updatedProject, setUpdatedProject] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectAllByPage, setSelectAllByPage] = useState({});
    const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
    const [userId, setUserId] = useState("");
    const targetRef = useRef(null);
    const showingDropdownRef = useRef(null);
    const { appSettings } = useSelector((state) => state.appSettings);
    const { projectAdded } = useSelector((state) => state.project);
    const statusDropdownRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(0); // Reset page to the first page
    };

    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }
    const renderProjectBillableStatus = (rowData) => {
        // Determine the class based on the payment_status value
        let statusClass;
        let statusName;
        if (rowData.is_billable === true) {
            statusClass = "active";
            statusName = "Yes";
        } else if (rowData.is_billable === false) {
            statusClass = "inactive";
            statusName = "No";
        }
        return (
            <span className={`payment-status ${statusClass}`}>{statusName}</span>
        );
    };

    const renderProjectStatus = (rowData) => {
        let statusClass;
        let statusName;
        if (rowData.is_active === true) {
            statusClass = "active";
            statusName = "Active";
        } else if (rowData.is_active === false) {
            statusClass = "inactive";
            statusName = "Inactive";
        }
        return (
            <span className={`payment-status ${statusClass}`}>{statusName}</span>
        );
    };

    const renderProjectArchiveStatus = (rowData) => {
        let statusClass;
        let statusName;
        if (rowData.is_archived === true) {
            statusClass = "active";
            statusName = "Archived";
        } else if (rowData.is_archived === false) {
            statusClass = "inactive";
            statusName = "Archived";
        }
        return (
            <span className={`payment-status ${statusClass} archived`}>{statusName}</span>
        );
    };

    // react Query for project
    const { data: project = [], isLoading, refetch: refetchProjects } = useFetchClientProjectDetails(id, token);


    useEffect(() => {
        const initializeObserver = () => {
            const resizeObserver = new ResizeObserver((entries) => {
                // Your resize observer logic here
                setTimeout(() => {
                    entries.forEach((entry) => {
                        console.log("Element resized:", entry.target);
                        // Perform non-blocking operations as necessary
                    });
                }, 0);
            });

            if (targetRef.current) {
                resizeObserver.observe(targetRef.current);
            }

            // Clean up the observer on component unmount
            return () => {
                setTimeout(() => {
                    if (targetRef.current) {
                        resizeObserver.unobserve(targetRef.current);
                    }
                }, 0);
            };
        };

        // Defer the initialization
        setTimeout(initializeObserver, 0);
    }, []);



    useEffect(() => {
        const initialize = async () => {
            // Set the updated project
            setUpdatedProject(project);

            // Check user type and set user ID
            if (user.userType !== 1 && user.userType !== 2) {
                setUserId(user._id);
            }

            // Refetch projects in a non-blocking manner
            try {
                await Promise.resolve(refetchProjects());
            } catch (error) {
                console.error("Error refetching projects:", error);
            }
        };

        // Defer initialization to ensure non-blocking
        setTimeout(initialize, 0);
    }, [dispatch, project, token, user._id, user.userType, projectAdded]);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
        if (!isClickInsideShowingDropdown) {
            setShowingDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    var filteredData = updatedProject.filter((item) => {
        const searchTerm = filter.trim().toLowerCase();
        const hasMatch = item.project_name.toLowerCase().includes(searchTerm);
        return hasMatch;
    });

    useEffect(() => {

        if (selectedDate) {
            console.log('selectedDate', selectedDate);
            const filteredData = updatedProject.filter((item) => {
                return moment.tz(item.created_at, global.config.getTimeZone()).format('YYYY-MM-DD') === moment.tz(selectedDate, global.config.getTimeZone()).format('YYYY-MM-DD');
            });

            setUpdatedProject(filteredData);


        }

        console.log('selectedStatus', selectedStatus);

        if (selectedStatus === "Active") {

            console.log('project', project);

            setUpdatedProject(project.filter((item) => item.is_active === true))

        } else if (selectedStatus === "Inactive") {
            setUpdatedProject(project.filter((item) => item.is_active === false))
        } else if (selectedStatus === "Archived") {

            setUpdatedProject(project.filter((item) => item.is_archived === true))

        } else if (selectedStatus === "All") {

            setUpdatedProject(project);
        }

        if (selectedDate && selectedStatus) {

            if (selectedStatus === "Active") {

                console.log('project', project);

                setUpdatedProject(project.filter((item) => item.is_active === true && moment.tz(item.created_at, global.config.getTimeZone()).format('YYYY-MM-DD') === moment.tz(selectedDate, global.config.getTimeZone()).format('YYYY-MM-DD')))

            } else if (selectedStatus === "Inactive") {
                setUpdatedProject(project.filter((item) => item.is_active === false && moment.tz(item.created_at, global.config.getTimeZone()).format('YYYY-MM-DD') === moment.tz(selectedDate, global.config.getTimeZone()).format('YYYY-MM-DD')))
            } else if (selectedStatus === "Archived") {

                setUpdatedProject(project.filter((item) => item.is_archived === true && moment.tz(item.created_at, global.config.getTimeZone()).format('YYYY-MM-DD') === moment.tz(selectedDate, global.config.getTimeZone()).format('YYYY-MM-DD')))

            } else if (selectedStatus === "All") {

                setUpdatedProject(project.filter((item) => moment.tz(item.created_at, global.config.getTimeZone()).format('YYYY-MM-DD') === moment.tz(selectedDate, global.config.getTimeZone()).format('YYYY-MM-DD')))
            }



        }

    }, [selectedDate, selectedStatus]);

    const totalResults = filteredData.length;


    const handleRowsPerPageChange = (e) => {
        const newRowsPerPage = parseInt(e.target.value);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0); // Set the current page to 0 when changing rows per page
    };

    const onPageChange = (e) => {
        const newPage = e.page;
        // Store the current "Select All" state for the current page
        setSelectAllByPage((prevSelectAllByPage) => ({
            ...prevSelectAllByPage,
        }));
        setCurrentPage(e.page);
    };


    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }


    const toggleStatusDropdown = () => {
        setStatusDropdownOpen(!statusDropdownOpen);
    };

    const selectStatusOption = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
        setStatusDropdownOpen(false);
    };

    const ranges = [
        {
            label: "today",
            value: new Date(), // Use a single date instead of an array
        },
        {
            label: "yesterday",
            value: subDays(new Date(), 1),
        },
    ];

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setCurrentPage(1);
    };

    const handleApplyConfirm = () => {
        setShowConfirmationModal(false);
        setShowFilterModal(false);
    };

    const toggleFilterModal = () => {
        setShowConfirmationModal(false);
        setShowFilterModal(!showFilterModal);
    };
    const handleCancel = () => {
        setShowConfirmationModal(false);
    };

    const applyFilters = () => {
        setShowFilterModal(false);
    };

    const clearFilters = () => {

        setShowFilterModal(false);
    };

    const checkAndShowConfirmationModal = () => {
        // const isTeamsModified = !isEqual(
        //   selectedTeams,
        //   userProfiles.map((team) => team._id)
        // );
        // const isUserCategoryModified = !isEqual(
        //   selectedStatus,
        //   userCategoryList.map((item) => item.value)
        // );
        // const isAccountTypeModified = !isEqual(
        //   selectedAccountType,
        //   filteredRoleId.map((type) => type._id)
        // );

        // if (isTeamsModified || isUserCategoryModified || isAccountTypeModified) {
        //   setShowConfirmationModal(true);
        // } else {
        //   setShowFilterModal(false);
        // }
    };

    return (
        <div className="outer-project-client-section">
            <div className="text-arrow-outer">
                <div className="text-arrow-inner">
                    <Link to={`/client/details/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`}>
                        <div className="text-arrow">
                            <div className="left-arrow">
                                <img src={LeftArrow} alt="left-arrow" />
                            </div>
                            <div className="top-text">
                                Back to Overview
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            <div className="user-outer-overview invoice-outer-invoice"  >
                <div className="invoice-top">
                    <div className="new-top-flter">

                        <NewProjectModal clientId={id} />

                        <div className="search-sec">
                            <div class="search-container">
                                <input
                                    type="text"
                                    onChange={handleFilterChange}
                                    value={filter}
                                    class="search-input"
                                    placeholder="Search by Project name"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="invoice-filter">
                        <div className="invoice-filter-inner-section no-flter-all">
                            <div className="filter-inner invoice-type">
                                <label for="invoice-type">Project Status</label>
                                <div className="custom-dropdown1" ref={statusDropdownRef}>
                                    <div className="drop-down-inner">
                                        <div
                                            className="selected-options1"
                                            onClick={toggleStatusDropdown}
                                        >
                                            {selectedStatus ? selectedStatus : "All"}
                                        </div>
                                        {statusDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <ul className="type-list">
                                                    <li onClick={() => selectStatusOption('All')}>All</li>
                                                    <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                                                    <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                                                    <li onClick={() => selectStatusOption('Archived')}>Archived</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="filter-inner due-date">
                                <label htmlFor="dueDate" className="calendar-icon">
                                    Date Created
                                </label>
                                <div className="date-picker-outer date-picker-outer-all">
                                    <div className="custom-picker-icon custom-picker-icon-all">
                                        <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 14 14"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            focusable="false"
                                            class="rs-picker-toggle-caret rs-icon"
                                            aria-label="calendar"
                                            data-category="legacy"
                                        >
                                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                        </svg>
                                    </div>
                                    <div className="custoom-icon-calender custoom-icon-calender-all">
                                        <DatePicker
                                            oneTap // Use DatePicker instead of DateRangePicker
                                            format="dd/MM/yyyy"
                                            ranges={ranges}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            placement="auto"
                                            className="invoice-filter-top-date"
                                            isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                                            timeZone={global.config.getTimeZone()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-invoice-right apply-fliter-right">
                            <button onClick={toggleFilterModal}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="17"
                                    viewBox="0 0 13 17"
                                    fill="none"
                                >
                                    <path
                                        d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                                        fill="#6479F8"
                                    />
                                    <path
                                        d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                                        fill="#6479F8"
                                    />
                                    <path
                                        d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                                        fill="#6479F8"
                                    />
                                </svg>
                                All Filters
                            </button>
                        </div>


                        <Modal
                            isOpen={showFilterModal}
                            onRequestClose={checkAndShowConfirmationModal}
                            contentLabel="Filter Modal"
                            className="new-filter-modal"
                            style={{
                                content: {
                                    height: "50%",
                                    width: "50%",
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-25%, -25%)",
                                    borderRadius: "10px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    // overflow: "auto",
                                },
                                overlay: {
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                },
                            }}
                        >
                            <div className="new-nodal-invoice-outer-sec">

                                <div className="new-modal-top-sec">
                                    <div className="new-modal-heading">
                                        All filters
                                    </div>
                                    <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M1 13L13 1" stroke="black" />
                                            <path d="M1 0.999999L13 13" stroke="black" />
                                        </svg>{" "}
                                    </a>
                                </div>
                                <div className="invoice-filter-inner-section">

                                    <div className="filter-inner invoice-type">
                                        <label for="invoice-type">Project Status</label>
                                        <div className="custom-dropdown1" ref={statusDropdownRef}>
                                            <div className="drop-down-inner">
                                                <div
                                                    className="selected-options1"
                                                    onClick={toggleStatusDropdown}
                                                >
                                                    {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'ALL') : null)}
                                                </div>
                                                {statusDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <ul className="type-list">
                                                            <li onClick={() => selectStatusOption(null)}>All</li>
                                                            <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                                                            <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                                                            <li onClick={() => selectStatusOption('Archived')}>Archived</li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="filter-inner due-date">
                                        <label htmlFor="dueDate" className="calendar-icon">
                                            Date Created
                                        </label>
                                        <div className="date-picker-outer date-picker-outer-all">
                                            <div className="custom-picker-icon custom-picker-icon-all">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 14 14"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    class="rs-picker-toggle-caret rs-icon"
                                                    aria-label="calendar"
                                                    data-category="legacy"
                                                >
                                                    <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                </svg>
                                            </div>
                                            <div className="custoom-icon-calender custoom-icon-calender-all">
                                                <DatePicker
                                                    oneTap // Use DatePicker instead of DateRangePicker
                                                    format="dd/MM/yyyy"
                                                    ranges={ranges}
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    placement="auto"
                                                    className="invoice-filter-top-date"
                                                    isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                                                    timeZone={global.config.getTimeZone()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Modal
                                        className="delete-modal"
                                        isOpen={showConfirmationModal}
                                        onRequestClose={handleCancel}
                                        contentLabel="Confirm Delete"
                                        style={{
                                            content: {
                                                height: "41%",
                                                width: "30%",
                                                position: "fixed",
                                                top: "36%",
                                                left: "50%",
                                                transform: "translate(-24.75%, 0%)",
                                            },
                                        }}
                                    >
                                        <div className="delete-modal-content">
                                            <h2>Apply or Discard changes?</h2>
                                            <p>
                                                Do you want to Apply or Discard filter changes?
                                            </p>

                                            <div className="delete-modal-buttons">
                                                <button className="delete" onClick={handleApplyConfirm} >
                                                    Apply Changes
                                                </button>
                                                <button onClick={clearFilters}>
                                                    Discard Changes
                                                </button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className="filter-buttons">
                                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                                <button className="apply-filter" onClick={applyFilters}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                        <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                                        <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                                        <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                                    </svg>
                                    Apply Filters
                                </button>
                            </div>
                        </Modal>



                    </div>
                    <div className="filter-invoice-right" ref={targetRef} >


                        <div className="filter-inner showing-result">
                            <label>Showing</label>

                            <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                                    {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                                </div>
                                {showingDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                        <ul className="showing-options-list">
                                            {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 10 } }); setShowingDropdownOpen(false); }}>10</li>}
                                            {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 20 } }); setShowingDropdownOpen(false); }}>20</li>}
                                            {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 30 } }); setShowingDropdownOpen(false); }}>30</li>}
                                            {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 40 } }); setShowingDropdownOpen(false); }}>40</li>}
                                            {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>ALL</li>}
                                            {/* Add more conditions as needed */}
                                            {totalResults < 10 ? (
                                                <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>{totalResults}</li>
                                            ) : null}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <label>of {totalResults} Results</label>
                        </div>

                    </div>

                </div>

                <div className="datatable-team-maintb">


                    {isLoading ? (

                        <div className="outter-load-table">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>

                    ) : (
                        <DataTable
                            value={filteredData}
                            removableSort
                            tableStyle={{
                                minWidth: "50rem",
                                width: "100%",
                                textAlign: "left",
                            }}
                            paginator={filteredData.length > 10}
                            paginatorClassName="recent-invoices-pagination"
                            rows={rowsPerPage} // Set the number of rows per page
                            first={currentPage * rowsPerPage} // Set the current page using currentPage
                            onPage={onPageChange} // Update currentPage when changing pages
                        >
                            <Column
                                field="project_name"
                                header="Project"
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="created_at"
                                body={(rowData) => (
                                    <DateConverter
                                        date={rowData.created_at}
                                    />
                                )}
                                header="Date Created"
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="tracked_hours"
                                header="Tracked Hours"
                                className=""
                                body={TrackedProjectHoursByProjectId}
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="budget"
                                header="Budget"
                                // body={(rowData) => {
                                //     const { client_name, budget } = rowData;
                                //     if (client_name && client_name.preferred_currency) {
                                //         return new Intl.NumberFormat("en-US", {
                                //             style: "currency",
                                //             currency: settings.currency,
                                //         }).format(ConvertHelper(client_name.preferred_currency, settings.currency, budget, forex));
                                //     } else {
                                //         return new Intl.NumberFormat("en-US", {
                                //             style: "currency",
                                //             currency: settings.currency,
                                //         }).format(ConvertHelper("USD", settings.currency, budget, forex));
                                //     }
                                // }}
                                body={ProjectBudget}
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="cost"
                                header="Cost"
                                body={ProjectCost}
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="roi"
                                header="ROI"
                                body={Roi}
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="is_billable"
                                header="Billable"
                                body={(rowData) => renderProjectBillableStatus(rowData)}
                                className="amount-bold"
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            {/* <Column
                                field="is_archived"
                                header="Archived"
                                body={(rowData) => renderProjectArchiveStatus(rowData)}
                                className="amount-bold"
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column> */}
                            <Column
                                field="is_active"
                                header="Status"
                                body={(rowData) => rowData.is_archived ? renderProjectArchiveStatus(rowData) : renderProjectStatus(rowData)}
                                className="amount-bold"
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="_id"
                                body={renderClientProjectCTA}
                                className=""
                                style={{
                                    paddingRight: "45px",
                                    minWidth: "121.73px",
                                }}
                            ></Column>
                        </DataTable>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Project;