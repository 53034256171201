import axios from "axios";
import * as XLSX from "xlsx";
import Modal from "react-modal";
import css from "./VendorsInvoices.css";
import { DatePicker } from "rsuite";
import moment from "moment-timezone";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import isEqual from "lodash/isEqual";
import invStatus from "../../../constants/invStatus";
import VendorsInvoicesModal from "./elements/vendorsInvoicesModal";
import { useSelector, useDispatch } from "react-redux";
import { renderVendorsInvoicesCTA } from "../../../helpers/invoiceHelpers";
import invPaymStatus from "../../../constants/invPaymStatus";
import React, { useState, useRef, useEffect } from "react";
import VendorWisePaymentStatus from "../VendorInvoice/elements/vendorPaymentStatusWise";
import invStatusConsts from "../../../constants/invStatusConsts";
import InvoiceImage from "../../../images/icons/invoice-icon-1.svg";
import DownloadImage from "../../../images/icons/download-icon.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import payStatusConsts from "../../../constants/invPaymetStatusConsts";
import { createDateVenInvConvert, dateVenInvConvert } from "../../../helpers/dateHelper";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { setSideBarText } from '../../../features/sideBarText/sideBarTextSlice.js';
import { useGetTeams } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset, setAppLoading } from "../../../features/appSettings/appSettingsSlice.js";
import userTypeConsts from "../../../constants/userTypeConsts.js";
import { useGetVendors } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import { useFetchVendorsInvoices } from "../VendorInvoice/useVendorInvoice.jsx";
import MemberName from "../../Dashboard/elements/memberName.jsx";
import venInvPaymStatus from "../../../constants/venInvPaymStatus.js";
import venInvStatusConsts from "../../../constants/venInvStatusConsts.js";
import venInvoiceStatus from "../../../constants/venInvoiceStatus.js";
import userCategory from "../../../constants/userCategory.js";
import VendorPaymentStatus from "./elements/vendorPaymentStatus.jsx";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);
  const vendorName =
    (rowData.ven_inv_for.vendor_name || rowData.ven_inv_for.vendor_legalentity_name);
  const displayName = showFullname
    ? vendorName
    : vendorName.length <= 15
      ? vendorName
      : vendorName.slice(0, 15) + "...";

  return (
    <div
      className={`member-name-vendor member-name${showFullname ? " active" : ""}`}
      onMouseEnter={() => {
        if (vendorName.length > 15) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      <span>{displayName}</span>
    </div>
  );
};


const ranges = [
  {
    label: "today",
    value: new Date(), // Use a single date instead of an array
  },
  {
    label: "yesterday",
    value: subDays(new Date(), 1),
  },
];

const VendorsInvoices = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [vendorsFilter, setVendorsFilter] = useState("All");
  const [selectAllVendor, setSelectAllVendor] = useState(true);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [isVendorDropdownOpen, setIsVendorDropdownOpen] = useState(false);
  const [vendorDropdownSearchTerm, setVendorDropdownSearchTerm] = useState('');
  const [isVendorModalDropdownOpen, setIsVendorModalDropdownOpen] = useState(false);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  // const [vendorFilter, setVendorFilter] = useState("");
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [invStatusList, setInvStatusList] = useState([]);
  const [invPaymStatusList, setInvPaymStatusList] = useState([]);
  const [invoiceTypeModalIsOpen, setInvoiceTypeModalIsOpen] = useState(false);
  const [isAutomaticInv, setIsAutomaticInv] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isStatusModalDropdownOpen, setIsStatusModalDropdownOpen] = useState(false);
  const [selectAllPaymentStatus, setSelectAllPaymentStatus] = useState(true);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [isPaymentStatusDropdownOpen, setIsPaymentStatusDropdownOpen] = useState(false);
  const [isPaymentStatusModalDropdownOpen, setIsPaymentStatusModalDropdownOpen] = useState(false);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const teamDropdownRef = useRef(null);
  const paymentStatusDropdownRef = useRef(null);
  const paymentStatusModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const vendorDropdownRef = useRef(null);
  const vendorModalDropdownRef = useRef(null);
  var filteredData = [];
  const [sec3, setSec3] = useState(100);
  const [selectAllByPage, setSelectAllByPage] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectAllTeam, setSelectAllTeam] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPage(0);
  };
  // const [products, setProducts] = useState([]);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const {
    data: teams = [],
    isLoading: teamLoading,
    isError: teamError,
  } = useGetTeams(token);
  const { data: vendorInvoiceList = [], isLoading, refetch: refetchVendorInvoiceList, isError } = useFetchVendorsInvoices(token);
  const { data: vendors = [], isLoading: vendorLoading, isError: vendorError } = useGetVendors(token);

  useEffect(() => {
    refetchVendorInvoiceList();
    setInvStatusList(venInvoiceStatus.ven_inv_status);
    setInvPaymStatusList(venInvPaymStatus.ven_inv_paym_status);
    if (user && (user?.userCategory === userCategory.Vendor)) {
      dispatch(setHeaderText("Invoices"));
    } else {
      dispatch(setHeaderText("Vendor Invoices"));
    }
    dispatch(setSideBarText(""));
  }, [dispatch, refetchVendorInvoiceList]);

  useEffect(() => {
    const handleLoading = async () => {
      await dispatch(setAppLoading(isLoading));
    };
    handleLoading();
  }, [isLoading]);

  // filteredData = vendorInvoiceList?.filter((item) => {
  //   const vendorName = (item.ven_inv_for.vendor_name || item.ven_inv_for.vendor_legalentity_name);
  //   const currentDate = new Date().toISOString().slice(0, 10);

  //   if (
  //     paymentStatusFilter.includes(payStatusConsts.Pending) &&
  //     (item.ven_inv_payment_status !== 0 ||
  //       item.ven_inv_due_date <= currentDate ||
  //       item.ven_inv_status === invStatusConsts.Rejected)
  //   ) {
  //     return false;
  //   } else if (
  //     paymentStatusFilter.includes(payStatusConsts.Overdue) &&
  //     (item.ven_inv_payment_status !== 0 ||
  //       item.ven_inv_due_date > currentDate ||
  //       item.ven_inv_status === invStatusConsts.Rejected)
  //   ) {
  //     return false;
  //   } else if (
  //     paymentStatusFilter.includes(payStatusConsts.Rejected) &&
  //     (item.ven_inv_payment_status !== 0 ||
  //       item.ven_inv_status !== invStatusConsts.Rejected)
  //   ) {
  //     return false;
  //   } else if (
  //     paymentStatusFilter !== "All" &&
  //     !paymentStatusFilter.includes(item.ven_inv_payment_status)
  //   ) {
  //     return false;
  //   }
  //   if (statusFilter !== "All" && !statusFilter.includes(item.ven_inv_status)) {
  //     return false;
  //   }

  //   if (
  //     selectedDate &&
  //     new Date(item.ven_inv_due_date).toISOString().slice(0, 10) !==
  //     selectedDate.toISOString().slice(0, 10)
  //   ) {
  //     return false;
  //   }
  //   if (
  //     invoiceNumberFilter.trim() !== "" &&
  //     !item.ven_inv_number
  //       .toLowerCase()
  //       .includes(invoiceNumberFilter.trim().toLowerCase())
  //   ) {
  //     return false;
  //   }
  //   if (vendorsFilter !== "All" && !vendorsFilter.includes(item.ven_inv_for._id)) {
  //     return false;
  //   }
  //   return true;
  // });

  const filter = {
    paymentStat: paymentStatusFilter,
    invoiceStat: statusFilter,
    seldate: selectedDate,
    invoiceNumber: invoiceNumberFilter,
    vendor_name: vendorsFilter
  };

  filteredData = vendorInvoiceList && vendorInvoiceList.filter(item => {
    // If all filter criteria are set to non-filtering values, include the item
    if (filter['invoiceNumber'] === "" && filter['invoiceStat'] === "All" && filter['paymentStat'] === "All" && filter['seldate'] === null && filter['vendor_name'] === "All") {
      return true;
    } else {
      let ven_inv_payment_status = true;
      let ven_inv_status = true;
      let user = true;
      let ven_inv_number = true;
      let ven_inv_due_date = true;
      let vendor_name = true;

      // Check payment status
      if (filter['paymentStat'] !== "All" && !paymentStatusFilter.includes(item['ven_inv_payment_status'])) {

        ven_inv_payment_status = false;
        if (paymentStatusFilter.includes(0)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (item.ven_inv_status === 2 && item['ven_inv_payment_status'] === 0) {
            ven_inv_payment_status = false;
          }

        }

        if (paymentStatusFilter.includes(payStatusConsts.Rejected)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (item['ven_inv_status'] === invStatusConsts.Rejected && moment(item.ven_inv_due_date).isSameOrBefore(currentDate, "day")) {
            ven_inv_payment_status = true;
          }
        }

        if (paymentStatusFilter.includes(payStatusConsts.Overdue)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (moment(item.ven_inv_due_date).isSameOrBefore(currentDate, "day") && item['ven_inv_status'] !== invStatusConsts.Rejected && item['ven_inv_payment_status'] !== payStatusConsts.Manually_Processed && item['ven_inv_payment_status'] !== payStatusConsts.Payment_Processing && item['ven_inv_payment_status'] !== payStatusConsts.Cancelled) {
            ven_inv_payment_status = true;
          }
        }
      }

      // Check invoice status
      if (filter['invoiceStat'] !== "All" && !statusFilter.includes(item['ven_inv_status'])) {
        ven_inv_status = false;
        if (statusFilter.includes(venInvStatusConsts.Overdue)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (moment(item.ven_inv_due_date).isSameOrBefore(currentDate, "day") && item['ven_inv_status'] !== venInvStatusConsts.Invoice_Send && item['ven_inv_status'] !== venInvStatusConsts.Rejected && item['ven_inv_status'] !== venInvStatusConsts.Cancelled && item['ven_inv_status'] !== venInvStatusConsts.Payment_Processed) {
            ven_inv_status = true;
          }
        }
        if (statusFilter.includes(venInvStatusConsts.Pending)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (moment(item.ven_inv_due_date).isAfter(currentDate, "day") && item['ven_inv_status'] !== venInvStatusConsts.Invoice_Send && item['ven_inv_status'] !== venInvStatusConsts.Rejected && item['ven_inv_status'] !== venInvStatusConsts.Cancelled && item['ven_inv_status'] !== venInvStatusConsts.Payment_Processed) {
            ven_inv_status = true;
          }
        }
      }

      if (
        invoiceNumberFilter.trim() !== "" &&
        !item.ven_inv_number
          .toLowerCase()
          .includes(invoiceNumberFilter.trim().toLowerCase())
      ) {
        ven_inv_number = false;
      }

      if (
        selectedDate &&
        (new Date(item.ven_inv_due_date)?.getFullYear() !== selectedDate.getFullYear() ||
          new Date(item.ven_inv_due_date)?.getMonth() !== selectedDate.getMonth() ||
          new Date(item.ven_inv_due_date)?.getDate() !== selectedDate.getDate())
      ) {
        ven_inv_due_date = false;
      }

      // if (vendorFilter.trim() !== "" &&
      //   !vendorName
      //     .toLowerCase()
      //     .includes(vendorFilter.trim().toLowerCase())) {
      //   vendor_name = false;
      // }

      if (vendorsFilter !== "All" && !vendorsFilter.includes(item.ven_inv_for._id)) {
        return false;
      }


      if (ven_inv_payment_status === false || ven_inv_status === false || user === false || ven_inv_number === false || ven_inv_due_date === false || vendor_name === false) {
        return false;
      } else {
        return true;
      }

    }

  });

  filteredData = filteredData.filter((item) => {
    if (paymentStatusFilter.includes(0)) {
      if (item.ven_inv_status === 2) {
        return false;
      }
    }
    return true;
  });

  //   // if (vendorFilter.trim() !== "" &&
  //   //   !vendorName
  //   //     .toLowerCase()
  //   //     .includes(vendorFilter.trim().toLowerCase())) {
  //   //   return false;
  //   // }
  //   // if (invTypeFilter !== "All" && !invTypeFilter.includes(item.inv_type)) {
  //   //   return false;
  //   // }
  //   return true;
  // });

  const totalResults = filteredData.length;
  const renderAmount = (rowData) => {
    var totalAmount = rowData.ven_inv_total_amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.ven_inv_currency,
    });

    return <span> {totalAmount}</span>;
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(teams.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  // useEffect(() => {
  //   setSelectAllTeam(selectedTeams.length === teams.length);
  //   if (selectedTeams.length === teams.length) {
  //     setVendorFilter("All");
  //   } else {
  //     setVendorFilter(selectedTeams);
  //   }
  // }, [selectedTeams, teams]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const handleCheckboxVendorChange = (vendorId) => {
    const updatedSelection = [...selectedVendors];
    const index = updatedSelection.indexOf(vendorId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(vendorId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedVendors(updatedSelection);
  };

  const handleSelectAllVendorChange = () => {
    if (!selectAllVendor) {
      const allVendorIds = vendors.map(vendor => vendor._id);
      setSelectedVendors(allVendorIds);
    } else {
      setSelectedVendors([]);
    }
    setCurrentPage(0);
    setSelectAllVendor(!selectAllVendor);
  };

  const filteredDropdownVendors = vendors.filter((vendors) => {
    const vendorName = vendors.vendor_name || vendors.vendor_legalentity_name;
    return vendorName.toLowerCase().includes(vendorDropdownSearchTerm.toLowerCase());
  });

  const handleVendorDropdownSearchChange = (e) => {
    setVendorDropdownSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSelectAllVendor(selectedVendors.length === vendors.length);
    if (selectedVendors.length === vendors.length) {
      setVendorsFilter("All");
    } else {
      setVendorsFilter(selectedVendors);
    }
  }, [selectedVendors, vendors]);

  const toggleVendorDropdown = () => {
    setIsVendorDropdownOpen(!isVendorDropdownOpen);
    setVendorDropdownSearchTerm('');
  };

  const toggleVendorModalDropdown = () => {
    setIsVendorModalDropdownOpen(!isVendorModalDropdownOpen);
    setVendorDropdownSearchTerm('');
  };

  const handleCheckboxStatusChange = (statusId) => {
    const updatedSelection = [...selectedStatus];
    const index = updatedSelection.indexOf(statusId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(statusId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedStatus(updatedSelection);
  };

  const handleSelectAllStatusChange = () => {
    if (!selectAllStatus) {
      setSelectedStatus(invStatusList.map((status) => status._id));
    } else {
      setSelectedStatus([]);
    }
    setSelectAllStatus(!selectAllStatus);
  };

  useEffect(() => {
    setSelectAllStatus(selectedStatus.length === invStatusList.length);
    if (selectedStatus.length === invStatusList.length) {
      setStatusFilter("All");
    } else {
      setStatusFilter(selectedStatus);
    }
  }, [selectedStatus, invStatusList]);

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setIsStatusModalDropdownOpen(!isStatusModalDropdownOpen);
  };

  const handleCheckboxPaymentStatusChange = (paymentStatusId) => {
    const updatedSelection = [...selectedPaymentStatus];
    const index = updatedSelection.indexOf(paymentStatusId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(paymentStatusId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedPaymentStatus(updatedSelection);
  };

  const handleSelectAllPaymentStatusChange = () => {
    if (!selectAllPaymentStatus) {
      setSelectedPaymentStatus(
        invPaymStatusList.map((paymentstatus) => paymentstatus._id)
      );
    } else {
      setSelectedPaymentStatus([]);
    }
    setSelectAllPaymentStatus(!selectAllPaymentStatus);
  };

  useEffect(() => {
    setSelectAllPaymentStatus(
      selectedPaymentStatus.length === invPaymStatusList.length
    );
    if (selectedPaymentStatus.length === invPaymStatusList.length) {
      setPaymentStatusFilter("All");
    } else {
      setPaymentStatusFilter(selectedPaymentStatus);
    }
  }, [selectedPaymentStatus, invPaymStatusList]);

  const togglePaymentStatusDropdown = () => {
    setIsPaymentStatusDropdownOpen(!isPaymentStatusDropdownOpen);
  };

  const togglePaymentStatusModalDropdown = () => {
    setIsPaymentStatusModalDropdownOpen(!isPaymentStatusModalDropdownOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTeamDropdown = teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideVendorDropdown = vendorDropdownRef.current && vendorDropdownRef.current.contains(event.target);
    const isClickInsideVendorModalDropdown = vendorModalDropdownRef.current && vendorModalDropdownRef.current.contains(event.target);
    const isClickInsidePaymentStatusDropdown = paymentStatusDropdownRef.current && paymentStatusDropdownRef.current.contains(event.target);
    const isClickInsidePaymentStatusModalDropdown = paymentStatusModalDropdownRef.current && paymentStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown = statusDropdownRef.current && statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown = statusModalDropdownRef.current && statusModalDropdownRef.current.contains(event.target);
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);

    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideVendorDropdown) {
      setIsVendorDropdownOpen(false);
    }
    if (!isClickInsideVendorModalDropdown) {
      setIsVendorModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setIsStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setIsStatusModalDropdownOpen(false);
    }
    if (!isClickInsidePaymentStatusDropdown) {
      setIsPaymentStatusDropdownOpen(false);
    }
    if (!isClickInsidePaymentStatusModalDropdown) {
      setIsPaymentStatusModalDropdownOpen(false);
    }
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setInvoiceNumberFilter("");
    // setVendorFilter("");
    setSelectedTeams(teams.map((team) => team._id));
    setSelectedVendors(vendors.map((vendor) => vendor._id));
    setSelectedStatus(invStatusList.map((status) => status._id));
    setSelectedPaymentStatus(
      invPaymStatusList.map((paymentstatus) => paymentstatus._id)
    );
    setSelectedDate(null);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    // const isCliNameModified = !isEqual(vendorFilter, "");
    const isVendorsModified = !isEqual(selectedVendors, vendors.map((vendor) => vendor._id));
    const isInvNumModified = !isEqual(invoiceNumberFilter, "");
    const isStatusModified = !isEqual(selectedStatus, invStatusList.map((status) => status._id));
    const isPaymentStatusModified = !isEqual(selectedPaymentStatus, invPaymStatusList.map((paymentstatus) => paymentstatus._id));
    const isDueDateModified = !isEqual(selectedDate, null);

    if (
      isVendorsModified ||
      isInvNumModified ||
      isStatusModified ||
      isPaymentStatusModified ||
      isDueDateModified
    ) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  useEffect(() => {
    setSelectedTeams(teams.map((team) => team._id));
    setSelectedVendors(vendors.map((vendor) => vendor._id));
    setSelectedStatus(invStatusList.map((status) => status._id));
    setSelectedPaymentStatus(
      invPaymStatusList.map((paymentstatus) => paymentstatus._id)
    );
    // setSelectedInvTypes(["Regular", "Reimbursement"]);
  }, [teams, invPaymStatusList, invStatusList, vendors]);


  const renderStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.ven_inv_status === venInvStatusConsts.Approved) {
      statusClass = "manual";
      statusName = "Approved";
    } else if (rowData.ven_inv_status === venInvStatusConsts.Not_Reviewed) {
      // const startDate = moment().tz(global.config.getTimeZone());
      // const endDate = moment(rowData.ven_inv_due_date).tz(global.config.getTimeZone());
      // const diffInDays = endDate.diff(startDate, "days");
      // if (diffInDays < 0) {
      //   statusClass = "overdue";
      //   statusName = "Overdue";
      // } else {
      statusClass = "pending";
      statusName = "Not Reviewed";
      // }
    } else if (rowData.ven_inv_status === venInvStatusConsts.Rejected) {
      statusClass = "overdue";
      statusName = "Rejected";
    } else if (rowData.ven_inv_payment_status === venInvStatusConsts.Cancelled) {
      statusClass = "overdue";
      statusName = "Cancelled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  const handleAutomaticModalOpen = async () => {
    setInvoiceTypeModalIsOpen(true);
  };

  const handleModalCancel = () => {
    setInvoiceTypeModalIsOpen(false);
  };

  const exportToExcel = async () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const paymentDetailsPromises = filteredData.map(async (item) => {
        if (item.ven_inv_number && (item.ven_inv_payment_status === 2 || item.ven_inv_payment_status === 3)) {
          try {
            const response = await fetch('/api/vendor/invoices/paymentDetails', {
              method: 'POST',
              body: JSON.stringify({ _id: item._id }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
            console.log("response", response);
            if (response.ok) {
              const data = await response.json();
              return data;
            } else {
              return null;
            }
          } catch (error) {
            // Non-blocking logging
            Promise.resolve().then(() => {
              global.config.slackMessage(error.toString());
              console.error('Error fetching payment details:', error);
            });
            return null;
          }
        } else {
          return null;
        }
      });

      const paymentDetails = await Promise.all(paymentDetailsPromises);

      const modifiedLog = [];
      filteredData.forEach((item, index) => {
        // Assuming these items in an array
        const descriptions = item.ven_inv_description;
        const costs = item.ven_inv_cost;
        const taxs = item.ven_inv_tax;
        const paymentData = paymentDetails[index];
        const paymentDate = paymentData ? paymentData.created_at : null;

        let paymentStatusName;
        if (!paymentDate) {
          paymentStatusName = 'Not updated';
        } else {
          paymentStatusName = moment(paymentDate).tz(global.config.getTimeZone()).format("DD/MM/YYYY, hh:mm:ss A");
        }

        const renderStatus = () => {
          let statusName;
          if (item.ven_inv_status === venInvStatusConsts.Approved) {
            statusName = "Approved";
          } else if (item.ven_inv_status === venInvStatusConsts.Not_Reviewed) {
            const startDate = moment().tz(global.config.getTimeZone());
            const endDate = moment(item.ven_inv_due_date).tz(global.config.getTimeZone());
            const diffInDays = endDate.diff(startDate, "days");
            if (diffInDays < 0) {
              statusName = "Overdue";
            } else {
              statusName = "Not Reviewed";
            }
          } else if (item.ven_inv_status === venInvStatusConsts.Rejected) {
            statusName = "Rejected";
          } else if (item.ven_inv_payment_status === venInvStatusConsts.Cancelled) {
            statusName = "Cancelled";
          }
          return statusName;
        };

        if (descriptions && descriptions.length > 0) {
          descriptions.forEach((description, i) => {
            const cost = parseFloat(costs[i]);
            const tax = parseFloat(taxs[i]);
            const vat = (cost * (tax / 100)).toFixed(2);
            const amount = (cost + parseFloat(vat)).toFixed(2);
            const renderAmount = () => {
              const totalAmount = amount.toLocaleString("en-US", {
                style: "currency",
                currency: item.ven_inv_currency,
              });
              return totalAmount;
            };
            modifiedLog.push({
              "Invoice Number": item.ven_inv_number,
              "User Name": item.ven_inv_for
                && item.ven_inv_for.vendor_name
                && item.ven_inv_for.vendor_name || item.ven_inv_for.vendor_legalentity_name || '',
              "Invoice Issue Date": moment(item.ven_inv_issue_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              "Invoice Due Date": moment(item.ven_inv_due_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              Period:
                moment(item.ven_inv_start_date)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY") +
                " - " +
                moment(item.ven_inv_end_date)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY"),
              Item: i + 1,
              Description: description,
              Cost: costs[i],
              "VAT(%)": taxs[i],
              VAT: (costs[i] * (taxs[i] / 100)).toFixed(2),
              Amount: renderAmount(),
              "Invoice Currency": item.ven_inv_currency,
              "Invoice Payment Date & Time": paymentStatusName,
              "Status": renderStatus(),
              "Created At": moment(item.ven_inv_created_at)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY, hh:mm:ss A"),
            });
          });
        } else {
          modifiedLog.push({
            "Invoice Number": item.ven_inv_number,
            "Invoice Issue Date": moment(item.ven_inv_issue_date)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            "Invoice Due Date": moment(item.ven_inv_due_date)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            Period:
              moment(item.ven_inv_start_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY") +
              " - " +
              moment(item.ven_inv_end_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
            User: item.ven_inv_for
              && item.ven_inv_for.vendor_name
              && item.ven_inv_for.vendor_name || item.ven_inv_for.vendor_legalentity_name || '',
            Item: 1,
            Description: "", // Set empty description for single row
            Cost: "",
            "VAT(%)": "",
            Amount: "",
            "Invoice Currency": item.ven_inv_currency,
            "Invoice Payment Date and Time": paymentStatusName,

            "Created At": moment(item.ven_inv_created_at)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY, hh:mm:ss A"),
          });
        }
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 80 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 125 },
        { wpx: 125 },
        { wpx: 35 },
        { wpx: 200 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 175 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 150 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Invoices List exported to Excel"
      );
      const fileName = `Invoice_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting INVOICES to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); // Set the current page to 0 when changing rows per page
  };

  const onPageChange = (e) => {
    const newPage = e.page;
    // Store the current "Select All" state for the current page
    setSelectAllByPage((prevSelectAllByPage) => ({
      ...prevSelectAllByPage,
    }));
    setCurrentPage(e.page);
  };

  return (
    <div className="outer-invoice-clinet-section">
      <div className="invoice-outer-invoice">
        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <a onClick={handleAutomaticModalOpen}>
                <span className="icon-invoice">
                  <img src={InvoiceImage} alt="Create Invoice" />
                </span>
                Create Invoice
              </a>
              {/* invoice type modal */}
              <Modal
                isOpen={invoiceTypeModalIsOpen}
                onRequestClose={handleModalCancel}
                contentLabel="Invoice Type"
                style={{
                  content: {
                    height: "50%",
                    width: "50%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-17.5%, -46%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="outter-modal-main">
                    <a
                      className="close-image-clr1"
                      onClick={() => {
                        setInvoiceTypeModalIsOpen(false);
                      }}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <VendorsInvoicesModal isAutomaticInv={isAutomaticInv} />
                </div>
              </Modal>
              {/* invoice type madal end */}
              <a onClick={exportToExcel}>
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a>
            </div>
            <div className="filter-invoice-right">
              <div className="filter-inner showing-result">
                <label>Showing</label>
                <div
                  ref={showingDropdownRef}
                  className="select-modal-mainM custom-dropdown-user-new-outer"
                >
                  <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                  >
                    {totalResults < 10
                      ? totalResults.toString()
                      : rowsPerPage.toString()}
                  </div>
                  {showingDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="showing-options-list">
                        {totalResults >= 10 && (
                          <li
                            className={rowsPerPage === 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 10 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            10
                          </li>
                        )}
                        {totalResults >= 20 && (
                          <li
                            className={rowsPerPage === 20 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 20 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            20
                          </li>
                        )}
                        {totalResults >= 30 && (
                          <li
                            className={rowsPerPage === 30 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 30 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            30
                          </li>
                        )}
                        {totalResults >= 40 && (
                          <li
                            className={rowsPerPage === 40 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 40 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            40
                          </li>
                        )}
                        {totalResults > 10 && (
                          <li
                            className={
                              totalResults > 10 && rowsPerPage === totalResults
                                ? "selected-item"
                                : ""
                            }
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            ALL
                          </li>
                        )}
                        {totalResults < 10 ? (
                          <li
                            className={totalResults < 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            {totalResults}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                </div>
                <label>of {totalResults} Results</label>
              </div>
            </div>
          </div>

          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all">
              <div className="filter-inner invoice-id">
                <label for="fname">Invoice ID</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setInvoiceNumberFilter(e.target.value);
                    setCurrentPage(0);
                  }}
                  value={invoiceNumberFilter}
                  id="invoiceId"
                  name="invoiceId"
                  placeholder="All"
                />
              </div>
              {(user && (user.userCategory !== userCategory.Vendor)) && (
                <div className="filter-inner users">
                  <label for="fname">Vendor</label>
                  {/* <input
                  type="text"
                  onChange={(e) => {
                    setVendorFilter(e.target.value);
                    setCurrentPage(0);
                  }}
                  value={vendorFilter}
                  id="vendorFilter"
                  name="vendorFilter"
                  placeholder="All"
                /> */}
                  <div className="custom-dropdown1" ref={vendorDropdownRef}>
                    <div className="drop-down-inner">
                      <div className="selected-options1" onClick={toggleVendorDropdown}>
                        {selectedVendors.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div className="selected-option1" key={selectedVendors[0]}>
                              {selectedVendors.length === vendors.length
                                ? 'All'
                                : (
                                  <>
                                    <TruncateFilterFieldView textContent={vendors.find((item) => item._id === selectedVendors[0])?.vendor_name} />
                                    {selectedVendors.length > 1 && selectedVendors.length !== vendors.length && (
                                      <div className="selected-option-count">
                                        {selectedVendors.length - 1}+
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                      {isVendorDropdownOpen && (
                        <div className="dropdown-list1">
                          <input
                            type="text"
                            placeholder="Search vendors"
                            value={vendorDropdownSearchTerm}
                            onChange={handleVendorDropdownSearchChange}
                            className="search-bar"
                          />
                          {filteredDropdownVendors.length > 0 && (
                            <>
                              {vendorDropdownSearchTerm === "" && (
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllVendor}
                                    onChange={handleSelectAllVendorChange}
                                  />
                                  All
                                </label>
                              )}
                              {filteredDropdownVendors
                                .slice()
                                .sort((a, b) =>
                                  a.vendor_name.toLowerCase().localeCompare(b.vendor_name.toLowerCase())
                                )
                                .map((vendor) => (
                                  <label className="checkbox-label1" key={vendor._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedVendors.includes(vendor._id)}
                                      onChange={() => handleCheckboxVendorChange(vendor._id)}
                                    />
                                    {vendor.vendor_name && `${vendor.vendor_name}`}
                                  </label>
                                ))}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="filter-inner invoice-status">
                <label for="invoiceStatus">Invoice Status</label>
                <div className="custom-dropdown1" ref={statusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleStatusDropdown}
                    >
                      {selectedStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedStatus[0]}
                          >
                            {selectedStatus.length === invStatusList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    invStatusList.find(
                                      (item) => item._id === selectedStatus[0]
                                    )?.status
                                  }
                                />
                                {selectedStatus.length > 1 &&
                                  selectedStatus.length !==
                                  invStatusList.length && (
                                    <div className="selected-option-count">
                                      {selectedStatus.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isStatusDropdownOpen && (
                      <div className="dropdown-list1">
                        {invStatusList.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllStatus}
                                onChange={handleSelectAllStatusChange}
                              />
                              All
                            </label>
                            {invStatusList.map((status) => (
                              <label className="checkbox-label1" key={status._id}>
                                <input
                                  type="checkbox"
                                  checked={selectedStatus.includes(status._id)}
                                  onChange={() =>
                                    handleCheckboxStatusChange(status._id)
                                  }
                                />
                                {status.status && `${status.status}`}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner payment-status">
                <label for="paymentStatus">Payment Status</label>
                <div className="custom-dropdown1" ref={paymentStatusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={togglePaymentStatusDropdown}
                    >
                      {selectedPaymentStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedPaymentStatus[0]}
                          >
                            {selectedPaymentStatus.length ===
                              invPaymStatusList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    invPaymStatusList.find(
                                      (item) =>
                                        item._id === selectedPaymentStatus[0]
                                    )?.status
                                  }
                                />
                                {selectedPaymentStatus.length > 1 &&
                                  selectedPaymentStatus.length !==
                                  invPaymStatusList.length && (
                                    <div className="selected-option-count">
                                      {selectedPaymentStatus.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isPaymentStatusDropdownOpen && (
                      <div className="dropdown-list1">
                        {invPaymStatusList.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllPaymentStatus}
                                onChange={handleSelectAllPaymentStatusChange}
                              />
                              All
                            </label>
                            {invPaymStatusList.map((paymentstatus) => (
                              <label
                                className="checkbox-label1"
                                key={paymentstatus._id}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedPaymentStatus.includes(
                                    paymentstatus._id
                                  )}
                                  onChange={() =>
                                    handleCheckboxPaymentStatusChange(
                                      paymentstatus._id
                                    )
                                  }
                                />
                                {paymentstatus.status &&
                                  `${paymentstatus.status}`}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Due Date
                </label>
                <div className="date-picker-outer date-picker-outer-all">
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <DatePicker
                      oneTap // Use DatePicker instead of DateRangePicker
                      format="dd/MM/yyyy"
                      ranges={ranges}
                      value={selectedDate}
                      onChange={handleDateChange}
                      placement="auto"
                      className="invoice-filter-top-date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-invoice-right apply-fliter-right">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filters
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={toggleFilterModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">
                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                  <div className="filter-inner invoice-id">
                    <label for="fname">Invoice ID</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setInvoiceNumberFilter(e.target.value);
                        setCurrentPage(0);
                      }}
                      value={invoiceNumberFilter}
                      id="invoiceId"
                      name="invoiceId"
                      placeholder="All"
                    />
                  </div>

                  {(user && (user.userCategory !== userCategory.Vendor)) && (
                    <div className="filter-inner invoice-id">
                      <label for="fname">Vendor</label>
                      {/* <input
                      type="text"
                      onChange={(e) => {
                        setVendorFilter(e.target.value);
                        setCurrentPage(0);
                      }}
                      value={vendorFilter}
                      id="vendorFilter"
                      name="vendorFilter"
                      placeholder="All"
                    /> */}
                      <div className="custom-dropdown1" ref={vendorModalDropdownRef}>
                        <div className="drop-down-inner">
                          <div className="selected-options1" onClick={toggleVendorModalDropdown}>
                            {selectedVendors.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div className="selected-option1" key={selectedVendors[0]}>
                                  {selectedVendors.length === vendors.length
                                    ? 'All'
                                    : (
                                      <>
                                        <TruncateFilterFieldView textContent={vendors.find((item) => item._id === selectedVendors[0])?.vendor_name} />
                                        {selectedVendors.length > 1 && selectedVendors.length !== vendors.length && (
                                          <div className="selected-option-count">
                                            {selectedVendors.length - 1}+
                                          </div>
                                        )}
                                      </>
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                          {isVendorModalDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search vendors"
                                value={vendorDropdownSearchTerm}
                                onChange={handleVendorDropdownSearchChange}
                                className="search-bar"
                              />
                              {filteredDropdownVendors.length > 0 && (
                                <>
                                  {vendorDropdownSearchTerm === "" && (
                                    <label className="checkbox-label1" key="selectAll">
                                      <input
                                        type="checkbox"
                                        checked={selectAllVendor}
                                        onChange={handleSelectAllVendorChange}
                                      />
                                      All
                                    </label>
                                  )}
                                  {filteredDropdownVendors
                                    .slice()
                                    .sort((a, b) =>
                                      a.vendor_name.toLowerCase().localeCompare(b.vendor_name.toLowerCase())
                                    )
                                    .map((vendor) => (
                                      <label className="checkbox-label1" key={vendor._id}>
                                        <input
                                          type="checkbox"
                                          checked={selectedVendors.includes(vendor._id)}
                                          onChange={() => handleCheckboxVendorChange(vendor._id)}
                                        />
                                        {vendor.vendor_name && `${vendor.vendor_name}`}
                                      </label>
                                    ))}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="filter-inner invoice-status">
                    <label for="invoiceStatus">Invoice Status</label>
                    <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleStatusModalDropdown}
                        >
                          {selectedStatus.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedStatus[0]}
                              >
                                {selectedStatus.length ===
                                  invStatusList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        invStatusList.find(
                                          (item) => item._id === selectedStatus[0]
                                        )?.status
                                      }
                                    />
                                    {selectedStatus.length > 1 &&
                                      selectedStatus.length !==
                                      invStatusList.length && (
                                        <div className="selected-option-count">
                                          {selectedStatus.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isStatusModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {invStatusList.length > 0 && (
                              <>
                                <label
                                  className="checkbox-label1"
                                  key="selectAll"
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectAllStatus}
                                    onChange={handleSelectAllStatusChange}
                                  />
                                  All
                                </label>
                                {invStatusList.map((status) => (
                                  <label
                                    className="checkbox-label1"
                                    key={status._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedStatus.includes(
                                        status._id
                                      )}
                                      onChange={() =>
                                        handleCheckboxStatusChange(status._id)
                                      }
                                    />
                                    {status.status && `${status.status}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner payment-status">
                    <label for="paymentStatus">Payment Status</label>
                    <div
                      className="custom-dropdown1"
                      ref={paymentStatusModalDropdownRef}
                    >
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={togglePaymentStatusModalDropdown}
                        >
                          {selectedPaymentStatus.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedPaymentStatus[0]}
                              >
                                {selectedPaymentStatus.length ===
                                  invPaymStatusList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        invPaymStatusList.find(
                                          (item) =>
                                            item._id === selectedPaymentStatus[0]
                                        )?.status
                                      }
                                    />
                                    {selectedPaymentStatus.length > 1 &&
                                      selectedPaymentStatus.length !==
                                      invPaymStatusList.length && (
                                        <div className="selected-option-count">
                                          {selectedPaymentStatus.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isPaymentStatusModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {invPaymStatusList.length > 0 && (
                              <>
                                <label
                                  className="checkbox-label1"
                                  key="selectAll"
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectAllPaymentStatus}
                                    onChange={handleSelectAllPaymentStatusChange}
                                  />
                                  All
                                </label>
                                {invPaymStatusList.map((paymentstatus) => (
                                  <label
                                    className="checkbox-label1"
                                    key={paymentstatus._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedPaymentStatus.includes(
                                        paymentstatus._id
                                      )}
                                      onChange={() =>
                                        handleCheckboxPaymentStatusChange(
                                          paymentstatus._id
                                        )
                                      }
                                    />
                                    {paymentstatus.status &&
                                      `${paymentstatus.status}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner due-date">
                    <label htmlFor="dueDate" className="calendar-icon">
                      Due Date
                    </label>
                    <div className="date-picker-outer date-picker-outer-all">
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <DatePicker
                          oneTap // Use DatePicker instead of DateRangePicker
                          format="dd/MM/yyyy"
                          ranges={ranges}
                          value={selectedDate}
                          onChange={handleDateChange}
                          placement="auto"
                          className="invoice-filter-top-date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                className="delete-modal"
                isOpen={showConfirmationModal}
                onRequestClose={handleCancel}
                contentLabel="Confirm Delete"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "36%",
                    left: "50%",
                    transform: "translate(-24.75%, 0%)",
                  },
                }}
              >
                <div className="delete-modal-content">
                  <h2>Apply or Discard changes?</h2>
                  <p>
                    Do you want to Apply or Discard filter changes?
                  </p>

                  <div className="delete-modal-buttons">
                    <button className="delete" onClick={handleApplyConfirm} >
                      Apply Changes
                    </button>
                    <button onClick={clearFilters}>
                      Discard Changes
                    </button>
                  </div>
                </div>
              </Modal>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card">
              {isLoading == true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="datatable-in-invoice">
                  <DataTable
                    value={filteredData}
                    removableSort
                    tableStyle={{
                      minWidth: "50rem",
                      width: "100%",
                      textAlign: "left",
                    }}
                    paginator={filteredData.length > 10}
                    paginatorClassName="recent-invoices-pagination"
                    rows={rowsPerPage} // Set the number of rows per page
                    first={currentPage * rowsPerPage} // Set the current page using currentPage
                    onPage={onPageChange} // Update currentPage when changing pages
                  >
                    <Column
                      field="ven_inv_number"
                      header="Invoice ID"
                      className="fixed-sec1"
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="ven_inv_for.vendor_name"
                      body={useRenderMemberName}
                      header="Vendor"
                      className="fixed-se11"
                      sortable
                      style={{
                        paddingRight: "35px",
                        maxWidth: "230px",
                        minWidth: "230px",
                        width: "230px",
                        // left: `${sec1}px`,
                      }}
                      sortField="vendorName" // Use a different sortField to avoid confusion with the original field
                      sortFunction={(e) => {
                        const dataCopy = [...filteredData];
                        dataCopy.sort((rowData1, rowData2) => {
                          const vendorName1 =
                            (rowData1.ven_inv_for.vendor_name ||
                              rowData1.ven_inv_for.vendor_legalentity_name);

                          const vendorName2 =
                            (rowData2.ven_inv_for.vendor_name ||
                              rowData2.ven_inv_for.vendor_legalentity_name);

                          // Implement your custom comparison logic here
                          return e.order === 1
                            ? vendorName1.localeCompare(vendorName2)
                            : vendorName2.localeCompare(vendorName1);
                        });
                        return dataCopy;
                      }}
                    ></Column>
                    <Column
                      field="_id"
                      body={renderVendorsInvoicesCTA}
                      className="fixed-sec21"
                      style={{
                        paddingRight: "45px",
                        minWidth: "121.73px",
                      }}
                    ></Column>
                    <Column
                      field="ven_inv_due_date"
                      body={dateVenInvConvert}
                      header="Due Date"
                      className="fixed-sec31"
                      sortable
                      style={{ paddingRight: "44px", left: `${sec3}px` }}
                    ></Column>
                    <Column
                      field="ven_inv_total_amount"
                      header="Amount"
                      body={renderAmount}
                      className="amount-bold"
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="ven_inv_created_at"
                      body={createDateVenInvConvert}
                      header="Issue Date"
                      className="fixed-sec31"
                      sortable
                      style={{ paddingRight: "44px", left: `${sec3}px` }}
                    ></Column>
                    <Column
                      field="ven_inv_created_by"
                      body={(rowData) => (
                        <MemberName userId={rowData.ven_inv_created_by} />
                      )}
                      header="Created by"
                      // sortable
                      style={{
                        paddingRight: "44px",
                        maxWidth: "230px",
                        minWidth: "230px",
                        width: "230px",
                      }}
                    ></Column>
                    <Column
                      field="ven_inv_status"
                      header="Status"
                      className="fixed-sec41"
                      body={renderStatus}
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="ven_inv_payment_status"
                      header="Payment Status"
                      body={(rowData) => (
                        <VendorWisePaymentStatus
                          inv_payment_details={rowData.ven_inv_payment_details}
                          invData={rowData}
                          invoiceId={rowData.ven_inv_number}
                          createdAt={rowData.ven_inv_created_at}
                          paymentStatus={rowData.ven_inv_payment_status}
                        />
                      )}
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="ven_inv_payment_status"
                      header="Payment Date"
                      body={(rowData) => (
                        <VendorPaymentStatus
                          invData={rowData}
                          payStatus={rowData.ven_inv_payment_status}
                        />
                      )}
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                  </DataTable>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorsInvoices;