import "./accountSetting.css";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OwnerDetails from '../../../components/OwnerDetails/OwnerDetail';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import PaymentOption from "../../../components/AccountSettings/PaymentOptions/PaymentOption";
import GeneralSettings from "../../../components/AccountSettings/GeneralSettings/GeneralSettings";
import NotificationSettings from "../../../components/AccountSettings/Notification/Notification";
import SecuritySettings from "../../../components/AccountSettings/Security/Security";
import Profiles from "../../../components/AccountSettings/Profiles/Profiles";
import ClientPayment from "../../../components/AccountSettings/ClientPayment/ClientPayment";
import BankAccount from "../../../components/AccountSettings/BankAccount/BankAccount";
import Integrations from "../../../components/AccountSettings/Integrations/Integrations";
import InviteHistory from "../../user/inviteHistory.jsx";

function AccountSettings() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(
    (state) => state.auth.user
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const { typeOfAccount } = useSelector((state) => state.accountDetails);

  const id = user._id;
  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem('activeTab', String(index));
  };

  useEffect(() => {
    const processData = () => {
      const storedActiveTab = localStorage.getItem('activeTab');
      if (storedActiveTab) {
        setActiveTab(Number(storedActiveTab));
      }
      dispatch(setHeaderText("Account Settings"));
    };

    // Defer execution to the next event loop
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [id, dispatch]);

  return (
    <div>

      <div className='tab-outer-tab-account'>
        <ul className="tabs">
          <li
            className={activeTab === 0 ? 'active-tab' : ''}
            onClick={() => handleTabClick(0)}
          >
            {global.config.locate(appSettings && appSettings.language, 'Company_Profile')}
          </li>

          {(user.userType === 1 || user.userType === 2) &&
            (<li
              className={activeTab === 1 ? 'active-tab' : ''}
              onClick={() => handleTabClick(1)}
            >
              Bank Accounts
            </li>)}

          <li
            className={activeTab === 2 ? 'active-tab' : ''}
            onClick={() => handleTabClick(2)}
          >
            Profiles
          </li>

          {window.location.hostname !== "wx-agency.talenton.app" && (
            <li
              className={activeTab === 4 ? 'active-tab' : ''}
              style={{ pointerEvents: "none", opacity: 0.6 }}
            >
              {global.config.locate(appSettings && appSettings.language, 'Subscription')}
            </li>
          )}

          <li
            className={activeTab === 5 ? 'active-tab' : ''}
            onClick={() => handleTabClick(5)}
          >
            {global.config.locate(appSettings && appSettings.language, 'Security')}
          </li>
          <li
            className={activeTab === 6 ? 'active-tab' : ''}
            onClick={() => handleTabClick(6)}
          >
            {global.config.locate(appSettings && appSettings.language, 'General_Settings')}
          </li>
          <li
            className={activeTab === 7 ? 'active-tab' : ''}
            onClick={() => handleTabClick(7)}
          >
            {global.config.locate(appSettings && appSettings.language, 'Notifications')}
          </li>

          <li
            className={activeTab === 8 ? 'active-tab' : ''}
            onClick={() => handleTabClick(8)}
          >
            Integrations
          </li>

          <li
            className={activeTab === 9 ? 'active-tab' : ''}
            onClick={() => handleTabClick(9)}
          >
            Invite History
          </li>

        </ul>

        <div className="tabs-content account-settings-tab">
          <div className='company-profile' style={{ display: activeTab === 0 ? 'block' : 'none' }}>
            <OwnerDetails id={id} />
          </div>
          <div className='payment-option' style={{ display: activeTab === 1 ? 'block' : 'none' }}>
            {typeOfAccount === "Operational Accounts" ? (
              <PaymentOption />
            ) : (
              <ClientPayment />
            )}
          </div>
          <div className='payment-option' style={{ display: activeTab === 2 ? 'block' : 'none' }}>
            <Profiles id={id} />
          </div>
          <div style={{ display: activeTab === 4 ? 'block' : 'none' }}>
            Content of third tab
          </div>
          <div className='security-setting-option' style={{ display: activeTab === 5 ? 'block' : 'none' }}>
            <SecuritySettings id={id} />
          </div>
          <div className='general-setting-option' style={{ display: activeTab === 6 ? 'block' : 'none' }}>
            <GeneralSettings id={id} />
          </div>
          <div className='notification-option' style={{ display: activeTab === 7 ? 'block' : 'none' }}>
            <NotificationSettings id={id} />
          </div>
          <div className='general-setting-option' style={{ display: activeTab === 8 ? 'block' : 'none' }}>
            <Integrations id={id} />
          </div>
          <div className='general-setting-option' style={{ display: activeTab === 9 ? 'block' : 'none' }}>
            <InviteHistory />
          </div>

        </div>
      </div>
    </div >
  );
}

export default AccountSettings;
