import moment from "moment";
import Modal from "react-modal";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AES, enc } from 'crypto-js';
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import NewAlertsModal from "./helpers/NewAlertsModal.jsx";
import { useFetchClientAlertsDetails } from "../../../features/threshold/useProjectThreshold.jsx";
import { useGetActiveTeams, useGetTeams } from "../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import userType from "../../../constants/userType.js";
import "./ClientAlerts.css";


const Alerts = () => {
    const { encryptedId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);
    const { appSettings } = useSelector((state) => state.appSettings);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { forex } = useSelector((state) => state.forex);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updatedAlerts, setUpdatedAlerts] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectAllByPage, setSelectAllByPage] = useState({});
    const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
    const [showTimeThresholdModal, setShowTimeThresholdModal] = useState(false);
    const [thresholdForEdit, setThresholdForEdit] = useState("");
    const [projectIdEdit, setProjectIdEdit] = useState("");
    const [clientIdEdit, setClientIdEdit] = useState("");
    const [timeThresholdEdit, setTimeThresholdEdit] = useState("");
    const [showTimeThresholdUserModal, setShowTimeThresholdUserModal] = useState(false);
    const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
    const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
    const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
    const timeThresholdTeamDropdownRef = useRef(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [showBudgetThresholdModal, setShowBudgetThresholdModal] = useState(false);
    const [budgetThresholdEdit, setBudgetThresholdEdit] = useState("");
    const [showBudgetThresholdUserModal, setShowBudgetThresholdUserModal] = useState(false);
    const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
    const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
    const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
    const [activeForState, setActiveForState] = useState({});
    const budgetThresholdTeamDropdownRef = useRef(null);
    const [userId, setUserId] = useState("");
    const targetRef = useRef(null);
    const showingDropdownRef = useRef(null);
    const { projectAdded } = useSelector((state) => state.project);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(0); // Reset page to the first page
    };

    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    // react Query for project
    const { data: alerts = [], isLoading, refetch: refetchAlerts } = useFetchClientAlertsDetails({ clientId: id, token });
    const { data: teams = [], isLoading: teamLoading, isError: teamError, } = useGetActiveTeams(token);
    //

    useEffect(() => {
        const initializeObserver = () => {
            const resizeObserver = new ResizeObserver((entries) => {
                // Your resize observer logic here
                setTimeout(() => {
                    entries.forEach((entry) => {
                        console.log("Element resized:", entry.target);
                        // Perform non-blocking operations as necessary
                    });
                }, 0);
            });

            if (targetRef.current) {
                resizeObserver.observe(targetRef.current);
            }

            // Clean up the observer on component unmount
            return () => {
                setTimeout(() => {
                    if (targetRef.current) {
                        resizeObserver.unobserve(targetRef.current);
                    }
                }, 0);
            };
        };

        // Defer the initialization
        setTimeout(initializeObserver, 0);
    }, []);

    // useEffect(() => {
    //     setUpdatedAlerts(alerts);
    //     // dispatch(setHeaderText("Client Overview"));

    //     if ((user.userType !== 1 || user.userType !== 2)) {
    //         setUserId(user._id);
    //     }
    //     refetchAlerts();


    // }, [dispatch, alerts, token, user._id, user.userType, alertsAdded]);

    useEffect(() => {
        const initialize = async () => {
            // Set the updated alerts
            setUpdatedAlerts(alerts);

            // Check user type and set user ID
            // if (user.userType !== 1 && user.userType !== 2) {
            //     setUserId(user._id);
            // }

            // Refetch alerts in a non-blocking manner
            try {
                await Promise.resolve(refetchAlerts());
            } catch (error) {
                console.error("Error refetching alerts:", error);
            }
        };

        // Defer initialization to ensure non-blocking
        setTimeout(initialize, 0);
    }, [dispatch, alerts, token]);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
        const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
        const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
        if (!isClickInsideTimeThresholdTeamDropdown) {
            setIsTimeThresholdTeamDropdownOpen(false);
        }
        if (!isClickInsideBudgetThresholdTeamDropdown) {
            setIsBudgetThresholdTeamDropdownOpen(false);
        }
        if (!isClickInsideShowingDropdown) {
            setShowingDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const filteredData = updatedAlerts?.filter((item) => {
        const searchTerm = filter.trim().toLowerCase();
        const nameField = item.thresholdFor === "projects"
            ? item.projectOrSprintData?.project_name
            : item.projectOrSprintData?.sprint_name;
        const hasMatch = nameField.toLowerCase().includes(searchTerm);
        return hasMatch;
    });

    const totalResults = filteredData.length;

    const handleTimeThresholdShow = (rowData) => {
        setTimeThresholdEdit(rowData.timeThreshold);
        setSelectedTimeThresholdTeamsEdit(rowData.timeAlertTo);
        setThresholdForEdit(rowData.thresholdFor);
        setProjectIdEdit(rowData?.projectOrSprintData?._id);
        setClientIdEdit(rowData?.projectOrSprintData?.client_name);
        setShowTimeThresholdModal(true);
    }

    const handleTimeThresholdClose = () => {
        setTimeThresholdEdit("");
        setSelectedTimeThresholdTeamsEdit([]);
        setThresholdForEdit("");
        setProjectIdEdit("");
        setClientIdEdit("");
        setShowTimeThresholdModal(false);
    };

    const handleTimeThresholdSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);
        if (thresholdForEdit <= 0) {
            toast.error("Time threshold must be greater than 0", {
                position: "bottom-right",
                autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
        }
        try {
            const response = await axios.post(`/api/project/sprint/threshold`, {
                thresholdFor: thresholdForEdit,
                recordId: projectIdEdit,
                clientId: id || clientIdEdit,
                thresholdType: "time",
                thresholdPercentage: timeThresholdEdit,
                alertTo: selectedTimeThresholdTeamsEdit,
                thresholdTimeStatus: true,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                refetchAlerts();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleTimeThresholdClose();
        }
    };

    const handleTimeThresholdUserModalShow = (rowData) => {
        setTimeThresholdEdit(rowData.timeThreshold);
        setSelectedTimeThresholdTeamsEdit(rowData.timeAlertTo);
        setThresholdForEdit(rowData.thresholdFor);
        setProjectIdEdit(rowData?.projectOrSprintData?._id);
        setClientIdEdit(rowData?.projectOrSprintData?.client_name);
        setShowTimeThresholdUserModal(true);
    }

    const handleTimeThresholdUserModalClose = () => {
        setTimeThresholdEdit("");
        setSelectedTimeThresholdTeamsEdit([]);
        setThresholdForEdit("");
        setProjectIdEdit("");
        setClientIdEdit("");
        setShowTimeThresholdUserModal(false);
    }

    const toggleTimeThresholdTeamDropdown = () => {
        setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
    };

    const handleCheckboxTimeThresholdTeamChange = (teamId) => {
        const updatedSelection = [...selectedTimeThresholdTeamsEdit];
        const index = updatedSelection.indexOf(teamId);

        if (index === -1) {
            // Add to selection
            updatedSelection.push(teamId);
        } else {
            // Remove from selection
            updatedSelection.splice(index, 1);
        }
        setSelectedTimeThresholdTeamsEdit(updatedSelection);
    };

    const handleSelectTimeThresholdAllTeamChange = () => {
        if (!selectTimeThresholdAllTeam) {
            setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
        } else {
            setSelectedTimeThresholdTeamsEdit([]);
        }
        setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
    };

    const handleTimeThresholdSelectByRole = (role) => {
        const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
        setSelectedTimeThresholdTeamsEdit((prev) => {
            if (filteredUsers.every((id) => prev.includes(id))) {
                return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
            } else {
                return [...new Set([...prev, ...filteredUsers])];
            }
        });
    };

    useEffect(() => {
        const updateSelectionStatus = () => {
            setSelectTimeThresholdAllTeam(selectedTimeThresholdTeamsEdit.length === teams.length);
            // if (selectedTimeThresholdTeamsEdit.length === teams.length) {
            //     setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
            // } else {
            //     setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
            // }
        };

        // Call the updateSelectionStatus asynchronously
        Promise.resolve().then(() => updateSelectionStatus());
    }, [selectedTimeThresholdTeamsEdit, teams]);

    const handleTimeThresholdUserSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);

        if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
            toast.error("Please select at least one team for time threshold alerts", {
                position: "bottom-right",
                autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
        }
        try {
            const response = await axios.post(`/api/project/sprint/threshold`, {
                thresholdFor: thresholdForEdit,
                recordId: projectIdEdit,
                clientId: id || clientIdEdit,
                thresholdType: "time",
                thresholdPercentage: timeThresholdEdit,
                alertTo: selectedTimeThresholdTeamsEdit,
                thresholdTimeStatus: true,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                refetchAlerts();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleTimeThresholdUserModalClose();
        }
    };

    const handleBudgetThresholdShow = (rowData) => {
        setBudgetThresholdEdit(rowData.budgetThreshold);
        setSelectedBudgetThresholdTeamsEdit(rowData.budgetAlertTo || []);
        setThresholdForEdit(rowData.thresholdFor);
        setProjectIdEdit(rowData?.projectOrSprintData?._id);
        setClientIdEdit(rowData?.projectOrSprintData?.client_name);
        setShowBudgetThresholdModal(true);
    }

    const handleBudgetThresholdClose = () => {
        setBudgetThresholdEdit("");
        setSelectedBudgetThresholdTeamsEdit([]);
        setThresholdForEdit("");
        setProjectIdEdit("");
        setClientIdEdit("");
        setShowBudgetThresholdModal(false);
    };

    const handleBudgetThresholdSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);
        if (budgetThresholdEdit <= 0) {
            toast.error("Budget threshold must be greater than 0", {
                position: "bottom-right",
                autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
        }
        try {
            const response = await axios.post(`/api/project/sprint/threshold`, {
                thresholdFor: thresholdForEdit,
                recordId: projectIdEdit,
                clientId: id || clientIdEdit,
                thresholdType: "budget",
                thresholdPercentage: budgetThresholdEdit,
                alertTo: selectedBudgetThresholdTeamsEdit,
                thresholdBudgetStatus: true,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                refetchAlerts();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleBudgetThresholdClose();
        }
    };

    const handleBudgetThresholdUserModalShow = (rowData) => {
        setBudgetThresholdEdit(rowData.budgetThreshold);
        setSelectedBudgetThresholdTeamsEdit(rowData.budgetAlertTo || []);
        setThresholdForEdit(rowData.thresholdFor);
        setProjectIdEdit(rowData?.projectOrSprintData?._id);
        setClientIdEdit(rowData?.projectOrSprintData?.client_name);
        setShowBudgetThresholdUserModal(true);
    }

    const handleBudgetThresholdUserModalClose = () => {
        setBudgetThresholdEdit('');
        setSelectedBudgetThresholdTeamsEdit([]);
        setThresholdForEdit("");
        setProjectIdEdit("");
        setClientIdEdit("");
        setShowBudgetThresholdUserModal(false);
    }

    const toggleBudgetThresholdTeamDropdown = () => {
        setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
    };

    const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
        const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
        const index = updatedSelection.indexOf(teamId);

        if (index === -1) {
            // Add to selection
            updatedSelection.push(teamId);
        } else {
            // Remove from selection
            updatedSelection.splice(index, 1);
        }
        setSelectedBudgetThresholdTeamsEdit(updatedSelection);
    };

    const handleSelectBudgetThresholdAllTeamChange = () => {
        if (!selectBudgetThresholdAllTeam) {
            setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
        } else {
            setSelectedBudgetThresholdTeamsEdit([]);
        }
        setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
    };

    const handleBudgetThresholdSelectByRole = (role) => {
        const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
        setSelectedBudgetThresholdTeamsEdit((prev) => {
            if (filteredUsers.every((id) => prev.includes(id))) {
                return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
            } else {
                return [...new Set([...prev, ...filteredUsers])];
            }
        });
    };

    useEffect(() => {
        const updateSelectionStatus = () => {
            setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
            // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
            //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
            // } else {
            //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
            // }
        };

        // Call the updateSelectionStatus asynchronously
        Promise.resolve().then(() => updateSelectionStatus());
    }, [selectedBudgetThresholdTeamsEdit, teams]);

    const handleBudgetThresholdUserSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitLoading(true);
        if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
            toast.error("Please select at least one team for budget threshold alerts", {
                position: "bottom-right",
                autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
        }
        try {
            const response = await axios.post(`/api/project/sprint/threshold`, {
                thresholdFor: thresholdForEdit,
                recordId: projectIdEdit,
                clientId: id || clientIdEdit,
                thresholdType: "budget",
                thresholdPercentage: budgetThresholdEdit,
                alertTo: selectedBudgetThresholdTeamsEdit,
                thresholdBudgetStatus: true,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                refetchAlerts();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error);
            toast.error("An error occurred while updating the threshold.", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
            handleBudgetThresholdUserModalClose();
        }
    };

    // const handleCheckboxChange = (rowData, type) => {
    //     setActiveForState((prevState) => ({
    //         ...prevState,
    //         [rowData._id]: {
    //             ...prevState[rowData._id],
    //             [type]: !prevState[rowData._id]?.[type],
    //         },
    //     }));
    // };
    const handleCheckboxChange = async (rowData, type) => {
        setActiveForState((prevState) => {
            // const currentChecked = prevState[rowData._id]?.[type] ?? !!(rowData[type === "timeChecked" ? "timeThreshold" : "budgetThreshold"] || rowData[type === "timeChecked" ? "timeAlertTo" : "budgetAlertTo"]);
            const currentChecked = prevState[rowData._id]?.[type] ?? (rowData[type === "timeChecked" ? "thresholdTimeStatus" : "thresholdBudgetStatus"]);

            return {
                ...prevState,
                [rowData._id]: {
                    ...prevState[rowData._id],
                    [type]: !currentChecked,  // Toggle the current checked state properly
                },
            };
        });
        const updateType = type === "timeChecked" ? "thresholdTimeStatus" : "thresholdBudgetStatus";
        const updateThresholdType = type === "timeChecked" ? "time" : "budget";
        try {
            const response = await axios.post("/api/threshold/status/update", {
                thresholdFor: rowData.thresholdFor,
                recordId: rowData.projectOrSprintData._id,
                thresholdType: updateThresholdType,
                updateType,
                status: !rowData[updateType], // Send the toggled status
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === "success") {
                refetchAlerts();
                toast.success("Threshold updated successfully!", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                console.error("Failed to update threshold:", response.data.message);
                toast.error(response.data.message || "Failed to update threshold.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error updating threshold:", error.response?.data?.message || error.message);
        }
    };

    const ActiveFor = (rowData) => {
        // const [timeChecked, setTimeChecked] = useState(!!(rowData.timeThreshold || rowData.timeAlertTo));
        // const [budgetChecked, setBudgetChecked] = useState(!!(rowData.budgetThreshold || rowData.budgetAlertTo)); setTimeChecked(!timeChecked)
        // const timeChecked = activeForState[rowData._id]?.timeChecked ?? !!(rowData.timeThreshold || rowData.timeAlertTo);
        // const budgetChecked = activeForState[rowData._id]?.budgetChecked ?? !!(rowData.budgetThreshold || rowData.budgetAlertTo);
        const timeChecked = activeForState[rowData._id]?.timeChecked ?? (rowData.thresholdTimeStatus);
        const budgetChecked = activeForState[rowData._id]?.budgetChecked ?? (rowData.thresholdBudgetStatus);

        return (
            <div className="altert-row">
                <label className="alert-label">
                    <input type="checkbox" checked={timeChecked} onChange={() => handleCheckboxChange(rowData, "timeChecked")} />
                    <span>For time</span>
                </label>
                <label className="alert-label">
                    <input type="checkbox" checked={budgetChecked} onChange={() => handleCheckboxChange(rowData, "budgetChecked")} />
                    <span>For budget</span>
                </label>
            </div>
        );
    };

    const AlertSetup = (rowData) => {
        // const timeChecked = activeForState[rowData._id]?.timeChecked ?? !!(rowData.timeThreshold || rowData.timeAlertTo);
        // const budgetChecked = activeForState[rowData._id]?.budgetChecked ?? !!(rowData.budgetThreshold || rowData.budgetAlertTo);
        const timeChecked = activeForState[rowData._id]?.timeChecked ?? (rowData.thresholdTimeStatus);
        const budgetChecked = activeForState[rowData._id]?.budgetChecked ?? (rowData.thresholdBudgetStatus);
        return (
            <div className="threashold-count-user-outer-table">
                <span className={`custom-switch-description threashold-count-user-outer ${!timeChecked ? "disabled" : ""}`}>
                    If {rowData.thresholdFor || "project"} reaches <a onClick={() => handleTimeThresholdShow(rowData)}>{rowData.timeThreshold || "0"}%</a> of estimate, alert
                    <a className="threashold-count-user" onClick={() => handleTimeThresholdUserModalShow(rowData)}>
                        {isLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                            </div>
                        ) : (() => {
                            const roles = {
                                Super_Admin: "All Super Admin",
                                Admin: "All Admin",
                                Project_Manager: "All Project Manager",
                                Team_Manager: "All Team Manager",
                                User: "All Users"
                            };

                            const timeAlertTo = rowData?.timeAlertTo ?? [];

                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                const roleUsers = teams
                                    .filter((team) => team.role_id === userType[roleKey])
                                    .map((team) => team._id);

                                if (
                                    roleUsers?.length > 0 &&
                                    roleUsers?.every((id) => timeAlertTo.includes(id)) &&
                                    timeAlertTo.length === roleUsers.length
                                ) {
                                    return roleLabel;
                                }
                            }

                            if (timeAlertTo?.length === teams.length) {
                                return "All Team";
                            }

                            const firstSelectedUser = teams.find((item) => item._id === timeAlertTo[0])
                                ? (() => {
                                    const user = teams.find((item) => item._id === timeAlertTo[0]);
                                    return user.first_name
                                        ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                        : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                })()
                                : "No Users selected";

                            return (
                                <>
                                    {firstSelectedUser}
                                    {timeAlertTo.length > 1 && (
                                        <div className="selected-option-count">
                                            {timeAlertTo.length - 1}+
                                        </div>
                                    )}
                                </>
                            );
                        })()}
                    </a>
                </span>
                <span className={`custom-switch-description threashold-count-user-outer ${!budgetChecked ? "disabled" : ""}`}>
                    If {rowData.thresholdFor || "project"} reaches <a onClick={() => handleBudgetThresholdShow(rowData)}>{rowData.budgetThreshold || "0"}%</a> of estimate, alert
                    <a className="threashold-count-user" onClick={() => handleBudgetThresholdUserModalShow(rowData)}>
                        {isLoading ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                            </div>
                        ) : (() => {
                            const roles = {
                                Super_Admin: "All Super Admin",
                                Admin: "All Admin",
                                Project_Manager: "All Project Manager",
                                Team_Manager: "All Team Manager",
                                User: "All Users"
                            };

                            const budgetAlertTo = rowData?.budgetAlertTo ?? []; // Ensure it's always an array

                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                const roleUsers = teams
                                    .filter((team) => team.role_id === userType[roleKey])
                                    .map((team) => team._id);

                                if (
                                    roleUsers.length > 0 &&
                                    roleUsers.every((id) => budgetAlertTo.includes(id)) &&
                                    budgetAlertTo.length === roleUsers.length
                                ) {
                                    return roleLabel;
                                }
                            }

                            if (budgetAlertTo.length === teams.length) {
                                return "All Team";
                            }

                            const firstSelectedUser = teams.find((item) => item._id === budgetAlertTo[0])
                                ? (() => {
                                    const user = teams.find((item) => item._id === budgetAlertTo[0]);
                                    return user.first_name
                                        ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                        : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                })()
                                : "No Users selected";

                            return (
                                <>
                                    {firstSelectedUser}
                                    {budgetAlertTo.length > 1 && (
                                        <div className="selected-option-count">
                                            {budgetAlertTo.length - 1}+
                                        </div>
                                    )}
                                </>
                            );
                        })()}
                    </a>
                </span>
            </div>
        );
    };


    const handleRowsPerPageChange = (e) => {
        const newRowsPerPage = parseInt(e.target.value);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0); // Set the current page to 0 when changing rows per page
    };

    const onPageChange = (e) => {
        const newPage = e.page;
        // Store the current "Select All" state for the current page
        setSelectAllByPage((prevSelectAllByPage) => ({
            ...prevSelectAllByPage,
        }));
        setCurrentPage(e.page);
    };

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    return (
        <div className="outer-project-client-section outer-project-client-section-alert">
            <div className="text-arrow-outer">
                <div className="text-arrow-inner">
                    <Link to={`/client/details/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`}>
                        <div className="text-arrow">
                            <div className="left-arrow">
                                <img src={LeftArrow} alt="left-arrow" />
                            </div>
                            <div className="top-text">
                                Back to Overview
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="search-sec">
                    <div class="search-container">
                        <input
                            type="text"
                            onChange={handleFilterChange}
                            value={filter}
                            class="search-input"
                            placeholder="Search by name"
                        />
                    </div>
                </div>
            </div>

            <div className="user-outer-timeoff user-outer-timeoff-alert-sec">
                <div className="alert-section-inner-add-new">
                    <div className="invoice-top">
                        <NewAlertsModal
                            clientId={id}
                            refetchAlerts={refetchAlerts}
                            projectOrSprintData={updatedAlerts.map(alert => alert.projectOrSprintData)}
                        />

                    </div>
                    <Modal
                        className="select-users-alert"
                        isOpen={showTimeThresholdModal}
                        onRequestClose={handleTimeThresholdClose}
                        contentLabel="Set Threshold"
                        style={{
                            content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-25%, -50%)",
                                parent: document.querySelector(".admin-outer.time.tracker"),
                            },
                        }}
                    >
                        <div className="modal">
                            <div className="modal-close-invite outter-modal-main">
                                <a className="" onClick={handleTimeThresholdClose}>
                                    <img src={crssrImage} alt="Edit Icon" />{" "}
                                </a>
                            </div>
                            <div className="modal-client-head">
                                <h2 className="">
                                    Set Threshold
                                </h2>
                            </div>
                            <div className="form-field-client-outer">
                                <form onSubmit={handleTimeThresholdSubmit}>
                                    <div className="form-field-client">
                                        <label htmlFor="clientName" className="form-label">
                                            Threshold Percentage
                                        </label>
                                        <input
                                            type="number"
                                            id="clientName"
                                            name="clientName"
                                            className="form-input"
                                            value={timeThresholdEdit}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                                    setTimeThresholdEdit(value);
                                                }
                                            }}
                                            onWheel={numberInputOnWheelPreventChange}
                                            min="0"
                                            max="100"
                                            step="1"
                                            required
                                        />
                                    </div>
                                    {isSubmitLoading ? (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className="form-field-client-btns">
                                            <button
                                                type="submit"
                                                className="save-changes"
                                                disabled={isSubmitLoading}
                                            >
                                                {global.config.locate(
                                                    appSettings && appSettings.language,
                                                    "Save_changes"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        className="select-users-alert"
                        isOpen={showTimeThresholdUserModal}
                        onRequestClose={handleTimeThresholdUserModalClose}
                        contentLabel="Select Users"
                        style={{
                            content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-25%, -50%)",
                            },
                        }}
                    >
                        <div className="modal">
                            <div className="modal-close-invite outter-modal-main">
                                <a className="" onClick={handleTimeThresholdUserModalClose}>
                                    <img src={crssrImage} alt="Edit Icon" />{" "}
                                </a>
                            </div>
                            <div className="modal-client-head">
                                <h2>Select Users</h2>
                            </div>
                            <div className="form-field-client-outer">
                                <form onSubmit={handleTimeThresholdUserSubmit}>
                                    <div className="filter-inner invoice-status">
                                        <label htmlFor="user">User</label>
                                        <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                                            <div className="drop-down-inner">
                                                <div className="selected-options1" onClick={toggleTimeThresholdTeamDropdown}>
                                                    {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                                        <div className="placeholder">Select</div>
                                                    ) : (
                                                        <>
                                                            <div className="selected-option1">
                                                                {selectedTimeThresholdTeamsEdit.length === teams.length ? (
                                                                    "All"
                                                                ) : (
                                                                    <>
                                                                        {/* {teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])?.first_name || "User"}
                                                                        {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                                            <div className="selected-option-count">
                                                                                {selectedTimeThresholdTeamsEdit.length - 1}+
                                                                            </div>
                                                                        )} */}
                                                                        {(() => {
                                                                            const roles = {
                                                                                Super_Admin: "All Super Admin",
                                                                                Admin: "All Admin",
                                                                                Project_Manager: "All Project Manager",
                                                                                Team_Manager: "All Team Manager",
                                                                                User: "All Users"
                                                                            };

                                                                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                                                const roleUsers = teams
                                                                                    .filter((team) => team.role_id === userType[roleKey])
                                                                                    .map((team) => team._id);

                                                                                if (
                                                                                    roleUsers?.length > 0 &&
                                                                                    roleUsers?.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                                                                    selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                                                                ) {
                                                                                    return roleLabel;
                                                                                }
                                                                            }

                                                                            if (selectedTimeThresholdTeamsEdit?.length === teams.length) {
                                                                                return "All Team";
                                                                            }

                                                                            const firstSelectedUser = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                                                                ? (() => {
                                                                                    const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);
                                                                                    return user.first_name
                                                                                        ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                                        : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                                                })()
                                                                                : "No Users selected";

                                                                            return (
                                                                                <>
                                                                                    {firstSelectedUser}
                                                                                    {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                                                        <div className="selected-option-count">
                                                                                            {selectedTimeThresholdTeamsEdit.length - 1}+
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        })()}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {isTimeThresholdTeamDropdownOpen && (
                                                    <div className="dropdown-list1">
                                                        <label className="checkbox-label1" key="selectAll">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectTimeThresholdAllTeam}
                                                                onChange={handleSelectTimeThresholdAllTeamChange}
                                                            />
                                                            All Teams
                                                        </label>
                                                        {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                                            .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                                            .map((role) => (
                                                                <label className="checkbox-label1" key={role}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={teams
                                                                            .filter((team) => team.role_id === userType[role])
                                                                            .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                                                        onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                                                    />
                                                                    All {role.replace("_", " ")}
                                                                </label>
                                                            ))}
                                                        {teams.map((team) => (
                                                            <label className="checkbox-label1" key={team._id}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                                                    onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                                                />
                                                                {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                                            </label>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-field-client-btns">
                                        <button type="submit" className="save-changes">
                                            {isSubmitLoading ? (
                                                <div className="outter-load-table">
                                                    <ThreeDots
                                                        height="25"
                                                        width="25"
                                                        radius="9"
                                                        color="white"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div>
                                            ) : (
                                                "Save Changes"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        className="select-users-alert"
                        isOpen={showBudgetThresholdModal}
                        onRequestClose={handleBudgetThresholdClose}
                        contentLabel="Set Threshold"
                        style={{
                            content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-25%, -50%)",
                                parent: document.querySelector(".admin-outer.time.tracker"),
                            },
                        }}
                    >
                        <div className="modal">
                            <div className="modal-close-invite outter-modal-main">
                                <a className="" onClick={handleBudgetThresholdClose}>
                                    <img src={crssrImage} alt="Edit Icon" />{" "}
                                </a>
                            </div>
                            <div className="modal-client-head">
                                <h2 className="">
                                    Set Threshold
                                </h2>
                            </div>
                            <div className="form-field-client-outer">
                                <form onSubmit={handleBudgetThresholdSubmit}>
                                    <div className="form-field-client">
                                        <label htmlFor="clientName" className="form-label">
                                            Threshold Percentage
                                        </label>
                                        <input
                                            type="number"
                                            id="clientName"
                                            name="clientName"
                                            className="form-input"
                                            value={budgetThresholdEdit}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                                    setBudgetThresholdEdit(value);
                                                }
                                            }}
                                            onWheel={numberInputOnWheelPreventChange}
                                            min="0"
                                            max="100"
                                            step="1"
                                            required
                                        />
                                    </div>
                                    {isSubmitLoading ? (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className="form-field-client-btns">
                                            <button
                                                type="submit"
                                                className="save-changes"
                                                disabled={isSubmitLoading}
                                            >
                                                {global.config.locate(
                                                    appSettings && appSettings.language,
                                                    "Save_changes"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        className="select-users-alert"
                        isOpen={showBudgetThresholdUserModal}
                        onRequestClose={handleBudgetThresholdUserModalClose}
                        contentLabel="Select Users"
                        style={{
                            content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-25%, -50%)",
                            },
                        }}
                    >
                        <div className="modal">
                            <div className="modal-close-invite outter-modal-main">
                                <a className="" onClick={handleBudgetThresholdUserModalClose}>
                                    <img src={crssrImage} alt="Edit Icon" />{" "}
                                </a>
                            </div>
                            <div className="modal-client-head">
                                <h2>Select Users</h2>
                            </div>
                            <div className="form-field-client-outer">
                                <form onSubmit={handleBudgetThresholdUserSubmit}>
                                    <div className="filter-inner invoice-status">
                                        <label htmlFor="user">User</label>
                                        <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                                            <div className="drop-down-inner">
                                                <div className="selected-options1" onClick={toggleBudgetThresholdTeamDropdown}>
                                                    {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                                        <div className="placeholder">Select</div>
                                                    ) : (
                                                        <>
                                                            <div className="selected-option1">
                                                                {selectedBudgetThresholdTeamsEdit.length === teams.length ? (
                                                                    "All"
                                                                ) : (
                                                                    <>
                                                                        {/* {teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])?.first_name || "User"}
                                                                        {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                                            <div className="selected-option-count">
                                                                                {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                                            </div>
                                                                        )} */}
                                                                        {(() => {
                                                                            const roles = {
                                                                                Super_Admin: "All Super Admin",
                                                                                Admin: "All Admin",
                                                                                Project_Manager: "All Project Manager",
                                                                                Team_Manager: "All Team Manager",
                                                                                User: "All Users"
                                                                            };

                                                                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                                                const roleUsers = teams
                                                                                    .filter((team) => team.role_id === userType[roleKey])
                                                                                    .map((team) => team._id);

                                                                                if (
                                                                                    roleUsers?.length > 0 &&
                                                                                    roleUsers?.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                                                    selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                                                ) {
                                                                                    return roleLabel;
                                                                                }
                                                                            }

                                                                            if (selectedBudgetThresholdTeamsEdit?.length === teams.length) {
                                                                                return "All Team";
                                                                            }

                                                                            const firstSelectedUser = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                                                ? (() => {
                                                                                    const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);
                                                                                    return user.first_name
                                                                                        ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                                                        : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                                                })()
                                                                                : "No Users selected";

                                                                            return (
                                                                                <>
                                                                                    {firstSelectedUser}
                                                                                    {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                                                        <div className="selected-option-count">
                                                                                            {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        })()}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {isBudgetThresholdTeamDropdownOpen && (
                                                    <div className="dropdown-list1">
                                                        <label className="checkbox-label1" key="selectAll">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectBudgetThresholdAllTeam}
                                                                onChange={handleSelectBudgetThresholdAllTeamChange}
                                                            />
                                                            All Teams
                                                        </label>
                                                        {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                                            .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                                            .map((role) => (
                                                                <label className="checkbox-label1" key={role}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={teams
                                                                            .filter((team) => team.role_id === userType[role])
                                                                            .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                                                        onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                                                    />
                                                                    All {role.replace("_", " ")}
                                                                </label>
                                                            ))}
                                                        {teams.map((team) => (
                                                            <label className="checkbox-label1" key={team._id}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                                                    onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                                                />
                                                                {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                                            </label>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-field-client-btns">
                                        <button type="submit" className="save-changes">
                                            {isSubmitLoading ? (
                                                <div className="outter-load-table">
                                                    <ThreeDots
                                                        height="25"
                                                        width="25"
                                                        radius="9"
                                                        color="white"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div>
                                            ) : (
                                                "Save Changes"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    <div className="overview-top-right" ref={targetRef} >
                        <div className="showing-count showing-count-timeoff">
                            <div className="filter-inner showing-result show-result-team-fill">
                                <label>Showing</label>

                                <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                    <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                                        {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                                    </div>
                                    {showingDropdownOpen && (
                                        <div className="custom-dropdown-new">
                                            <ul className="showing-options-list">
                                                {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 10 } }); setShowingDropdownOpen(false); }}>10</li>}
                                                {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 20 } }); setShowingDropdownOpen(false); }}>20</li>}
                                                {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 30 } }); setShowingDropdownOpen(false); }}>30</li>}
                                                {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 40 } }); setShowingDropdownOpen(false); }}>40</li>}
                                                {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>ALL</li>}
                                                {/* Add more conditions as needed */}
                                                {totalResults < 10 ? (
                                                    <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>{totalResults}</li>
                                                ) : null}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <label>of {totalResults} Results</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datatable-team-maintb">
                    <DataTable
                        value={filteredData}
                        removableSort
                        tableStyle={{
                            minWidth: "50rem",
                            width: "100%",
                            textAlign: "left",
                        }}
                        paginator={filteredData.length > 10}
                        paginatorClassName="recent-invoices-pagination"
                        rows={rowsPerPage}
                        first={currentPage * rowsPerPage}
                        onPage={onPageChange}
                        emptyMessage={
                            isLoading ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#405BFF"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                "No results found"
                            )
                        }
                    >
                        <Column
                            field="projectOrSprintData"
                            header="Project/Sprint Name"
                            className=""
                            body={(rowData) => rowData.thresholdFor === "projects"
                                ? rowData.projectOrSprintData?.project_name
                                : `${rowData.projectOrSprintData?.sprint_name} - ${rowData.projectOrSprintData?.project_name || ''}`}
                            style={{ paddingRight: "44px" }}
                        ></Column>
                        <Column
                            field="ActiveFor"
                            header="Active for"
                            className=""
                            body={ActiveFor}
                            style={{ paddingRight: "44px" }}
                        ></Column>
                        <Column
                            field="AlertSetup"
                            header="Alert Setup"
                            body={AlertSetup}
                            className=""
                            style={{ paddingRight: "44px" }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default Alerts;