import Modal from "react-modal";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "react-moment";

import css from "../Tracker.css";

import { ThreeDots } from "react-loader-spinner";

import Card from "react-bootstrap/Card";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";


const TimeCard = ({ index, item, resumeTimeTracking, timeFormat, resumeLoading, customeTime, selectedUser }) => {

    return (
        <Card className="text-center card-bg" >
            {/* Header time section total duration date */}
            <CardHeader index={index} item={item} />
            <CardBody item={item} resumeTimeTracking={resumeTimeTracking} resumeLoading={resumeLoading} customeTime={customeTime} timeFormat={timeFormat} selectedUser={selectedUser} />
        </Card>
    );

}
export default TimeCard;