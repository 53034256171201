import { AES, enc } from 'crypto-js';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import userTypeConsts from '../../../constants/userTypeConsts';
import { Link, useNavigate, Outlet, useParams } from 'react-router-dom';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { useClientView } from '../../../components/TimeTracker/TimeTrackerClient/useClientDetails';
import { setSideBarText } from '../../../features/sideBarText/sideBarTextSlice';
import userCategory from '../../../constants/userCategory';
import userTypeConsts from '../../../constants/userTypeConsts';


function ClientDetailsLayout() {
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const optionDropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Profile");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url + encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F')));
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };
  // const handleSelectChange = (event) => {
  //   const selectedPage = event.target.value;
  //   navigate(selectedPage + encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F')));
  // };
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: client = [], isLoading, refetch: refetchClientProfiles, isError: clientViewError } = useClientView(id, token);

  // const getOptionText = (path) => {

  //   switch (path) {
  //     case `/client/profile/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Profile";
  //     case `/client/projects/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Projects";
  //     case `/client/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Invoices";
  //     case path.startsWith("/client/invoices/view/"):
  //       return "Invoices";
  //     case `/client/documents/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Documents";
  //     case `/client/setup/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Client Setup";
  //     case `/client/alerts/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`:
  //       return "Client Alerts";
  //     default:
  //       return "";
  //   }
  // };

  const getOptionText = (path) => {
    const basePathRegex = /\/client\/(profile|projects|invoices|documents|setup|alerts)(\/[^/]+)?/;
    const match = path.match(basePathRegex);
    if (!match) {
      return "";
    }
    const base = match[1];
    const encryptedIdFromPath = match[2]?.slice(1);

    switch (base) {
      case "profile":
        return "Profile";
      case "projects":
        return "Projects";
      case "invoices":
        if (path.startsWith("/client/invoices/view/")) {
          return "Invoices";
        }
        return encryptedIdFromPath ? "Invoices" : "";
      case "documents":
        return "Documents";
      case "setup":
        return "Client Setup";
      case "alerts":
        return "Alerts";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Set the initial value of the select to match the current URL path
    const currentPath = window.location.pathname;
    setSelectedOption(getOptionText(currentPath));
    const selectElement = document.querySelector('.mobile-tab-select');
    if (selectElement) {
      selectElement.value = currentPath;
    }
  }, []);

  useEffect(() => {
    refetchClientProfiles();
    if (!isLoading) {
      if (user && (user?.userCategory === userCategory.Client)) {
        dispatch(setHeaderText("Invoices"));
      } else {
        dispatch(setHeaderText(client?.client_name || client?.legal_entity_name || "Client Overview"));
        dispatch(setSideBarText("Client Overview"));
      }
    }
  }, [id, isLoading]);

  // const originalUrl = window.location.pathname;
  // const lastIndex = originalUrl.lastIndexOf('/');
  // const newUrl = originalUrl.substring(0, lastIndex);

  return (
    <div className='tab-outer-time'>
      {(user && (user.userCategory !== userCategory.Client)) && (
        <>
          <div className='tab-mobile-select'>
            <h4>Select an option</h4>
            {/* <select className="mobile-tab-select" onChange={handleSelectChange} value={`${newUrl}/`}>
          <option value={`/client/profile/`} >Profile</option>
          <option value={`/client/projects/`} >Projects</option>
          <option value={`/client/invoices/`} >Invoices</option>
          <option value={`/client/documents/`} >Documents</option>
          <option value={`/client/setup/`} >Client Setup</option>
          <option value={`/client/alerts/`} >Alerts</option>
        </select> */}
            <div className="custom-dropdown-user-new-outer">
              <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
                {selectedOption}
              </div>
              {dropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="showing-options-list">
                    <li onClick={() => handleOptionClick("Profile", "/client/profile/")}>Profile</li>
                    <li onClick={() => handleOptionClick("Projects", "/client/projects/")}>Projects</li>
                    <li onClick={() => handleOptionClick("Invoices", "/client/invoices/")}>Invoices</li>
                    <li onClick={() => handleOptionClick("Documents", "/client/documents/")}>Documents</li>
                    <li onClick={() => handleOptionClick("Client Setup", "/client/setup/")}>Client Setup</li>
                    <li onClick={() => handleOptionClick("Alerts", "/client/alerts/")}>Alerts</li>
                  </ul>
                </div>
              )}
            </div>

          </div>

          <ul className="tabs">
            <li>
              <Link to={`/client/profile/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/profile') ? 'active' : ''}>
                Profile
              </Link>
            </li>
            <li>
              <Link to={`/client/projects/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/projects') ? 'active' : ''}>
                Projects
              </Link>
            </li>
            <li>
              <Link to={`/client/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/invoices') ? 'active' : ''}>
                Invoices
              </Link>
            </li>
            <li>
              <Link to={`/client/documents/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/documents') ? 'active' : ''}>
                Documents
              </Link>
            </li>
            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
              <li>
                <Link to={`/client/setup/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/setup') ? 'active' : ''}>
                  Client Setup
                </Link>
              </li>
            )}
            <li>
              <Link to={`/client/alerts/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/alert') ? 'active' : ''}>
                Alerts
              </Link>
            </li>
          </ul>
        </>
      )}

      <div className="tabs-content">
        <div className='tab-inner-time'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default ClientDetailsLayout;
