import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import AES from "crypto-js/aes";
import axios from "axios";
import Modal from "react-modal";
import "./ProjectOverviewListingSingleDetails.css";
import editImage from "../../../../images/singleinvoice/edit.svg";
import Delete from "../../../../images/icons/delete.svg";
import Close from "../../../../images/icons/black-close.svg";
import Tick from "../../../../images/singleinvoice/tick-green.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import LeftArrow from "../../../../images/icons/blue-left-arrow.svg";
import { useClientView } from "../../../../components/TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { logout, reset } from "../../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useGetClients, useGetTeams } from "../../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import ProjectOverview from "./ProjectOverviewListingSingleActivities.jsx";
import SprintTable from "./SprintTable";
import { Link } from "react-router-dom";
import { useFetchClientProjectManager } from "../../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import TruncateUserName from "../../../../components/helpers/truncateUserName.js";
import Teams from "../../details/modules/Teams.jsx";
import { useProjectDetails } from "../../../../features/projects/ProjectList.jsx";
import { useGetProjectThreshold } from "../../../../features/projects/useProjects.jsx";
import userType from "../../../../constants/userType.js";

const ProjectDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectDiscription, setProjectDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [budget, setBudget] = useState("");
  const [timeLoading, setTimeLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [budgetThreshold, setBudgetThreshold] = useState(0);

  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);

  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);
  // const [totalHours, setTotalHours] = useState("");
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  // const [countriesList, setCountriesList] = useState([]);
  const [isProjectDeleteModalOpen, setIsProjectDeleteModalOpen] = useState(false);
  const [isProjectArchiveModalOpen, setIsProjectArchiveModalOpen] = useState(false);
  const [isProjectunArchiveModalOpen, setIsProjectunArchiveModalOpen] = useState(false);

  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  // const [budgetIsValid, setBudgetIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const clientNameRef = useRef(null);
  // const budgetRef = useRef(null);
  const clientDropdownRef = useRef(null);
  const clientCurrency = props.clientCurrency;
  const projectLoading = props.isLoadingProject || false;
  const projectFetching = props.projectFetching || false;

  const user = useSelector((state) => state.auth.user);
  // const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userId = [], isLoading: isUserLoading, refetch: refetchProjectManager, } = useFetchClientProjectManager(token, props.id);
  const { data: clientView = [], isLoading, isError: clientViewError, } = useClientView(props.id, token);
  const { data: timeThresholdData = [], isLoading: timeThresholdLoading, isFetching: timeThresholdFetching, refetch: refetchTimeThreshold, isError: timeThresholdError, } = useGetProjectThreshold({ projectId: props.project._id, thresholdType: "time", token });
  const { data: budgetThresholdData = [], isLoading: budgetThresholdLoading, isFetching: budgetThresholdFetching, refetch: refetchBudgetThreshold, isError: budgetThresholdError, } = useGetProjectThreshold({ projectId: props.project._id, thresholdType: "budget", token });
  const { data: allTeams = [], isLoading: teamLoading, isError: teamError } = useGetTeams(token);

  // Filter teams where `is_active: true` and have at least one active user
  const teams = allTeams.filter(team => team.is_active === true);

  // const { data: clients = [], isLoading: clientLoading, isError: clientError, } = useGetClients(token);
  // const { data: project = [], isLoading: isLoadingProject, refetch: refetchProject, } = useProjectDetails(token, projectId);

  useEffect(() => {
    // setSelectedClient(props.id);
    // const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    // setCountriesList(sortedCountries);
    // refetchProject();
    refetchTimeThreshold();
    refetchBudgetThreshold();
    if (clientViewError || timeThresholdError || budgetThresholdError) {
      if (clientViewError.response?.data?.status === "deleted" || timeThresholdError.response?.data?.status === "deleted" || budgetThresholdError.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This Client is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error(`Error fetching data: ${clientViewError?.response?.data?.message || timeThresholdError?.response?.data?.message || budgetThresholdError?.response?.data?.message}`, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [projectId]);

  // useEffect(() => {
  //   setTimeThreshold(timeThresholdData?.data?.thresholdPercentage || 0);
  //   setSelectedTimeThresholdTeamsEdit(timeThresholdData?.data?.alertTo || []);
  //   setBudgetThreshold(budgetThresholdData?.data?.thresholdPercentage || 0);
  //   setSelectedBudgetThresholdTeamsEdit(budgetThresholdData?.data?.alertTo || []);
  // }, [timeThresholdData, budgetThresholdData, dispatch]);

  useEffect(() => {
    if (timeThresholdData?.data) {
      setTimeChecked(true);
      // setTimeThreshold((prev) =>
      //   prev !== timeThresholdData.data.thresholdPercentage ? timeThresholdData.data.thresholdPercentage : prev
      // );
      // setSelectedTimeThresholdTeamsEdit((prev) =>
      //   JSON.stringify(prev) !== JSON.stringify(timeThresholdData.data.alertTo) ? timeThresholdData.data.alertTo : prev
      // );
      setTimeThreshold(timeThresholdData?.data?.thresholdPercentage || 0);
      setSelectedTimeThresholdTeamsEdit(timeThresholdData?.data?.alertTo || []);
    } else {
      setTimeChecked(false);
      setTimeThreshold(0);
      setSelectedTimeThresholdTeamsEdit([]);
    }

    if (budgetThresholdData?.data) {
      setBudgetChecked(true);
      // setBudgetThreshold((prev) =>
      //   prev !== budgetThresholdData.data.thresholdPercentage ? budgetThresholdData.data.thresholdPercentage : prev
      // );
      // setSelectedBudgetThresholdTeamsEdit((prev) =>
      //   JSON.stringify(prev) !== JSON.stringify(budgetThresholdData.data.alertTo) ? budgetThresholdData.data.alertTo : prev
      // );
      setBudgetThreshold(budgetThresholdData?.data?.thresholdPercentage || 0);
      setSelectedBudgetThresholdTeamsEdit(budgetThresholdData?.data?.alertTo || []);
    } else {
      setBudgetChecked(false);
      setBudgetThreshold(0);
      setSelectedBudgetThresholdTeamsEdit([]);
    }
  }, [timeThresholdData, budgetThresholdData]);


  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };

  const handleTimeCheckboxChange = async () => {
    const newTimeChecked = !timeChecked;
    setTimeChecked(newTimeChecked);

    if (newTimeChecked) {
      if (timeThreshold === 0 && selectedTimeThresholdTeamsEdit.length === 0) {
        setTimeLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "time" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setTimeThreshold(data?.thresholdPercentage || 0);
          setSelectedTimeThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setTimeLoading(false); // Stop loading
        }
      }
    } else {
      setTimeThreshold(timeThreshold);
      setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
    }
  };

  const handleBudgetCheckboxChange = async () => {
    const newBudgetChecked = !budgetChecked;
    setBudgetChecked(newBudgetChecked);

    if (newBudgetChecked) {
      if (budgetThreshold === 0 && selectedBudgetThresholdTeamsEdit.length === 0) {
        setBudgetLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "budget" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setBudgetThreshold(data?.thresholdPercentage || 0);
          setSelectedBudgetThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setBudgetLoading(false); // Stop loading
        }
      }
    } else {
      setBudgetThreshold(budgetThreshold);
      setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
    }
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };

  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    // Avoid unnecessary state updates
    const isAllSelected = selectedTimeThresholdTeamsEdit.length === teams.length;

    setSelectTimeThresholdAllTeam((prev) => {
      return prev !== isAllSelected ? isAllSelected : prev;
    });

  }, [selectedTimeThresholdTeamsEdit, teams]);

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  // useEffect(() => {
  //   const updateSelectionStatus = () => {
  //     setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
  //     // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
  //     //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
  //     // } else {
  //     //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
  //     // }
  //   };

  //   // Call the updateSelectionStatus asynchronously
  //   Promise.resolve().then(() => updateSelectionStatus());
  // }, [selectedBudgetThresholdTeamsEdit, teams]);

  useEffect(() => {
    const isAllSelected = selectedBudgetThresholdTeamsEdit.length === teams.length;

    setSelectBudgetThresholdAllTeam((prev) => {
      return prev !== isAllSelected ? isAllSelected : prev;
    });

  }, [selectedTimeThresholdTeamsEdit, teams]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    // const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    // const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    // const isClickInsideAccManagerSelectDropdown = accManagerSelectDropdownRef.current && accManagerSelectDropdownRef.current.contains(event.target);
    // const isClickInsideContactPointSelectDropdown = contactPointSelectDropdownRef.current && contactPointSelectDropdownRef.current.contains(event.target);
    // if (!isClickInsideCountryDropdown) {
    //     setCountryDropdownOpen(false);
    // }
    // if (!isClickInsideClientDropdown) {
    //     setClientDropdownOpen(false);
    // }
    // if (!isClickInsideAccManagerSelectDropdown) {
    //     setAccManagerDropdownOpen(false);
    // }
    // if (!isClickInsideContactPointSelectDropdown) {
    //     setContactPointDropdownOpen(false);
    // }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  //   const handleOpenModal = () => {
  //     setIsModalOpen(true);
  //   };

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name
          ? a.company_first_name +
          (a.company_last_name && a.company_last_name)
          : a.company_name
        }`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name
          ? b.company_first_name +
          (b.company_last_name && b.company_last_name)
          : b.company_name
        }`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name
        ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}`
        : item.company_first_name
          ? `${item.company_first_name +
          (item.company_last_name && item.company_last_name)
          }`
          : `${item.company_name}(Company)`,
      value: item._id,
    }));

  const StatusChangeActive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/project/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `Project ${props.project?.project_name} has been deactivated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Project ${props.project?.project_name} has been deactivated successfully!`
      );
      dispatch(setNotification(Date.now()));
      dispatch(setProjectAdded());
    } catch (error) {
      console.log("Something went wrong while deactivating project: ", error);
      toast.error(
        `Something went wrong while deactivating project ${props.project?.project_name}`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while deactivating Project ${props.project?.project_name}!!`
      );
      console.log(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const StatusChangeInactive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/project/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `Project ${props.project?.project_name} has been activated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch(setNotification(Date.now()));
      global.config.activityLog(
        window.location.href,
        "User",
        `Project ${props.project?.project_name} has been activated successfully!`
      );
      dispatch(setProjectAdded());
    } catch (error) {
      console.log("Something went wrong while activating project: ", error);
      toast.error("Something went wrong in changing project status!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while activating Project ${props.project?.project_name}!!`
      );
      console.log(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleOpenDetailsModal = async (item) => {
    if (item.project_name) {
      setProjectName(item.project_name);
    }
    if (item.client_name) {
      setClientName(item.client_name._id);
    }
    if (item.discription) {
      setProjectDiscription(item.discription);
    }
    // if (item.budget) {
    //   setBudget(item.budget);
    // }
    // if (item.total_hours) {
    //   setTotalHours(item.total_hours);
    // }
    if (item.project_manager) {
      const findProjectManagerOptions = (
        selectedProjectManagers,
        projectManagerOptions
      ) => {
        return selectedProjectManagers.map((manager) => {
          const option = projectManagerOptions.find(
            (opt) => opt.value === manager._id
          );
          return option
            ? option
            : {
              label: manager.first_name
                ? `${manager.first_name} ${manager.middle_name || ""} ${manager.last_name
                }`
                : manager.company_first_name
                  ? `${manager.company_first_name +
                  (manager.company_last_name && manager.company_last_name)
                  }`
                  : `${manager.company_name}(Company)`,
              value: manager._id,
            };
        });
      };
      setSelectedProjectManagers(
        findProjectManagerOptions(item.project_manager, projectManagerOptions)
      );
    }
    setBillable(item.is_billable);
    setTimeChecked(!!timeThresholdData?.data);
    setTimeThreshold(timeThresholdData?.data?.thresholdPercentage || 0);
    setSelectedTimeThresholdTeamsEdit(timeThresholdData?.data?.alertTo || []);
    setBudgetChecked(!!budgetThresholdData?.data);
    setBudgetThreshold(budgetThresholdData?.data?.thresholdPercentage || 0);
    setSelectedBudgetThresholdTeamsEdit(budgetThresholdData?.data?.alertTo || []);
    refetchTimeThreshold();
    refetchBudgetThreshold();
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setProjectName("");
    setClientName("");
    setProjectDiscription("");
    // setTotalHours("");
    // setBudget("");
    setBillable(true);
    setIsDetailsModalOpen(false);
  };

  const handleProjectDelete = () => {
    setIsProjectDeleteModalOpen(true);
  };

  const handleProjectArchive = () => {
    setIsProjectArchiveModalOpen(true);
  };
  const handleProjectunArchive = () => {
    setIsProjectunArchiveModalOpen(true);
  };

  // Confirm Project Delete
  const handleConfirmProjectDelete = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(
        `/api/project-details/delete/${props.project._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Project removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setProjectAdded());
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project (${props.project._id}) deleted successfully`
      );
      handleCloseDetailsModal();
      navigate(
        `/client/projects/${encodeURIComponent(
          AES.encrypt(props.id, "encryptionKey")
            .toString()
            .replace(/\//g, "%2F")
        )}`
      );
    } catch (error) {
      console.error(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsProjectDeleteModalOpen(false);
  };

  // Confirm Project Archive

  const handleConfirmProjectArchive = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(
        `/api/project-details/archive/${props.project._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Project Archived successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsProjectunArchiveModalOpen(false);
      dispatch(setProjectAdded());
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project (${props.project._id}) Archived successfully`
      );
      handleCloseDetailsModal();

      const encryptedCliId = AES.encrypt(props.project?.client_name?._id, 'encryptionKey').toString();
      const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');
      setIsProjectArchiveModalOpen(false);
      // navigate(`/client/projects/details/${props.project?._id}/${encodedId}`);


    } catch (error) {
      console.error(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsProjectDeleteModalOpen(false);
  };


  const handleConfirmProjectunArchive = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(
        `/api/project-details/unarchive/${props.project._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Project unarchived successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setProjectAdded());
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project (${props.project._id}) unarchived successfully`
      );
      handleCloseDetailsModal();


      const encryptedCliId = AES.encrypt(props.project?.client_name?._id, 'encryptionKey').toString();
      const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');
      setIsProjectunArchiveModalOpen(false);
      // navigate(`/client/projects/details/${props.project?._id}/${encodedId}`);


    } catch (error) {
      console.error(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsProjectDeleteModalOpen(false);
  };

  const handleCancelProjectDelete = () => {
    setIsProjectDeleteModalOpen(false);
  };

  const handleCancelProjectArchive = () => {
    setIsProjectArchiveModalOpen(false);
  };

  const handleCancelProjectunArchive = () => {
    setIsProjectunArchiveModalOpen(false);
  };

  const handleProjectEdit = async (event) => {
    event.preventDefault();
    setProjectNameIsValid(false);
    setClientNameIsValid(false);
    // setBudgetIsValid(false);
    // || budget === ""
    if (projectName === "" || clientName === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        return;
      } else if (clientName === "") {
        setClientNameIsValid(true);
        clientNameRef.current.focus();
        return;
      }
      // else if (budget === "") {
      //   setBudgetIsValid(true);
      //   budgetRef.current.focus();
      //   return;
      // }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (timeChecked) {
      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }
    if (budgetChecked) {
      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    const requestData = {
      project_name: projectName,
      client_name: clientName,
      discription: projectDiscription,
      is_billable: billable,
      // total_hours: totalHours,
      // budget: budget,
      project_manager: selectedProjectManagers.map((manager) => manager.value),
    };
    try {
      setIsSubmitLoading(true);
      await axios.put(
        `/api/project-details/update/${props.project._id}`,
        requestData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // const data = response.data.message;
      // setUpdatedProject((prevState) => {
      //     const updatedProjectList = prevState.map((item) => {
      //         if (item._id === props.project._id) {
      //             return {
      //                 ...item,
      //                 project_name: projectName,
      //                 discription: projectDiscription,
      //                 is_billable: billable,
      //                 total_hours: totalHours,
      //                 project_manager: selectedProjectManagers.map(manager => manager.value),
      //             };
      //         }
      //         return item;
      //     });
      //     return updatedProjectList;
      // });
      const thresholdRequests = [];
      if (timeChecked) {
        thresholdRequests.push(
          axios.post(
            "/api/project/sprint/threshold",
            {
              thresholdFor: "projects",
              recordId: props.project._id,
              clientId: clientName,
              thresholdType: "time",
              thresholdPercentage: timeThreshold,
              alertTo: selectedTimeThresholdTeamsEdit,
              thresholdTimeStatus: timeChecked,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
        );
      }

      if (budgetChecked) {
        thresholdRequests.push(
          axios.post(
            "/api/project/sprint/threshold",
            {
              thresholdFor: "projects",
              recordId: props.project._id,
              clientId: clientName,
              thresholdType: "budget",
              thresholdPercentage: budgetThreshold,
              alertTo: selectedBudgetThresholdTeamsEdit,
              thresholdBudgetStatus: budgetChecked,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
        );
      }
      await Promise.all(thresholdRequests);
      toast.success("Project updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      global.config.activityLog(
        window.location.href,
        "Project",
        `Project "${projectName}" updated successfully`
      );
      handleCloseDetailsModal();
      dispatch(setProjectAdded());
    } catch (error) {
      console.log(error);
      // global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      await Promise.all([
        refetchTimeThreshold(),
        refetchBudgetThreshold(),
        refetchProjectManager(),
      ]);

      setIsSubmitLoading(false);
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="client-details-overview-outer">
      <div className="text-arrow-outer">
        <div className="text-arrow-inner">
          <Link
            to={`/client/projects/${encodeURIComponent(
              AES.encrypt(props.id, "encryptionKey")
                .toString()
                .replace(/\//g, "%2F")
            )}`}
          >
            <div className="text-arrow">
              <div className="left-arrow">
                <img src={LeftArrow} alt="left-arrow" />
              </div>
              <div className="top-text">Back to All Projects</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="client-with-invitation client-with-invitation-clietnt client-with-invitation-archive">

        {props.project.is_archived === false && (<button
          type="button"
          className="btn-blue-sec"
          onClick={() => handleOpenDetailsModal(props.project)}
          disabled={isSubmitLoading || projectLoading || projectFetching || timeThresholdLoading || budgetThresholdLoading || timeThresholdFetching || budgetThresholdFetching}
        >
          <img src={editImage} alt="Edit Icon" /> Edit Project
        </button>)}
        <div className="modal-invite-main-outter">
          <Modal
            className="client-modal project-modal"
            isOpen={isDetailsModalOpen}
            onRequestClose={handleCloseDetailsModal}
            contentLabel="New Project"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="modal">
              <div className="modal-close-invite outter-modal-main">
                <a className="" onClick={handleCloseDetailsModal}>
                  <img src={crssrImage} alt="Edit Icon" />{" "}
                </a>
              </div>
              <div className="project-detail-modal">
                <div className="modal-client-head ">
                  <h2 className="">Project Details</h2>
                </div>
                <div className="form-field-client-outer">
                  <form>
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Project Name
                      </label>
                      <input
                        type="text"
                        id="projectName"
                        name="projectName"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className={
                          projectNameIsValid
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        ref={projectNameRef}
                      />
                      {projectNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Project Name Field Cannot be empty
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        Client Name
                      </label>
                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select `}
                        ref={clientDropdownRef}
                      >
                        <div className="selected-option custom-dropdown-user-new form-input readonly">
                          {clientView?.client_name || clientView?.legal_entity_name || "Select"}
                        </div>
                        {/* 
                            <div className="selected-option custom-dropdown-user-new">
                              {clientName ? (
                                  clients.find((client) => client._id === clientName)?.client_name
                              ) : (
                                  'Select'
                              )}
                            </div> 
                          */}
                      </div>
                      {clientNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Client Name Field Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={projectDiscription}
                        onChange={(e) => setProjectDiscription(e.target.value)}
                        className="form-textarea"
                        rows="3"
                      ></textarea>
                    </div>

                    {/* <div className="form-field-client">
                      <label htmlFor="time" className="form-label">
                        Time Allocated (in hours)
                      </label>
                      <input
                        type="number"
                        id="time"
                        name="time"
                        value={totalHours}
                        onChange={(e) => setTotalHours(e.target.value)}
                        className="form-input"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    </div> */}

                    {/* <div className="form-field-client">
                        <label htmlFor="time" className="form-label">
                          Budget
                        </label>
                        <input
                          type="number"
                          id="budget"
                          name="budget"
                          value={budget}
                          onChange={(e) => setBudget(e.target.value)}
                          className={
                            budgetIsValid
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          ref={budgetRef}
                          onWheel={numberInputOnWheelPreventChange}
                        />
                        {budgetIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Budget Field Cannot be empty
                          </span>
                        )}
                      </div> */}

                    <div className="form-field-client">
                      <label htmlFor="projectManager" className="form-label">
                        Assigned Team
                      </label>
                      <Select
                        isMulti
                        options={projectManagerOptions}
                        value={selectedProjectManagers}
                        onChange={(selectedOptions) =>
                          setSelectedProjectManagers(selectedOptions)
                        }
                      />
                    </div>

                    <div className={`form-field-client radio-sec`}>
                      <label htmlFor="billable" className="form-label">
                        Billable
                      </label>
                      <div className="radio-container">
                        <div className="radio-option yes">
                          <input
                            type="radio"
                            id="yes"
                            name="billable"
                            value={billable}
                            checked={billable === true}
                            onChange={(e) => setBillable(true)}
                          />
                          <label htmlFor="yes">
                            Yes <img src={Tick} alt="Tick" />
                          </label>
                        </div>
                        <div className="radio-option no">
                          <input
                            type="radio"
                            id="no"
                            name="billable"
                            value={billable}
                            checked={billable === false}
                            onChange={(e) => setBillable(false)}
                          />
                          <label htmlFor="no">
                            No <img src={Close} alt="Edit Icon" />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="projectTimeframe" className="form-label">
                        Project Alerts
                      </label>
                      <div className="project-alert-outer">
                        {/* Time Alert */}
                        {/* onChange={() => setTimeChecked(!timeChecked)} onChange={() => setBudgetChecked(!budgetChecked)}*/}
                        <div className="alert-section for-time-outer">
                          <label className="alert-label">
                            <input type="checkbox" checked={timeChecked} onChange={handleTimeCheckboxChange} />
                            <span>For time</span>
                          </label>

                          <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                            <div className="input-group">
                              <span>If project reaches</span>
                              {timeLoading ? (
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="number"
                                  value={timeThreshold}
                                  // onChange={(e) => setTimeThreshold(e.target.value)}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                      setTimeThreshold(value);
                                    }
                                  }}
                                  onWheel={numberInputOnWheelPreventChange}
                                  min="0"
                                  max="100"
                                  step="1"
                                />
                              )}
                              <span>% of estimate</span>
                            </div>

                            <div className="dropdown-group">
                              <span>Alert</span>
                              <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                                <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                                  <div className="selected-options1">
                                    {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                      <div className="placeholder">Select</div>
                                    ) : (
                                      <>
                                        <div className="selected-option1">
                                          {timeLoading ? (
                                            <div className="outter-load-table">
                                              <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                              />
                                            </div>
                                          ) : (() => {
                                            const roles = {
                                              Super_Admin: "All Super Admin",
                                              Admin: "All Admin",
                                              Project_Manager: "All Project Manager",
                                              Team_Manager: "All Team Manager",
                                              User: "All Users",
                                            };

                                            // Check if all users of a specific role are selected
                                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                              const roleUsers = teams
                                                .filter((team) => team.role_id === userType[roleKey])
                                                .map((team) => team._id);

                                              if (
                                                roleUsers.length > 0 &&
                                                roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                                selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                              ) {
                                                return roleLabel;
                                              }
                                            }

                                            // If all team members are selected
                                            if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                              return "All Team";
                                            }

                                            // Get the first selected user name
                                            const firstSelectedUser =
                                              teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                                ? (() => {
                                                  const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                                  return user.first_name
                                                    ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                    : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                })()
                                                : "No Users selected";

                                            return (
                                              <>
                                                {firstSelectedUser}
                                                {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                  <div className="selected-option-count">
                                                    {selectedTimeThresholdTeamsEdit.length - 1}+
                                                  </div>
                                                )}
                                              </>
                                            );
                                          })()}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {isTimeThresholdTeamDropdownOpen && (
                                  <div className="dropdown-list1">
                                    <label className="checkbox-label1" key="selectAll">
                                      <input
                                        type="checkbox"
                                        checked={selectTimeThresholdAllTeam}
                                        onChange={handleSelectTimeThresholdAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                    {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                      .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                      .map((role) => (
                                        <label className="checkbox-label1" key={role}>
                                          <input
                                            type="checkbox"
                                            checked={teams
                                              .filter((team) => team.role_id === userType[role])
                                              .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                            onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                          />
                                          All {role.replace("_", " ")}
                                        </label>
                                      ))}
                                    {teams.map((team) => (
                                      <label className="checkbox-label1" key={team._id}>
                                        <input
                                          type="checkbox"
                                          checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                          onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                        />
                                        {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Budget Alert */}
                        <div className="alert-section for-budget-outer">
                          <label className="alert-label">
                            <input type="checkbox" checked={budgetChecked} onChange={handleBudgetCheckboxChange} />
                            <span>For budget</span>
                          </label>

                          <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                            <div className="input-group">
                              <span>If project reaches</span>
                              {budgetLoading ? (
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="number"
                                  value={budgetThreshold}
                                  // onChange={(e) => setBudgetThreshold(e.target.value)}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                      setBudgetThreshold(value);
                                    }
                                  }}
                                  onWheel={numberInputOnWheelPreventChange}
                                  min="0"
                                  max="100"
                                  step="1"
                                />
                              )}
                              <span>% of estimate</span>
                            </div>

                            <div className="dropdown-group">
                              <span>Alert</span>
                              <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                                <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                                  <div className="selected-options1">
                                    {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                      <div className="placeholder">Select</div>
                                    ) : (
                                      <div className="selected-option1">
                                        {budgetLoading ? (
                                          <div className="outter-load-table">
                                            <ThreeDots
                                              height="18"
                                              width="20"
                                              radius="9"
                                              color="#6479f9"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </div>
                                        ) : (() => {
                                          const roles = {
                                            Super_Admin: "All Super Admin",
                                            Admin: "All Admin",
                                            Project_Manager: "All Project Manager",
                                            Team_Manager: "All Team Manager",
                                            User: "All Users",
                                          };

                                          // Check if all users of a specific role are selected
                                          for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                            const roleUsers = teams
                                              .filter((team) => team.role_id === userType[roleKey])
                                              .map((team) => team._id);

                                            if (
                                              roleUsers.length > 0 &&
                                              roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                              selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                            ) {
                                              return roleLabel;
                                            }
                                          }

                                          // If all team members are selected
                                          if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                            return "All Team";
                                          }

                                          // Get the first selected user name
                                          const firstSelectedUser =
                                            teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                              ? (() => {
                                                const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                return user.first_name
                                                  ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                  : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                              })()
                                              : "No Users selected";

                                          return (
                                            <>
                                              {firstSelectedUser}
                                              {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                <div className="selected-option-count">
                                                  {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                </div>
                                              )}
                                            </>
                                          );
                                        })()}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {isBudgetThresholdTeamDropdownOpen && (
                                  <div className="dropdown-list1">
                                    <label className="checkbox-label1" key="selectAll">
                                      <input
                                        type="checkbox"
                                        checked={selectBudgetThresholdAllTeam}
                                        onChange={handleSelectBudgetThresholdAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                    {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                      .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                      .map((role) => (
                                        <label className="checkbox-label1" key={role}>
                                          <input
                                            type="checkbox"
                                            checked={teams
                                              .filter((team) => team.role_id === userType[role])
                                              .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                            onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                          />
                                          All {role.replace("_", " ")}
                                        </label>
                                      ))}
                                    {teams.map((team) => (
                                      <label className="checkbox-label1" key={team._id}>
                                        <input
                                          type="checkbox"
                                          checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                          onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                        />
                                        {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="sprint-btn-outer">
                        <button
                          type="button"
                          onClick={handleCloseDetailsModal}
                          className="submit-client cancel-sprint"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleProjectEdit}
                          className="submit-client save-sprint"
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="outer-archive-delete">
          {props.project && (props.project?.is_archived === false || props.project?.is_archived === undefined) ? (
            <button
              type="button"
              className="delete-client delete-client-archive"
              onClick={handleProjectArchive}
              disabled={isSubmitLoading}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.533474 9.45506H1.755V2.96324C1.755 2.81601 1.81473 2.68268 1.91126 2.58615L4.3404 0.156252C4.44457 0.0520841 4.5807 0 4.7175 0H12.4264C13.4251 0 14.2452 0.820152 14.2452 1.81868V9.45487H15.4666C15.7611 9.45487 16 9.69374 16 9.98824V14.1402C16 15.1645 15.1646 16 14.1402 16H1.85974C0.835432 16 0 15.1646 0 14.1402V9.98824C0 9.69374 0.239038 9.45506 0.533474 9.45506ZM5.61116 7.84948H10.3896C11.0918 7.84948 11.0918 6.7828 10.3896 6.7828H5.61116C4.90839 6.7828 4.90839 7.84948 5.61116 7.84948ZM5.61116 4.86331H10.3896C11.0918 4.86331 11.0918 3.79663 10.3896 3.79663H5.61116C4.90839 3.79663 4.90839 4.86331 5.61116 4.86331ZM2.82165 9.45496H5.61116C5.9056 9.45496 6.1445 9.69393 6.1445 9.98833C6.1445 11.637 8.14663 12.4667 9.3132 11.3009C9.64932 10.9647 9.85694 10.5009 9.85694 9.98833C9.85694 9.69393 10.0958 9.45496 10.3903 9.45496H13.1798L13.1791 1.81883C13.1791 1.40356 12.8424 1.06673 12.427 1.06673H4.93813L2.82151 3.18409L2.82165 9.45496ZM5.12654 10.5217H1.0668V14.1411C1.0668 14.5758 1.42444 14.9335 1.85986 14.9335H14.1411C14.5758 14.9335 14.9335 14.5758 14.9335 14.1404V10.5217H10.8737C10.4501 12.8168 7.60226 13.7239 5.93346 12.0551C5.52374 11.6454 5.23557 11.1147 5.12654 10.5217Z" fill="#282828" />
              </svg>
              Archive
            </button>
          ) : (
            <button
              type="button"
              className="delete-client delete-client-archive"
              onClick={handleProjectunArchive}
              disabled={isSubmitLoading}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.533474 9.45506H1.755V2.96324C1.755 2.81601 1.81473 2.68268 1.91126 2.58615L4.3404 0.156252C4.44457 0.0520841 4.5807 0 4.7175 0H12.4264C13.4251 0 14.2452 0.820152 14.2452 1.81868V9.45487H15.4666C15.7611 9.45487 16 9.69374 16 9.98824V14.1402C16 15.1645 15.1646 16 14.1402 16H1.85974C0.835432 16 0 15.1646 0 14.1402V9.98824C0 9.69374 0.239038 9.45506 0.533474 9.45506ZM5.61116 7.84948H10.3896C11.0918 7.84948 11.0918 6.7828 10.3896 6.7828H5.61116C4.90839 6.7828 4.90839 7.84948 5.61116 7.84948ZM5.61116 4.86331H10.3896C11.0918 4.86331 11.0918 3.79663 10.3896 3.79663H5.61116C4.90839 3.79663 4.90839 4.86331 5.61116 4.86331ZM2.82165 9.45496H5.61116C5.9056 9.45496 6.1445 9.69393 6.1445 9.98833C6.1445 11.637 8.14663 12.4667 9.3132 11.3009C9.64932 10.9647 9.85694 10.5009 9.85694 9.98833C9.85694 9.69393 10.0958 9.45496 10.3903 9.45496H13.1798L13.1791 1.81883C13.1791 1.40356 12.8424 1.06673 12.427 1.06673H4.93813L2.82151 3.18409L2.82165 9.45496ZM5.12654 10.5217H1.0668V14.1411C1.0668 14.5758 1.42444 14.9335 1.85986 14.9335H14.1411C14.5758 14.9335 14.9335 14.5758 14.9335 14.1404V10.5217H10.8737C10.4501 12.8168 7.60226 13.7239 5.93346 12.0551C5.52374 11.6454 5.23557 11.1147 5.12654 10.5217Z" fill="#282828" />
              </svg>
              Unarchive
            </button>
          )}

          <button
            type="button"
            className="delete-client"
            onClick={handleProjectDelete}
            disabled={isSubmitLoading}
          >
            <img src={Delete} alt="Delete" /> Delete
          </button>

          {/* delete modal */}
          <Modal
            className="delete-modal"
            isOpen={isProjectDeleteModalOpen}
            onRequestClose={handleCancelProjectDelete}
            contentLabel="Confirm Delete"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "36%",
                left: "50%",
                transform: "translate(-19.75%, 0%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="delete-modal-content">
              <h2>Confirm Delete</h2>
              <p>Are you sure you want to delete this Project?</p>
              <div className="delete-modal-buttons">
                <button
                  className="buttons-rejected"
                  onClick={handleConfirmProjectDelete}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  onClick={handleCancelProjectDelete}
                  disabled={isSubmitLoading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
          {/* delete modal */}


          {/*  archive modal */}

          <Modal
            className="delete-modal"
            isOpen={isProjectArchiveModalOpen}
            onRequestClose={handleCancelProjectArchive}
            contentLabel="Confirm Archive"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "36%",
                left: "50%",
                transform: "translate(-19.75%, 0%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="delete-modal-content">
              <h2>Confirm Archive</h2>
              <p>Are you sure you want to archive this Project?</p>
              <div className="delete-modal-buttons">
                <button
                  className="buttons-rejected"
                  onClick={handleConfirmProjectArchive}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Archive"
                  )}
                </button>
                <button
                  onClick={handleCancelProjectArchive}
                  disabled={isSubmitLoading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {/*  archive modal */}


          {/*  unarchive modal */}

          <Modal
            className="delete-modal"
            isOpen={isProjectunArchiveModalOpen}
            onRequestClose={handleCancelProjectunArchive}
            contentLabel="Confirm Archive"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "36%",
                left: "50%",
                transform: "translate(-19.75%, 0%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="delete-modal-content">
              <h2>Confirm Unarchive</h2>
              <p>Are you sure you want to Unarchive this Project?</p>
              <div className="delete-modal-buttons">
                <button
                  className="buttons-rejected"
                  onClick={handleConfirmProjectunArchive}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Unarchive"
                  )}
                </button>
                <button
                  onClick={handleCancelProjectunArchive}
                  disabled={isSubmitLoading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {/*  unarchive modal */}
        </div>
      </div>
      <div className="client-professional-details-outer project-detai-outer-iner">
        <div className="client-detail-row-one">
          <div className="client-detail-row-one-left">
            <div className="client-status">
              {projectLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="client-status-text client-status-text-title">
                  {props.project.project_name}
                </div>
              )}
            </div>
          </div>
          <div className="client-detail-row-one-right">
            <div className="account-manager">
              <div className="account-manager-text">Account Manager</div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : clientView.acc_manager ? (
                <div className="account-manager-image-with-name">
                  <span className="manager-profile-icon">
                    {clientView.acc_manager?.profile_image ? (
                      <img
                        src={`/uploads/lowProfileImage/${clientView.acc_manager.profile_image}`}
                        alt={clientView.acc_manager.first_name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "/uploads/lowProfileImage/user_default.png";
                        }}
                      />
                    ) : (
                      <img
                        src={profile_image}
                      // alt={clientView.acc_manager.first_name}
                      />
                    )}
                  </span>
                  <div className="account-manager-name">
                    <TruncateUserName
                      textContent={
                        clientView.acc_manager?.first_name ? (
                          <>
                            {clientView.acc_manager.first_name}{" "}
                            {clientView.acc_manager.middle_name &&
                              clientView.acc_manager.middle_name !== "null"
                              ? clientView.acc_manager.middle_name + " "
                              : ""}
                            {clientView.acc_manager.last_name}{" "}
                          </>
                        ) : (
                          <>
                            {clientView.acc_manager?.company_first_name
                              ? `${clientView.acc_manager.company_first_name} ${clientView.acc_manager.company_last_name
                                ? clientView.acc_manager.company_last_name
                                : ""
                              }`
                              : `${clientView.acc_manager.company_name || ""
                              }(Company)`}
                          </>
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div>Account manager is not assigned</div>
              )}
            </div>

            <div className="client-status">
              <div className="client-status-text">Project Status</div>
              {isLoading || projectLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  {(props.project?.is_active === false && props.project?.is_archived === false) ? (
                    <div
                      className="inactive"
                      onClick={() =>
                        (!isSubmitLoading || !projectLoading) &&
                        StatusChangeInactive(props.project?._id)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="18"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Inactive"
                      )}
                    </div>
                  ) : props.project?.is_active === true ? (
                    <div
                      className="client-status-bar"
                      onClick={() =>
                        !isSubmitLoading && StatusChangeActive(props.project?._id)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="18"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Active"
                      )}
                    </div>
                  ) : props.project?.is_archived === true ? (
                    <div
                      className="client-status-bar archived"
                    // onClick={() =>
                    //   !isSubmitLoading && StatusChangeActive(props.project?._id)
                    // }
                    // style={{ cursor: "pointer" }}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="18"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Archived"
                      )}
                    </div>
                  ) : (
                    props.project?.is_active
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="account-manager project-manager">
          <div className="account-manager-text">Assigned Team </div>
          <div className="account-manager-image-with-name-outer">
            {isLoading || projectLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              props.project?.project_manager &&
              props.project?.project_manager.map((manager, index) => (
                <div className="account-manager-image-with-name">
                  <span className="manager-profile-icon">
                    {manager.profile_image ? (
                      <img
                        src={`/uploads/lowProfileImage/${manager.profile_image}`}
                        alt={manager.first_name || manager.company_name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "/uploads/lowProfileImage/user_default.png";
                        }}
                      />
                    ) : (
                      <img
                        src={profile_image}
                        alt={manager.first_name || manager.company_name}
                      />
                    )}
                  </span>
                  <div className="account-manager-name">
                    <TruncateUserName
                      textContent={
                        manager.first_name ? (
                          <>
                            {manager.first_name}{" "}
                            {manager.middle_name &&
                              manager.middle_name !== "null"
                              ? manager.middle_name + " "
                              : ""}
                            {manager.last_name}{" "}
                          </>
                        ) : (
                          <>
                            {manager.company_first_name
                              ? `${manager.company_first_name} ${manager.company_last_name
                                ? manager.company_last_name
                                : ""
                              }`
                              : `${manager.company_name || ""}(Company)`}
                          </>
                        )
                      }
                    />
                    {/* {index < props.project.project_manager.length - 1 && ', '} */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <ProjectOverview
          id={props.id}
          project={props.project}
          isLoadingProject={projectLoading}
          clientCurrency={clientCurrency}
        />
        <SprintTable
          project={props.project}
          isLoadingProject={projectLoading}
          clientCurrency={clientCurrency}
        />
        <Teams
          id={props.id}
          project={props.project}
          projectManager={props.project?.project_manager}
          isLoadingProject={projectLoading}
          clientCurrency={clientCurrency}
        />
      </div>
    </div>
  );
};
export default ProjectDetails;
