import "./Teams.css";
// import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import ProjectCost from "./elemets/ProjectCost.jsx";
import Delete from "../../../../images/icons/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import typeOfContract from "../../../../constants/typeOfContract";
import { logout, reset } from "../../../../features/auth/authSlice";
import ticno from "../../../../images/team-member-tab/table-no.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import UpArrowImage from "../../../../images/icons/up-down-arrow.svg";
import ticyes from "../../../../images/team-member-tab/table-yes.svg";
import DownArrowImage from "../../../../images/icons/up-down-arrow.svg";
// import editimgblue from "../../../../images/team-member-tab/edit-btn-blue.svg";
import NewTeamMembers from "../../helpers/NewTeamMember";
import TruncateUserName from "../../../../components/helpers/truncateUserName.js";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import {
  useFetchAppSettings,
  useFetchUsers,
  useUpdateBillableCostRate,
  useUpdateTeam,
} from "../../../../components/TimeTracker/TimeTrackerTeam/TimeTrackerTeamMembers/teamMemberApi.jsx";
import userType from "../../../../constants/userType.js";

const ProjectTeamMembers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = "";
  const billableRateEditRef = useRef(null);
  const perPage = 10;
  const billableCostRateEditRef = useRef(null);
  const selectedId = "";
  const [selectedPage, setSelectedPage] = useState(0);
  const userFilter = "All";
  const [costEditableRow, setCostEditableRow] = useState(-1);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  // const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState({});
  const [sortedUserProfiles, setSortedUserProfiles] = useState([]);
  const [billableCostRateEdit, setBillableCostRateEdit] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  let token = null;
  const projects = props.project;
  const clientPreferredCurrency = props?.project?.client_name?.preferred_currency || 'USD';
  // const id = props.id;
  const { forex } = useSelector((state) => state.forex);
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userProfiles = [], isLoading, refetch, } = useFetchUsers(projects.project_manager);
  const { data: appSettingsData = [], isLoading: isAppSettingsLoading, error, refetch: refetchAppSettings } = useFetchAppSettings(token, appSettings?.appId);
  const updateBillableCostRateMutation = useUpdateBillableCostRate(token);
  const updateTeam = useUpdateTeam(token);
  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key, direction });
    const sortedProfiles = [...userProfiles].sort((a, b) => {
      const aValue =
        key === "billableRate"
          ? a.billable_cost
          : key === "cost"
            ? a.cost
            : a[key];
      const bValue =
        key === "billableRate"
          ? b.billable_cost
          : key === "cost"
            ? b.cost
            : b[key];

      if (key === "department") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedUserProfiles(sortedProfiles);
  };

  useEffect(() => {
    setSortedUserProfiles(userProfiles);
  }, [userProfiles]);

  useEffect(() => {
    refetchAppSettings();
  }, [appSettings?.appId]);

  useEffect(() => {
    refetch();
  }, [projects.project_manager, refetch]);

  const filteredData = sortedUserProfiles.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name ||
        (item.company_first_name
          ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ""
          }`
          : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;
    if (userFilter !== "All" && item._id !== userFilter) {
      return false;
    }
    if (
      searchTerm !== "" &&
      !(
        fullName.toLowerCase().includes(searchTerm) ||
        item.email.toLowerCase().includes(searchTerm)
      )
    ) {
      return false;
    }
    return true;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key !== "") {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (sortConfig.key === "department") {
        return sortConfig.direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (sortConfig.key === "roleId") {
        const roleIdA = a.role_id.user_type ? `${a.role_id.user_type}` : `Role`;
        const roleIdB = b.role_id.user_type ? `${b.role_id.user_type}` : `Role`;
        return sortConfig.direction === "asc"
          ? roleIdA.localeCompare(roleIdB)
          : roleIdB.localeCompare(roleIdA);
      }

      if (sortConfig.key === "userCost") {
        const userCostA =
          a.cost && a.typeOfContract === typeOfContract.Hourly_Rate
            ? `${a.cost}/hr (${a.payment_currency ? a.payment_currency : ""})`
            : `${a.cost} (${a.payment_currency ? a.payment_currency : ""})`;
        const userCostB =
          b.cost && b.typeOfContract === typeOfContract.Hourly_Rate
            ? `${b.cost}/hr (${b.payment_currency ? b.payment_currency : ""})`
            : `${b.cost} (${b.payment_currency ? b.payment_currency : ""})`;
        return sortConfig.direction === "asc"
          ? userCostA.localeCompare(userCostB)
          : userCostA.localeCompare(userCostB);
      }

      if (sortConfig.key === "first_name") {
        const nameA = a.first_name
          ? `${a.first_name} ${a.middle_name ? a.middle_name : ""} ${a.last_name
          }`
          : `${a.company_first_name
            ? a.company_first_name +
            (a.company_last_name && a.company_last_name)
            : a.company_name
          }`;
        const nameB = b.first_name
          ? `${b.first_name} ${b.middle_name ? b.middle_name : ""} ${b.last_name
          }`
          : `${b.company_first_name
            ? b.company_first_name +
            (b.company_last_name && b.company_last_name)
            : b.company_name
          }`;
        return sortConfig.direction === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }

      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  // const handleEditCost = (item, index) => {
  //   setCostEditableRow(index);
  //   setSelectedId(item._id);
  //   setBillableCostRateEdit(item.cost);
  // };

  const handleDeleteUser = async (item, index) => {
    // setIsDeleteLoading(true);
    setIsDeleteLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [item._id]: true,
    }));
    try {
      const response = await updateTeam.mutateAsync({
        userId: item._id,
        projectId: props.project._id,
      });

      const data = response.data;
      refetch();
      dispatch(setNotification(Date.now()));
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("User removed successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      global.config.slackMessage(error.toString());
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      refetch();
      dispatch(setProjectAdded());
      setIsDeleteLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [item._id]: false,
      }));
    }
  };

  const cancelCostChanges = () => {
    setCostEditableRow(-1);
  };

  const handleCostUpdateSubmit = async (e) => {
    e.preventDefault();
    if (billableCostRateEdit === "") {
      toast.error("Billable cost cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      billableCostRateEditRef.current.focus();
      return;
    }

    try {
      setIsUpdateLoading(true); // Set loading state to true
      const response = await updateBillableCostRateMutation.mutateAsync({
        selectedId,
        cost: billableCostRateEdit,
      });
      const data = response.data;

      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Cost rate updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // global.config.activityLog(window.location.href, 'Leave Type', `Leave Type updated successfully`);
        setCostEditableRow(-1);
        dispatch(setNotification(Date.now()));
        setSortedUserProfiles((prevState) => {
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, cost: billableCostRateEdit };
            }
            return item;
          });
          return updatedInvoiceView;
        });
      }
    } catch (error) {
      console.log(error); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsUpdateLoading(false); // Set loading state back to false
    }
  };

  const handleClickOutsideDropdown = (event) => {
    // const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    // if (!isClickInsideShowingDropdown) {
    //   setShowingDropdownOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  // const totalResults = sortedData.length;
  const pageCount = Math.ceil(sortedData.length / perPage); // Total number of pages
  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = sortedData.slice(offset, offset + perPage);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }

  return (
    <div className="member-main-outter table-top-project-client">
      <div class="table-head-outer">
        <div class="table-head">Costs per user</div>
        <div class="button-sec-invoice">
          {!props.project.is_archived && <NewTeamMembers />}
        </div>
      </div>
    
      <div className="time-table">
        <table>
          <thead className="head-tb-of-team-member">
            <tr>
              <th className="sortable-header" onClick={() => sortTable("first_name")}
                style={{ paddingRight: "44px", minWidth: "230px" }}
              >
                User{" "}
                {sortConfig.key === "first_name" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>

              <th className="sortable-header" onClick={() => sortTable("roleId")} >
                Role{" "}
                {sortConfig.key === "roleId" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>

              <th className="sortable-header"
              // onClick={() => sortTable("billableRate")}
              >
                Billable Rate{" "}
                {/* {sortConfig.key === "billableRate" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )} */}
              </th>

              <th className="sortable-header" onClick={() => sortTable("cost")}>
                Cost Rate /hr{" "}
                {sortConfig.key === "cost" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th className="sortable-header"></th>
            </tr>
          </thead>
          <tbody className="body-tb-of-team-member">
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentPageData.length > 0 ? (
                  <>
                    {currentPageData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="member-name">
                            {item.profile_image !== null &&
                              item.profile_image !== "null" ? (
                              <img
                                src={`/uploads/lowProfileImage/${item.profile_image}`}
                                alt={item.first_name}
                                className="member-image"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "/uploads/lowProfileImage/user_default.png";
                                }}
                              />
                            ) : (
                              <img
                                src={profile_image}
                                alt={item.first_name}
                                className="member-image"
                              />
                            )}
                            <span>
                              <TruncateUserName
                                textContent={
                                  item.first_name ? (
                                    <>
                                      {item.first_name}{" "}
                                      {item.middle_name &&
                                        item.middle_name !== "null"
                                        ? item.middle_name + " "
                                        : ""}
                                      {item.last_name}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {item.company_first_name
                                        ? `${item.company_first_name} ${item.company_last_name
                                          ? item.company_last_name
                                          : ""
                                        }`
                                        : `${item.company_name || ""}(Company)`}
                                    </>
                                  )
                                }
                              />
                            </span>
                          </div>
                        </td>
                        <td>{item.role_id.user_type}</td>

                        <td>
                          <ProjectCost
                            isProjectArchived={props.project.is_archived}
                            projectId={props.project._id}
                            clientPreferredCurrency={clientPreferredCurrency}
                            item={item}
                          />
                        </td>
                        {(user.role_id === userType.Super_Admin || user.role_id === userType.Admin || (appSettingsData && appSettingsData.app_user_cost_visibility)) ? (
                          item.typeOfContract === typeOfContract.Hourly_Rate ? (
                            <td>
                              <div className="edit-value-changebtn-tic">
                                {costEditableRow === item._id ? (
                                  <input
                                    type="number"
                                    value={billableCostRateEdit}
                                    onChange={(e) =>
                                      setBillableCostRateEdit(e.target.value)
                                    }
                                    ref={billableCostRateEditRef}
                                    onWheel={numberInputOnWheelPreventChange}
                                  />
                                ) : (
                                  // `${item.cost} (${item.payment_currency})`
                                  `${item.cost ?
                                    new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: clientPreferredCurrency
                                    }).format(
                                      ConvertHelper(item.payment_currency, clientPreferredCurrency, item.cost, forex)
                                    ) : new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: clientPreferredCurrency
                                    }).format(0.00)}`
                                )}
                                {costEditableRow === item._id && (
                                  <>
                                    <div className="hello-boom-open">
                                      {isUpdateLoading ? (
                                        <ThreeDots
                                          height="18"
                                          width="20"
                                          radius="9"
                                          color="#6479f9"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                      ) : (
                                        <>
                                          <button className="sep-pop-btn" onClick={handleCostUpdateSubmit}>
                                            <img src={ticyes} alt="yes" />
                                          </button>
                                          <button className="sep-pop-btn" onClick={() => cancelCostChanges()}>
                                            <img src={ticno} alt="no" />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="edit-value-changebtn-tic">
                                {costEditableRow === item._id ? (
                                  <input
                                    type="number"
                                    value={billableCostRateEdit}
                                    onChange={(e) =>
                                      setBillableCostRateEdit(e.target.value)
                                    }
                                    ref={billableRateEditRef}
                                    onWheel={numberInputOnWheelPreventChange}
                                  />
                                ) : (
                                  // `${(item.cost / parseInt(item?.monthly_hours)).toFixed(2)} (${item.payment_currency})`
                                  `${item.cost ?
                                    new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: clientPreferredCurrency
                                    }).format(
                                      ConvertHelper(item.payment_currency, clientPreferredCurrency, item.cost / parseInt(item?.monthly_hours), forex)
                                    ) : new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: clientPreferredCurrency
                                    }).format(0.00)}`
                                )}
                                {costEditableRow === item._id ? (
                                  <>
                                    <div className="hello-boom-open">
                                      {isUpdateLoading ? (
                                        <ThreeDots
                                          height="18"
                                          width="20"
                                          radius="9"
                                          color="#6479f9"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                      ) : (
                                        <>
                                          <button className="sep-pop-btn" onClick={handleCostUpdateSubmit}>
                                            <img src={ticyes} alt="yes" />
                                          </button>
                                          <button className="sep-pop-btn" onClick={() => cancelCostChanges()}>
                                            <img src={ticno} alt="no" />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                  // <button className="main-edit-btn-parent" onClick={() => handleEditCost(item, item._id)}><img src={editimgblue} alt="edit" />Edit</button>
                                )}
                              </div>
                            </td>
                          )
                        ) : (
                          <td>
                            <div className="edit-value-changebtn-tic blurred">
                              {`${new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: clientPreferredCurrency
                              }).format(0.00)}`}
                            </div>
                          </td>
                        )}

                        <td>
                          {isDeleteLoading[item._id] ? (
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            <>
                              <button
                                onClick={() => handleDeleteUser(item, item._id)}
                                disabled={props.project.is_archived}
                                style={{ cursor: props.project.is_archived ? "not-allowed" : "pointer", opacity: props.project.is_archived ? 0.6 : 1 }}
                              >
                                <img src={Delete} alt="Delete" />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6">
                      {!isLoading && (
                        <div className="outter-load-table">
                          No results found
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        {isLoading ? (
          <></>
        ) : (
          <>
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={selectedPage > 0 ? "< Back" : null}
                nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={"pagination maininteampag"}
                activeClassName={"active"}
                forcePage={selectedPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectTeamMembers;
