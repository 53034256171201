import React from "react";
import UserInviteHistory from "../../components/User/UserInviteHistory";

function InviteHistory() {
    return (
        <div className="react-outer">
            <UserInviteHistory />
        </div>
    )
}

export default InviteHistory;