import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import "./ProjectOverviewListingRecenInvoices.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { createDateConvert, dateConvert } from "../../../../helpers/dateHelper";
import NewSprintModal from '../../helpers/NewSprintModal';
import { useSelector } from "react-redux";
import moment from "moment";
import SprintViewModal from "./modals/SprintViewModal";
import StatusModal from "./modals/StatusModal";
import { useDispatch } from 'react-redux';
import { setSprintView, setSprintData, setSprintStatusView } from "../../../../features/projects/projectSlice";
import { BudgetedSprintHours, SprintCost, TrackedSprintHours } from '../../../../helpers/ProjectHelpers';
import { useGetProjectThreshold } from '../../../../features/projects/useProjects';
import { logout, reset } from "../../../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";

const SprintTable = (props) => {
    const [sprintModalOpen, setSprintModalOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { forex } = useSelector((state) => state.forex);
    const projectLoading = props.isLoadingProject;
    const preferredCurrency = props.project?.client_name?.preferred_currency || 'USD';
    const [timeThresholdData, setTimeThresholdData] = useState({});
    const [loadingRowIndex, setLoadingRowIndex] = useState(-1);
    const [budgetThresholdData, setBudgetThresholdData] = useState({});
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const user = useSelector((state) => state.auth.user);
    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const convertCreatedDate = (rowData) => {
        const timestamp = rowData.created_at;
        const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
        return formattedDate;
    }

    const ConvertDeliveryDate = (rowData) => {
        if (rowData.delivered_date && rowData.sprint_status === "Delivered") {
            const timestamp = rowData.delivered_date;
            return moment(timestamp).format('MMMM DD, YYYY');
        } else if (rowData.sprint_status === "In Progress") {
            return "In Progress";
        }
        else if (rowData.sprint_status === "Cancelled") {
            return "N/A";
        } else {
            return rowData.sprint_status;
        }
    };

    const ConvertStatus = (rowData) => {
        const isClickable = props.project?.is_archived === false;

        const getStatusElement = (status, className) => (
            <span
                className={className}
                style={{ cursor: isClickable ? "pointer" : "default", opacity: isClickable ? 1 : 0.6 }}
                onClick={isClickable ? () => handleOpenStatusModal(rowData) : undefined}
            >
                {status}
            </span>
        );

        if (rowData.delivered_date && rowData.sprint_status === "Delivered") {
            return getStatusElement("Delivered", "delivered");
        } else if (rowData.sprint_status === "In Progress") {
            return getStatusElement("In Progress", "in-progress");
        } else if (rowData.sprint_status === "Cancelled") {
            return getStatusElement("Cancelled", "cancelled");
        } else {
            return getStatusElement("Scheduled", "scheduled");
        }
    }

    function detailCTA(rowData) {
        return (
            <div className="detail-button">
                <button
                    className="cta-button"
                    onClick={() => handleDetailClick(rowData)}
                    disabled={props.project?.is_archived}
                    style={{
                        cursor: props.project?.is_archived ? "not-allowed" : "pointer",
                        opacity: props.project?.is_archived ? 0.5 : 1,
                    }}
                >
                    {loadingRowIndex === rowData._id ? (
                        <div className="outter-load-table">
                            <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        "Details"
                    )}
                </button>
            </div>
        );
    }

    const handleDetailClick = async (rowData) => {
        setSprintModalOpen(true);
        setLoadingRowIndex(rowData._id); // Show loading indicator for this row

        try {
            // Fetch Time and Budget thresholds in parallel
            const [timeResponse, budgetResponse] = await Promise.all([
                axios.post(
                    "/api/get/project/thresholds",
                    { projectId: rowData._id, thresholdType: "time" },
                    { headers: { Authorization: `Bearer ${token}` } }
                ),
                axios.post(
                    "/api/get/project/thresholds",
                    { projectId: rowData._id, thresholdType: "budget" },
                    { headers: { Authorization: `Bearer ${token}` } }
                ),
            ]);

            // Store threshold data
            const newTimeThresholdData = timeResponse.data;
            const newBudgetThresholdData = budgetResponse.data;

            setTimeThresholdData(newTimeThresholdData);
            setBudgetThresholdData(newBudgetThresholdData);

            // Prepare sprint data
            const sprintData = {
                ...rowData,
                project_name: props.project?.project_name,
                timeThresholdData: newTimeThresholdData?.data,
                budgetThresholdData: newBudgetThresholdData?.data,
                clientId: props.project?.client_name?._id
            };

            // Dispatch actions
            await dispatch(setSprintData(sprintData));
            await dispatch(setSprintView(true));
        } catch (error) {
            console.error("Error fetching thresholds:", error);

            if (error.response?.data?.status === "deleted") {
                handleDeletedUser();
            } else {
                alert("Something went wrong. Please try again.");
            }
        } finally {
            setLoadingRowIndex(-1); // Remove loading indicator
        }
    };

    // Handle user deletion scenario
    const handleDeletedUser = () => {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());

        toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        navigate("/");
    };


    // const TrackedHours = (rowData) => {
    //     console.log('track', rowData._id);
    // }

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    const [statusOpen, setStatusOpen] = useState(false);
    const [statusModalData, setStatusModalData] = useState(null);
    const handleOpenStatusModal = async (data) => {

        await dispatch(setSprintData(data));
        await dispatch(setSprintStatusView(true));
        //     setStatusOpen(!statusOpen);
        //    await setStatusModalData(data);

    };


    return (
        <>
            <div className="table-head-outer">
                <div className="table-head">
                    Sprints
                </div>
                {(projectLoading) ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>

                ) : (
                    props.project.is_archived === false && (
                        <NewSprintModal proId={props.project?._id} project={props.project} preferredCurrency={preferredCurrency} />
                    )
                )}
            </div>
            <div className="listing-recent-invoice-inner sprint-client-outer-section">
                {projectLoading ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>

                ) : (
                    <DataTable
                        value={props.project.sprints}
                        removableSort
                        tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
                        paginator={false}
                        paginatorClassName="recent-invoices-pagination"
                        rows={5}
                        emptyMessage="No sprints found"
                    >
                        <Column
                            field="sprint_name"
                            header="Sprint"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="budget"
                            header={`Budget (${preferredCurrency})`}
                            body={(rowData) => {
                                const { client_name, budget } = rowData;
                                if (preferredCurrency) {
                                    return new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: props.clientCurrency ? props.clientCurrency : "USD",
                                    }).format(ConvertHelper(preferredCurrency, props.clientCurrency ? props.clientCurrency : "USD", budget, forex));
                                } else {
                                    return new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: props.clientCurrency ? props.clientCurrency : "USD",
                                    }).format(ConvertHelper("USD", props.clientCurrency ? props.clientCurrency : "USD", budget, forex));
                                }
                            }}
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="totalHours"
                            header="Budgeted Hours"
                            body={BudgetedSprintHours}
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="project.total_hours"
                            header="Tracked Hours"
                            body={TrackedSprintHours}
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="cost"
                            header="Cost"
                            body={(rowData) => SprintCost(rowData, preferredCurrency || 'USD')}
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="project.created_at"
                            body={convertCreatedDate}
                            header="Date Created"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="delivered_date"
                            body={ConvertDeliveryDate}
                            header="Date Delivered"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="sprint_status"
                            header="Status"
                            body={ConvertStatus}
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="_id"
                            body={detailCTA}
                            style={{ paddingRight: "20px" }}
                        ></Column>
                    </DataTable>
                )}
            </div>
            <SprintViewModal preferredCurrency={preferredCurrency} />
            <StatusModal data={statusModalData} statusOpen={statusOpen} />
        </>
    );
};
export default SprintTable;