import './myteam.css';
import { Outlet } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userTypeConsts from '../../constants/userTypeConsts';
import { Link, useNavigate } from 'react-router-dom';
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { motion } from 'framer-motion';

function MyTeamTabModule() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [selectedOption, setSelectedOption] = useState("Overview");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url);
    setDropdownOpen(false);
  };
  const navigate = useNavigate();
  const optionDropdownRef = useRef(null);
  const handleSelectChange = (event) => {
    const selectedPage = event.target.value;
    navigate(selectedPage);
  };

  const getOptionText = (path) => {
    switch (path) {
      case "/user/myteam/list":
        return "Overview";
      case "/user/myteam/department":
        return "Departments";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Set the initial value of the select to match the current URL path
    const currentPath = window.location.pathname;
    setSelectedOption(getOptionText(currentPath));
    const selectElement = document.querySelector('.mobile-tab-select');
    if (selectElement) {
      selectElement.value = currentPath;
    }
  }, []);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideOptionDropdown = optionDropdownRef.current && optionDropdownRef.current.contains(event.target);
    if (!isClickInsideOptionDropdown) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);


  dispatch(setHeaderText("My Team"));

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <div>
      <div className='tab-outer-time'>
        <div className='tab-mobile-select'>
          <h4>Select an option</h4>
          <div className="custom-dropdown-user-new-outer">
            <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
              {selectedOption}
            </div>
            {dropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  <li onClick={() => handleOptionClick("Overview", "/user/myteam/list")}>Overview</li>
                  <li onClick={() => handleOptionClick("Departments", "/user/myteam/department")}>Departments</li>
                 
                </ul>
              </div>
            )}
          </div>
        </div>


        <ul className="tabs">
          <li>
            <Link to="/user/myteam/list" className={window.location.pathname.startsWith('/user/myteam/list') ? 'active' : ''}>
              Overview
            </Link>
          </li>

          <li>
            <Link to='/user/myteam/department' className={window.location.pathname.startsWith('/user/myteam/department') ? 'active' : ''}>
              Departments
            </Link>
          </li>
        </ul>

        <div className="tabs-content">
          <div className='tab-inner-time'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyTeamTabModule;
