import axios from "axios";
import Modal from "react-modal";
import "../../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import userType from "../../../../constants/userType.js";
import { useGetActiveTeams, useGetTeams } from "../../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import { useFetchClientProjectDetails, useFetchClientSprintDetails } from "../../../../components/Clients/ClientInvoice/useClientInvoice.jsx";


const NewAlertsModal = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);

  const [thresholdFor, setThresholdFor] = useState("");
  const [thresholdForIsValid, setThresholdForIsValid] = useState(false);
  const [thresholdDropdownOpen, setThresholdDropdownOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [recordDropdownOpen, setRecordDropdownOpen] = useState(false);
  const [recordSearchTerm, setRecordSearchTerm] = useState("");
  const [sprintId, setSprintId] = useState("");
  const [sprintDropdownOpen, setSprintDropdownOpen] = useState("");
  const [sprintSearchTerm, setSprintSearchTerm] = useState("");
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeLoading, setTimeLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [budgetThreshold, setBudgetThreshold] = useState(0);
  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);
  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [recordIsValid, setRecordIsValid] = useState(false);
  const [sprintIdIsValid, setSprintIdIsValid] = useState(false);
  const recordNameRef = useRef(null);
  const sprintNameRef = useRef(null);
  const thresholdForRef = useRef(null);
  const recordDropdownRef = useRef(null);
  const sprintDropdownRef = useRef(null);
  const thresholdDropdownRef = useRef(null);

  const [shouldFetchProject, setShouldFetchProject] = useState(false);

  const token = useSelector(
    (state) => state.auth.token
  )
  const { data: teams = [], isLoading: teamLoading, isError: teamError, } = useGetActiveTeams(token);
  const { data: projects = [], isLoading, refetch: refetchProjects } = useFetchClientProjectDetails(id, token);
  const { data: sprints = [], isLoading: isSprintLoading, refetch: refetchSprints } = useFetchClientSprintDetails(id, token);

  const handleOpenAlertModal = () => {
    refetchProjects();
    refetchSprints();
    setIsProjectModalOpen(true);
  };
  const handleCloseAlertModal = () => {
    setIsProjectModalOpen(false);
    setThresholdFor("");
    setThresholdForIsValid(false);
    setSelectedRecord("");
    setRecordIsValid(false);
    setTimeThreshold(0);
    setSelectedTimeThresholdTeamsEdit([]);
    setBudgetThreshold(0);
    setSelectedBudgetThresholdTeamsEdit([]);
    setTimeChecked(false);
    setBudgetChecked(false);
  };

  const handleToggleThresholdDropdown = () => {
    setThresholdDropdownOpen(!thresholdDropdownOpen);
  };

  const handleToggleProjectDropdown = () => {
    setRecordSearchTerm("");
    setRecordDropdownOpen(!recordDropdownOpen);
  };

  const handleToggleSprintDropdown = () => {
    setSprintSearchTerm("");
    setSprintDropdownOpen(!sprintDropdownOpen);
  };

  const handleRecordSearchInputChange = (e) => {
    setRecordSearchTerm(e.target.value);
  };

  const handleSprintSearchInputChange = (e) => {
    setSprintSearchTerm(e.target.value);
  };

  const filteredProjects = projects
    .filter((project) =>
      project.project_name
        .toLowerCase()
        .includes(recordSearchTerm.toLowerCase())
    )
    .filter((project) =>
      !project.is_archived
    )
    .filter((project) =>
      thresholdFor === "projects"
        ? !props.projectOrSprintData.some(
          (existingProject) => existingProject._id === project._id
        )
        : true
    )
    .sort((a, b) =>
      a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase())
    );

  const filteredSprints = sprints
    .filter((sprint) =>
      sprint.sprint_name
        .toLowerCase()
        .includes(sprintSearchTerm.toLowerCase())
    )
    .filter((sprint) =>
      thresholdFor === "sprints"
        ? sprint.project._id === selectedRecord
        : true
    )
    .filter((sprint) =>
      thresholdFor === "sprints"
        ? !props.projectOrSprintData.some(
          (existingSprint) => existingSprint._id === sprint._id
        )
        : true
    )
    .sort((a, b) =>
      a.sprint_name.toLowerCase().localeCompare(b.sprint_name.toLowerCase())
    );

  const filteredProjectOrSprintData = props.projectOrSprintData.filter(
    (project) => !filteredProjects.some(filteredProject => filteredProject._id === project._id)
  );

  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };

  const handleTimeCheckboxChange = async () => {
    const newTimeChecked = !timeChecked;
    setTimeChecked(newTimeChecked);

    if (newTimeChecked) {
      if (thresholdFor === "projects" && timeThreshold === 0 && selectedTimeThresholdTeamsEdit.length === 0) {
        setTimeLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "time" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setTimeThreshold(data?.thresholdPercentage || 0);
          setSelectedTimeThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setTimeLoading(false); // Stop loading
        }
      }
    } else {
      setTimeThreshold(timeThreshold);
      setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
    }
  };

  const handleBudgetCheckboxChange = async () => {
    const newBudgetChecked = !budgetChecked;
    setBudgetChecked(newBudgetChecked);

    if (newBudgetChecked) {
      if (thresholdFor === "projects" && budgetThreshold === 0 && selectedBudgetThresholdTeamsEdit.length === 0) {
        setBudgetLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "budget" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setBudgetThreshold(data?.thresholdPercentage || 0);
          setSelectedBudgetThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setBudgetLoading(false); // Stop loading
        }
      }
    } else {
      setBudgetThreshold(budgetThreshold);
      setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
    }
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };

  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectTimeThresholdAllTeam(selectedTimeThresholdTeamsEdit.length === teams.length);
      // if (selectedTimeThresholdTeamsEdit.length === teams.length) {
      //     setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedTimeThresholdTeamsEdit, teams]);

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
      // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
      //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedBudgetThresholdTeamsEdit, teams]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideProjectDropdown = recordDropdownRef.current && recordDropdownRef.current.contains(event.target);
    const isClickInsideSprintDropdown = sprintDropdownRef.current && sprintDropdownRef.current.contains(event.target);
    const isClickInsideThresholdDropdown = thresholdDropdownRef.current && thresholdDropdownRef.current.contains(event.target)
    // const isClickInsideSprintProjectDropdown = sprintrecordDropdownRef.current && sprintrecordDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintProjectDropdown) {
    //   setSprintProjectDropdownOpen(false);
    // }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideProjectDropdown) {
      setRecordDropdownOpen(false);
    }
    if (!isClickInsideSprintDropdown) {
      setSprintDropdownOpen(false);
    }
    if (!isClickInsideThresholdDropdown) {
      setThresholdDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleThresholdAlertSubmit = async () => {
    setIsSubmitLoading(true);
    setThresholdForIsValid(false);
    setRecordIsValid(false);

    if (selectedRecord === "" || selectedRecord === null || thresholdFor === "") {
      if (thresholdFor === "") {
        setThresholdForIsValid(true);
        thresholdForRef.current.focus();
        thresholdForRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedRecord || selectedRecord === "" || selectedRecord === null) {
        setRecordIsValid(true);
        recordNameRef.current.focus();
        recordNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        setIsSubmitLoading(false);
        return;
      }

      toast.error("Please fill all required fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (thresholdFor === "sprints" && !sprintId) {
      setSprintIdIsValid(true);
      sprintNameRef.current.focus();
      sprintNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setIsSubmitLoading(false);
      return;
    }

    if (!timeChecked && !budgetChecked) {
      toast.error("Please select at least one type of threshold alert (Time or Budget)", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsSubmitLoading(false);
      return;
    }

    const recordId = thresholdFor === "projects" ? selectedRecord : sprintId;
    const selectedSprint = sprints.find((sprint) => sprint._id === sprintId);

    if (timeChecked) {
      if (thresholdFor === "sprints" && (!selectedSprint || !selectedSprint.totalHours || selectedSprint.totalHours <= 0)) {
        toast.error("Selected sprint does not have valid total hours", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    if (budgetChecked) {
      if (thresholdFor === "sprints" && (!selectedSprint || !selectedSprint.budget || selectedSprint.budget <= 0)) {
        toast.error("Selected sprint does not have a valid budget", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;

      if (timeChecked) {
        response = await axios.post(
          "/api/project/sprint/threshold",
          {
            thresholdFor: thresholdFor,
            recordId,
            clientId: props.clientId,
            thresholdType: "time",
            thresholdPercentage: timeThreshold,
            alertTo: selectedTimeThresholdTeamsEdit,
            thresholdTimeStatus: timeChecked,
          },
          config
        );
      }

      if (budgetChecked) {
        response = await axios.post(
          "/api/project/sprint/threshold",
          {
            thresholdFor: thresholdFor,
            recordId,
            clientId: props.clientId,
            thresholdType: "budget",
            thresholdPercentage: budgetThreshold,
            alertTo: selectedBudgetThresholdTeamsEdit,
            thresholdBudgetStatus: budgetChecked,
          },
          config
        );
      }

      if (response.data.status === "success") {
        props.refetchAlerts();
        toast.success("Threshold updated successfully!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(response.data.message || "Failed to update threshold.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error adding project:", error);
      global.config.activityLog(window.location.href, "Project", `Failed to add Project (${selectedRecord})`);
      toast.error("An error occurred while adding the project", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      handleCloseAlertModal();
      setShouldFetchProject((prev) => !prev);
      setIsSubmitLoading(false);
      dispatch(setProjectAdded());
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="button-sec-invoice">
      <a className="new-client" href="#" onClick={handleOpenAlertModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Alert
      </a>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal project-modal"
          isOpen={isProjectModalOpen}
          onRequestClose={handleCloseAlertModal}
          contentLabel="New Project"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseAlertModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div>
              <div className="modal-client-head">
                <h2 className="">New Alert</h2>
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    <label htmlFor="thresholdFor" className="form-label">
                      Type
                    </label>

                    <div
                      className={`custom-dropdown-user-new-outer custom-dropdown-select ${thresholdDropdownOpen ? "open" : ""}`}
                      ref={thresholdDropdownRef}
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        onClick={handleToggleThresholdDropdown}
                        style={{ cursor: "pointer" }}
                        ref={thresholdForRef}
                      >
                        {thresholdFor ? thresholdFor.charAt(0).toUpperCase() + thresholdFor.slice(1) : "Select"}
                      </div>
                      {/* , "sprints" */}
                      {thresholdDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="threshold-list">
                            {["projects", "sprints"].map((option) => (
                              <li
                                key={option}
                                className={thresholdFor === option ? "selected-item" : ""}
                                onClick={() => {
                                  setThresholdFor(option);
                                  setSelectedRecord(null);
                                  setSprintId(null);
                                  setThresholdDropdownOpen(false);
                                }}
                              >
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {thresholdForIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Type Field Cannot be empty
                      </span>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="client" className="form-label">
                      Project Name
                    </label>

                    <div
                      className={`custom-dropdown-user-new-outer custom-dropdown-select ${recordDropdownOpen ? "open" : ""}`}
                      ref={recordDropdownRef}
                      style={{ cursor: thresholdFor ? "pointer" : "not-allowed", opacity: thresholdFor ? 1 : 0.5 }}
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        // onClick={handleToggleProjectDropdown}
                        onClick={thresholdFor ? handleToggleProjectDropdown : undefined}
                        ref={recordNameRef}
                      // style={{ cursor: "pointer" }}
                      >
                        {selectedRecord
                          ? projects.find((project) => project._id === selectedRecord)?.project_name
                          : "Select"}
                      </div>
                      {recordDropdownOpen && thresholdFor && (
                        <div className="custom-dropdown-new">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Search..."
                            value={recordSearchTerm}
                            onChange={handleRecordSearchInputChange}
                          />
                          <ul className="project-list">
                            {/* {filteredProjects.length > 0 ? (
                              filteredProjects.map((project) => ( */}
                            {filteredProjects.length > 0 ? (
                              filteredProjects.map((project) => (
                                <li
                                  key={project._id}
                                  className={selectedRecord === project._id ? "selected-item" : ""}
                                  onClick={() => {
                                    setSelectedRecord(project._id);
                                    setRecordDropdownOpen(false);
                                    setSprintId(null); // Reset sprint selection when project changes
                                  }}
                                >
                                  {project.project_name}
                                </li>
                              ))
                            ) : (
                              <li className="no-data-message">No projects available</li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                    {recordIsValid && (
                      <span className="text-red-500 text-xs italic">Project Field Cannot be empty</span>
                    )}
                  </div>

                  {thresholdFor === "sprints" && selectedRecord && (
                    <div className="form-field-client">
                      <label htmlFor="sprint" className="form-label">Sprint Name</label>

                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${sprintDropdownOpen ? "open" : ""}`}
                        ref={sprintDropdownRef}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="selected-option custom-dropdown-user-new"
                          onClick={handleToggleSprintDropdown}
                          ref={sprintNameRef}
                        >
                          {sprintId
                            ? sprints.find((sprint) => sprint._id === sprintId)?.sprint_name
                            : "Select"}
                        </div>

                        {sprintDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={sprintSearchTerm}
                              onChange={handleSprintSearchInputChange}
                            />
                            <ul className="sprint-list">
                              {filteredSprints.length > 0 ? (
                                filteredSprints
                                  .map((sprint) => (
                                    <li
                                      key={sprint._id}
                                      className={sprintId === sprint._id ? "selected-item" : ""}
                                      onClick={() => {
                                        setSprintId(sprint._id);
                                        setSprintDropdownOpen(false);
                                      }}
                                    >
                                      {sprint.sprint_name}
                                    </li>
                                  ))
                              ) : (
                                <li className="no-data-message">No sprints available</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>

                      {sprintIdIsValid && (
                        <span className="text-red-500 text-xs italic">Sprint Field Cannot be empty</span>
                      )}
                    </div>
                  )}

                  <div className="form-field-client">
                    <label htmlFor="projectTimeframe" className="form-label">
                      Project Alerts
                    </label>
                    <div className="project-alert-outer">
                      {/* Time Alert */}
                      {/* onChange={() => setTimeChecked(!timeChecked)} */}
                      <div className="alert-section for-time-outer">
                        <label className="alert-label">
                          <input type="checkbox" checked={timeChecked} onChange={handleTimeCheckboxChange} />
                          <span>For time</span>
                        </label>

                        <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                          <div className="input-group">
                            <span>If project reaches</span>
                            <input
                              type="number"
                              value={timeThreshold}
                              // onChange={(e) => setTimeThreshold(e.target.value)}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                  setTimeThreshold(value);
                                }
                              }}
                              onWheel={numberInputOnWheelPreventChange}
                              min="0"
                              max="100"
                              step="1"
                            />
                            <span>% of estimate</span>
                          </div>

                          <div className="dropdown-group">
                            <span>Alert</span>
                            <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                              <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                                <div className="selected-options1">
                                  {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                    <div className="placeholder">Select</div>
                                  ) : (
                                    <>
                                      <div className="selected-option1">
                                        {(() => {
                                          const roles = {
                                            Super_Admin: "All Super Admin",
                                            Admin: "All Admin",
                                            Project_Manager: "All Project Manager",
                                            Team_Manager: "All Team Manager",
                                            User: "All Users",
                                          };

                                          // Check if all users of a specific role are selected
                                          for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                            const roleUsers = teams
                                              .filter((team) => team.role_id === userType[roleKey])
                                              .map((team) => team._id);

                                            if (
                                              roleUsers.length > 0 &&
                                              roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                              selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                            ) {
                                              return roleLabel;
                                            }
                                          }

                                          // If all team members are selected
                                          if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                            return "All Team";
                                          }

                                          // Get the first selected user name
                                          const firstSelectedUser =
                                            teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                              ? (() => {
                                                const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                                return user.first_name
                                                  ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                  : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                              })()
                                              : "No Users selected";

                                          return (
                                            <>
                                              {firstSelectedUser}
                                              {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                <div className="selected-option-count">
                                                  {selectedTimeThresholdTeamsEdit.length - 1}+
                                                </div>
                                              )}
                                            </>
                                          );
                                        })()}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              {isTimeThresholdTeamDropdownOpen && (
                                <div className="dropdown-list1">
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectTimeThresholdAllTeam}
                                      onChange={handleSelectTimeThresholdAllTeamChange}
                                    />
                                    All Teams
                                  </label>
                                  {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                    .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                    .map((role) => (
                                      <label className="checkbox-label1" key={role}>
                                        <input
                                          type="checkbox"
                                          checked={teams
                                            .filter((team) => team.role_id === userType[role])
                                            .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                          onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                        />
                                        All {role.replace("_", " ")}
                                      </label>
                                    ))}
                                  {teams.map((team) => (
                                    <label className="checkbox-label1" key={team._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                        onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                      />
                                      {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Budget Alert */}
                      <div className="alert-section for-budget-outer">
                        <label className="alert-label">
                          <input type="checkbox" checked={budgetChecked} onChange={handleBudgetCheckboxChange} />
                          <span>For budget</span>
                        </label>

                        <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                          <div className="input-group">
                            <span>If project reaches</span>
                            <input
                              type="number"
                              value={budgetThreshold}
                              // onChange={(e) => setBudgetThreshold(e.target.value)}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                  setBudgetThreshold(value);
                                }
                              }}
                              onWheel={numberInputOnWheelPreventChange}
                              min="0"
                              max="100"
                              step="1"
                            />
                            <span>% of estimate</span>
                          </div>

                          <div className="dropdown-group">
                            <span>Alert</span>
                            <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                              <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                                <div className="selected-options1">
                                  {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                    <div className="placeholder">Select</div>
                                  ) : (
                                    <>
                                      <div className="selected-option1">
                                        {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                          <div className="placeholder">Select</div>
                                        ) : (
                                          <>
                                            <div className="selected-option1">
                                              {(() => {
                                                const roles = {
                                                  Super_Admin: "All Super Admin",
                                                  Admin: "All Admin",
                                                  Project_Manager: "All Project Manager",
                                                  Team_Manager: "All Team Manager",
                                                  User: "All Users",
                                                };

                                                // Check if all users of a specific role are selected
                                                for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                  const roleUsers = teams
                                                    .filter((team) => team.role_id === userType[roleKey])
                                                    .map((team) => team._id);

                                                  if (
                                                    roleUsers.length > 0 &&
                                                    roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                    selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                  ) {
                                                    return roleLabel;
                                                  }
                                                }

                                                // If all team members are selected
                                                if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                                  return "All Team";
                                                }

                                                // Get the first selected user name
                                                const firstSelectedUser =
                                                  teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                    ? (() => {
                                                      const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                      return user.first_name
                                                        ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                        : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                    })()
                                                    : "No Users selected";

                                                return (
                                                  <>
                                                    {firstSelectedUser}
                                                    {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                      <div className="selected-option-count">
                                                        {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                      </div>
                                                    )}
                                                  </>
                                                );
                                              })()}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              {isBudgetThresholdTeamDropdownOpen && (
                                <div className="dropdown-list1">
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectBudgetThresholdAllTeam}
                                      onChange={handleSelectBudgetThresholdAllTeamChange}
                                    />
                                    All Teams
                                  </label>
                                  {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                    .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                    .map((role) => (
                                      <label className="checkbox-label1" key={role}>
                                        <input
                                          type="checkbox"
                                          checked={teams
                                            .filter((team) => team.role_id === userType[role])
                                            .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                          onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                        />
                                        All {role.replace("_", " ")}
                                      </label>
                                    ))}
                                  {teams.map((team) => (
                                    <label className="checkbox-label1" key={team._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                        onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                      />
                                      {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isSubmitLoading ?
                    (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button type="button" onClick={handleThresholdAlertSubmit} className="submit-client">
                        Create Alert
                      </button>
                    )}
                </form>
              </div>
            </div>

          </div>
        </Modal>
      </div>
    </div>
  )
}

export default NewAlertsModal;