import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "../OnboardingCompanyDetails/CompanyDetaislForm.css";
import { useParams } from "react-router-dom";
import { DatePicker } from "rsuite";
import subDays from "date-fns/subDays";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from 'react-phone-number-input';
import Countries from "../../../../src/constants/countries.js";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import { addressValidation, cityValidation, mobileNumberValidation, postalCodeValidation, passwordValidation, passportIdValidation, nameValidation, dobValidation, emailValidator, taxIdvalidator } from "./formValidations.js";
import {
  setOnboardingPersonalSecond,
  setOnboardingPersonalSecurity,
  setPage,
  setBPage,
  setVPage,
  setOnboardingPersonalFirst,
  setOnboardingBusinessFirst,
  setOnboardingBusinessSecond,
  setOnboardingBusinessThird,
  setOnboardingBusinessSecurity,
  setOnboardingVendorFirst,
  setOnboardingVendorSecond,
} from "../../../features/onBoard/onBoardSlice.js";
// import CompanyDetailsForm from "../OnboardingCompanyDetails/CompanyDetaislForm.jsx";
function CompanyAccountInformation({ email }) {
  const uType = useParams();
  const dispatch = useDispatch();
  const personalFirst = useSelector((state) => state.onBoard.personalFirst);
  const personalTwo = useSelector((state) => state.onBoard.personalTwo);
  const personalSecurity = useSelector(
    (state) => state.onBoard.personalSecurity
  );
  const page = useSelector((state) => state.onBoard.page);
  const pageB = useSelector((state) => state.onBoard.pageB);
  const pageV = useSelector((state) => state.onBoard.pageV);

  const page1 = useSelector((state) => state.onBoard.page1);
  const page2 = useSelector((state) => state.onBoard.page2);
  const page3 = useSelector((state) => state.onBoard.page3);
  const page4 = useSelector((state) => state.onBoard.page4);
  const [personalFirstlength, setPersonalFirstlength] = useState(0);
  const [personalTwolength, setPersonalTwolength] = useState(0);
  const [personalSecuritylength, setPersonalSecuritylength] = useState(0);
  const [securityButton, setSecurityButton] = useState(true);
  const [existMessage, setExistMessage] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const USER_TYPE_PERSONAL = "personal";
  const USER_TYPE_BUSINESS = "business";
  const USER_TYPE_VENDOR = "vendor";
  const ZERO = 0;
  const ONE = 1;
  const TWO = 2;
  const THREE = 3;
  const FOUR = 4;
  const FIVE = 5;
  const SIX = 6;
  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const [formPersonalData, setFormPersonalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    idNumber: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
    phoneNo: "",
  });

  const [formData, setFormData] = useState({
    address: "",
    city: "",
    country: "",
    postalCode: "",
    mobileNo: "",
  });

  const [formSecurityData, setFormSecurityData] = useState({
    password: "",
    confirmPassword: "",
  });

  // business

  const [formBusinessCompanyData, setFormBusinessCompanyData] = useState({
    commercialName: "",
    legalEntityName: "",
    email: "",
    taxId: "",
  });

  const handleBusinessCompanyChange = (e) => {
    const { name, value } = e.target;
    setFormBusinessCompanyData({
      ...formBusinessCompanyData,
      [name]: value,
    });
  };

  const [formBusinessAccountData, setFormBusinessAccountData] = useState({
    address: "",
    city: "",
    country: "",
    postalCode: "",
    mobileNo: "",
  });

  const handleBusinessAccountChange = (e) => {
    const { name, value } = e.target;
    setFormBusinessAccountData({
      ...formBusinessAccountData,
      [name]: value,
    });
  };

  const handleBusinessMobileAccountChange = (value, name) => {
    setFormBusinessAccountData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [formBusinessPersonalData, setFormBusinessPersonalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    idNumber: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
    phoneNo: "",
  });

  const handleBusinessPersonalChange = (e) => {
    const { name, value } = e.target;
    setFormBusinessPersonalData({
      ...formBusinessPersonalData,
      [name]: value,
    });
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    const currentDate = new Date();

    const initialDate = new Date(date);

    if (date > currentDate) {
      // If the selected date is in the future, show an error message
      toast.error("Date of Birth cannot be in the future", {
        position: "bottom-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // If the date is valid, set the selected date and update form data
      setSelectedDate(date);
      setFormBusinessPersonalData({
        ...formBusinessPersonalData,
        dob: date,
        email: email,
      });

      setFormPersonalData({
        ...formPersonalData,
        dob: date,
        email: email,
      });


    }

  };

  // end Business

  // vendor

  const [formVendorCompanyData, setFormVendorCompanyData] = useState({
    commercialName: "",
    legalEntityName: "",
    email: "",
    taxId: "",
  });

  const handleVendorCompanyChange = (e) => {
    const { name, value } = e.target;
    setFormVendorCompanyData({
      ...formVendorCompanyData,
      [name]: value,
      email: email,
    });
  };

  const [formVendorAccountData, setFormVendorAccountData] = useState({
    address: "",
    city: "",
    country: "",
    postalCode: "",
    mobileNo: "",
  });

  const handleVendorAccountChange = (e) => {
    const { name, value } = e.target;
    setFormVendorAccountData({
      ...formVendorAccountData,
      [name]: value,
    });
  };

  const handleVendorMobileAccountChange = (value, name) => {
    setFormVendorAccountData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // end Vendor

  const handlePersonalChange = (e) => {
    const { name, value } = e.target;
    setFormPersonalData({
      ...formPersonalData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMobileChange = (value, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSecurityChange = (e) => {
    const { name, value } = e.target;
    setFormSecurityData({
      ...formSecurityData,
      [name]: value,
    });
  };

  // personal form actions

  const handleSubmitPersonalFirst = (event) => {
    event.preventDefault();

    if (passportIdValidation(formPersonalData.passportId) && nameValidation(formPersonalData.firstName) && nameValidation(formPersonalData.lastName) && dobValidation(selectedDate)) {

      dispatch(setOnboardingPersonalFirst(formPersonalData));
      dispatch(setPage(2));

    }
  };

  const handleAccountSubmit = (e) => {
    e.preventDefault();
    // addressValidation(formData.address);
    
    if (country && addressValidation(formData.address) && cityValidation(formData.city) && mobileNumberValidation(formData.mobileNo)) {
      dispatch(setPage(0));
      dispatch(setOnboardingPersonalSecond(formData));
      dispatch(setPage(3));
    }

    if (!country) {
      toast.error("Please select Country", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSecuritySubmit = async (e) => {
    e.preventDefault();


    if (formSecurityData.password && formSecurityData.confirmPassword && passwordValidation(formSecurityData.password)) {

      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setVPage(0));
      dispatch(setOnboardingPersonalSecurity(formSecurityData));

      // console.log("uType.usertype", uType.usertype);

      if (uType.usertype === "personal") {
        try {
          const response = await axios
            .post(
              `/api/onboard/personal`,
              {
                firstName: formPersonalData.firstName,
                lastName: formPersonalData.lastName,
                email: email,
                dob: selectedDate,
                address: formData.address,
                city: formData.city,
                country: formData.country,
                postalCode: formData.postalCode,
                mobileNo: formData.mobileNo,
                password: formSecurityData.password,
                confirmPassword: formSecurityData.confirmPassword,
                passportNo: formPersonalData.passportId,
              },
              {
                headers: {
                  // Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.status === "added") {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                return;
              } else {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                setExistMessage(true);
                return;
              }
            });
        } catch (e) {
          console.log(e);
        }
      } else if (uType.usertype === "business") {
        dispatch(setBPage(5));
        dispatch(setVPage(4));
        dispatch(setPage(4));
        try {
          const response = await axios
            .post(
              `/api/onboard/business`,
              {
                firstName: formBusinessPersonalData.firstName,
                lastName: formBusinessPersonalData.lastName,
                email: formBusinessPersonalData.email,
                dob: formBusinessPersonalData.dob,

                address: formBusinessPersonalData.address,
                city: formBusinessPersonalData.city,
                country: formBusinessPersonalData.country,
                postalCode: formBusinessPersonalData.postalCode,
                mobileNo: formBusinessPersonalData.mobileNo,
                password: formSecurityData.password,
                confirmPassword: formSecurityData.confirmPassword,

                companyPersonalAddress: formBusinessAccountData.address,
                companyPersonalCity: formBusinessAccountData.city,
                companyPostalCode: formBusinessAccountData.postalCode,
                companyMobileNo: formBusinessAccountData.mobileNo,
                idNumber: formBusinessPersonalData.idNumber,

                commercialName: formBusinessCompanyData.commercialName,
                legalEntityName: formBusinessCompanyData.legalEntityName,
                companyEmail: formBusinessCompanyData.companyEmail,
                taxId: formBusinessCompanyData.taxId,
              },
              {
                headers: {
                  // Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.status === "added") {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                return;
              } else {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                setExistMessage(true);
                return;
              }
            });
        } catch (e) {
          console.log(e);
        }
      } else if (uType.usertype === "vendor") {
        try {
          dispatch(setBPage(5));
          dispatch(setVPage(4));
          dispatch(setPage(4));

          const response = await axios
            .post(
              `/api/onboard/vendor`,
              {
                commercialName: formVendorCompanyData.commercialName,
                legalEntityName: formVendorCompanyData.legalEntityName,
                companyEmail: formVendorCompanyData.email,
                taxId: formVendorCompanyData.taxId,

                address: formVendorAccountData.address,
                city: formVendorAccountData.city,
                country: formVendorAccountData.country,
                postalCode: formVendorAccountData.postalCode,
                phoneNo: formVendorAccountData.mobileNo,

                password: formSecurityData.password,
                confirmPassword: formSecurityData.confirmPassword,
              },
              {
                headers: {
                  // Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.status === "added") {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                return;
              } else {
                dispatch(setBPage(5));
                dispatch(setVPage(4));
                dispatch(setPage(4));
                setExistMessage(true);
                return;
              }
            });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    setPersonalFirstlength(Object.keys(personalFirst).length);
    setPersonalTwolength(Object.keys(personalTwo).length);
    setPersonalSecuritylength(Object.keys(personalSecurity).length);
  }, [personalFirst, personalTwo, personalSecurity]);

  useEffect(() => {
    if (formSecurityData.password !== formSecurityData.confirmPassword) {
      setSecurityButton(true);
    } else {
      setSecurityButton(false);
    }
  }, [formSecurityData]);

  // personal form actions

  // Business for actions

  const handleSubmitBusinessCompanyDetailsSubmit = (e) => {
    e.preventDefault();
    if (nameValidation(formBusinessCompanyData.commercialName) && nameValidation(formBusinessCompanyData.legalEntityName)) {
      dispatch(setVPage(0));
      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setOnboardingBusinessFirst(formBusinessCompanyData));
      dispatch(setBPage(2));
    }
  };

  const handleSubmitBusinessAccountInfoSubmit = (e) => {
    e.preventDefault();
    if (country && addressValidation(formBusinessAccountData.address) && cityValidation(formBusinessAccountData.city) && postalCodeValidation(formBusinessAccountData.postalCode) && mobileNumberValidation(formBusinessAccountData.mobileNo)) {
      dispatch(setVPage(0));
      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setOnboardingBusinessSecond(formBusinessAccountData));
      dispatch(setBPage(3));
    }

    if (!country) {
      toast.error("Please select Country", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitBusinessPersonalDetailsSubmit = (e) => {
    e.preventDefault();

    if (country && nameValidation(formBusinessPersonalData.firstName) && nameValidation(formBusinessPersonalData.lastName) && passportIdValidation(formBusinessPersonalData.idNumber) && addressValidation(formBusinessPersonalData.address) && cityValidation(formBusinessPersonalData.city) && postalCodeValidation(formBusinessPersonalData.postalCode) && mobileNumberValidation(formBusinessPersonalData.phoneNo)) {

      dispatch(setVPage(0));
      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setOnboardingBusinessThird(formBusinessPersonalData));
      dispatch(setBPage(4));

    }

    if (!country) {
      toast.error("Please select Country", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Business  form actions

  // Vendor for actions

  const handleSubmitVendorCompanyDetailsSubmit = (e) => {
    e.preventDefault();

    if (nameValidation(formVendorCompanyData.commercialName) && nameValidation(formVendorCompanyData.legalEntityName) && nameValidation(formVendorCompanyData.legalEntityName)) {
      dispatch(setVPage(0));
      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setOnboardingVendorFirst(formVendorCompanyData));
      dispatch(setVPage(2));
    }
  };

  const handleSubmitVendorAccountInfoSubmit = (e) => {
    e.preventDefault();
    if (country && addressValidation(formVendorAccountData.address) && cityValidation(formVendorAccountData.city) && postalCodeValidation(formVendorAccountData.postalCode) && mobileNumberValidation(formVendorAccountData.mobileNo)) {
      dispatch(setVPage(0));
      dispatch(setPage(0));
      dispatch(setBPage(0));
      dispatch(setOnboardingVendorSecond(formVendorAccountData));
      dispatch(setVPage(3));
    }

    if (!country) {
      toast.error("Please select Country", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Vendor  form actions
  const countryDropdownRef = useRef(null);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [country, setCountry] = useState("");
  const filteredCountries = countriesList.filter((country) => {
    return country.country
      ?.toLowerCase()
      .includes(countrySearchTerm?.toLowerCase());
  });

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  useEffect(() => {
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, []);

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className="onboarding-detail-outer">
      {/* personal form */}

      {(uType.usertype === USER_TYPE_PERSONAL &&
        page1 === ZERO &&
        page2 === ZERO &&
        page3 === ZERO &&
        page !== THREE &&
        page !== TWO) ||
        page === ONE ? (
        <>
          <div className="company-detail-title">Your Profile</div>
          <form onSubmit={handleSubmitPersonalFirst}>
            <div className="form-group">
              <label htmlFor="firstNameInput">First Name*</label>
              <input
                type="text"
                id="firstNameInput"
                name="firstName"
                value={formPersonalData.firstName}
                onChange={handlePersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal-entity-name">Last Name*</label>
              <input
                type="text"
                id="legal-entity-name"
                name="lastName"
                value={formPersonalData.lastName}
                onChange={handlePersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handlePersonalChange}
                // readOnly
                className="read-only-input"
                required
              />
            </div>

            <div className="form-group date-of-birth">
              <label htmlFor="dob">Date of Birth*</label>
            </div>
            <div className="date-picker-outer date-picker-outer-all">
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">
                <DatePicker
                  value={selectedDate ? new Date(selectedDate) : new Date()}
                  oneTap // Use DatePicker instead of DateRangePicker
                  format="dd/MM/yyyy"
                  ranges={ranges}
                  onChange={handleDateChange}
                  placement="auto"
                  className="invoice-filter-top-date"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="tax-id">Passport or ID Number*</label>
              <input
                type="text"
                id="tax-id"
                name="passportId"
                value={formPersonalData.passportId}
                onChange={handlePersonalChange}
                required
                max={10}
              />
            </div>

            <div className="continue-button">
              <button type="submit">Continue</button>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {(uType.usertype === USER_TYPE_PERSONAL &&
        page1 === ONE &&
        page2 === ZERO &&
        page3 === ZERO &&
        page !== ONE &&
        page !== THREE) ||
        page === TWO ? (
        <>
          <div className="company-detail-title">Account Information</div>
          <form onSubmit={handleAccountSubmit}>
            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="country" className="form-label">Country*</label>
              <div
                ref={countryDropdownRef}
                className="select-modal-mainM custom-dropdown-user-new-outer"
              >
                <span
                  id="country"
                  onClick={handleToggleCountryDropdown}
                  className="custom-dropdown-user-new"
                >
                  {country ? country : "Select"}
                </span>
                {countryDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Search"
                      value={countrySearchTerm}
                      onChange={handleCountrySearchInputChange}
                    />
                    <ul className="country-list">
                      {filteredCountries.length > 0 &&
                        filteredCountries.map((c) => (
                          <li
                            key={c.country}
                            className={
                              country === c.country ? "selected-item" : ""
                            }
                            onClick={() => {
                              setFormData({ ...formData, country: c.country });
                              setCountry(c.country);
                              setCountryDropdownOpen(false);
                            }}
                          >
                            {c.country}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* 
            <div className="form-group">
              <label htmlFor="country">Country*</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div> */}
            <div className="form-group">
              <label htmlFor="postal-code">Postal Code*</label>
              <input
                type="text"
                id="postal-code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile-no">Mobile No.*</label>
              {/* <input
                type="number"
                id="mobile-no"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                required
              /> */}
              <PhoneInput
                placeholder="Enter phone number"
                id="mobile-no"
                value={formData.mobileNo}
                onChange={(value) => handleMobileChange(value, 'mobileNo')} 
                // readOnly={userType === ""}
                required
              />
            </div>
            <div className="form-button-group">
              <div className="form-back-button">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setPage(1));
                  }}
                >
                  Back
                </button>
              </div>
              <div className="continue-button">
                <button type="submit">Continue</button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {/* personal form */}

      {/* Business form */}

      {(uType.usertype === USER_TYPE_BUSINESS &&
        page1 === ZERO &&
        page2 === ZERO &&
        page3 === ZERO &&
        pageB !== THREE &&
        pageB !== TWO &&
        pageB !== FOUR) ||
        pageB === ONE ? (
        <>
          <div className="company-detail-title">Company Details</div>
          <form onSubmit={handleSubmitBusinessCompanyDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="commercial-name">Commercial Name*</label>
              <input
                type="text"
                id="commercial-name"
                name="commercialName"
                value={formBusinessCompanyData.commercialName}
                onChange={handleBusinessCompanyChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal-entity-name">Legal Entity Name*</label>
              <input
                type="text"
                id="legal-entity-name"
                name="legalEntityName"
                value={formBusinessCompanyData.legalEntityName}
                onChange={handleBusinessCompanyChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formBusinessCompanyData.email}
                // readOnly
                // className="read-only-input"
                onChange={handleBusinessCompanyChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="tax-id">Tax ID number</label>
              <input
                type="text"
                id="tax-id"
                name="taxId"
                value={formBusinessCompanyData.taxId}
                onChange={handleBusinessCompanyChange}

              />
            </div>

            <div className="continue-button">
              <button type="submit">Continue</button>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {(uType.usertype === USER_TYPE_BUSINESS &&
        (page1 === ONE) & (page2 === ZERO) &&
        page3 === ZERO &&
        page4 === ZERO &&
        pageB !== ONE &&
        pageB !== THREE &&
        pageB !== FOUR) ||
        pageB === TWO ? (
        <>
          <div className="company-detail-title">
            Company Account Information
          </div>
          <form onSubmit={handleSubmitBusinessAccountInfoSubmit}>
            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formBusinessAccountData.address}
                onChange={handleBusinessAccountChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formBusinessAccountData.city}
                onChange={handleBusinessAccountChange}
                required
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formBusinessAccountData.country}
                onChange={handleBusinessAccountChange}
                required
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="country" className="form-label">Country*</label>
              <div
                ref={countryDropdownRef}
                className="select-modal-mainM custom-dropdown-user-new-outer"
              >
                <span
                  id="country"
                  onClick={handleToggleCountryDropdown}
                  className="custom-dropdown-user-new"
                >
                  {country ? country : "Select"}
                </span>
                {countryDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Search"
                      value={countrySearchTerm}
                      onChange={handleCountrySearchInputChange}
                    />
                    <ul className="country-list">
                      {filteredCountries.length > 0 &&
                        filteredCountries.map((c) => (
                          <li
                            key={c.country}
                            className={
                              country === c.country ? "selected-item" : ""
                            }
                            onClick={() => {
                              setFormBusinessAccountData({
                                ...formBusinessAccountData,
                                country: c.country,
                              });
                              setCountry(c.country);
                              setCountryDropdownOpen(false);
                            }}
                          >
                            {c.country}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="postal-code">Postal Code*</label>
              <input
                type="text"
                id="postal-code"
                name="postalCode"
                value={formBusinessAccountData.postalCode}
                onChange={handleBusinessAccountChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile-no">Mobile No.*</label>
              {/* <input
                type="number"
                id="mobile-no"
                name="mobileNo"
                value={formBusinessAccountData.mobileNo}
                onChange={handleBusinessAccountChange}
                required
              /> */}
              <PhoneInput
                placeholder="Enter phone number"
                id="mobile-no"
                value={formBusinessAccountData.mobileNo}
                onChange={(value) => handleBusinessMobileAccountChange(value, 'mobileNo')} 
                // readOnly={userType === ""}
                required
              />
            </div>
            <div className="form-button-group">
              <div className="form-back-button">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setBPage(1));
                  }}
                >
                  Back
                </button>
              </div>
              <div className="continue-button">
                <button type="submit">Continue</button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {(uType.usertype === USER_TYPE_BUSINESS &&
        page1 === ONE &&
        page2 === ONE &&
        page3 === ZERO &&
        page4 === ZERO &&
        pageB !== ONE &&
        pageB !== TWO &&
        pageB !== 4) ||
        pageB === 3 ? (
        <>
          <div className="company-detail-title">Your Personal Details</div>
          <div className="company-detail-sub-title">
            You will be the company account’s super admin. Please fill out your
            personal information.
          </div>
          <form onSubmit={handleSubmitBusinessPersonalDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="first-name">First Name*</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={formBusinessPersonalData.firstName}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name*</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={formBusinessPersonalData.lastName}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleBusinessPersonalChange}
                // readOnly
                className="read-only-input"
                required
              />
            </div>
            <div className="form-group date-of-birth">
              <label htmlFor="dob">Date of Birth*</label>
            </div>
            <div className="date-picker-outer date-picker-outer-all">
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">
                <DatePicker
                  oneTap // Use DatePicker instead of DateRangePicker
                  value={selectedDate ? new Date(selectedDate) : new Date()}
                  format="dd/MM/yyyy"
                  ranges={ranges}
                  onChange={handleDateChange}
                  placement="auto"
                  className="invoice-filter-top-date"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="id-number">Passport or ID Number*</label>
              <div className="id-brwosing">
                <div className="id-detail">
                  <input
                    type="text"
                    id="id-number"
                    name="idNumber"
                    value={formBusinessPersonalData.idNumber}
                    onChange={handleBusinessPersonalChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formBusinessPersonalData.address}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formBusinessPersonalData.city}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="country" className="form-label">Country*</label>
              <div
                ref={countryDropdownRef}
                className="select-modal-mainM custom-dropdown-user-new-outer"
              >
                <span
                  id="country"
                  onClick={handleToggleCountryDropdown}
                  className="custom-dropdown-user-new"
                >
                  {country ? country : "Select"}
                </span>
                {countryDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Search"
                      value={countrySearchTerm}
                      onChange={handleCountrySearchInputChange}
                    />
                    <ul className="country-list">
                      {filteredCountries.length > 0 &&
                        filteredCountries.map((c) => (
                          <li
                            key={c.country}
                            className={
                              country === c.country ? "selected-item" : ""
                            }
                            onClick={() => {
                              setFormBusinessPersonalData({
                                ...formBusinessPersonalData,
                                country: c.country,
                              });
                              setCountry(c.country);
                              setCountryDropdownOpen(false);
                            }}
                          >
                            {c.country}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="form-group">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formBusinessPersonalData.country}
                  onChange={handleBusinessPersonalChange}
                  required
                />
              </div> */}
            <div className="form-group">
              <label htmlFor="postal-code">Postal Code*</label>
              <input
                type="text"
                id="postal-code"
                name="postalCode"
                value={formBusinessPersonalData.postalCode}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone-no">Phone No.*</label>
              <input
                type="text"
                id="phone-no"
                name="phoneNo"
                value={formBusinessPersonalData.phoneNo}
                onChange={handleBusinessPersonalChange}
                required
              />
            </div>
            <div className="form-button-group">
              <div className="form-back-button">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setBPage(2));
                  }}
                >
                  Back
                </button>
              </div>
              <div className="continue-button">
                <button type="submit">Continue</button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {/* Business form end */}

      {/* Vendor form */}

      {(uType.usertype === "vendor" &&
        page1 === 0 &&
        page2 === 0 &&
        page3 === 0 &&
        pageB !== 3 &&
        pageB !== 2 &&
        pageB !== 4) ||
        pageV === 1 ? (
        <>
          <div className="company-detail-title">Company Details</div>
          <form onSubmit={handleSubmitVendorCompanyDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="commercial-name">Commercial Name*</label>
              <input
                type="text"
                id="commercial-name"
                name="commercialName"
                value={formVendorCompanyData.commercialName}
                onChange={handleVendorCompanyChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal-entity-name">Legal Entity Name*</label>
              <input
                type="text"
                id="legal-entity-name"
                name="legalEntityName"
                value={formVendorCompanyData.legalEntityName}
                onChange={handleVendorCompanyChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleVendorCompanyChange}
                // readOnly
                className="read-only-input"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="tax-id">Tax ID number</label>
              <input
                type="text"
                id="tax-id"
                name="taxId"
                value={formVendorCompanyData.taxId}
                onChange={handleVendorCompanyChange}

              />
            </div>

            <div className="continue-button">
              <button type="submit">Continue</button>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {(uType.usertype === "vendor" &&
        page1 === 1 &&
        page2 === 0 &&
        page3 === 0 &&
        pageV !== 1 &&
        pageV !== 3) ||
        pageV === 2 ? (
        <>
          <div className="company-detail-title">
            Company Account Information 
          </div>
          <form onSubmit={handleSubmitVendorAccountInfoSubmit}>
            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formVendorAccountData.address}
                onChange={handleVendorAccountChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formVendorAccountData.city}
                onChange={handleVendorAccountChange}
                required
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formVendorAccountData.country}
                onChange={handleVendorAccountChange}
                required
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="country" className="form-label">
                Country*
              </label>
              <div
                ref={countryDropdownRef}
                className="select-modal-mainM custom-dropdown-user-new-outer"
              >
                <span
                  id="country"
                  onClick={handleToggleCountryDropdown}
                  className="custom-dropdown-user-new"
                >
                  {country ? country : "Select"}
                </span>
                {countryDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Search"
                      value={countrySearchTerm}
                      onChange={handleCountrySearchInputChange}
                    />
                    <ul className="country-list">
                      {filteredCountries.length > 0 &&
                        filteredCountries.map((c) => (
                          <li
                            key={c.country}
                            className={
                              country === c.country ? "selected-item" : ""
                            }
                            onClick={() => {
                              setFormVendorAccountData({
                                ...formVendorAccountData,
                                country: c.country,
                              });
                              setCountry(c.country);
                              setCountryDropdownOpen(false);
                            }}
                          >
                            {c.country}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="postal-code">Postal Code*</label>
              <input
                type="text"
                id="postal-code"
                name="postalCode"
                value={formVendorAccountData.postalCode}
                onChange={handleVendorAccountChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile-no">Mobile No.*</label>
              {/* <input
                type="number"
                id="mobile-no"
                name="mobileNo"
                value={formVendorAccountData.mobileNo}
                onChange={handleVendorAccountChange}
                required
              /> */}
              <PhoneInput
                placeholder="Enter phone number"
                id="mobile-no"
                value={formVendorAccountData.mobileNo}
                onChange={(value) => handleVendorMobileAccountChange(value, 'mobileNo')} 
                // readOnly={userType === ""}
                required
              />
            </div>
            <div className="form-button-group">
              <div className="form-back-button">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setVPage(1));
                  }}
                >
                  Back
                </button>
              </div>
              <div className="continue-button">
                <button type="submit">Continue</button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {/* Vendor form end */}

      {(uType.usertype === "personal" &&
        page1 === 1 &&
        page2 === 1 &&
        page3 === 1 &&
        page4 === 0 &&
        pageB !== 1 &&
        pageB !== 2 &&
        pageB !== 3 &&
        pageB !== 5 &&
        pageV !== 1 &&
        pageV !== 2 &&
        page !== 1 &&
        page !== 2) ||
        page === 3 ||
        pageB === 4 ||
        pageV === 3 ? (
        <>
          <div className="company-detail-title">Security </div>
          <form onSubmit={handleSecuritySubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address*</label>
              <input
                disabled
                type="text"
                id="email"
                name="email"
                // value={personalFirst.email ? personalFirst.email : formBusinessPersonalData.email ? formBusinessPersonalData.email : formVendorCompanyData.email}
                value={email}
                onChange={handleSecurityChange}
                // readOnly
                className="read-only-input"
                required
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="password">Password*</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formSecurityData.password}
                onChange={handleSecurityChange}
                required
              />
            </div> */}

            <div className="form-group password">
              <label htmlFor="password">Password*</label>
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"} // Use the showPassword state to toggle between text and password type
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formSecurityData.password}
                  onChange={handleSecurityChange}
                />
                <img
                  src={showPassword ? EyeCrossed : EyeIcon} // Use the custom eye icon image based on the showPassword state
                  className="password-toggle-icon"
                  alt="Toggle Password"
                  onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on eye icon click
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password*</label>
              <input
                type={showPassword ? "text" : "password"}
                id="confirm-password"
                name="confirmPassword"
                value={formSecurityData.confirmPassword}
                onChange={handleSecurityChange}
                className={securityButton && "error"}
                required
              />
            </div>



            <div className="form-button-group">
              <div className="form-back-button">
                <button
                  type="button"
                  onClick={() => {
                    if (uType.usertype === "personal") {
                      dispatch(setPage(2));
                    }
                    if (uType.usertype === "business") {
                      dispatch(setBPage(3));
                    }
                    if (uType.usertype === "vendor") {
                      dispatch(setVPage(2));
                    }
                  }}
                >
                  Back
                </button>
              </div>
              <div className="continue-button">
                <button
                  type="submit"
                  onClick={() => { }}
                  disabled={securityButton}
                >
                  Complete onboarding
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      {(uType.usertype === "personal" &&
        page1 === 1 &&
        page2 === 1 &&
        page3 === 1 &&
        page4 === 1) ||
        page === 4 ||
        pageB === 5 ||
        pageV === 4 ? (
        <>
          <>
            <div className="company-detail-title">You're all set!</div>
            <form onSubmit={handleSecuritySubmit}>
              <p className="thank-you-form-submit">
                {!existMessage
                  ? "Thank you for registering! Your account has been created. To access your Talenton account, please log in using your credentials."
                  : "You are already registered. To access your Talenton account, please log in using your credentials."}
              </p>
              <div className="form-button-group">
                <div className="continue-button">
                  <Link type="submit" disabled={securityButton} to="/">
                    Go to my account
                  </Link>
                </div>
              </div>
            </form>
          </>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CompanyAccountInformation;
