import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import crssrImage from "../../../../../images/singleinvoice/cross-red.svg";
import Delete from "../../../../../images/icons/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import { setSprintView } from "../../../../../features/projects/projectSlice";
import { logout, reset } from "../../../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../../../features/projects/projectSlice.js";
import { ThreeDots } from "react-loader-spinner";
import editImage from "../../../../../images/singleinvoice/edit.svg";
import { useGetActiveTeams } from "../../../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import userType from "../../../../../constants/userType.js";


const SprintViewModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSprintsModalOpen, setIsSprintsModalOpen] = useState(false);
  const [isSprintEdit, setIsSprintEdit] = useState(false);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");
  const [sprintDiscriptionView, setSprintDiscriptionView] = useState("");
  const [budget, setBudget] = useState("");
  const [sprintStatus, setSprintStatus] = useState("");
  const [sprintStartDate, setSprintStartDate] = useState("");
  const [sprintEndDate, setSprintEndDate] = useState("");
  const [totalHours, setTotalHours] = useState("");
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [budgetThreshold, setBudgetThreshold] = useState(0);
  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);
  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);
  const [sprintStatusDropdownOpen, setSprintStatusDropdownOpen] = useState(false);
  const [isSprintDeleteModalOpen, setIsSprintDeleteModalOpen] = useState(false);
  const [sprintIsValid, setSprintIsValid] = useState(false);
  const { sprintView, sprintData } = useSelector((state) => state.project);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };
  const sprintRef = useRef(null);
  const sprintStatusDropdownRef = useRef(null);

  const { data: teams = [], isLoading: teamLoading, isError: teamError } = useGetActiveTeams(token);
  const ranges = [
    {
      label: "today",
      value: [new Date(), new Date()],
    },
    {
      label: "yesterday",
      value: [subDays(new Date(), 1), subDays(new Date(), 1)],
    },
  ];


  useEffect(() => {
    if (sprintView && sprintData) {
      if (sprintData.sprint_name) {
        setSprint(sprintData.sprint_name);
        setSprintView(sprintData.sprint_name);
      }
      if (sprintData.sprint_name) {
        setSprintStatus(sprintData.sprint_status)
      } else {
        setSprintStatus("Scheduled")
      }

      if (sprintData.budget) {
        setBudget(sprintData.budget);
      } else {
        setBudget("");
      }
      if (sprintData.start_date && sprintData.end_date) {
        setDateRange([new Date(sprintData.start_date), new Date(sprintData.end_date)]);
        setSprintStartDate(moment(sprintData.start_date).tz(global.config.getTimeZone()).format("DD/MM/YYYY"));
        setSprintEndDate(moment(sprintData.end_date).tz(global.config.getTimeZone()).format("DD/MM/YYYY"));
      } else {
        setDateRange(null);
      }
      if (sprintData.discription) {
        setSprintDiscription(sprintData.discription);
        setSprintDiscriptionView(sprintData.discription);
      }
      if (sprintData.totalHours) {
        setTotalHours(sprintData.totalHours);
      } else {
        setTotalHours("");
      }
      setTimeChecked(!!sprintData?.timeThresholdData);
      setTimeThreshold(sprintData.timeThresholdData?.thresholdPercentage || 0);
      setSelectedTimeThresholdTeamsEdit(sprintData.timeThresholdData?.alertTo || []);
      // Update budget threshold states
      setBudgetChecked(!!sprintData?.budgetThresholdData);
      setBudgetThreshold(sprintData.budgetThresholdData?.thresholdPercentage || 0);
      setSelectedBudgetThresholdTeamsEdit(sprintData.budgetThresholdData?.alertTo || []);

    }
  }, [sprintView, sprintData]);

  const handleCloseDetailsModal = async () => {
    setSprint("");
    setDateRange(null);
    setSprintDiscription("");
    setSprintView("");
    setSprintDiscriptionView("");
    setBudget("");
    setTotalHours("");
    setSprintStartDate("");
    setSprintEndDate("");
    setIsSprintEdit(false);
    await dispatch(setSprintView(false));
  };

  const handleSprintStatusToggleDropdown = () => {
    setSprintStatusDropdownOpen(!sprintStatusDropdownOpen);
  };

  const handleSprintStatusSelectOption = (value) => {
    setSprintStatus(value);
    setSprintStatusDropdownOpen(false);
  };

  function convertHoursToTimeFormat(hours) {
    const totalSeconds = Math.floor(hours * 3600);
    const hh = Math.floor(totalSeconds / 3600);
    const mm = Math.floor((totalSeconds % 3600) / 60);
    const ss = totalSeconds % 60;

    return [
      String(hh).padStart(2, '0'),
      String(mm).padStart(2, '0'),
      String(ss).padStart(2, '0')
    ].join(':');
  }

  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };

  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    // Avoid unnecessary state updates
    const isAllSelected = selectedTimeThresholdTeamsEdit.length === teams.length;

    setSelectTimeThresholdAllTeam((prev) => {
      return prev !== isAllSelected ? isAllSelected : prev;
    });

  }, [selectedTimeThresholdTeamsEdit, teams]);

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const isAllSelected = selectedBudgetThresholdTeamsEdit.length === teams.length;

    setSelectBudgetThresholdAllTeam((prev) => {
      return prev !== isAllSelected ? isAllSelected : prev;
    });

  }, [selectedTimeThresholdTeamsEdit, teams]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideSprintStatusDropdown = sprintStatusDropdownRef.current && sprintStatusDropdownRef.current.contains(event.target);
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    if (!isClickInsideSprintStatusDropdown) {
      setSprintStatusDropdownOpen(false);
    }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleSetSprintEdit = () => {
    setIsSprintEdit(true);
  };

  const handleResetSprintEdit = () => {
    setIsSprintEdit(false);
  };

  const handleSprintDelete = () => {
    setIsSprintDeleteModalOpen(true);
  };

  const handleConfirmSprintDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/sprint-details/delete/${sprintData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Sprint removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(window.location.href, 'Sprint', `Sprint (${sprintData._id}) deleted successfully`);
      dispatch(setProjectAdded());
      handleCloseDetailsModal();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsSprintDeleteModalOpen(false);
  };

  const handleCancelSprintDelete = () => {
    setIsSprintDeleteModalOpen(false);
  };

  const handleSprintEdit = async (event) => {
    event.preventDefault();
    setSprintIsValid(false);

    if (sprint === "") {
      if (sprint === "") {
        setSprintIsValid(true);
        sprintRef.current.focus();
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (timeChecked) {
      if (!totalHours || totalHours <= 0) {
        toast.error("Total hours are required when time threshold is enabled", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }
      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }
    }

    if (budgetChecked) {
      if (!budget || budget <= 0) {
        toast.error("Budget is required when budget threshold is enabled", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }
      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }

      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        return;
      }
    }

    let startdate, enddate;
    if (dateRange === null) {
      startdate = null;
      enddate = null;
    } else {
      startdate = dateRange[0];
      enddate = dateRange[1];
    }

    const requestData = {
      sprint_name: sprint,
      discription: sprintDiscription,
      budget,
      totalHours,
      sprint_status: sprintStatus,
      start_date: startdate,
      end_date: enddate,
    };
    try {
      setIsSubmitLoading(true);
      const response = await axios.put(
        `/api/sprint-details/update/${sprintData._id}`,
        requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      );
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const thresholdRequests = [];
        if (timeChecked) {
          thresholdRequests.push(
            axios.post(
              "/api/project/sprint/threshold",
              {
                thresholdFor: "sprints",
                recordId: sprintData._id,
                clientId: sprintData.clientId,
                thresholdType: "time",
                thresholdPercentage: timeThreshold,
                alertTo: selectedTimeThresholdTeamsEdit,
                thresholdTimeStatus: timeChecked,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }

        if (budgetChecked) {
          thresholdRequests.push(
            axios.post(
              "/api/project/sprint/threshold",
              {
                thresholdFor: "sprints",
                recordId: sprintData._id,
                clientId: sprintData.clientId,
                thresholdType: "budget",
                thresholdPercentage: budgetThreshold,
                alertTo: selectedBudgetThresholdTeamsEdit,
                thresholdBudgetStatus: budgetChecked,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
        await Promise.all(thresholdRequests);
        toast.success("Sprint updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Sprint', `Sprint "${sprint}" Updated Successfully`);
        dispatch(setProjectAdded());
        handleCloseDetailsModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(window.location.href, 'Sprint', `Something went wrong: Sprint update`);
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }


  return (
    <>
      <Modal
        className="client-modal project-modal"
        isOpen={sprintView}
        onRequestClose={handleCloseDetailsModal}
        contentLabel="View Sprint"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="modal">
          <div className="modal-close-invite outter-modal-main">
            <a className="" onClick={handleCloseDetailsModal}>
              <img src={crssrImage} alt="Edit Icon" />{" "}
            </a>
          </div>
          {!isSprintsModalOpen && (
            <div className="project-detail-modal">
              <div className="modal-client-head ">
                <h2 className="">Sprint Details</h2>
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Sprint Name
                    </label>

                    {isSprintEdit ? (
                      <>
                        <input
                          type="text"
                          id="sprintName"
                          name="sprintName"
                          value={sprint}
                          onChange={(e) => setSprint(e.target.value)}
                          className={
                            sprintIsValid
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          ref={sprintRef}
                        />
                        {sprintIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Sprint Field Cannot be empty
                          </span>
                        )}
                      </>
                    ) : (
                      <p className="form-input">{sprintData && sprintData.sprint_name}</p>
                    )}
                  </div>

                  {(isSprintEdit || sprintData?.discription) && (
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Description
                      </label>

                      {isSprintEdit ? (
                        <>
                          <textarea
                            id="sprintDiscription"
                            name="sprintDiscription"
                            className="form-textarea"
                            rows="3"
                            value={sprintDiscription}
                            onChange={(e) =>
                              setSprintDiscription(e.target.value)
                            }
                          ></textarea>
                        </>
                      ) : (
                        <p className="form-input">{sprintData && sprintData.discription}</p>
                      )}
                    </div>
                  )}

                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Budget
                    </label>
                    {isSprintEdit ? (
                      <div className="form-field-client-currency">
                        <div className="currency-input">
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.preferredCurrency || settings.currency || 'USD' }).format(0).replace(/[\d.,]/g, '')}
                        </div>
                        <input
                          type="number"
                          id="budget"
                          name="budget"
                          value={budget}
                          onChange={(e) => setBudget(e.target.value)}
                          className="form-input"
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      </div>
                    ) : (
                      <p className="form-input">{sprintData && new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: props.preferredCurrency || settings.currency || 'USD',
                      }).format(sprintData.budget)}</p>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Total Hours
                    </label>
                    {isSprintEdit ? (
                      <input
                        type="number"
                        id="totalHours"
                        name="totalHours"
                        value={totalHours}
                        onChange={(e) => setTotalHours(e.target.value)}
                        className="form-input"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    ) : (
                      <p className="form-input">{convertHoursToTimeFormat(sprintData?.totalHours || 0)}</p>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Sprint Status
                    </label>
                    {isSprintEdit ? (
                      <div className="custom-dropdown">

                        <div ref={sprintStatusDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${sprintStatusDropdownOpen ? 'open' : ''}`}>
                          <div className="selected-option custom-dropdown-user-new" onClick={handleSprintStatusToggleDropdown}>
                            {sprintStatus ? sprintStatus : 'Select Type'}
                          </div>
                          {sprintStatusDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <ul className="type-list">
                                <li className={sprintStatus === 'In Progress' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('In Progress')}>In Progress</li>
                                <li className={sprintStatus === 'Cancelled' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('Cancelled')}>Cancelled</li>
                                <li className={sprintStatus === 'Delivered' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('Delivered')}>Delivered</li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <span className="custom-arrow"></span>
                      </div>
                    ) : (
                      <p className="form-input">{sprintData && sprintData.sprint_status}</p>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Project Name
                    </label>

                    {/* <>
                          <input
                            type="text"
                            id="projectName"
                            name="projectName"
                            value=""
                            // onChange={(e) => setProjectName(e.target.value)}
                            // className={
                            //   projectNameIsValid
                            //     ? "input-validation-error form-input"
                            //     : "form-input"
                            // }
                            // ref={projectNameRef}
                          />
                          {/* {projectNameIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Project Name Field Cannot be empty
                            </span>
                          )} 
                        </> */}
                    <p className="form-input">{sprintData && sprintData.project_name}</p>
                  </div>

                  {(isSprintEdit || (sprintEndDate && sprintStartDate)) && (
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Date Frames
                      </label>

                      {isSprintEdit ? (
                        <DateRangePicker
                          showOneCalendar
                          format="dd/MM/yyyy"
                          placeholder="From - to"
                          placement="bottomEnd"
                          ranges={ranges}
                          className="project-calender"
                          value={dateRange}
                          onChange={handleSelect}
                        />
                      ) : (
                        sprintStartDate &&
                        sprintEndDate && (
                          <p className="form-textarea">
                            {sprintStartDate} to {sprintEndDate}
                          </p>
                        )
                      )}
                    </div>
                  )}

                  {isSprintEdit && (
                    <div className="form-field-client">
                      <label htmlFor="projectTimeframe" className="form-label">
                        Sprint Alerts
                      </label>
                      <div className="project-alert-outer">
                        {/* Time Alert */}
                        <div className="alert-section for-time-outer">
                          <label className="alert-label">
                            <input type="checkbox" checked={timeChecked} onChange={() => setTimeChecked(!timeChecked)} />
                            <span>For time</span>
                          </label>

                          <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                            <div className="input-group">
                              <span>If sprint reaches</span>
                              <input
                                type="number"
                                value={timeThreshold}
                                // onChange={(e) => setTimeThreshold(e.target.value)}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                    setTimeThreshold(value);
                                  }
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                                min="0"
                                max="100"
                                step="1"
                              />
                              <span>% of estimate</span>
                            </div>

                            <div className="dropdown-group">
                              <span>Alert</span>
                              <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                                <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                                  <div className="selected-options1">
                                    {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                      <div className="placeholder">Select</div>
                                    ) : (
                                      <>
                                        <div className="selected-option1">
                                          {(() => {
                                            const roles = {
                                              Super_Admin: "All Super Admin",
                                              Admin: "All Admin",
                                              Project_Manager: "All Project Manager",
                                              Team_Manager: "All Team Manager",
                                              User: "All Users",
                                            };

                                            // Check if all users of a specific role are selected
                                            for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                              const roleUsers = teams
                                                .filter((team) => team.role_id === userType[roleKey])
                                                .map((team) => team._id);

                                              if (
                                                roleUsers.length > 0 &&
                                                roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                                selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                              ) {
                                                return roleLabel;
                                              }
                                            }

                                            // If all team members are selected
                                            if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                              return "All Team";
                                            }

                                            // Get the first selected user name
                                            const firstSelectedUser =
                                              teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                                ? (() => {
                                                  const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                                  return user.first_name
                                                    ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                    : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                })()
                                                : "No Users selected";

                                            return (
                                              <>
                                                {firstSelectedUser}
                                                {selectedTimeThresholdTeamsEdit.length > 1 && (
                                                  <div className="selected-option-count">
                                                    {selectedTimeThresholdTeamsEdit.length - 1}+
                                                  </div>
                                                )}
                                              </>
                                            );
                                          })()}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {isTimeThresholdTeamDropdownOpen && (
                                  <div className="dropdown-list1">
                                    <label className="checkbox-label1" key="selectAll">
                                      <input
                                        type="checkbox"
                                        checked={selectTimeThresholdAllTeam}
                                        onChange={handleSelectTimeThresholdAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                    {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                      .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                      .map((role) => (
                                        <label className="checkbox-label1" key={role}>
                                          <input
                                            type="checkbox"
                                            checked={teams
                                              .filter((team) => team.role_id === userType[role])
                                              .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                            onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                          />
                                          All {role.replace("_", " ")}
                                        </label>
                                      ))}
                                    {teams.map((team) => (
                                      <label className="checkbox-label1" key={team._id}>
                                        <input
                                          type="checkbox"
                                          checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                          onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                        />
                                        {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Budget Alert */}
                        <div className="alert-section for-budget-outer">
                          <label className="alert-label">
                            <input type="checkbox" checked={budgetChecked} onChange={() => setBudgetChecked(!budgetChecked)} />
                            <span>For budget</span>
                          </label>

                          <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                            <div className="input-group">
                              <span>If sprint reaches</span>
                              <input
                                type="number"
                                value={budgetThreshold}
                                // onChange={(e) => setBudgetThreshold(e.target.value)}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                    setBudgetThreshold(value);
                                  }
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                                min="0"
                                max="100"
                                step="1"
                              />
                              <span>% of estimate</span>
                            </div>

                            <div className="dropdown-group">
                              <span>Alert</span>
                              <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                                <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                                  <div className="selected-options1">
                                    {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                      <div className="placeholder">Select</div>
                                    ) : (
                                      <div className="selected-option1">
                                        {(() => {
                                          const roles = {
                                            Super_Admin: "All Super Admin",
                                            Admin: "All Admin",
                                            Project_Manager: "All Project Manager",
                                            Team_Manager: "All Team Manager",
                                            User: "All Users",
                                          };

                                          // Check if all users of a specific role are selected
                                          for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                            const roleUsers = teams
                                              .filter((team) => team.role_id === userType[roleKey])
                                              .map((team) => team._id);

                                            if (
                                              roleUsers.length > 0 &&
                                              roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                              selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                            ) {
                                              return roleLabel;
                                            }
                                          }

                                          // If all team members are selected
                                          if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                            return "All Team";
                                          }

                                          // Get the first selected user name
                                          const firstSelectedUser =
                                            teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                              ? (() => {
                                                const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                return user.first_name
                                                  ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                  : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                              })()
                                              : "No Users selected";

                                          return (
                                            <>
                                              {firstSelectedUser}
                                              {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                <div className="selected-option-count">
                                                  {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                </div>
                                              )}
                                            </>
                                          );
                                        })()}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {isBudgetThresholdTeamDropdownOpen && (
                                  <div className="dropdown-list1">
                                    <label className="checkbox-label1" key="selectAll">
                                      <input
                                        type="checkbox"
                                        checked={selectBudgetThresholdAllTeam}
                                        onChange={handleSelectBudgetThresholdAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                    {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                      .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                      .map((role) => (
                                        <label className="checkbox-label1" key={role}>
                                          <input
                                            type="checkbox"
                                            checked={teams
                                              .filter((team) => team.role_id === userType[role])
                                              .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                            onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                          />
                                          All {role.replace("_", " ")}
                                        </label>
                                      ))}
                                    {teams.map((team) => (
                                      <label className="checkbox-label1" key={team._id}>
                                        <input
                                          type="checkbox"
                                          checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                          onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                        />
                                        {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isSprintEdit ? (
                    <>
                      {isSubmitLoading ?
                        (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="sprint-btn-outer">
                            <button
                              type="button"
                              onClick={handleResetSprintEdit}
                              className="submit-client cancel-sprint"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="submit-client save-sprint"
                              onClick={handleSprintEdit}
                            >
                              Save Changes
                            </button>
                          </div>
                        )}
                    </>
                  ) : (
                    <div className="sprint-btn-outer">
                      <button
                        type="button"
                        className="submit-client cancel-sprint"
                        onClick={handleSprintDelete}
                        disabled={isSubmitLoading}
                      >
                        <img src={Delete} alt="Delete" />  Delete
                      </button>

                      <Modal
                        className="delete-modal"
                        isOpen={isSprintDeleteModalOpen}
                        onRequestClose={handleCancelSprintDelete}
                        contentLabel="Confirm Delete"
                        style={{
                          content: {
                            height: "41%",
                            width: "30%",
                            position: "fixed",
                            top: "36%",
                            left: "50%",
                            transform: "translate(-19.75%, 0%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                          },
                        }}
                      >
                        <div className="delete-modal-content">
                          <h2>Confirm Delete</h2>
                          <p>
                            Are you sure you want to delete this Sprint?
                          </p>
                          <div className="delete-modal-buttons">
                            <button className="buttons-rejected" onClick={handleConfirmSprintDelete} disabled={isSubmitLoading}>
                              {isSubmitLoading ?
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="white"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div> : "Delete"}
                            </button>
                            <button onClick={handleCancelSprintDelete} disabled={isSubmitLoading}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <button
                        type="button"
                        className="submit-client save-sprint"
                        onClick={handleSetSprintEdit}
                      >
                        <img alt="" src={editImage} />
                        Edit Sprint
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )

}
export default SprintViewModal;