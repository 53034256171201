import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

export const useFetchClientAlertsDetails = ({ clientId, token }) => {
    return useQuery(
        ["clientThresholdAlerts", clientId],
        async () => {
            const response = await axios.post(
                "/api/get/client/thresholds",
                { clientId },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return response.data.data;
        },
        {
            enabled: !!clientId, // Ensures API call happens only when both values are available
            onError: (error) => {
                global.config.slackMessage(error.toString());
            },
        }
    );
};
