import moment from "moment";

const zoneConverter = (time, zone, format) => {

    // UTC time in ISO format
    const utcTimeString = time;

    // Convert UTC time to a specific time zone (e.g., 'America/New_York')
    const targetTimeZone = zone;

    const utcDateTime = new Date(utcTimeString);
    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
        timeZone: targetTimeZone,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }).format(utcDateTime);

    const parsedMoment = moment(formattedDateTime, "dddd, MMMM D, YYYY [at] hh:mm:ss A");
    const formatted = parsedMoment.format("YYYY-MM-DD HH:mm:ss");
    return formatted;
};

export default zoneConverter;