import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const timeData = localStorage.getItem("startTime") !== "undefined" ? JSON.parse(localStorage.getItem("startTime")) : null;
const Running = localStorage.getItem("isRunning") !== "undefined" ? JSON.parse(localStorage.getItem("isRunning")) : null;
const getTask = localStorage.getItem("task") !== "undefined" ? JSON.parse(localStorage.getItem("task")) : null;
const getTag = localStorage.getItem("tag") !== "undefined" ? JSON.parse(localStorage.getItem("tag")) : null;
const getTagId = localStorage.getItem("tagId") !== "undefined" ? JSON.parse(localStorage.getItem("tagId")) : null;
const getProject = localStorage.getItem("project") !== "undefined" ? JSON.parse(localStorage.getItem("project")) : null;
const getProjectId = localStorage.getItem("projectId") !== "undefined" ? JSON.parse(localStorage.getItem("projectId")) : null;
const getSprintName = localStorage.getItem("sprintName") !== "undefined" ? JSON.parse(localStorage.getItem("sprintName")) : null;
const getSprintId = localStorage.getItem("sprintId") !== "undefined" ? JSON.parse(localStorage.getItem("sprintId")) : null;
const isBillable = localStorage.getItem("isbillable") !== "undefined" ? JSON.parse(localStorage.getItem("isbillable")) : null;
const client = localStorage.getItem("client") !== "undefined" ? JSON.parse(localStorage.getItem("client")) : null;
const clientId = localStorage.getItem("clientId") !== "undefined" ? JSON.parse(localStorage.getItem("clientId")) : null;
const getRunningTask = localStorage.getItem("runningTask") !== "undefined" ? JSON.parse(localStorage.getItem("runningTask")) : null;


const initialState = {
  // Timer-related
  time: timeData ?? null,
  isRunning: Running ?? false,
  runningTask: getRunningTask ?? null,

  // Task-related
  task: getTask ?? null,
  isbillable: isBillable ?? false,

  // Project-related
  project: getProject ?? null,
  projectId: getProjectId ?? null,

  // Sprint-related
  sprintName: getSprintName ?? null,
  sprintId: getSprintId ?? null,

  // Tag-related
  tag: getTag ?? null,
  tagId: getTagId ?? null,

  // Client-related
  client: client ?? null,
  clientId: clientId ?? null,
};

export const setTimeData = createAsyncThunk("tracker/time", async (time) => {
  return time;
});

export const setIsRunningData = createAsyncThunk(
  "tracker/isRunning",
  async (isRunning) => {
    return isRunning;
  }
);

export const setTaskData = createAsyncThunk("tracker/task", async (task) => {
  return task;
});

export const setTagData = createAsyncThunk("tracker/tag", async (tag) => {
  return tag;
});

export const setTagIdData = createAsyncThunk("tracker/tagId", async (tagId) => {
  return tagId;
});

export const setProjectData = createAsyncThunk(
  "tracker/project",
  async (project) => {
    return project;
  }
);

export const setProjectIdData = createAsyncThunk(
  "tracker/projectId",
  async (projectId) => {
    return projectId;
  }
);

export const setSprintNameData = createAsyncThunk(
  "tracker/sprintName",
  async (sprintName) => {
    return sprintName;
  }
);

export const setSprintIdData = createAsyncThunk(
  "tracker/sprintId",
  async (sprintId) => {
    return sprintId;
  }
);

export const setIsbillableData = createAsyncThunk(
  "tracker/isbillable",
  async (isbillable) => {
    return isbillable;
  }
);

export const setClientData = createAsyncThunk("tracker/client", async (client) => {
  return client;
});

export const setClientIdData = createAsyncThunk("tracker/clientId", async (clientId) => {
  return clientId;
});

export const setRunningTaskData = createAsyncThunk("tracker/runningTask", async (runningTask) => {
  return runningTask;
});

export const timeTrackerLogSlice = createSlice({
  name: "timeTrackerLog",
  initialState,
  reducers: {
    reset: (state) => {
      state.time = null;
      state.isRunning = false;
      state.task = null;
      state.tag = null;
      state.tagId = null;
      state.project = null;
      state.projectId = null;
      state.sprintName = null;
      state.sprintId = null;
      state.isbillable = false;
      state.client = null;
      state.clientId = null;
      state.runningTask = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTimeData.fulfilled, (state, action) => {
      state.time = action.payload;
    });
    builder.addCase(setIsRunningData.fulfilled, (state, action) => {
      state.isRunning = action.payload;
    });
    builder.addCase(setTaskData.fulfilled, (state, action) => {
      state.task = action.payload;
    });
    builder.addCase(setTagData.fulfilled, (state, action) => {
      state.tag = action.payload;
    });
    builder.addCase(setTagIdData.fulfilled, (state, action) => {
      state.tagId = action.payload;
    });
    builder.addCase(setProjectData.fulfilled, (state, action) => {
      state.project = action.payload;
    });
    builder.addCase(setProjectIdData.fulfilled, (state, action) => {
      state.projectId = action.payload;
    });
    builder.addCase(setSprintNameData.fulfilled, (state, action) => {
      state.sprintName = action.payload;
    });
    builder.addCase(setSprintIdData.fulfilled, (state, action) => {
      state.sprintId = action.payload;
    });
    builder.addCase(setIsbillableData.fulfilled, (state, action) => {
      state.isbillable = action.payload;
    });
    builder.addCase(setClientData.fulfilled, (state, action) => {
      state.client = action.payload;
    });
    builder.addCase(setClientIdData.fulfilled, (state, action) => {
      state.clientId = action.payload;
    });
    builder.addCase(setRunningTaskData.fulfilled, (state, action) => {
      state.runningTask = action.payload;
    });
  },
});

export const { reset } = timeTrackerLogSlice.actions;
export default timeTrackerLogSlice.reducer;
