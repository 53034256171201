import css from "./Tenant.css";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import addicon from "../../../images/icons/addicon.svg";
import InviteIcon from "../../../images/icons/invite.svg";
import { useOwnerProfiles } from "./tenantProfileQueries";
import { useSelector, useDispatch } from "react-redux";
import UserIcon from "../../../images/icons/user-icon.svg";
import { RenderMasterTenantCTA, RenderUserCTA } from "../../../helpers/invoiceHelpers";
import profile_image from "../../../images/user/Default_pfp.png";
import dletImage from "../../../images/singleinvoice/delete.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import Plus from "../../../images/icons/plus-black.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import userCategory from "../../../constants/userCategory";
import userTypeName from "../../../constants/userType";
import DownloadImage from "../../../images/icons/download-icon.svg";
import { motion } from "framer-motion";
import moment from "moment-timezone";
import TruncateFilterFieldView from "../../../components/helpers/truncateFilterFieldView.js";
import { dateMasterCreateConvert } from "../../../helpers/dateHelper.js";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);
  const { masterUser } = useSelector((state) => state.masterAuth);
  var token = null;
  if (masterUser) {
      if (masterUser && masterUser.token) {
          token = masterUser.token;
      }
  }
console.log('rowData',rowData);
const [userData, setUserData] = useState(null);

useEffect(() => {
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`/api/user/data-subdomain/${rowData.email}/${rowData.subDomain}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  fetchUserData();
}, [rowData.email,rowData.subDomain]);




 
  const fullName = userData && userData.first_name ? `${userData.first_name} ${userData.last_name}` : `${rowData.ownerName}`

  const displayName = showFullname ? fullName : fullName && fullName.length <= 21 ? fullName : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={toggleShowFullname}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData && rowData?.ownerDetail?.company_logo !== "null" ? (
        <img
          src={`/uploads/orgiOwnerCompyLog/${rowData?.ownerDetail?.company_logo}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const Tenants = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [userType, setUserType] = useState("");
  const roleDropdownRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("All");
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [subTypeFilter, setSubTypeFilter] = useState("All");
  const [selectAllSubType, setSelectAllSubType] = useState(false);
  const [selectedSubType, setSelectedSubType] = useState([]);
  const [isSubTypeDropdownOpen, setIsSubTypeDropdownOpen] = useState(false);
  const [isSubTypeModalDropdownOpen, setIsSubTypeModalDropdownOpen] = useState(false);
  const [subStatusFilter, setSubStatusFilter] = useState("All");
  const [selectAllSubStatus, setSelectAllSubStatus] = useState(true);
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [isSubStatusDropdownOpen, setIsSubStatusDropdownOpen] = useState(false);
  const [isSubStatusModalDropdownOpen, setIsSubStatusModalDropdownOpen] = useState(false);
  const [statusModalDropdownOpen, setStatusModalDropdownOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [invEmail, setInvEmail] = useState("");
  const [invEmails, setInvEmails] = useState([]);
  const [alreadySentInvEmailsList, setAlreadySentInvEmailsList] = useState([]);
  const [isResendInviteModalOpen, setIsResendInviteModalOpen] = useState(false);
  const [isInvMailLoading, setIsInvMailLoading] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [statusUpdateLoading , setStatusUpdateLoading]= useState(false);
  const [statusLoadingId, setStatusLoadingId] = useState(null);

  const [tenantStatus, setTenantStatus] = useState(false);

  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const subTypeDropdownRef = useRef(null);
  const subTypeModalDropdownRef = useRef(null);
  const subStatusDropdownRef = useRef(null);
  const subStatusModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);

  let token = null;
  const { masterUser } = useSelector((state) => state.masterAuth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (masterUser) {
    if (masterUser && masterUser.token) {
      token = masterUser.token;
    }
  }

  useEffect(() => {
    dispatch(setHeaderText("Tenant Management"));
  }, []);
  const { data: ownerProfiles = [], refetch: refetchOwnerProfiles, isLoading, isError, error, } = useOwnerProfiles(token);
 

  useEffect(() => {
    if (ownerProfiles) {
   
      setUsers(ownerProfiles);
    }
  }, [ownerProfiles, tenantStatus]);

  useEffect(() => {
    refetchOwnerProfiles();
  }, [tenantStatus]);

  const renderUserStatus = (rowData) => {
    const handleToggleStatus = async (rowData) => {
      setStatusLoadingId(rowData._id); // Set the current row's ID to show loading only for that row
      try {
        const response = await axios.get(`/api/master/tenant/status/toggle/${rowData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data.status === "success") {
          setTenantStatus(!tenantStatus); // Assuming you fetch the status again elsewhere to reflect changes
          toast.success("Tenant status updated", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setStatusLoadingId(null); // Reset the loading state
      }
    };
  
    let statusClass = rowData.status ? "active" : "inactive";
    let statusName = rowData.status ? "Active" : "Inactive";
  
    return (
      <>
      <div className="accout-status-outer">
          <span className={`account-status ${statusClass}`}>{statusName}</span>

          <button
            onClick={() => {
              handleToggleStatus(rowData);
            }}
            className={`status-toggle-btn ${
              statusLoadingId === rowData._id ? "loading" : ""
            }`}
          >
            {statusLoadingId === rowData._id ? (
              <div className="outter-load-table outter-load-table-position">
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="blue"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              ""
            )}
          </button>

        </div>
        </>
    );
  };

  const renderUserSubscriptionStatus = (rowData) => {
    let statusClass;
    let statusName;
    if (rowData.status === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.status === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <span className={`payment-status active`}>Free Trial</span>
    );
  };

  const [selectValidation, setSelectValodation] = useState(false);


  const handleToggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const handleToggleUserTypeDropdown = () => {
    setUserTypeDropdownOpen(!userTypeDropdownOpen);
  };

  const handleUserTypeOptionClick = (selectedValue) => {
    setUserType(selectedValue);
    setSelectValodation(false);
    setUserTypeDropdownOpen(false);
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setFirst(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(ownerProfiles.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  useEffect(() => {
    setSelectAllTeam(selectedTeams.length === ownerProfiles.length);
    if (selectedTeams.length === ownerProfiles.length) {
      setUserFilter("All");
    } else {
      setUserFilter(selectedTeams);
    }
  }, [selectedTeams, ownerProfiles]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  };

  const subTypeList = Object.entries(userCategory)
    .filter(([name]) => name === "Individual" || name === "Business")
    .map(([name, value]) => ({
      name,
      value,
    }));

  const toggleSubTypeDropdown = () => {
    setIsSubTypeDropdownOpen(!isSubTypeDropdownOpen);
  };

  const toggleSubTypeModalDropdown = () => {
    setIsSubTypeModalDropdownOpen(!isSubTypeModalDropdownOpen);
  };

  const handleCheckboxSubTypeChange = (value) => {
    setSelectedSubType((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const handleSelectAllSubTypeChange = () => {
    setSelectAllSubType(!selectAllSubType);
    if (!selectAllSubType) {
      setSelectedSubType(subTypeList.map((item) => item.value));
    } else {
      setSelectedSubType([]);
    }
  };

  useEffect(() => {
    setSelectAllSubType(selectedSubType.length === subTypeList.length);
    if (selectedSubType.length === subTypeList.length) {
      setSubTypeFilter("All");
    } else {
      setSubTypeFilter(selectedTeams);
    }
  }, [selectedSubType, subTypeList]);

  // const filteredSubStatus = subscriptionStatus.filter(
  //   (role) =>
  //     !(
  //       user.role_id === userTypeName.Admin &&
  //       role._id === userTypeName.Super_Admin
  //     )
  // );

  const toggleSubStatusDropdown = () => {
    setIsSubStatusDropdownOpen(!isSubStatusDropdownOpen);
  };

  const toggleSubStatusModalDropdown = () => {
    setIsSubStatusModalDropdownOpen(!isSubStatusModalDropdownOpen);
  };

  const handleCheckboxSubStatusChange = (value) => {
    const updatedSelection = [...selectedSubStatus];
    const index = updatedSelection.indexOf(value);

    if (index === -1) {
      updatedSelection.push(value);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedSubStatus(updatedSelection);
  };

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setStatusModalDropdownOpen(!statusModalDropdownOpen);
  };

  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedTeams(ownerProfiles.map((team) => team._id));
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isTeamsModified = !isEqual(
      selectedTeams,
      ownerProfiles.map((team) => team._id)
    );
    if (isTeamsModified) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  useEffect(() => {
    setSelectedTeams(ownerProfiles.map((team) => team._id));
  }, [ownerProfiles]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideRoleDropdown =
      roleDropdownRef.current && roleDropdownRef.current.contains(event.target);
    const isClickInsideTeamDropdown =
      teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown =
      teamModalDropdownRef.current &&
      teamModalDropdownRef.current.contains(event.target);
    const isClickInsideSubTypeDropdown =
      subTypeDropdownRef.current &&
      subTypeDropdownRef.current.contains(event.target);
    const isClickInsideSubTypeModalDropdown =
      subTypeModalDropdownRef.current &&
      subTypeModalDropdownRef.current.contains(event.target);
    const isClickInsideSubStatusDropdown =
      subStatusDropdownRef.current &&
      subStatusDropdownRef.current.contains(event.target);
    const isClickInsideSubStatusModalDropdown =
      subStatusModalDropdownRef.current &&
      subStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown =
      statusDropdownRef.current &&
      statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown =
      statusModalDropdownRef.current &&
      statusModalDropdownRef.current.contains(event.target);

    if (!isClickInsideRoleDropdown) {
      setRoleDropdownOpen(false);
    }
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideSubTypeDropdown) {
      setIsSubTypeDropdownOpen(false);
    }
    if (!isClickInsideSubTypeModalDropdown) {
      setIsSubTypeModalDropdownOpen(false);
    }
    if (!isClickInsideSubStatusDropdown) {
      setIsSubStatusDropdownOpen(false);
    }
    if (!isClickInsideSubStatusModalDropdown) {
      setIsSubStatusModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setStatusModalDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = invEmails.every((obj) => (!obj.invEmail || emailRegex.test(obj.invEmail)));
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseInviteModal();
      return;
    }
    var allInvEmails = invEmails;
    if (invEmail !== "") {
      allInvEmails = [...invEmails, { invEmail: invEmail, id: Date.now() }];
    }
    if (!invEmail) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allInvEmails.some(obj => !obj.invEmail)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsInvMailLoading(true);
      const response = await fetch("/api/tenant-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          invEmails: allInvEmails.map((obj) => obj.invEmail),
          invite_status: "0",
        }),
      });
      const data = await response.json();
      setInvEmails([]);
      setInvEmail("");
      setIsInviteModalOpen(false);
      if (response.ok) {
        if (data.alreadySentInvEmails.length > 0 || data.alreadyRegisteredInvEmails.length > 0) {
          const alreadySentInvEmails = data.alreadySentInvEmails.join(", ");
          const alreadyRegisteredInvEmails = data.alreadyRegisteredInvEmails.join(", ");
          const successSentInvEmails = data.successSentInvEmails.join(", ");
          if (alreadySentInvEmails) {

            setAlreadySentInvEmailsList(data.alreadySentInvEmails);
            const sentInvEmailsList = data.alreadySentInvEmails.map((entry) => entry).join(", ");
            handleOpenResendInviteModal();
            toast.warning(`Invitation already sent to ${sentInvEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (alreadyRegisteredInvEmails) {
            toast.warning(`User with email ${alreadyRegisteredInvEmails} is already registered`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (successSentInvEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(`Invitation successfully sent to ${successSentInvEmails}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentInvEmails}`
            );
          }
        } else {
          const successSentInvEmails = data.successSentInvEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentInvEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsInvMailLoading(false);
      handleCloseInviteModal();
    }
  };

  const handleResendInvEmails = async () => {
    try {
      setIsInvMailLoading(true);
      const response = await fetch("/api/tenant/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          invEmails: alreadySentInvEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendInviteModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentInvEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle network or server error
      // Show an error message
      global.config.slackMessage(error.toString());
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsInvMailLoading(false);
      handleCloseResendInviteModal();
    }
  };

  const handleAddInvEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidInvEmails = emailRegex.test(invEmail);
    if (invEmail && !isValidInvEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newInvEmailEntry = { invEmail: invEmail, id: Date.now() };
    setInvEmails([...invEmails, newInvEmailEntry]);
    // }
    setInvEmail("");
    // }
  };

  const handleEditInvEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = invEmails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedInvEmails = [...invEmails];
      // Update the specified field of the email entry
      updatedInvEmails[index][field] = value;
      // Update the state with the new array
      setInvEmails(updatedInvEmails);
    }
  };

  const handleRemoveInvEmail = (id) => {
    const filteredInvEmails = invEmails.filter((obj) => obj.id !== id);
    setInvEmails(filteredInvEmails);
  };

  const handleRemoveLatestInvEmail = () => {
    const updatedInvEmails = [...invEmails];
    const lastInvEmail = updatedInvEmails.pop(); // Remove the last element
    setInvEmails(updatedInvEmails);
    // Set state variables with the latest values
    setInvEmail(lastInvEmail.invEmail);
  };

  // Function to remove an email from the list
  const handleRemoveResendInvEmail = (invEmailToRemove) => {
    const updatedInvEmails = alreadySentInvEmailsList.filter((invEmail) => invEmail !== invEmailToRemove);
    setAlreadySentInvEmailsList(updatedInvEmails);
  };

  const handleOpenResendInviteModal = () => {
    // Set the list of already sent emails here
    setIsResendInviteModalOpen(true);
  };

  const handleCloseResendInviteModal = () => {
    setIsResendInviteModalOpen(false);
    setAlreadySentInvEmailsList([]);
  };

  const handleOpenInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setInvEmails([]);
    setInvEmail("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    if (bulkImportFile.size > maxFileSize) {
      toast.error("File size exceeds the 5 MB limit.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/import/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (
          response.data.existingUserEmails &&
          response.data.existingUserEmails.length > 0
        ) {
          toast.error(
            `The following emails are already registered: ${response.data.existingUserEmails.join(
              ", "
            )}`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          if (
            response.data.filteredUsers &&
            response.data.filteredUsers.length > 0
          ) {
            toast.success(
              `Inserted ${response.data.filteredUsers.length} records`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        } else {
          toast.success("File uploaded successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        refetchOwnerProfiles();
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false); // Reset loading state after upload process completes
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null);
    setBulkImportFileName("");
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0]);
      setBulkImportFileName(file.name);
    } else {
      setBulkImportFile(null);
      setBulkImportFileName("");
    }
  };

  const filteredData = users.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName = item.commercial_name || item.ownerName;

    const hasMatch =
      (fullName?.toLowerCase().includes(searchTerm) ||
        item.subDomain?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm) ||
        item.ownerName?.toLowerCase().includes(searchTerm)) &&
      (selectedStatus === "Active" ? item.status : true) &&
      (selectedStatus === "Inactive" ? !item.status : true);

    const isUserFilterMatched =
      userFilter === "All" || selectedTeams.includes(item._id);
   
    return (
      hasMatch &&
      isUserFilterMatched
    
    );
  });

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "User Name": item.first_name
          ? `${item.first_name} ${item.middle_name && item.middle_name !== "null"
            ? item.middle_name + " "
            : ""
          }${item.last_name}`
          : item.company_first_name
            ? `${item.company_first_name} ${item.company_last_name || ""}`
            : `${item.company_name || ""} (Company)`,
        Email: item.email,
        "Company Name": item.company_name,
        "User Category":
          item.user_category === userCategory.Individual
            ? "Individual"
            : item.user_category === userCategory.Business
              ? "Business"
              : item.user_category === userCategory.Vendor
                ? "Vendor"
                : item.user_category === userCategory.Client
                  ? "Client"
                  : "",
        Company: item?.user_wx_comapny?.wx_company
          ? item.user_wx_comapny.wx_company
          : "",
        Role: item.role_id.user_type,
        "Job Title": item.job_title,
        Department: item.department.user_department,
        "Date of Birth": item.dob
          ? moment(item.dob)
            .tz(global.config.getTimeZone())
            .format("DD/MM/YYYY")
          : "",
        "Passport Number": item.passport_no,
        "Registration Number": item.register_number,
        Mobile: item.mobile_no,
        Address: item.address,
        Pincode: item.pincode,
        Country: item.country,
        State: item.state,
        City: item.city,
        Currency: item.payment_currency,
        "Type of contract": item.typeOfContract
          ? item.typeOfContract.typeOfContract
          : " ",
        "Monthly Hours": `${item.monthly_hours || 168} hrs`,
        Compensation: item.cost
          ? item.cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "Overhead Cost per Hour": item.overhead_cost
          ? item.overhead_cost.toLocaleString("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          })
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.payment_currency || "USD",
          }).format(0),
        "VAT (%)": `${item.vat} %`,
        "Vat Id": item.vat_number ? item.vat_number : " ",
        "Is Client Active": item.is_active ? "Yes" : "No",
        "Created at": moment(parseInt(item.created_at)).format(
          "DD/MM/YYYY, hh:mm:ss A"
        ),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);

      // Calculate column widths based on the maximum length of the data in each column
      const getMaxLength = (arr, field) =>
        Math.max(
          ...arr.map((item) => (item[field] || "").toString().length),
          field.length
        );

      const headers = Object.keys(modifiedLog[0]);
      const colWidths = headers.map((header) => ({
        wch: getMaxLength(modifiedLog, header) + 5, // Add padding to each column
      }));

      worksheet["!cols"] = colWidths;

      // Add alignment and font styling for header
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < headers.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center";
        style.alignment.vertical = "center";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size for data
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      const fileName = `Users_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Clients Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="user-outer-overview invoice-outer-invoice">
        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <Link to="/master-user/tenants/register">
                <span className="icon-invoice">
                  <img
                    src={UserIcon}
                    alt="Create Invoice"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/uploads/lowProfileImage/user_default.png";
                    }}
                  />
                </span>
                New Tenant
              </Link>
              <a className="btn-forpop-initem" onClick={handleOpenInviteModal}>
                <span className="icon-invoice">
                  <img src={InviteIcon} alt="Export" />
                </span>
                Invite Tenant
              </a>

              <Modal
                isOpen={isInviteModalOpen}
                onRequestClose={!isInvMailLoading ? handleCloseInviteModal : undefined}
                contentLabel="Invite a User"
                className="user-invite-modal-main-outer"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-top-section-user-invite">
                    <div className="modal-invite-head">
                      <h2 className="">Invite a new tenant</h2>
                    </div>
                    <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                      <a className="" onClick={handleCloseInviteModal} disabled={isInvMailLoading}>
                        <img src={crssrImage} alt="Edit Icon" />{" "}
                      </a>
                    </div>

                  </div>

                  <form onSubmit={handleInviteSubmit}>
                    <div>
                      {invEmails.map((obj) => (
                        <div key={obj.id} className="the-modal-inuser">

                          <div className="user-add-outer-innner">
                            <div className="invite-user">
                              <label htmlFor="grid-password" className="">
                                Email Address
                              </label>
                              <input
                                type="email"
                                id={`email-${obj.id}`}
                                className=""
                                name="email"
                                required
                                placeholder="Enter Email ID"
                                value={obj.invEmail}
                                onChange={(e) => handleEditInvEmail(obj.id, 'invEmail', e.target.value)}
                              />
                            </div>
                            <div
                              className="invite-user-delete"
                              onClick={() => handleRemoveInvEmail(obj.id)}
                            >
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </div>
                          </div>

                        </div>
                      ))}
                      <div className="the-modal-inuser">
                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              name="email"
                              className=""
                              placeholder="Enter Email ID"
                              value={invEmail}
                              onChange={(e) => setInvEmail(e.target.value)}
                            />
                          </div>
                          {invEmails.length > 0 && (
                            <div className="invite-user-delete" onClick={handleRemoveLatestInvEmail}>
                              <img src={dletImage} alt="delete-icon" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="add-email-sec-modal" onClick={handleAddInvEmail} style={{ cursor: "pointer" }}>
                        <img src={addicon} alt="Edit Icon" /><div className="add-icon-main-text">Add new email</div>
                      </div>
                    </div>
                    <div className="invite-mail-popup-btn" >
                      <button type="submit" className="" disabled={isInvMailLoading}>
                        {isInvMailLoading ?
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div> : "Send Invite"}
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>

              <Modal
                isOpen={isResendInviteModalOpen}
                onRequestClose={!isInvMailLoading ? handleCloseResendInviteModal : undefined}
                contentLabel="Resend Invitations"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a
                      className=""
                      onClick={handleCloseResendInviteModal}
                      disabled={isInvMailLoading}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-invite-head">
                    <h2 className="">Resend Invitations</h2>
                  </div>
                  {/* List the already sent emails here */}
                  <div>
                    <div className="the-modal-inuser">
                      <label htmlFor="grid-password" className="">
                        Email
                      </label>
                      {alreadySentInvEmailsList.map((email) => (
                        <div key={email} className="the-modal-inusers">

                          <div className="resend-invitation">
                            <input
                              type="email"
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={email}
                              disabled
                            />
                            {alreadySentInvEmailsList.length > 1 && (
                              <button className="del-resend" onClick={() => handleRemoveResendInvEmail(email)}>
                                <img src={dletImage} alt="delete-icon" />{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="sprint-btn-outer resend-invoice">
                    <button
                      type="button"
                      onClick={handleCloseResendInviteModal}
                      disabled={isInvMailLoading}
                      className="submit-client cancel-sprint"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="submit-client save-sprint"
                      disabled={isInvMailLoading}
                      onClick={handleResendInvEmails}
                    >
                      {isInvMailLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Resend Invitations"}
                    </button>
                  </div>
                </div>
              </Modal>

             
            </div>
          </div>

        </div>
        <div className="invoice-filter">
          <div className="invoice-filter-inner-section no-flter-all">
            <div className="filter-inner invoice-status">
              <label for="user">User</label>
              <div className="custom-dropdown1" ref={teamDropdownRef}>
                <div className="drop-down-inner">
                  <div className="selected-options1" onClick={toggleTeamDropdown} >
                    {selectedTeams.length === 0 ? (
                      <div className="placeholder">Select</div>
                    ) : (
                      <>
                        <div
                          className="selected-option1"
                          key={selectedTeams[0]}
                        >
                          {selectedTeams.length === ownerProfiles.length ? (
                            `All`
                          ) : (
                            <>
                              <TruncateFilterFieldView
                                textContent={ownerProfiles.find((item) => item._id === selectedTeams[0])?.commercial_name || ownerProfiles.find((item) => item._id === selectedTeams[0]).ownerName}
                              />
                              {selectedTeams.length > 1 &&
                                selectedTeams.length !== ownerProfiles.length && (
                                  <div className="selected-option-count">
                                    {selectedTeams.length - 1}+
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {isTeamDropdownOpen && (
                    <div className="dropdown-list1">
                      {ownerProfiles.length > 0 && (
                        <>
                          <label className="checkbox-label1" key="selectAll" >
                            <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                            All Teams
                          </label>
                          {ownerProfiles
                            .slice()
                            .sort((a, b) => {
                              const nameA = a.commercial_name || a.ownerName;
                              const nameB = b.commercial_name || b.ownerName;
                              return nameA.localeCompare(nameB);
                            })
                            .map((team) => (
                              <label className="checkbox-label1" key={team._id}>
                                <input
                                  type="checkbox"
                                  checked={selectedTeams.includes(team._id)}
                                  onChange={() => handleCheckboxTeamChange(team._id)}
                                />
                                {team.commercial_name || team.ownerName}
                              </label>
                            ))}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            
            <div className="filter-inner invoice-type">
              <label for="invoice-type">Status</label>
              <div className="custom-dropdown1" ref={statusDropdownRef}>
                <div className="drop-down-inner">
                  <div
                    className="selected-options1"
                    onClick={toggleStatusDropdown}
                  >
                    {selectedStatus ? selectedStatus : "All"}
                  </div>
                  {statusDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="type-list">
                        <li onClick={() => selectStatusOption(null)}>All</li>
                        <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                        <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="filter-invoice-right apply-fliter-right">
            <button onClick={toggleFilterModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="17"
                viewBox="0 0 13 17"
                fill="none"
              >
                <path
                  d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                  fill="#6479F8"
                />
                <path
                  d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                  fill="#6479F8"
                />
                <path
                  d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                  fill="#6479F8"
                />
              </svg>
              All Filters
            </button>
          </div>
          <Modal
            isOpen={showFilterModal}
            onRequestClose={checkAndShowConfirmationModal}
            contentLabel="Filter Modal"
            className="new-filter-modal"
            style={{
              content: {
                height: "50%",
                width: "50%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-25%, -25%)",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                padding: "20px",
                // overflow: "auto",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <div className="new-nodal-invoice-outer-sec">

              <div className="new-modal-top-sec">
                <div className="new-modal-heading">
                  All filters
                </div>
                <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M1 13L13 1" stroke="black" />
                    <path d="M1 0.999999L13 13" stroke="black" />
                  </svg>{" "}
                </a>
              </div>
              <div className="invoice-filter-inner-section">
                <div className="filter-inner invoice-status">
                  <label for="user">User</label>
                  <div className="custom-dropdown1" ref={teamModalDropdownRef}>
                    <div className="drop-down-inner">
                      <div className="selected-options1" onClick={toggleTeamModalDropdown} >
                        {selectedTeams.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div
                              className="selected-option1"
                              key={selectedTeams[0]}
                            >
                              {selectedTeams.length === ownerProfiles.length ? (
                                `All`
                              ) : (
                                <>
                                  <TruncateFilterFieldView
                                    textContent={ownerProfiles.find((item) => item._id === selectedTeams[0])?.commercial_name || ownerProfiles.find((item) => item._id === selectedTeams[0]).ownerName}
                                  />
                                  {selectedTeams.length > 1 &&
                                    selectedTeams.length !== ownerProfiles.length && (
                                      <div className="selected-option-count">
                                        {selectedTeams.length - 1}+
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {isTeamModalDropdownOpen && (
                        <div className="dropdown-list1">
                          {ownerProfiles.length > 0 && (
                            <>
                              <label className="checkbox-label1" key="selectAll" >
                                <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                                All Teams
                              </label>
                              {ownerProfiles
                                .slice()
                                .sort((a, b) => {
                                  const nameA = a.commercial_name || a.ownerName;
                                  const nameB = b.commercial_name || b.ownerName;
                                  return nameA.localeCompare(nameB);
                                })
                                .map((team) => (
                                  <label className="checkbox-label1" key={team._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedTeams.includes(team._id)}
                                      onChange={() => handleCheckboxTeamChange(team._id)}
                                    />
                                    {team.commercial_name || team.ownerName}
                                  </label>
                                ))}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                
                <div className="filter-inner invoice-type">
                  <label for="invoice-type">Status</label>
                  <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                    <div className="drop-down-inner">
                      <div
                        className="selected-options1"
                        onClick={toggleStatusModalDropdown}
                      >
                        {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'All') : null)}
                      </div>
                      {statusModalDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="type-list">
                            <li onClick={() => selectStatusOption(null)}>All</li>
                            <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                            <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Modal
                  className="delete-modal"
                  isOpen={showConfirmationModal}
                  onRequestClose={handleCancel}
                  contentLabel="Confirm Delete"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "36%",
                      left: "50%",
                      transform: "translate(-24.75%, 0%)",
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <h2>Apply or Discard changes?</h2>
                    <p>
                      Do you want to Apply or Discard filter changes?
                    </p>

                    <div className="delete-modal-buttons">
                      <button className="delete" onClick={handleApplyConfirm} >
                        Apply Changes
                      </button>
                      <button onClick={clearFilters}>
                        Discard Changes
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>Clear All</button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                  <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                  <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                  <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                </svg>
                Apply Filters
              </button>
            </div>
          </Modal>
          <div className="search-sec search-sec-user-overview">
            <div class="search-container">
              <input
                type="text"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                class="search-input"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card-outer-user">
              <h3>All accounts</h3>
            </div>
            <div className="card">
              {isLoading === true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <DataTable
                  value={filteredData}
                  first={first}
                  rows={rows}
                  removableSort
                  paginator={true}
                  paginatorPosition="bottom"
                  totalRecords={filteredData.length}
                  onPage={(e) => setFirst(e.first)}
                  tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                  paginatorClassName="recent-invoices-pagination"
                >
                  <Column
                    body={useRenderMemberName}
                    field="ownerName"
                    sortField="ownerName"
                    header="Admin Name"
                    sortable
                    style={{ minWidth: "300px", paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="ownerName"
                    header="Company Name"
                    sortable
                    style={{ paddingRight: "20px" }}
                  ></Column>
                  <Column
                    field="subDomain"
                    header="Sub-domain"
                    sortable
                    style={{ paddingRight: "20px" }}
                  ></Column>
                  <Column
                    field="_id"
                    body={RenderMasterTenantCTA}
                    style={{ paddingRight: "8px", width: "80px" }}
                  ></Column>
                  <Column
                    field="subscriptionType"
                    header="Subscription Type"
                    body={(rowData) => rowData.subscriptionType || "Pro"}
                    sortable
                    style={{ paddingRight: "20px", paddingLeft: "20px" }}
                  ></Column>
                  <Column
                    field="created_at"
                    body={dateMasterCreateConvert}
                    header="Joined Date"
                    className="fixed-sec3"
                    sortable
                    style={{ paddingRight: "44px", left: "22px" }}
                  ></Column>
                  <Column
                    field="subscriptionStatus"
                    header="Subscription Status"
                    body={(rowData) => renderUserSubscriptionStatus(rowData.subscriptionStatus || "Free Trial")}
                    sortable
                    style={{ paddingRight: "20px" }}
                  ></Column>
                  <Column
                    field="status"
                    body={(rowData) => renderUserStatus(rowData)}
                    header="Account Status"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Tenants;
