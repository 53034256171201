import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { calculateUserHours } from './Services/reportTimeWorked';


const data = [
  { name: 'Rent', billableHours: 44, nonBillableHours: 24 },
  { name: 'Utilities', billableHours: 55, nonBillableHours: 39 },
  { name: 'Groceries', billableHours: 13, nonBillableHours: 30 },
  { name: 'Transport', billableHours: 43, nonBillableHours: 50 },
  { name: 'Entertainment', billableHours: 46, nonBillableHours: 27 },
  { name: 'Healthcare', billableHours: 55, nonBillableHours: 40 },
  { name: 'Miscellaneous', billableHours: 32, nonBillableHours: 18 },
];

const UserHourGraph = ({ trackReport, trackReportLoading, trackReportError }) => {
  const reportingTimeWorked = useSelector((state) => state.reportingTimeWorked);

  const processedData = useMemo(() => {
    // return calculateUserHours(
    //   trackReport, reportingTimeWorked
    // );
    return calculateUserHours(trackReport, reportingTimeWorked).map(item => ({
      ...item,
      totalHours: item.billableHours + item.nonBillableHours
    }));
  }, [trackReport, reportingTimeWorked]);

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getTextWidth = (text, font = "12px Arial") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  };

  const getMaxNameWidth = (data, key) => {
    const longestName = data.reduce((max, item) =>
      item[key].length > max.length ? item[key] : max, ""
    );
    return getTextWidth(longestName);
  };

  const maxNameWidth = useMemo(() => getMaxNameWidth(processedData, "name"), [processedData]);

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;

  if (trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.billableHours, item.nonBillableHours)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }
  // const xAxisTicks = calculateXTicks(processedData);
  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50; // Adjust this value to set the height per bar
    const baseHeight = 100; // Add some base height for padding and labels
    return dataLength * rowHeight + baseHeight;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 30,
            left: maxNameWidth - 50,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={100}
            tickMargin={10} 
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <Legend />
          <Bar dataKey="nonBillableHours" name="Non-billable hours" stackId="a" fill="#C7B6F6">
            <LabelList
              dataKey="nonBillableHours"
              position="right"
              formatter={(value, entry) => {
                if (entry && entry.payload && entry.payload.billableHours === 0) {
                  return changeDurationFormat(value);
                }
                return null;
              }}
            />
          </Bar>
          <Bar dataKey="billableHours" name="Billable hours" stackId="a" fill="#86ABE1">
            {/* <LabelList dataKey="billableHours" position="right" formatter={(value) => changeDurationFormat(value)} /> */}
            <LabelList dataKey="totalHours" position="right" formatter={(value) => changeDurationFormat(value)} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default UserHourGraph;
