import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';


const API_BASE_URL = '/api';
// Fetch Projects by ID
export const useProjectsById = (token) => {

    return useQuery('timeOffRequests', async () => {
        const response = await axios.get(`${API_BASE_URL}/time-off-request/view`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useGetProjectThreshold = ({projectId, thresholdType, token}) => {
    return useQuery(
        ["projectThreshold", projectId, thresholdType],
        async () => {
            const response = await axios.post(
                "/api/get/project/thresholds",
                { projectId, thresholdType },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return response.data;
        },
        {
            enabled: !!projectId && !!thresholdType, // Ensures API call happens only when both values are available
            onError: (error) => {
                global.config.slackMessage(error.toString());
            },
        }
    );
};