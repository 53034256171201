import axios from "axios";
import Modal from "react-modal";
import "../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import Close from "../../../images/icons/black-close.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import Tick from "../../../images/singleinvoice/tick-green.svg";
import { logout, reset } from "../../../features/auth/authSlice";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../features/projects/projectSlice.js";
import { useFetchClientProjectManager } from "../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import userType from "../../../constants/userType.js";
import { useGetTeams } from "../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";


const NewProjectModal = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [discription, setDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [budget, setBudget] = useState("");
  const [timeLoading, setTimeLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [timeChecked, setTimeChecked] = useState(false);
  const [budgetChecked, setBudgetChecked] = useState(false);
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [budgetThreshold, setBudgetThreshold] = useState(0);

  const [selectedTimeThresholdTeamsEdit, setSelectedTimeThresholdTeamsEdit] = useState([]);
  const [selectTimeThresholdAllTeam, setSelectTimeThresholdAllTeam] = useState(false);
  const [isTimeThresholdTeamDropdownOpen, setIsTimeThresholdTeamDropdownOpen] = useState(false);
  const timeThresholdTeamDropdownRef = useRef(null);

  const [selectedBudgetThresholdTeamsEdit, setSelectedBudgetThresholdTeamsEdit] = useState([]);
  const [selectBudgetThresholdAllTeam, setSelectBudgetThresholdAllTeam] = useState(false);
  const [isBudgetThresholdTeamDropdownOpen, setIsBudgetThresholdTeamDropdownOpen] = useState(false);
  const budgetThresholdTeamDropdownRef = useRef(null);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  // const [totalHours, setTotalHours] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");

  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const projectNameRef = useRef(null);

  const [shouldFetchProject, setShouldFetchProject] = useState(false);

  const token = useSelector(
    (state) => state.auth.token
  )

  const { data: userId = [], isLoading: isUserLoading, refetch: refetchProjectManager } = useFetchClientProjectManager(token, id);
  const { data: allTeams = [], isLoading: teamLoading, isError: teamError } = useGetTeams(token);

  // Filter teams where `is_active: true` and have at least one active user
  const teams = allTeams.filter(team => team.is_active === true);

  const handleOpenProjectModal = () => {
    setIsProjectModalOpen(true);
  };
  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectName("");
    setDiscription("");
    setBillable(true);
    // setBudget("");
    setSelectedProjectManagers([]);
    // setTotalHours("");
    setSprint("");
    setSprintDiscription("");
    setProjectNameIsValid(false);
    setTimeThreshold(0);
    setSelectedTimeThresholdTeamsEdit([]);
    setBudgetThreshold(0);
    setSelectedBudgetThresholdTeamsEdit([]);
    setTimeChecked(false);
    setBudgetChecked(false);
  };

  useEffect(() => {

    const radioLabels = document.querySelectorAll(".radio-option label");
    radioLabels.forEach((label) => {
      label.addEventListener("click", (e) => {
        const radioInput = label.previousElementSibling;
        radioInput.checked = true;
      });
    });
  }, [userId, id]);

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}` : (item.company_first_name ? `${item.company_first_name + (item.company_last_name && item.company_last_name)}` : `${item.company_name}(Company)`),
      value: item._id,
    }));

  const toggleTimeThresholdTeamDropdown = () => {
    setIsTimeThresholdTeamDropdownOpen(!isTimeThresholdTeamDropdownOpen);
  };

  const handleTimeCheckboxChange = async () => {
    const newTimeChecked = !timeChecked;
    setTimeChecked(newTimeChecked);

    if (newTimeChecked) {
      if (timeThreshold === 0 && selectedTimeThresholdTeamsEdit.length === 0) {
        setTimeLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "time" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setTimeThreshold(data?.thresholdPercentage || 0);
          setSelectedTimeThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setTimeLoading(false); // Stop loading
        }
      }
    } else {
      setTimeThreshold(timeThreshold);
      setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
    }
  };

  const handleBudgetCheckboxChange = async () => {
    const newBudgetChecked = !budgetChecked;
    setBudgetChecked(newBudgetChecked);

    if (newBudgetChecked) {
      if (budgetThreshold === 0 && selectedBudgetThresholdTeamsEdit.length === 0) {
        setBudgetLoading(true);
        try {
          const response = await axios.post(
            "/api/general/thresholds/type",
            { thresholdType: "budget" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = response.data?.data;
          setBudgetThreshold(data?.thresholdPercentage || 0);
          setSelectedBudgetThresholdTeamsEdit(data?.alertTo || []);
        } catch (error) {
          console.error("Error fetching time threshold data:", error);
        } finally {
          setBudgetLoading(false); // Stop loading
        }
      }
    } else {
      setBudgetThreshold(budgetThreshold);
      setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
    }
  };

  const handleCheckboxTimeThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedTimeThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTimeThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectTimeThresholdAllTeamChange = () => {
    if (!selectTimeThresholdAllTeam) {
      setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedTimeThresholdTeamsEdit([]);
    }
    setSelectTimeThresholdAllTeam(!selectTimeThresholdAllTeam);
  };

  const handleTimeThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedTimeThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectTimeThresholdAllTeam(selectedTimeThresholdTeamsEdit.length === teams.length);
      // if (selectedTimeThresholdTeamsEdit.length === teams.length) {
      //     setSelectedTimeThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedTimeThresholdTeamsEdit(selectedTimeThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedTimeThresholdTeamsEdit, teams]);

  const toggleBudgetThresholdTeamDropdown = () => {
    setIsBudgetThresholdTeamDropdownOpen(!isBudgetThresholdTeamDropdownOpen);
  };

  const handleCheckboxBudgetThresholdTeamChange = (teamId) => {
    const updatedSelection = [...selectedBudgetThresholdTeamsEdit];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedBudgetThresholdTeamsEdit(updatedSelection);
  };

  const handleSelectBudgetThresholdAllTeamChange = () => {
    if (!selectBudgetThresholdAllTeam) {
      setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
    } else {
      setSelectedBudgetThresholdTeamsEdit([]);
    }
    setSelectBudgetThresholdAllTeam(!selectBudgetThresholdAllTeam);
  };

  const handleBudgetThresholdSelectByRole = (role) => {
    const filteredUsers = teams.filter((team) => team.role_id === role).map((team) => team._id);
    setSelectedBudgetThresholdTeamsEdit((prev) => {
      if (filteredUsers.every((id) => prev.includes(id))) {
        return prev.filter((id) => !filteredUsers.includes(id)); // Unselect if already selected
      } else {
        return [...new Set([...prev, ...filteredUsers])];
      }
    });
  };

  useEffect(() => {
    const updateSelectionStatus = () => {
      setSelectBudgetThresholdAllTeam(selectedBudgetThresholdTeamsEdit.length === teams.length);
      // if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
      //     setSelectedBudgetThresholdTeamsEdit(teams.map((team) => team._id));
      // } else {
      //     setSelectedBudgetThresholdTeamsEdit(selectedBudgetThresholdTeamsEdit);
      // }
    };

    // Call the updateSelectionStatus asynchronously
    Promise.resolve().then(() => updateSelectionStatus());
  }, [selectedBudgetThresholdTeamsEdit, teams]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTimeThresholdTeamDropdown = timeThresholdTeamDropdownRef.current && timeThresholdTeamDropdownRef.current.contains(event.target);
    const isClickInsideBudgetThresholdTeamDropdown = budgetThresholdTeamDropdownRef.current && budgetThresholdTeamDropdownRef.current.contains(event.target);
    // const isClickInsideSprintProjectDropdown = sprintProjectDropdownRef.current && sprintProjectDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintProjectDropdown) {
    //   setSprintProjectDropdownOpen(false);
    // }
    if (!isClickInsideTimeThresholdTeamDropdown) {
      setIsTimeThresholdTeamDropdownOpen(false);
    }
    if (!isClickInsideBudgetThresholdTeamDropdown) {
      setIsBudgetThresholdTeamDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleProjectSubmit = async () => {
    setIsSubmitLoading(true);
    let startdate, enddate;

    if (projectName === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        setIsSubmitLoading(false);
        return;
      }
      toast.error("Please fill all required fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsSubmitLoading(false);
      return;
    }
    if (timeChecked) {
      if (timeThreshold <= 0) {
        toast.error("Time threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for time threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    if (budgetChecked) {
      if (budgetThreshold <= 0) {
        toast.error("Budget threshold must be greater than 0", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
        toast.error("Please select at least one team for budget threshold alerts", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsSubmitLoading(false);
        return;
      }
    }
    const requestData = {
      project_name: projectName.trim(),
      client_name: props.clientId,
      discription,
      is_billable: billable,
      // budget,
      // total_hours: totalHours,
      project_manager: selectedProjectManagers.map(manager => manager.value),
    };
    const allSprints = [
      ...sprints,
      {
        sprint: sprint,
        sprintDiscription: sprintDiscription,
        sprintStartDate: startdate,
        sprintEndDate: enddate,
        id: Date.now(),
      },
    ]; // combine all Sprints including the last added one
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post("/api/project-details", {
        ...requestData,
        sprint: allSprints.map((obj) => obj.sprint),
        sprintDiscription: allSprints.map((obj) => obj.sprintDiscription),
        sprintStartDate: allSprints.map((obj) => obj.sprintStartDate),
        sprintEndDate: allSprints.map((obj) => obj.sprintEndDate),
      }, config);

      setSprints([]);
      setSprint("");
      if (response.status === 200) {
        const projectId = response.data?.id;

        if (timeChecked) {
          if (timeThreshold <= 0) {
            toast.error("Time threshold must be greater than 0", {
              position: "bottom-right",
              autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
          }

          if (!selectedTimeThresholdTeamsEdit || selectedTimeThresholdTeamsEdit.length === 0) {
            toast.error("Please select at least one team for time threshold alerts", {
              position: "bottom-right",
              autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
          }
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "projects",
            recordId: projectId,
            clientId: props.clientId,
            thresholdType: "time",
            thresholdPercentage: timeThreshold,
            alertTo: selectedTimeThresholdTeamsEdit,
            thresholdTimeStatus: timeChecked,
          }, config);
        }

        if (budgetChecked) {
          if (budgetThreshold <= 0) {
            toast.error("Budget threshold must be greater than 0", {
              position: "bottom-right",
              autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
          }

          if (!selectedBudgetThresholdTeamsEdit || selectedBudgetThresholdTeamsEdit.length === 0) {
            toast.error("Please select at least one team for budget threshold alerts", {
              position: "bottom-right",
              autoClose: 2000,
            });
            setIsSubmitLoading(false);
            return;
          }
          await axios.post("/api/project/sprint/threshold", {
            thresholdFor: "projects",
            recordId: projectId,
            clientId: props.clientId,
            thresholdType: "budget",
            thresholdPercentage: budgetThreshold,
            alertTo: selectedBudgetThresholdTeamsEdit,
            thresholdBudgetStatus: budgetChecked,
          }, config);
        }

        if (!sprint) {
          toast.success("Project added successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) added Successfully`);
          dispatch(setProjectAdded());
          handleCloseProjectModal();
        } else {
          global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) and Sprints added Successfully`);
          toast.success("Project and sprint added successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleCloseProjectModal();
        }
      } else {
        global.config.activityLog(window.location.href, 'Project', `Failed to add Project (${projectName}) successfully`);
        toast.error("Failed to add project and sprint successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw new Error("Failed to add project");
      }
    } catch (error) {
      console.error("Error adding project:", error);
      global.config.activityLog(window.location.href, "Project", `Failed to add Project (${projectName})`);
      toast.error("An error occurred while adding the project", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setShouldFetchProject(prev => !prev);
      setIsSubmitLoading(false);
      dispatch(setProjectAdded());
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (

    <div className="button-sec-invoice">
      <a className="new-client" href="#" onClick={handleOpenProjectModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Project
      </a>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal project-modal tracker-overview-mod"
          isOpen={isProjectModalOpen}
          onRequestClose={handleCloseProjectModal}
          contentLabel="New Project"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseProjectModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div>
              <div className="modal-client-head">
                <h2 className="">New Project</h2>
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Project Name
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      name="projectName"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      className={projectNameIsValid ? "input-validation-error form-input" : "form-input"}
                      ref={projectNameRef}
                    />
                    {projectNameIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Project Name Field Cannot be empty
                      </span>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={discription}
                      onChange={(e) => setDiscription(e.target.value)}
                      className="form-textarea"
                      rows="3"
                    ></textarea>
                  </div>
                  {/* <div className="form-field-client">
                    <label htmlFor="tome" className="form-label">
                      Time Allocated(in hours)
                    </label>
                    <input
                      type="number"
                      id="time"
                      name="time"
                      value={totalHours}
                      onChange={(e) => setTotalHours(e.target.value)}
                      className="form-input"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </div> */}
                  {/* <div className="form-field-client">
                    <label htmlFor="tome" className="form-label">
                      Budget
                    </label>
                    <input
                      type="number"
                      id="budget"
                      name="budget"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      className="form-input"
                      onWheel={numberInputOnWheelPreventChange}
                      
                    />
                  </div> */}
                  <div className="form-field-client">
                    <label htmlFor="projectManager" className="form-label">
                      Assigned Team
                    </label>
                    {isUserLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Select
                        isMulti
                        options={projectManagerOptions}
                        value={selectedProjectManagers}
                        onChange={(selectedOptions) => setSelectedProjectManagers(selectedOptions)}
                      />
                    )}
                  </div>
                  <div className="form-field-client radio-sec">
                    <label htmlFor="billable" className="form-label">
                      Billable
                    </label>
                    <div className="radio-container">
                      <div className="radio-option yes">
                        <input
                          type="radio"
                          id="yes"
                          name="billable"
                          value={billable}
                          checked={billable === true}
                          onChange={(e) => setBillable(true)}
                        />
                        <label htmlFor="yes">
                          Yes <img src={Tick} alt="Tick" />
                        </label>
                      </div>
                      <div className="radio-option no">
                        <input
                          type="radio"
                          id="no"
                          name="billable"
                          value={billable}
                          checked={billable === false}
                          onChange={(e) => setBillable(false)}
                        />
                        <label htmlFor="no">
                          No <img src={Close} alt="Edit Icon" />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="projectTimeframe" className="form-label">
                      Project Alerts
                    </label>
                    <div className="project-alert-outer">
                      {/* Time Alert */}
                      {/* onChange={() => setTimeChecked(!timeChecked)} */}
                      <div className="alert-section for-time-outer">
                        <label className="alert-label">
                          <input type="checkbox" checked={timeChecked} onChange={handleTimeCheckboxChange} />
                          <span>For time</span>
                        </label>

                        <div className={`outer-for-section ${!timeChecked ? "disabled" : ""}`}>
                          <div className="input-group">
                            <span>If project reaches</span>
                            {timeLoading ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <input
                                type="number"
                                value={timeThreshold}
                                // onChange={(e) => setTimeThreshold(e.target.value)}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                    setTimeThreshold(value);
                                  }
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                                min="0"
                                max="100"
                                step="1"
                              />
                            )}
                            <span>% of estimate</span>
                          </div>

                          <div className="dropdown-group">
                            <span>Alert</span>
                            <div className="custom-dropdown1" ref={timeThresholdTeamDropdownRef}>
                              <div className="drop-down-inner" onClick={toggleTimeThresholdTeamDropdown}>
                                <div className="selected-options1">
                                  {selectedTimeThresholdTeamsEdit.length === 0 ? (
                                    <div className="placeholder">Select</div>
                                  ) : (
                                    <div className="selected-option1">
                                      {timeLoading ? (
                                        <div className="outter-load-table">
                                          <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      ) : (() => {
                                        const roles = {
                                          Super_Admin: "All Super Admin",
                                          Admin: "All Admin",
                                          Project_Manager: "All Project Manager",
                                          Team_Manager: "All Team Manager",
                                          User: "All Users",
                                        };

                                        // Check if all users of a specific role are selected
                                        for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                          const roleUsers = teams
                                            .filter((team) => team.role_id === userType[roleKey])
                                            .map((team) => team._id);

                                          if (
                                            roleUsers.length > 0 &&
                                            roleUsers.every((id) => selectedTimeThresholdTeamsEdit.includes(id)) &&
                                            selectedTimeThresholdTeamsEdit.length === roleUsers.length
                                          ) {
                                            return roleLabel;
                                          }
                                        }

                                        // If all team members are selected
                                        if (selectedTimeThresholdTeamsEdit.length === teams.length) {
                                          return "All Team";
                                        }

                                        // Get the first selected user name
                                        const firstSelectedUser =
                                          teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0])
                                            ? (() => {
                                              const user = teams.find((item) => item._id === selectedTimeThresholdTeamsEdit[0]);

                                              return user.first_name
                                                ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                            })()
                                            : "No Users selected";

                                        return (
                                          <>
                                            {firstSelectedUser}
                                            {selectedTimeThresholdTeamsEdit.length > 1 && (
                                              <div className="selected-option-count">
                                                {selectedTimeThresholdTeamsEdit.length - 1}+
                                              </div>
                                            )}
                                          </>
                                        );
                                      })()}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {isTimeThresholdTeamDropdownOpen && (
                                <div className="dropdown-list1">
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectTimeThresholdAllTeam}
                                      onChange={handleSelectTimeThresholdAllTeamChange}
                                    />
                                    All Teams
                                  </label>
                                  {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                    .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                    .map((role) => (
                                      <label className="checkbox-label1" key={role}>
                                        <input
                                          type="checkbox"
                                          checked={teams
                                            .filter((team) => team.role_id === userType[role])
                                            .every((team) => selectedTimeThresholdTeamsEdit.includes(team._id))}
                                          onChange={() => handleTimeThresholdSelectByRole(userType[role])}
                                        />
                                        All {role.replace("_", " ")}
                                      </label>
                                    ))}
                                  {teams.map((team) => (
                                    <label className="checkbox-label1" key={team._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedTimeThresholdTeamsEdit.includes(team._id)}
                                        onChange={() => handleCheckboxTimeThresholdTeamChange(team._id)}
                                      />
                                      {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Budget Alert */}
                      <div className="alert-section for-budget-outer">
                        <label className="alert-label">
                          <input type="checkbox" checked={budgetChecked} onChange={handleBudgetCheckboxChange} />
                          <span>For budget</span>
                        </label>

                        <div className={`outer-for-section ${!budgetChecked ? "disabled" : ""}`}>
                          <div className="input-group">
                            <span>If project reaches</span>
                            {budgetLoading ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <input
                                type="number"
                                value={budgetThreshold}
                                // onChange={(e) => setBudgetThreshold(e.target.value)}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
                                    setBudgetThreshold(value);
                                  }
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                                min="0"
                                max="100"
                                step="1"
                              />
                            )}
                            <span>% of estimate</span>
                          </div>

                          <div className="dropdown-group">
                            <span>Alert</span>
                            <div className="custom-dropdown1" ref={budgetThresholdTeamDropdownRef}>
                              <div className="drop-down-inner" onClick={toggleBudgetThresholdTeamDropdown}>
                                <div className="selected-options1">
                                  {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                    <div className="placeholder">Select</div>
                                  ) : (
                                    <>
                                      <div className="selected-option1">
                                        {selectedBudgetThresholdTeamsEdit.length === 0 ? (
                                          <div className="placeholder">Select</div>
                                        ) : (
                                          <div className="selected-option1">
                                            {budgetLoading ? (
                                              <div className="outter-load-table">
                                                <ThreeDots
                                                  height="18"
                                                  width="20"
                                                  radius="9"
                                                  color="#6479f9"
                                                  ariaLabel="three-dots-loading"
                                                  wrapperStyle={{}}
                                                  wrapperClassName=""
                                                  visible={true}
                                                />
                                              </div>
                                            ) : (() => {
                                              const roles = {
                                                Super_Admin: "All Super Admin",
                                                Admin: "All Admin",
                                                Project_Manager: "All Project Manager",
                                                Team_Manager: "All Team Manager",
                                                User: "All Users",
                                              };

                                              // Check if all users of a specific role are selected
                                              for (const [roleKey, roleLabel] of Object.entries(roles)) {
                                                const roleUsers = teams
                                                  .filter((team) => team.role_id === userType[roleKey])
                                                  .map((team) => team._id);

                                                if (
                                                  roleUsers.length > 0 &&
                                                  roleUsers.every((id) => selectedBudgetThresholdTeamsEdit.includes(id)) &&
                                                  selectedBudgetThresholdTeamsEdit.length === roleUsers.length
                                                ) {
                                                  return roleLabel;
                                                }
                                              }

                                              // If all team members are selected
                                              if (selectedBudgetThresholdTeamsEdit.length === teams.length) {
                                                return "All Team";
                                              }

                                              // Get the first selected user name
                                              const firstSelectedUser =
                                                teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0])
                                                  ? (() => {
                                                    const user = teams.find((item) => item._id === selectedBudgetThresholdTeamsEdit[0]);

                                                    return user.first_name
                                                      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim()
                                                      : `${user.company_first_name ? user.company_first_name + (user.company_last_name ? " " + user.company_last_name : "") : user.company_name}`;
                                                  })()
                                                  : "No Users selected";

                                              return (
                                                <>
                                                  {firstSelectedUser}
                                                  {selectedBudgetThresholdTeamsEdit.length > 1 && (
                                                    <div className="selected-option-count">
                                                      {selectedBudgetThresholdTeamsEdit.length - 1}+
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })()}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              {isBudgetThresholdTeamDropdownOpen && (
                                <div className="dropdown-list1">
                                  <label className="checkbox-label1" key="selectAll">
                                    <input
                                      type="checkbox"
                                      checked={selectBudgetThresholdAllTeam}
                                      onChange={handleSelectBudgetThresholdAllTeamChange}
                                    />
                                    All Teams
                                  </label>
                                  {["Super_Admin", "Admin", "Project_Manager", "Team_Manager", "User"]
                                    .filter((role) => teams.some((team) => team.role_id === userType[role]))
                                    .map((role) => (
                                      <label className="checkbox-label1" key={role}>
                                        <input
                                          type="checkbox"
                                          checked={teams
                                            .filter((team) => team.role_id === userType[role])
                                            .every((team) => selectedBudgetThresholdTeamsEdit.includes(team._id))}
                                          onChange={() => handleBudgetThresholdSelectByRole(userType[role])}
                                        />
                                        All {role.replace("_", " ")}
                                      </label>
                                    ))}
                                  {teams.map((team) => (
                                    <label className="checkbox-label1" key={team._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedBudgetThresholdTeamsEdit.includes(team._id)}
                                        onChange={() => handleCheckboxBudgetThresholdTeamChange(team._id)}
                                      />
                                      {team.first_name ? `${team.first_name} ${team.middle_name || ""} ${team.last_name}`.trim() : `${team.company_first_name ? team.company_first_name + (team.company_last_name ? " " + team.company_last_name : "") : team.company_name}`}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isSubmitLoading ?
                    (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button type="button" onClick={handleProjectSubmit} className="submit-client">
                        Create Project
                      </button>
                    )}
                </form>
              </div>
            </div>

          </div>
        </Modal>
      </div>
    </div>
  )
}

export default NewProjectModal;