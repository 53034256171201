import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

export const useFetchClientDetails = (token) => {
    return useQuery('clientDetails', async () => {
        const response = await axios.get('/api/client-details', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useClientPendingInvitesCount = (token) => {
    return useQuery('clientPendingInvitesCount', async () => {
        const response = await axios.get(`/api/client/get-pending-invites-count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.cli_pending_invites;
    });
};

export const useFetchClientExportDetails = (token) => {
    return useQuery('clientExportDetails', async () => {
        const response = await axios.get('/api/client/export/details', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export function useClientView(clientId, token) {
    return useQuery(
        ['clientView', clientId],
        async () => {
            if (!clientId) {
                // Return an empty state if clientId is null or undefined
                return null;
            }
            const response = await axios.get(`/api/client-details/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            enabled: !!clientId, // Disable the query if clientId is null or undefined
        }
    );
}

export function usePosition(token) {
    return useQuery('position', async () => {
        const response = await axios.get('/api/position-details', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useDeleteClientDetails(token) {
    const mutation = useMutation((selectedId) => {
        return axios.get(`/api/client-details/delete/${selectedId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

export function useUpdateClientDetails(token) {
    const mutation = useMutation((updatedClientDetails) => {
        return axios.put(`/api/client-details/update/${updatedClientDetails.selectedId}`, updatedClientDetails.data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

export function useUpdateAccManager(token) {
    const mutation = useMutation((updatedAccManager) => {
        return axios.put(`/api/clients/update-acc-manager/${updatedAccManager.id}`,{acc_manager: updatedAccManager.acc_manager}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

export function useUpdateProjManager(token) {
    const mutation = useMutation((updatedProjManager) => {
        return axios.put(`/api/client/update-project-managers/${updatedProjManager.id}`,{projectManagers: updatedProjManager.projectManagers, previousProjectManager: updatedProjManager.previousProjectManager}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

export function useRemoveTeamMember(token) {
    const mutation = useMutation((removedTeamMember) => {
        return axios.put(`/api/client/remove-project-manager/${removedTeamMember.id}`,{projectManagerId: removedTeamMember.teamMember }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}