import axios from "axios";
import Modal from "react-modal";
import "./TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "../../Dashboard/FilterCalendar.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Close from "../../../images/icons/black-close.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import Tick from "../../../images/singleinvoice/tick-green.svg";
import TimeTrackerProjectTable from "./TimeTrackerProjectTable.jsx";
import { logout, reset } from "../../../features/auth/authSlice.js";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const TimeTrackerProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isSprintModalOpen, setIsSprintModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [discription, setDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  // const [totalHours, setTotalHours] = useState("");
  const [isSprint, setIsSprint] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");
  const [sprintProjectName, setSprintProjectName] = useState("");
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [sprintProjectDropdownOpen, setSprintProjectDropdownOpen] =
    useState(false);
  const [sprintProjectSearchTerm, setSprintProjectSearchTerm] = useState("");
  const [sprintIsValid, setSprintIsValid] = useState(false);
  const sprintRef = useRef(null);
  const [sprintProjectNameIsValid, setSprintProjectNameIsValid] =
    useState(false);
  const sprintProjectNameRef = useRef(null);
  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const clientNameRef = useRef(null);
  const [shouldFetchProject, setShouldFetchProject] = useState(false);
  const clientDropdownRef = useRef(null);
  const sprintProjectDropdownRef = useRef(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };
  const [clientId, setClientId] = useState([]);
  const [userId, setUserId] = useState([]);
  const [projectId, setProjectId] = useState([]);
  // const [isUserLoading, setIsUserLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const token = useSelector((state) => state.auth.token);
  const handleOpenProjectModal = () => {
    setIsProjectModalOpen(true);
  };
  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectName("");
    setClientName("");
    setDiscription("");
    setBillable(true);
    setSelectedProjectManagers([]);
    // setTotalHours("");
    setSprint("");
    setSprintDiscription("");
    setDateRange(null);
    setProjectNameIsValid(false);
    setClientNameIsValid(false);
    setIsSprint(false);
  };
  const handleOpenSprintModal = () => {
    setIsSprintModalOpen(true);
  };
  const handleCloseSprintModal = () => {
    setIsSprintModalOpen(false);
    setSprint("");
    setSprintDiscription("");
    setSprintProjectName("");
    setDateRange(null);
    setSprintIsValid(false);
    setSprintProjectNameIsValid(false);
  };

  useEffect(() => {
    async function fetchClientId() {
      try {
        const response = await axios.get("/api/client-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClientId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    fetchClientId();
    const fetchUserId = async () => {
      try {
        // setIsUserLoading(true);
        const response = await axios.get("/api/user/user-project-manager", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          await dispatch(logout());
          await dispatch(reset());
          await dispatch(invoiceReset());
          await dispatch(headerReset());
          await dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        // setIsUserLoading(false);
      }
    };
    fetchUserId();
    async function fetchProjectId() {
      try {
        const response = await axios.get("/api/project-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjectId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    fetchProjectId();
    const radioLabels = document.querySelectorAll(".radio-option label");
    radioLabels.forEach((label) => {
      label.addEventListener("click", (e) => {
        const radioInput = label.previousElementSibling;
        radioInput.checked = true;
      });
    });
  }, []);

  const ranges = [
    {
      label: "today",
      value: [new Date(), new Date()],
    },
    {
      label: "yesterday",
      value: [subDays(new Date(), 1), subDays(new Date(), 1)],
    },
  ];

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${
            a.company_first_name
              ? a.company_first_name +
                (a.company_last_name && a.company_last_name)
              : a.company_name
          }`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${
            b.company_first_name
              ? b.company_first_name +
                (b.company_last_name && b.company_last_name)
              : b.company_name
          }`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name
        ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}`
        : item.company_first_name
        ? `${
            item.company_first_name +
            (item.company_last_name && item.company_last_name)
          }`
        : `${item.company_name}(Company)`,
      value: item._id,
    }));

  const handleToggleClientDropdown = () => {
    setClientSearchTerm("");
    setClientDropdownOpen(!clientDropdownOpen);
  };

  const handleClientSearchInputChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const filteredClients = clientId
    .filter((client) =>
      client.client_name.toLowerCase().includes(clientSearchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
    );

  const handleToggleSprintProjectDropdown = () => {
    setSprintProjectSearchTerm("");
    setSprintProjectDropdownOpen(!sprintProjectDropdownOpen);
  };

  const handleSprintProjectSearchInputChange = (e) => {
    setSprintProjectSearchTerm(e.target.value);
  };

  const filteredProjects = projectId
    .filter((project) =>
      project.project_name
        .toLowerCase()
        .includes(sprintProjectSearchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase())
    );

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideClienDropdown =
      clientDropdownRef.current &&
      clientDropdownRef.current.contains(event.target);
    const isClickInsideSprintProjectDropdown =
      sprintProjectDropdownRef.current &&
      sprintProjectDropdownRef.current.contains(event.target);
    if (!isClickInsideClienDropdown) {
      setClientDropdownOpen(false);
    }
    if (!isClickInsideSprintProjectDropdown) {
      setSprintProjectDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleProjectSubmit = async (valid) => {
    setIsSubmitLoading(true);
    setSprintIsValid(false);
    let startdate, enddate;
    if (valid) {
      setSprintIsValid(false);
    } else if (isSprint) {
      if (sprint === "") {
        if (sprint === "") {
          setSprintIsValid(true);
          sprintRef.current.focus();
          setIsSubmitLoading(false);
          return;
        }
        toast.error("Please fill sprint field", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsSubmitLoading(false);
        return;
      }

      if (dateRange === null) {
        startdate = null;
        enddate = null;
      } else {
        startdate = dateRange[0];
        enddate = dateRange[1];
      }
    }
    const requestData = {
      project_name: projectName.trim(),
      client_name: clientName,
      discription,
      is_billable: billable,
      // total_hours: totalHours,
      project_manager: selectedProjectManagers.map((manager) => manager.value),
    };
    const allSprints = [
      ...sprints,
      {
        sprint: sprint,
        sprintDiscription: sprintDiscription,
        sprintStartDate: startdate,
        sprintEndDate: enddate,
        id: Date.now(),
      },
    ]; // combine all Sprints including the last added one
    const response = await fetch("/api/project-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...requestData,
        sprint: allSprints.map((obj) => obj.sprint), // use all Sprints to send
        sprintDiscription: allSprints.map((obj) => obj.sprintDiscription), // use all Sprints to send
        sprintStartDate: allSprints.map((obj) => obj.sprintStartDate), // use all Sprints to send
        sprintEndDate: allSprints.map((obj) => obj.sprintEndDate), // use all Sprints to send
      }),
    });
    setSprints([]);
    setSprint("");
    if (response.ok) {
      if (!sprint) {
        toast.success("Project added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Project",
          `Project (${projectName}) added Successfully`
        );
        handleCloseProjectModal();
      } else {
        global.config.activityLog(
          window.location.href,
          "Project",
          `Project (${projectName}) and Sprints added Successfully`
        );
        toast.success("Project and sprint added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseProjectModal();
      }
    } else {
      global.config.activityLog(
        window.location.href,
        "Project",
        `Failed to add Project (${projectName}) successfully`
      );
      toast.error("Failed to add project and sprint successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (shouldFetchProject === false) {
      setShouldFetchProject(true);
    } else {
      setShouldFetchProject(false);
    }
    setIsSubmitLoading(false);
  };

  const handleSprintSubmit = async (event) => {
    event.preventDefault();
    setSprintIsValid(false);
    setSprintProjectNameIsValid(false);
    if (sprint === "" || sprintProjectName === "") {
      if (sprint === "") {
        setSprintIsValid(true);
        sprintRef.current.focus();
        return;
      } else if (sprintProjectName === "") {
        setSprintProjectNameIsValid(true);
        sprintProjectNameRef.current.focus();
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let startdate, enddate;
    if (dateRange === null) {
      startdate = null;
      enddate = null;
    } else {
      startdate = dateRange[0];
      enddate = dateRange[1];
    }

    const requestData = {
      sprint_name: sprint,
      discription: sprintDiscription,
      project: sprintProjectName,
      start_date: startdate,
      end_date: enddate,
    };
    try {
      const response = await axios.post(
        "/api/sprint-details/create",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Sprint added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Sprint",
          `Sprint "${sprint}" added successfully`
        );
        handleCloseSprintModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleProjectSprintSubmit = () => {
    setProjectNameIsValid(false);
    setClientNameIsValid(false);
    if (projectName === "" || clientName === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        return;
      } else if (clientName === "") {
        setClientNameIsValid(true);
        clientNameRef.current.focus();
        return;
      }
      toast.error("Please fill all Required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsSprint(true);
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="time-client-client">
      <div className="time-client-top">
        <div className="button-sec-invoice">
          <a className="new-client" href="#" onClick={handleOpenProjectModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="New Client" />
            </span>
            New Project
          </a>
          <a className="new-client" href="#" onClick={handleOpenSprintModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="New Client" />
            </span>
            New Sprint
          </a>
          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal project-modal"
              isOpen={isProjectModalOpen}
              onRequestClose={!isSprint ? handleCloseProjectModal : undefined}
              contentLabel="New Project"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  {!isSprint && (
                    <a className="" onClick={handleCloseProjectModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  )}
                </div>
                {!isSprint ? (
                  <div>
                    <div className="modal-client-head">
                      <h2 className="">New Project</h2>
                    </div>
                    <div className="form-field-client-outer">
                      <form>
                        <div className="form-field-client">
                          <label htmlFor="projectName" className="form-label">
                            Project Name
                          </label>
                          <input
                            type="text"
                            id="projectName"
                            name="projectName"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            className={
                              projectNameIsValid
                                ? "input-validation-error form-input"
                                : "form-input"
                            }
                            ref={projectNameRef}
                          />
                          {projectNameIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Project Name field Cannot be empty
                            </span>
                          )}
                        </div>
                        <div className="form-field-client">
                          <label htmlFor="client" className="form-label">
                            Client Name
                          </label>

                          <div
                            className={`custom-dropdown-user-new-outer custom-dropdown-select ${
                              clientDropdownOpen ? "open" : ""
                            }`}
                            ref={clientDropdownRef}
                          >
                            <div
                              className="selected-option custom-dropdown-user-new"
                              onClick={handleToggleClientDropdown}
                              style={{ cursor: "pointer" }}
                            >
                              {clientName
                                ? clientId.find(
                                    (client) => client._id === clientName
                                  )?.client_name
                                : "Select"}
                            </div>
                            {clientDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={clientSearchTerm}
                                  onChange={handleClientSearchInputChange}
                                />
                                <ul className="client-list">
                                  {filteredClients.map((client) => (
                                    <li
                                      key={client._id}
                                      className={
                                        clientName === client._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() => {
                                        setClientName(client._id);
                                        setClientDropdownOpen(false);
                                      }}
                                    >
                                      {client.client_name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {clientNameIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Client Name field Cannot be empty
                            </span>
                          )}
                        </div>
                        <div className="form-field-client">
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            value={discription}
                            onChange={(e) => setDiscription(e.target.value)}
                            className="form-textarea"
                            rows="3"
                          ></textarea>
                        </div>
                        {/* <div className="form-field-client">
                          <label htmlFor="tome" className="form-label">
                            Time Allocated(in hours)
                          </label>
                          <input
                            type="number"
                            id="time"
                            name="time"
                            value={totalHours}
                            onChange={(e) => setTotalHours(e.target.value)}
                            className="form-input"
                            onWheel={numberInputOnWheelPreventChange}
                          />
                        </div> */}
                        <div className="form-field-client">
                          <label
                            htmlFor="projectManager"
                            className="form-label"
                          >
                            Project Manager
                          </label>
                          <Select
                            isMulti
                            options={projectManagerOptions}
                            value={selectedProjectManagers}
                            onChange={(selectedOptions) =>
                              setSelectedProjectManagers(selectedOptions)
                            }
                          />
                        </div>
                        <div className="form-field-client radio-sec">
                          <label htmlFor="billable" className="form-label">
                            Billable
                          </label>
                          <div className="radio-container">
                            <div className="radio-option yes">
                              <input
                                type="radio"
                                id="yes"
                                name="billable"
                                value={billable}
                                checked={billable === true}
                                onChange={(e) => setBillable(true)}
                              />
                              <label htmlFor="yes">
                                Yes <img src={Tick} alt="Tick" />
                              </label>
                            </div>
                            <div className="radio-option no">
                              <input
                                type="radio"
                                id="no"
                                name="billable"
                                value={billable}
                                checked={billable === false}
                                onChange={(e) => setBillable(false)}
                              />
                              <label htmlFor="no">
                                No <img src={Close} alt="Edit Icon" />
                              </label>
                            </div>
                          </div>
                        </div>

                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={handleProjectSprintSubmit}
                            className="submit-client"
                          >
                            Create Project
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="modal-client-head create-sprint-project">
                      <h2 className="">Create a Sprint</h2>
                    </div>
                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="modal-client-head skip-sec">
                        <button
                          type="button"
                          onClick={() => handleProjectSubmit(true)}
                        >
                          Skip
                        </button>
                      </div>
                    )}
                    <div className="form-field-client-outer">
                      <form>
                        <div className="form-field-client">
                          <label htmlFor="projectName" className="form-label">
                            Sprint Name
                          </label>
                          <input
                            type="text"
                            id="sprintName"
                            name="sprintName"
                            value={sprint}
                            onChange={(e) => setSprint(e.target.value)}
                            className={
                              sprintIsValid
                                ? "input-validation-error form-input"
                                : "form-input"
                            }
                            ref={sprintRef}
                          />
                          {sprintIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Sprint field Cannot be empty
                            </span>
                          )}
                        </div>
                        <div className="form-field-client">
                          <label htmlFor="client" className="form-label">
                            Project Name
                          </label>
                          <input
                            type="text"
                            id="projectName"
                            name="projectName"
                            value={projectName}
                            className="form-input readonly"
                            readOnly
                          />
                        </div>
                        <div className="form-field-client">
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                          <textarea
                            id="sprintDiscription"
                            name="sprintDiscription"
                            className="form-textarea"
                            rows="3"
                            value={sprintDiscription}
                            onChange={(e) =>
                              setSprintDiscription(e.target.value)
                            }
                          ></textarea>
                        </div>
                        <div className="form-field-client">
                          <label htmlFor="projectName" className="form-label">
                            Date Frames
                          </label>
                          <DateRangePicker
                            showOneCalendar
                            format="dd/MM/yyyy"
                            placeholder="From - to"
                            placement="bottomEnd"
                            ranges={ranges}
                            className="project-calender"
                            value={dateRange}
                            onChange={handleSelect}
                          />
                        </div>
                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="submit-client"
                            onClick={() => handleProjectSubmit(false)}
                          >
                            Create Sprint
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          </div>

          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal sprint-modal"
              isOpen={isSprintModalOpen}
              onRequestClose={handleCloseSprintModal}
              contentLabel="Create a Sprint"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseSprintModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">Create a Sprint</h2>
                </div>
                <div className="form-field-client-outer">
                  <form onSubmit={handleSprintSubmit}>
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Sprint Name
                      </label>
                      <input
                        type="text"
                        id="sprintName"
                        name="sprintName"
                        value={sprint}
                        onChange={(e) => setSprint(e.target.value)}
                        className={
                          sprintIsValid
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        ref={sprintRef}
                      />
                      {sprintIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Sprint Field Cannot be empty
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        Project Name
                      </label>

                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${
                          sprintProjectDropdownOpen ? "open" : ""
                        }`}
                        ref={sprintProjectDropdownRef}
                      >
                        <div
                          className="selected-option custom-dropdown-user-new"
                          onClick={handleToggleSprintProjectDropdown}
                          style={{ cursor: "pointer" }}
                        >
                          {sprintProjectName
                            ? projectId.find(
                                (project) => project._id === sprintProjectName
                              )?.project_name
                            : "Select"}
                        </div>
                        {sprintProjectDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={sprintProjectSearchTerm}
                              onChange={handleSprintProjectSearchInputChange}
                            />
                            <ul className="project-list">
                              {filteredProjects.map((project) => (
                                <li
                                  key={project._id}
                                  className={
                                    sprintProjectName === project._id
                                      ? "selected-item"
                                      : ""
                                  }
                                  onClick={() => {
                                    setSprintProjectName(project._id);
                                    setSprintProjectDropdownOpen(false);
                                  }}
                                >
                                  {project.project_name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {sprintProjectNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Sprint Field Cannot be empty
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        id="sprintDiscription"
                        name="sprintDiscription"
                        className="form-textarea"
                        rows="3"
                        value={sprintDiscription}
                        onChange={(e) => setSprintDiscription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Date Frames
                      </label>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DateRangePicker
                            showOneCalendar
                            format="dd/MM/yyyy"
                            placeholder="From - to"
                            placement="topEnd"
                            ranges={ranges}
                            className="project-calender"
                            value={dateRange}
                            onChange={handleSelect}
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="submit-client">
                      Create Sprint
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className="search-sec">
          <div className="search-container">
            <input
              type="text"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              className="search-input"
              placeholder="Search by project name"
            />
          </div>
        </div>
      </div>
      <div className="client-ls-sec">
        <TimeTrackerProjectTable
          filter={filter}
          shouldFetchProject={shouldFetchProject}
        />
      </div>
    </div>
  );
};

export default TimeTrackerProject;
