const typeofAccount = {
    type_of_account: [
      {
        _id: 0,
        account_type: "Team",
      },
      {
        _id: 1,
        account_type: "Client",
      },
      {
        _id: 2,
        account_type: "Vendor",
      },
    ],
  };
  export default typeofAccount;