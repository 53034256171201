import React, { useState, useEffect } from "react";
import Tracker from "./tracker/Tracker_test";
import TrackerImportData from "../TimerImportData/trackerImportData/TrackerImportData";
function Timer() {
  const [isImportData, setIsImportData] = useState(false);
  const viewIsImportData = (e) => {
    setIsImportData(true);
  };
  const handleCancelImportData = () => {
    setIsImportData(false);
  };
  return (
    <div>
      {isImportData ? (
        <TrackerImportData onCancel={handleCancelImportData} />
      ) : (
        <Tracker viewIsImport={viewIsImportData} />
      )}
    </div>
  )
}

export default Timer