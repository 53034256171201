import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TruncateFieldView from '../../../helpers/truncateFieldView';
import { ClientColumn, clientCost, ClientLoggedHours, clientRevenue, ClientRoi, clientTotalCost, ClientTotalCost, clientTotalRevenue, ClientTotalRevenue, ProjectLoggedHours, ProjectRoi, ProjectTotalCost } from '../../Services/reportingOverview';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';
import typeOfContract from '../../../../constants/typeOfContract';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

const CostPerClientOverview = ({ trackReport, projectCost, invoices, projects, sprints, expenses, clientLoading, clientError }) => {
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    // useEffect(() => {
    //     if (clients && reportingFinancialOverview) {
    //         const filtered = clients.filter(client =>
    //             !client.is_delete &&
    //             (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(client._id)) &&
    //             (reportingFinancialOverview.financialOverviewYear && new Date(client.created_at).getFullYear() === reportingFinancialOverview.financialOverviewYear) &&
    //             (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(client?.preferred_currency || 'USD'))
    //         );
    //         const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    //         setFilteredData(sortedData);
    //     }
    // }, [clients, reportingFinancialOverview]);
    // useEffect(() => {
    //     if (trackReport && reportingFinancialOverview) {
    //         const financialOverviewYear = reportingFinancialOverview.financialOverviewYear;

    //         const filteredClients = trackReport.filter((entry) => {
    //             const startTimeYear = new Date(entry.startTime).getFullYear();
    //             return startTimeYear === financialOverviewYear;
    //         });

    //         const uniqueClients = [];
    //         filteredClients.forEach((entry) => {
    //             const client = entry.clientId;
    //             if (
    //                 client &&
    //                 !client.is_delete &&
    //                 (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(client._id)) &&
    //                 (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(client?.preferred_currency || 'USD')) &&
    //                 !uniqueClients.some((c) => c._id === client._id)
    //             ) {
    //                 uniqueClients.push(client);
    //             }
    //         });

    //         const sortedData = uniqueClients.sort(
    //             (a, b) => new Date(b.created_at) - new Date(a.created_at)
    //         );

    //         setFilteredData(sortedData);
    //     }
    // }, [trackReport, reportingFinancialOverview]);

    useEffect(() => {
        if (trackReport && reportingFinancialOverview) {
            const financialOverviewYear = reportingFinancialOverview.financialOverviewYear;

            const filteredClients = trackReport.filter((entry) => {
                const startTimeYear = new Date(entry.startTime).getFullYear();
                return startTimeYear === financialOverviewYear;
            });

            const uniqueClients = [];
            filteredClients.forEach((entry) => {
                const client = entry.clientId;
                if (
                    client &&
                    !client.is_delete &&
                    (reportingFinancialOverview.financialOverviewClient &&
                        reportingFinancialOverview.financialOverviewClient.includes(client._id)) &&
                    (reportingFinancialOverview.financialOverviewCurrency &&
                        reportingFinancialOverview.financialOverviewCurrency.includes(client?.preferred_currency || 'USD')) &&
                    !uniqueClients.some((c) => c._id === client._id)
                ) {
                    uniqueClients.push(client);
                }
            });

            // const expenseClients = expenses
            //     .filter((expense) => new Date(expense.exp_date).getFullYear() === financialOverviewYear)
            //     .map((expense) => expense.exp_client)
            //     .filter((client) => client && !client.is_delete); // Remove deleted clients

            const expenseClients = expenses
                .filter(
                    (expense) =>
                        new Date(expense.exp_date).getFullYear() === financialOverviewYear &&
                        expense.exp_client &&
                        !expense.exp_client.is_delete &&
                        (reportingFinancialOverview.financialOverviewClient &&
                            reportingFinancialOverview.financialOverviewClient.includes(expense.exp_client._id)) &&
                        (reportingFinancialOverview.financialOverviewCurrency &&
                            reportingFinancialOverview.financialOverviewCurrency.includes(expense.exp_client?.preferred_currency || 'USD'))
                )
                .map((expense) => expense.exp_client);

            expenseClients.forEach((client) => {
                if (!uniqueClients.some((c) => c._id === client._id)) {
                    uniqueClients.push(client);
                }
            });

            const clientsWithMetrics = uniqueClients.map((client) => {

                const clientTrackReport = trackReport.filter(
                    (entry) => entry.clientId?._id === client._id
                        && new Date(entry.startTime).getFullYear() === financialOverviewYear &&
                        (reportingFinancialOverview.financialOverviewClient &&
                            reportingFinancialOverview.financialOverviewClient.includes(entry.clientId?._id)) &&
                        (reportingFinancialOverview.financialOverviewCurrency &&
                            reportingFinancialOverview.financialOverviewCurrency.includes(entry.clientId?.preferred_currency || 'USD')) &&
                        !entry.clientId?.is_delete
                );

                const totalRevenue = clientRevenue(
                    clientTrackReport,
                    client._id,
                    projectCost,
                    invoices,
                    projects,
                    sprints,
                    reportingFinancialOverview,
                    settings.currency || client.preferred_currency || 'USD',
                    forex,
                    typeOfContract,
                    expenses
                );


                const totalCost = clientCost(
                    clientTrackReport,
                    client._id,
                    projectCost,
                    reportingFinancialOverview,
                    settings.currency || client.preferred_currency || 'USD',
                    forex,
                    typeOfContract,
                    expenses
                );

                const timeLogged = clientTrackReport.reduce((sum, entry) => sum + entry.duration, 0);

                const totalExpenses = expenses
                    .filter(
                        (expense) =>
                            expense?.exp_client?._id === client._id &&
                            new Date(expense.exp_date).getFullYear() === financialOverviewYear
                    )
                    .reduce(
                        (sum, expense) =>
                            sum +
                            convertCurrencyHelper(
                                expense.exp_currency,
                                client.preferred_currency || 'USD',
                                expense.exp_amount,
                                forex
                            ),
                        0
                    );

                const roi = totalCost > 0 ? ((totalRevenue) / totalCost) * 100 : 0;

                return {
                    ...client,
                    totalRevenue: parseFloat(totalRevenue),
                    totalCost: parseFloat(totalCost),
                    timeLogged: changeDurationFormat(timeLogged),
                    totalExpenses: parseFloat(totalExpenses),
                    roi: roi.toFixed(2),
                };
            });

            const sortedData = clientsWithMetrics.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            setFilteredData(sortedData);
        }
    }, [trackReport, reportingFinancialOverview, projectCost, invoices, projects, sprints, expenses, forex, typeOfContract]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    return (
        <div className="datatable-team-maintb">
            <DataTable
                value={filteredData}
                removableSort
                tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                }}
                paginator={filteredData.length > 10}
                paginatorClassName="recent-invoices-pagination"
                rows={rowsPerPage} // Set the number of rows per page
                first={currentPage * rowsPerPage} // Set the current page using currentPage
                onPage={onPageChange} // Update currentPage when changing pages
            >
                <Column
                    field="client_name"
                    // body={(rowData) => {
                    //     const clientName = rowData?.client_name || rowData?.legal_entity_name || 'Client Name';
                    //     return <TruncateFieldView textContent={clientName} />;
                    // }}
                    body={(rowData) => <ClientColumn rowData={rowData} />}
                    header="Client Name"
                    // className="fixed-sec3"
                    sortable
                    style={{ paddingRight: "44px", minWidth: "280px" }}
                ></Column>
                <Column
                    field="totalRevenue"
                    header="Total Net Profit"
                    sortable
                    body={(rowData) => new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: settings.currency
                    }).format(rowData.totalRevenue)}
                    style={{ paddingRight: '44px' }}
                />
                {/* <Column
                    field="total_revenue"
                    header="Total Net Profit"
                    body={ClientTotalRevenue}
                    sortable
                    style={{ paddingRight: '44px' }}
                /> */}
                <Column
                    field="timeLogged"
                    header="Time Logged"
                    className="fixed-sec4"
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column>
                {/* <Column
                    field="tracked_hours"
                    header="Time Logged"
                    className="fixed-sec4"
                    body={ClientLoggedHours}
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column> */}
                <Column
                    field="totalCost"
                    header="Cost"
                    style={{ paddingRight: "44px" }}
                    body={(rowData) => new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: settings.currency
                    }).format(rowData.totalCost)}
                />
                {/* <Column
                    field="cost"
                    header="Cost"
                    body={ClientTotalCost}
                    // className=""
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column> */}
                <Column
                    field="roi"
                    header="ROI (%)"
                    style={{ paddingRight: "44px" }}
                    body={(rowData) => (rowData.roi ? `${(rowData.roi)} %` : "0.00%")}
                />
                {/* <Column
                    field="roi"
                    header="ROI"
                    body={ClientRoi}
                    // className="amount-bold"
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column> */}
            </DataTable>
        </div>
    );
};

export default CostPerClientOverview;
