import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { otpVerification, registerUser, checkGoogleUser } from "../../../services/emailRegister/emailRegisterServices.js";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { login, loginTemp } from '../../../features/auth/authSlice.js';
import { setForex } from "../../../features/forex/forexSlice.js";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import { setDashboardCurrencyRx } from "../../../features/invoice/invoiceSlice.js";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
const GoogleLoad = () => {

  const { id } = useParams();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  const USER = 3;
  // BUSINESS: 4,
  const COLLABERATOR = 5;
  const PROJECT_MANAGER = 6;
  const TEAM_MANAGER = 7;


  const domainName = window.location.hostname;
  const currentPort = window.location.port;

  const getCurrentDomain = () => {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  };


  useEffect(() => {

    const checkGoogleAuth = async () => {
      var getuser = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/app/google/token/${id}`, {
        headers: {

        },
      });
      if (getuser) {
        try {
          const res = await fetch(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/user/google/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: getuser.data.email, password: getuser.data.password }),
          });
          const data = await res.json();

          if (data.token) {
            await dispatch(loginTemp(data));
            fetchForex();
            if (data.userType === 'x' || data.userType === 'Y') {

              var settingsData = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/get/app-settings/${data.appId}`, {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                }
              });

              var favIconData = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/view/owner/by/appid/${data.appId}`, {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              });

              let favIcon_fileName;
              let app_owner_name;
              let app_owner_id;
              if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                favIcon_fileName = favIconData.data.fav_icon;
                app_owner_name = favIconData.data.commercial_name;
                app_owner_id = favIconData.data._id;
              } else {
                favIcon_fileName = "fav_icon_default.jpeg";
                app_owner_name = "TalenTon";
                app_owner_id = favIconData.data._id;
              }

              // await dispatch(setAppSettings(settingsData.data));
              await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
            } else {

              var userSettingsData = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/get/user-settings/${data._id}`, {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                }
              });

              // await dispatch(setAppSettings(settingsData2.data));
              var favIconData = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/view/owner/by/appid/${data.appId}`, {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              });
              let favIcon_fileName;
              let app_owner_name;
              let app_owner_id;
              if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                favIcon_fileName = favIconData.data.fav_icon;
                app_owner_name = favIconData.data.commercial_name;
                app_owner_id = favIconData.data._id;
              } else {
                favIcon_fileName = "fav_icon_default.jpeg";
                app_owner_name = "TalenTon";
                app_owner_id = favIconData.data._id;
              }

              var settingsData = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/get/app-settings/${data.appId}`, {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                }
              });
              // settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
              if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {
                // settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                // settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
              }
              if (settingsData.data !== null) {
                settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
              }
              // await dispatch(setAppSettings(settingsData.data));
              await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
            }

            const response = await axios.get(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/dashboard/invoice-currency`, {
              headers: {
                Authorization: `Bearer ${data.token}`,
              }
            });

            if (response.data.length > 0) {
              localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
              dispatch(setDashboardCurrencyRx(response.data[0]));
            }
            if (settingsData && settingsData.data?.twoFa === true) {
              var twoFactorEnabled = true;
              var twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();
            } else {
              var twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
              var twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
            }

            if (twoFactorEnabled && twoFactorEnabledAt) {
              if (twoFactorEnabled && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
                // 2FA is enabled and it's within the 30-day timeframe
                dispatch(login(data));

                window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/user/dashboard`;
                // navigate('/user/dashboard');
              } else {
                // Redirect user to enable 2FA
                // navigate("/2fa");
                window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/2fa`;
              }
            } else {
              dispatch(login(data));
              // navigate('/user/dashboard');
              window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/user/dashboard`;
            }
          } else {
            // setErrorMessage(data);
            toast.error(data.message, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          // setLoading(false);
        } catch (err) {
          console.error(err.message);
          global.config.slackMessage(err.toString());
          toast.error(err.message, {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false);
        }

      } else {


        alert('failed');
      }


    }

    checkGoogleAuth();
  }, []);
  const isCompanyEmail = (email) => {
    // List of common public email domains
    const publicDomains = [
      "gmail.com",
      "yahoo.com",
      "outlook.com",
      "hotmail.com",
      "aol.com",
      "icloud.com",
      "protonmail.com",
      "zoho.com",
      "yandex.com"
    ];

    // Extract the domain part of the email
    const emailDomain = email.split('@')[1].toLowerCase();

    // Check if the email domain is NOT in the public domains list
    return !publicDomains.includes(emailDomain);
  };

  const fetchForex = async () => {

    var settingsData = await axios.post(`api/forex/get`, { base_currency: "USD" }, {
      headers: {

      }
    });
    await dispatch(setForex(settingsData.data.json));
    return settingsData.data.json;

  }


  const handleLoginSuccess = async (response) => {
    // console.log("Login Success:", response);
    try {
      const googleUser = await checkGoogleUser(response.credential);
      let companyName = null;

      if (googleUser) {
        if (isCompanyEmail(googleUser.email)) {
          companyName = googleUser.hd;
        } else {
          companyName = googleUser.name;
        }
        const result = await registerUser(googleUser.email, companyName, companyName, "", googleUser.jti, true);
        if (result.status === "success") {
          if (result.data.subDomain) {
            // if (currentPort === "3000") {
            //   window.location.href = `http://${result.data.subDomain}.${domainName}:3000`;
            // } else {
            //   window.location.href = `https://${result.data.subDomain}.${domainName}`;
            // }


            // Login part


            try {

              const res = await fetch(`https://${result.data.subDomain}.${domainName}/api/user/google/login`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: googleUser.email, password: googleUser.jti }),
              });
              const data = await res.json();

              if (data.token) {

                await dispatch(loginTemp(data));
                fetchForex();
                if (data.userType === 'x' || data.userType === 'Y') {

                  var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                    headers: {
                      Authorization: `Bearer ${data.token}`,
                    }
                  });

                  var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                    headers: {
                      Authorization: `Bearer ${data.token}`,
                    },
                  });

                  let favIcon_fileName;
                  let app_owner_name;
                  let app_owner_id;
                  if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                    favIcon_fileName = favIconData.data.fav_icon;
                    app_owner_name = favIconData.data.commercial_name;
                    app_owner_id = favIconData.data._id;
                  } else {
                    favIcon_fileName = "fav_icon_default.jpeg";
                    app_owner_name = "TalenTon";
                    app_owner_id = favIconData.data._id;
                  }

                  // await dispatch(setAppSettings(settingsData.data));
                  await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                } else {

                  var userSettingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/user-settings/${data._id}`, {
                    headers: {
                      Authorization: `Bearer ${data.token}`,
                    }
                  });

                  // await dispatch(setAppSettings(settingsData2.data));
                  var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                    headers: {
                      Authorization: `Bearer ${data.token}`,
                    },
                  });
                  let favIcon_fileName;
                  let app_owner_name;
                  let app_owner_id;
                  if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                    favIcon_fileName = favIconData.data.fav_icon;
                    app_owner_name = favIconData.data.commercial_name;
                    app_owner_id = favIconData.data._id;
                  } else {
                    favIcon_fileName = "fav_icon_default.jpeg";
                    app_owner_name = "TalenTon";
                    app_owner_id = favIconData.data._id;
                  }

                  var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                    headers: {
                      Authorization: `Bearer ${data.token}`,
                    }
                  });
                  // settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                  if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {
                    // settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                    // settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                    settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                  }
                  if (settingsData.data !== null) {
                    settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                    settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                  }
                  // await dispatch(setAppSettings(settingsData.data));
                  await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                }

                const response = await axios.get(`https://${result.data.subDomain}.${domainName}/api/dashboard/invoice-currency`, {
                  headers: {
                    Authorization: `Bearer ${data.token}`,
                  }
                });

                if (response.data.length > 0) {
                  localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
                  dispatch(setDashboardCurrencyRx(response.data[0]));
                }
                if (settingsData && settingsData.data?.twoFa === true) {
                  var twoFactorEnabled = true;
                  var twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();
                } else {
                  var twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
                  var twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
                }

                if (twoFactorEnabled && twoFactorEnabledAt) {
                  if (twoFactorEnabled && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
                    // 2FA is enabled and it's within the 30-day timeframe
                    dispatch(login(data));

                    window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                    // navigate('/user/dashboard');
                  } else {
                    // Redirect user to enable 2FA
                    // navigate("/2fa");
                    window.location.href = `https://${result.data.subDomain}.${domainName}/2fa`;
                  }
                } else {
                  dispatch(login(data));
                  // navigate('/user/dashboard');
                  window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                }
              } else {
                // setErrorMessage(data);
                toast.error(data.message, {
                  position: "bottom-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              // setLoading(false);
            } catch (err) {
              console.error(err.message);
              global.config.slackMessage(err.toString());
              toast.error(err.message, {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              // setLoading(false);
            }




            // Login part end





          }
          toast.success(result.data.message || "App owner registered successfully.", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (result.status === "fail") {

          handleLogout();
          toast.error(result.message || "Something went wrong.", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (result.status === "failed") {
          handleLogout();
          toast.error(result.message || "Company name or email already exists.", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      }
    } catch (error) {
      console.error("Error:", error);
    } finally {

    }



    setIsLoggedIn(true);
  };

  const handleLoginFailure = (error) => {
    console.log("Login Failed:", error);
  };

  // Check for stored token on component mount
  useEffect(() => {
    const token = localStorage.getItem('googleToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    googleLogout(); // Logs out from Google session
    localStorage.removeItem('googleToken');
    setIsLoggedIn(false);
  };

  return (

    <div>
      <ThreeDots
        height="38"
        width="40"
        radius="9"
        color="white"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>

  );
};

export default GoogleLoad;