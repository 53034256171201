import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ThreeDots } from "react-loader-spinner";
import TruncateFieldView from '../../../helpers/truncateFieldView';
import { ProjectColumn, ProjectLoggedHours, ProjectRoi, ProjectTotalCost, ProjectTotalBudget } from '../../Services/reportingOverview';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';
import { useTotalCostByProject } from '../../../../features/projects/ProjectList';
import typeOfContract from '../../../../constants/typeOfContract';

const CostPerProjectOverview = ({ trackReport, projectCost, sprints, trackReportLoading }) => {
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    // useEffect(() => {
    //     if (projects && reportingFinancialOverview) {
    //         const filtered = projects.filter((project) =>
    //             project &&
    //             !project.is_delete &&
    //             (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(project._id)) &&
    //             new Date(project.created_at).getFullYear() === reportingFinancialOverview.financialOverviewYear &&
    //             (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(project?.client_name?.preferred_currency || 'USD'))
    //         );
    //         const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    //         setFilteredData(sortedData);
    //     }
    // }, [projects, reportingFinancialOverview]);

    // useEffect(() => {
    //     if (trackReport && reportingFinancialOverview && sprints) {
    //         const financialOverviewYear = reportingFinancialOverview.financialOverviewYear;

    //         // Filter projects based on the financial overview year
    //         const filteredProjects = trackReport.filter((entry) => {
    //             const startTimeYear = new Date(entry.startTime).getFullYear();
    //             return startTimeYear === financialOverviewYear;
    //         });

    //         const projectData = {};

    //         filteredProjects.forEach((entry) => {
    //             const project = entry.project;
    //             const user = entry.user;

    //             if (!project || project.is_delete) return; // Skip deleted projects

    //             if (!projectData[project._id]) {
    //                 // Get project budget from sprints (only taken once per project)
    //                 const projectSprints = sprints.filter(
    //                     (sprint) => sprint.project && sprint.project._id === project._id
    //                 );

    //                 const budget = projectSprints.reduce((sum, sprint) => {
    //                     const sprintBudget = sprint.budget || 0;
    //                     const clientCurrency = entry.clientId?.preferred_currency || 'USD';
    //                     return sum + convertCurrencyHelper(clientCurrency, settings.currency, sprintBudget, forex);
    //                 }, 0);

    //                 projectData[project._id] = {
    //                     project_name: project.project_name,
    //                     client_name: entry.clientId?.client_name || entry.clientId?.legal_entity_name || 'Client Name',
    //                     budget,
    //                     total_time_logged: 0,
    //                     total_cost: 0,
    //                     roi: 0,
    //                     currency: entry.clientId?.preferred_currency || 'USD',
    //                 };
    //             }

    //             // Sum time logged (convert seconds to hours)
    //             projectData[project._id].total_time_logged += entry.duration;

    //             // Calculate cost (user cost per hour * logged hours)
    //             const userHourlyRate = user?.cost ? user.cost / 3600 : 0;
    //             const userCost = userHourlyRate * entry.duration;

    //             // Add overhead cost if available
    //             const overheadCost = user?.overhead_cost || 0;
    //             projectData[project._id].total_cost += userCost + overheadCost;
    //         });

    //         // Calculate ROI = (Budget - Total Cost) / Total Cost
    //         Object.values(projectData).forEach((project) => {
    //             if (project.total_cost > 0) {
    //                 project.roi = ((project.budget - project.total_cost) / project.total_cost) * 100;
    //             }
    //         });

    //         // Convert projectData object to an array and sort by creation date
    //         const sortedData = Object.values(projectData).sort(
    //             (a, b) => new Date(b.created_at) - new Date(a.created_at)
    //         );

    //         setFilteredData(sortedData);
    //     }
    // }, [trackReport, reportingFinancialOverview, sprints, settings.currency, forex]);

    useEffect(() => {
        if (trackReport && reportingFinancialOverview && sprints) {
            const financialOverviewYear = reportingFinancialOverview.financialOverviewYear;

            // Filter projects based on the financial overview year
            const filteredProjects = trackReport.filter((entry) => {
                const { project, clientId, startTime } = entry;
                const startTimeYear = new Date(startTime).getFullYear();
                const projectId = project?._id;
    
                return (
                    startTimeYear === financialOverviewYear &&
                    project &&
                    !project.is_delete &&
                    clientId &&
                    !clientId.is_delete &&
                    reportingFinancialOverview.financialOverviewProject?.includes(projectId) &&
                    reportingFinancialOverview.financialOverviewCurrency?.includes(clientId?.preferred_currency || 'USD')
                );
            });

            const projectData = {};

            filteredProjects.forEach((entry) => {
                const { project, clientId, user, startTime, duration } = entry;
                const projectId = project?._id;

                if (!projectData[projectId]) {
                    const projectSprints = sprints.filter(
                        (sprint) => sprint.project && sprint.project._id === projectId
                    );

                    const budget = projectSprints.reduce((sum, sprint) => {
                        const sprintBudget = sprint.budget || 0;
                        const clientCurrency = clientId?.preferred_currency || 'USD';
                        return sum + convertCurrencyHelper(clientCurrency, settings.currency, sprintBudget, forex);
                    }, 0);

                    projectData[projectId] = {
                        project_id: project?._id,
                        project_name: project.project_name,
                        client_id: clientId?._id,
                        client_name: clientId?.client_name || clientId?.legal_entity_name || 'Client Name',
                        budget,
                        total_time_logged: 0,
                        total_cost: 0,
                        roi: 0,
                        currency: clientId?.preferred_currency || 'USD',
                        created_at: project.created_at,
                    };
                }

                projectData[projectId].total_time_logged += duration;

                const projectYear = new Date(startTime).getFullYear();
                if (
                    projectYear === financialOverviewYear &&
                    clientId &&
                    !clientId.is_delete &&
                    reportingFinancialOverview.financialOverviewProject?.includes(projectId) &&
                    reportingFinancialOverview.financialOverviewCurrency?.includes(clientId?.preferred_currency || 'USD')
                ) {
                    const paymentCurrency = user?.payment_currency || 'USD';
                    const preferredClientCurrency = clientId?.preferred_currency || 'USD';

                    const projectSpecificCost = projectCost.find(
                        (cost) => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete
                    );

                    let userCost;
                    if (projectSpecificCost && projectSpecificCost.amount > 0) {
                        userCost = convertCurrencyHelper(
                            preferredClientCurrency,
                            paymentCurrency,
                            projectSpecificCost.amount,
                            forex
                        );
                    } else {
                        userCost = user?.cost || 0;
                    }

                    const userOverheadCost = user?.overhead_cost || 0;
                    const userTypeOfContract = user?.typeOfContract || typeOfContract.Hourly_Rate;

                    let finalCost;
                    if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                        finalCost = userCost;
                    } else {
                        finalCost = userCost / parseInt(user?.monthly_hours || 168);
                    }

                    const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
                    projectData[projectId].total_cost += convertCurrencyHelper(paymentCurrency, settings.currency, cost, forex);
                }
            });

            // Calculate ROI = (Budget - Total Cost) / Total Cost
            Object.values(projectData).forEach((project) => {
                if (project.total_cost > 0) {
                    project.roi = parseFloat(((project.budget - project.total_cost) / project.total_cost * 100).toFixed(2));
                }
            });            

            // Convert projectData object to an array and sort by creation date
            const sortedData = Object.values(projectData).sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            setFilteredData(sortedData);
        }
    }, [trackReport, reportingFinancialOverview, sprints, settings.currency, forex]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    return (
        <div className="datatable-team-maintb">
            <DataTable
                value={filteredData}
                removableSort
                tableStyle={{ minWidth: '50rem', width: '100%', textAlign: 'left' }}
                paginator={filteredData.length > rowsPerPage}
                paginatorClassName="recent-invoices-pagination"
                rows={rowsPerPage}
                first={currentPage * rowsPerPage}
                onPage={onPageChange}
                emptyMessage={trackReportLoading ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : "No projects data found"}
            >
                <Column
                    field="project_name"
                    header="Project"
                    // body={(rowData) => <TruncateFieldView textContent={rowData?.project_name} />}
                    body={(rowData) => <ProjectColumn rowData={rowData} />}
                    className="fixed-sec"
                    sortable
                    style={{ paddingRight: '44px', minWidth: '280px' }}
                />
                <Column
                    field="client_name"
                    body={(rowData) => {
                        return <TruncateFieldView textContent={rowData.client_name} />;
                    }}
                    header="Client Name"
                    sortable
                    style={{ paddingRight: '44px', minWidth: '280px' }}
                />
                <Column
                    field="budget"
                    header="Total Budget"
                    body={(rowData) => new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: settings.currency
                    }).format(rowData.budget)}
                    sortable
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="total_time_logged"
                    header="Time Logged"
                    className="fixed-sec4"
                    body={(rowData) => changeDurationFormat(rowData.total_time_logged)}
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="total_cost"
                    header="Cost"
                    body={(rowData) => new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: settings.currency
                    }).format(rowData.total_cost)}
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="roi"
                    header="ROI"
                    body={(rowData) => (rowData.roi ? `${(rowData.roi)} %` : "0.00%")}
                    style={{ paddingRight: '44px' }}
                />
            </DataTable>
        </div>
    );
};

export default CostPerProjectOverview;
