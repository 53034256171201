import axios from "axios";
import "./Notification.css"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import userTypeName from "../../../constants/userType";
import userCategoryName from "../../../constants/userCategory";
import { logout, reset } from "../../../features/auth/authSlice";
import { toggleNotification } from '../../../features/auth/authSlice';
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useAppSettings } from "../../../features/appSettings/useAppSettings.jsx";

const NoticationSettings = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);
    const [isEmailNotificationLoading, setIsEmailNotificationLoading] = useState(false);
    const [isAdminTimeoffEmailNotificationLoading, setIsAdminTimeoffEmailNotificationLoading] = useState(false);
    const [isAdminInvoiceEmailNotificationLoading, setIsAdminInvoiceEmailNotificationLoading] = useState(false);
    const [isAppTimeoffSubmissionStatusLoading, setIsAppTimeoffSubmissionStatusLoading] = useState(false);
    const [isAppTimeoffDecisionStatusLoading, setIsAppTimeoffDecisionStatusLoading] = useState(false);
    const [isAppExpenseCreationStatusLoading, setIsAppExpenseCreationStatusLoading] = useState(false);
    const [isAppEquipmentCreationStatusLoading, setIsAppEquipmentCreationStatusLoading] = useState(false);
    const [isAppReimbursementSubmissionStatusLoading, setIsAppReimbursementSubmissionStatusLoading] = useState(false);
    const [isAppReimbursementDecisionStatusLoading, setIsAppReimbursementDecisionStatusLoading] = useState(false);
    const [isAppBudgetThresholdStatusLoading, setIsAppBudgetThresholdStatusLoading] = useState(false);
    const [isAppClientIntakeSubmissionStatusLoading, setIsAppClientIntakeSubmissionStatusLoading] = useState(false);
    const [isAppClientAccountCreationStatusLoading, setIsAppClientAccountCreationStatusLoading] = useState(false);
    const [isAppRunningTimerAlertStatusLoading, setIsAppRunningTimerAlertStatusLoading] = useState(false);
    const [isAppUserActivationStatusLoading, setIsAppUserActivationStatusLoading] = useState(false);
    const [isAppInvoiceCreationStatusLoading, setIsAppInvoiceCreationStatusLoading] = useState(false);
    const [isAppInvoiceDecisionStatusLoading, setIsAppInvoiceDecisionStatusLoading] = useState(false);
    const [isAppInvoicePaymentStatusLoading, setIsAppInvoicePaymentStatusLoading] = useState(false);
    const [isAppClientUpdatesStatusLoading, setIsAppClientUpdatesStatusLoading] = useState(false);
    const [isAppProjectUpdatesStatusLoading, setIsAppProjectUpdatesStatusLoading] = useState(false);
    const [isAppVendorInvoiceCreationStatusLoading, setIsAppVendorInvoiceCreationStatusLoading] = useState(false);
    const [isAppVendorInvoiceDecisionStatusLoading, setIsAppVendorInvoiceDecisionStatusLoading] = useState(false);
    const [isAppVendorInvoicePaymentStatusLoading, setIsAppVendorInvoicePaymentStatusLoading] = useState(false);
    const [isAppBankAccountStatusLoading, setIsAppBankAccountStatusLoading] = useState(false);
    const [isAppPaymentMethodStatusLoading, setIsAppPaymentMethodStatusLoading] = useState(false);
    const [isAppCurrencyWeekStatusLoading, setIsAppCurrencyWeekStatusLoading] = useState(false);
    const [isApp2FAActivationStatusLoading, setIsApp2FAActivationStatusLoading] = useState(false);
    const [userView, setUserView] = useState([]);
    const [appSettingView, setAppSettingView] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const { appSettings } = useSelector((state) => state.appSettings);
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const currencyDropdownRef = useRef(null);
    const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const timezoneDropdownRef = useRef(null);
    const [isTimeOffOpen, setIsTimeOffOpen] = useState(true);
    const [isExpenseOpen, setIsExpenseOpen] = useState(true);
    const [isEquipmentOpen, setIsEquipmentOpen] = useState(true);
    const [isReimbursementOpen, setIsReimbursementOpen] = useState(true);
    const [isBudgetThresholdOpen, setIsBudgetThresholdOpen] = useState(true);
    const [isClientIntakeSubmissionOpen, setIsClientIntakeSubmissionOpen] = useState(true);
    const [isUserActivityOpen, setIsUserActivityOpen] = useState(true);
    const [isInvoiceNotificationOpen, setIsInvoiceNotificationOpen] = useState(true);
    const [isProjectClientManagementOpen, setIsProjectClientManagementOpen] = useState(true);
    const [isVendorInvoiceNotificationOpen, setIsVendorInvoiceNotificationOpen] = useState(true);
    const [isFinancialSettingsOpen, setIsFinancialSettingsOpen] = useState(true);
    const [isSecurityNotificationOpen, setIsSecurityNotificationOpen] = useState(true);

    const toggleTimeOffAccordion = () => {
        setIsTimeOffOpen(!isTimeOffOpen);
    };

    const toggleExpenseAccordion = () => {
        setIsExpenseOpen(!isExpenseOpen);
    };

    const toggleEquipmentAccordion = () => {
        setIsEquipmentOpen(!isEquipmentOpen);
    };

    const toggleReimbursementAccordion = () => {
        setIsReimbursementOpen(!isReimbursementOpen);
    };

    const toggleBudgetThresholdAccordion = () => {
        setIsBudgetThresholdOpen(!isBudgetThresholdOpen);
    };

    const toggleClientIntakeSubmissionAccordion = () => {
        setIsClientIntakeSubmissionOpen(!isClientIntakeSubmissionOpen);
    };

    const toggleUserActivityAccordion = () => {
        setIsUserActivityOpen(!isUserActivityOpen);
    }

    const toggleInvoiceNotificationAccordion = () => {
        setIsInvoiceNotificationOpen(!isInvoiceNotificationOpen);
    }

    const toggleVendorInvoiceNotificationAccordion = () => {
        setIsVendorInvoiceNotificationOpen(!isVendorInvoiceNotificationOpen);
    }

    const toggleProjectClientManagementAccordion = () => {
        setIsProjectClientManagementOpen(!isProjectClientManagementOpen);
    }

    const toggleFinancialSettingsAccordion = () => {
        setIsFinancialSettingsOpen(!isFinancialSettingsOpen);
    }

    const toggleSecurityNotificationAccordion = () => {
        setIsSecurityNotificationOpen(!isSecurityNotificationOpen);
    }

    const toggleCurrencyDropdown = () => {
        setCurrencyDropdownOpen(!currencyDropdownOpen);
    };

    const selectCurrencyOption = (selectedCurrency) => {
        setSelectedCurrency(selectedCurrency);
        setCurrencyDropdownOpen(false);
    };

    const toggleTimezoneDropdown = () => {
        setTimezoneDropdownOpen(!timezoneDropdownOpen);
    };

    const selectTimezoneOption = (selectedTimezone) => {
        setSelectedTimezone(selectedTimezone);
        setTimezoneDropdownOpen(false);
    };

    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: appSettingsData = [], isLoading: appSettingsLoading, refetch: refetchAppSettingData } = useAppSettings(appSettings && appSettings.appId, token,);
    useEffect(() => {
        async function fetchUser() {
            setIsLoading(true);
            try {
                const response = await axios.get(`/api/user/view/${props.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserView(response.data);

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                global.config.slackMessage(error.toString());
                if (error.response?.data?.status === "deleted") {
                    localStorage.removeItem("user");
                    dispatch(logout());
                    dispatch(reset());
                    dispatch(invoiceReset());
                    dispatch(headerReset());
                    dispatch(appSettingsReset());
                    toast.error('This user is deleted!', {
                        position: "bottom-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate("/");
                    return;
                }
                if (error.response?.data?.status === "userDeleted") {
                    // toast.error('This user is deleted!', {
                    //     position: "bottom-right",
                    //     autoClose: 1500,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    // });
                    navigate("/user/myteam/list");
                    return;
                }
                alert("Something went wrong in fetching users");
            }
        }
        fetchUser().then(() => { });
    }, [props.id]);

    useEffect(() => {
        refetchAppSettingData();
        setAppSettingView(appSettingsData)
    }, [props.id, appSettingsData, appSettingsLoading]);

    const handleEnableEmailNotificationChange = async (id) => {
        try {
            setIsEmailNotificationLoading(true);
            const response = await axios.get(`/api/user/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s email notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s email notification status updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_email_enabled: !prevState.is_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing user status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing ${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s Email Notification Status`
            );
            console.log(error);
        } finally {
            setIsEmailNotificationLoading(false);
        }
    };

    const handleEnableAdminTimeoffEmailNotificationChange = async (id) => {
        try {
            setIsAdminTimeoffEmailNotificationLoading(true);
            const response = await axios.get(`/api/admin/user/time-off/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_admin_timeoff_email_enabled: !prevState.is_admin_timeoff_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing admin email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification status`
            );
            console.log(error);
        } finally {
            setIsAdminTimeoffEmailNotificationLoading(false);
        }
    };

    const handleEnableAdminInvoiceEmailNotificationChange = async (id) => {
        try {
            setIsAdminInvoiceEmailNotificationLoading(true);
            const response = await axios.get(`/api/admin/user/invoice/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" invoice email notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" Invoice email notification status updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_admin_invoice_email_enabled: !prevState.is_admin_invoice_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing admin email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" Invoice Email Notification Status`
            );
            console.log(error);
        } finally {
            setIsAdminInvoiceEmailNotificationLoading(false);
        }
    };

    const handleEnableNotificationChange = async (id) => {
        try {
            setIsNotificationLoading(true);
            await axios.get(`/api/user/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s notification status updated successfully`
            );
            if (user._id === id) {
                dispatch(toggleNotification());
            }
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                if (prevState._id === id) {
                    return { ...prevState, is_notification_enabled: !prevState.is_notification_enabled };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing user notification status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing ${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s Notification Status`
            );
            console.log(error);
        } finally {
            setIsNotificationLoading(false);
        }
    };

    const handleEnableAppTimeoffSubmissionStatusChange = async (id) => {
        try {
            setIsAppTimeoffSubmissionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/time-off/submission/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App time-off submission email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" time-off submission email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_timeoff_submission: !prevState.app_setting_timeoff_submission };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app time-off submission email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" time-off submission email status!!`
            );
            console.log(error);
        } finally {
            setIsAppTimeoffSubmissionStatusLoading(false);
        }
    };

    const handleEnableAppTimeoffDecisionStatusChange = async (id) => {
        try {
            setIsAppTimeoffDecisionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/time-off/decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App time-off decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" time-off decision email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_timeoff_decision: !prevState.app_setting_timeoff_decision };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app time-off decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" time-off decision email status!!`
            );
            console.log(error);
        } finally {
            setIsAppTimeoffDecisionStatusLoading(false);
        }
    };

    const handleEnableAppExpenseCreationStatusChange = async (id) => {
        try {
            setIsAppExpenseCreationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/expense/creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App expense creation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" expense creation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_expense_creation: !prevState.app_setting_expense_creation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app expense creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" expense creation email status!!`
            );
            console.log(error);
        } finally {
            setIsAppExpenseCreationStatusLoading(false);
        }
    };

    const handleEnableAppEquipmentCreationStatusChange = async (id) => {
        try {
            setIsAppEquipmentCreationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/equipment/creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App equipment creation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" equipment creation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_equipment_creation: !prevState.app_setting_equipment_creation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app equipment creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" equipment creation email status!!`
            );
            console.log(error);
        } finally {
            setIsAppEquipmentCreationStatusLoading(false);
        }
    };

    const handleEnableAppReimbursementSubmissionStatusChange = async (id) => {
        try {
            setIsAppReimbursementSubmissionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/reimbursement/submission/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App reimbursement submission email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" Reimbursement submission email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_reimbursement_submission: !prevState.app_setting_reimbursement_submission };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app Reimbursement submission email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" reimbursement submission email status!!`
            );
            console.log(error);
        } finally {
            setIsAppReimbursementSubmissionStatusLoading(false);
        }
    };

    const handleEnableAppReimbursementDecisionStatusChange = async (id) => {
        try {
            setIsAppReimbursementDecisionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/reimbursement/decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App reimbursement decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" reimbursement decision email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_reimbursement_decision: !prevState.app_setting_reimbursement_decision };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app reimbursement decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" reimbursement decision email status!!`
            );
            console.log(error);
        } finally {
            setIsAppReimbursementDecisionStatusLoading(false);
        }
    };

    const handleEnableAppBudgetThresholdStatusChange = async (id) => {
        try {
            setIsAppBudgetThresholdStatusLoading(true);
            const response = await axios.get(`/api/app-settings/budget/threshold/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App Project and Budget email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" Project and Budget email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_budget_threshold: !prevState.app_setting_budget_threshold };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app project and budget email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" project and budget email status!!`
            );
            console.log(error);
        } finally {
            setIsAppBudgetThresholdStatusLoading(false);
        }
    };

    const handleEnableAppClientIntakeSubmissionStatusChange = async (id) => {
        try {
            setIsAppClientIntakeSubmissionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/client/intake/submission/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App client intake submission email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" client intake submission email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_intake_form_submission: !prevState.app_setting_intake_form_submission };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app client intake submission email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" client intake submission email status!!`
            );
            console.log(error);
        } finally {
            setIsAppClientIntakeSubmissionStatusLoading(false);
        }
    };

    const handleEnableAppClientAccountCreationStatusChange = async (id) => {
        try {
            setIsAppClientAccountCreationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/client-account/creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App client account creation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" client account creation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_client_account_creation: !prevState.app_setting_client_account_creation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app client account creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" client account creation status!!`
            );
            console.log(error);
        } finally {
            setIsAppClientAccountCreationStatusLoading(false);
        }
    };

    const handleEnableAppUserActivationStatusChange = async (id) => {
        try {
            setIsAppUserActivationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/user-activation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App client account user activation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" user activation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_user_activiation: !prevState.app_setting_user_activiation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app user activation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" user activation email status!!`
            );
            console.log(error);
        } finally {
            setIsAppUserActivationStatusLoading(false);
        }
    };

    const handleEnableAppRunningTimerAlertStatusChange = async (id) => {
        try {
            setIsAppRunningTimerAlertStatusLoading(true);
            const response = await axios.get(`/api/app-settings/running-timer/alert/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App running timer alert email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" running timer alert email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_running_timer_alert: !prevState.app_setting_running_timer_alert };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app running timer alert email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" running timer alert email status!!`
            );
            console.log(error);
        } finally {
            setIsAppRunningTimerAlertStatusLoading(false);
        }
    };

    const handleEnableAppInvoiceCreationStatusChange = async (id) => {
        try {
            setIsAppInvoiceCreationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/invoice-creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App invoice creation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" invoice creation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_user_invoice_creation: !prevState.app_setting_user_invoice_creation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app invoice creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" invoice creation email status!!`
            );
            console.log(error);
        } finally {
            setIsAppInvoiceCreationStatusLoading(false);
        }
    }

    const handleEnableAppInvoiceDecisionStatusChange = async (id) => {
        try {
            setIsAppInvoiceDecisionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/invoice-decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App invoice decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" invoice decision email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_user_invoice_decision: !prevState.app_setting_user_invoice_decision };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app invoice decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" invoice decision email status!!`
            );
            console.log(error);
        } finally {
            setIsAppInvoiceDecisionStatusLoading(false);
        }
    }

    const handleEnableAppInvoicePaymentStatusChange = async (id) => {
        try {
            setIsAppInvoicePaymentStatusLoading(true);
            const response = await axios.get(`/api/app-settings/invoice-payment/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App invoice payment email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" invoice payment email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_user_invoice_payment: !prevState.app_setting_user_invoice_payment };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app invoice payment email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" invoice payment email status!!`
            );
            console.log(error);
        } finally {
            setIsAppInvoicePaymentStatusLoading(false);
        }
    }

    const handleEnableAppProjectUpdatesStatusChange = async (id) => {
        try {
            setIsAppProjectUpdatesStatusLoading(true);
            const response = await axios.get(`/api/app-settings/project-updates/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App project updates email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" project updates email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_project_updates: !prevState.app_setting_project_updates };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app project updates email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" project updates email status!!`
            );
            console.log(error);
        } finally {
            setIsAppProjectUpdatesStatusLoading(false);
        }
    }

    const handleEnableAppClientUpdatesStatusChange = async (id) => {
        try {
            setIsAppClientUpdatesStatusLoading(true);
            const response = await axios.get(`/api/app-settings/client-updates/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App clients updates email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" client updates email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_client_updates: !prevState.app_setting_client_updates };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app client updates email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" client updates email status!!`
            );
            console.log(error);
        } finally {
            setIsAppClientUpdatesStatusLoading(false);
        }
    }

    const handleEnableAppVendorInvoiceCreationStatusChange = async (id) => {
        try {
            setIsAppVendorInvoiceCreationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/vendor/invoice-creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App vendor invoice creation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" vendor invoice creation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_vendor_invoice_creation: !prevState.app_setting_vendor_invoice_creation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app invoice creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" vendor invoice creation email status!!`
            );
            console.log(error);
        } finally {
            setIsAppVendorInvoiceCreationStatusLoading(false);
        }
    }

    const handleEnableAppVendorInvoiceDecisionStatusChange = async (id) => {
        try {
            setIsAppVendorInvoiceDecisionStatusLoading(true);
            const response = await axios.get(`/api/app-settings/vendor/invoice-decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App vendor invoice decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" vendor invoice decision email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_vendor_invoice_decision: !prevState.app_setting_vendor_invoice_decision };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app vendor invoice decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" vendor invoice decision email status!!`
            );
            console.log(error);
        } finally {
            setIsAppVendorInvoiceDecisionStatusLoading(false);
        }
    }

    const handleEnableAppVendorInvoicePaymentStatusChange = async (id) => {
        try {
            setIsAppVendorInvoicePaymentStatusLoading(true);
            const response = await axios.get(`/api/app-settings/vendor/invoice-payment/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App vendor invoice payment email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" vendor invoice payment email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_vendor_invoice_payment: !prevState.app_setting_vendor_invoice_payment };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app vendor invoice payment email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" vendor invoice payment email status!!`
            );
            console.log(error);
        } finally {
            setIsAppVendorInvoicePaymentStatusLoading(false);
        }
    }

    const handleEnableAppBankAccountStatusChange = async (id) => {
        try {
            setIsAppBankAccountStatusLoading(true);
            const response = await axios.get(`/api/app-settings/bank-account/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App bank account email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" bank account email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_bank_account_updates: !prevState.app_setting_bank_account_updates };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app bank account email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" bank account email status!!`
            );
            console.log(error);
        } finally {
            setIsAppBankAccountStatusLoading(false);
        }
    }

    const handleEnableAppPaymentMethodStatusChange = async (id) => {
        try {
            setIsAppPaymentMethodStatusLoading(true);
            const response = await axios.get(`/api/app-settings/payment-method/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App payment method email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" payment method email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_payment_method_updates: !prevState.app_setting_payment_method_updates };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app payment method email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" payment method email status!!`
            );
            console.log(error);
        } finally {
            setIsAppPaymentMethodStatusLoading(false);
        }
    }

    const handleEnableAppCurrencyWeekStatusChange = async (id) => {
        try {
            setIsAppCurrencyWeekStatusLoading(true);
            const response = await axios.get(`/api/app-settings/currency-week/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App currency and work week email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" currency and work week email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_currency_week_changes: !prevState.app_setting_currency_week_changes };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app currency and work week email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" currency and work week email status!!`
            );
            console.log(error);
        } finally {
            setIsAppCurrencyWeekStatusLoading(false);
        }
    }

    const handleEnableApp2FAActivationStatusChange = async (id) => {
        try {
            setIsApp2FAActivationStatusLoading(true);
            const response = await axios.get(`/api/app-settings/2fa-activation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`App 2fa-activation email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `App "${appSettings && appSettings.appId}'s" 2fa-activation email notification updated successfully`
            );
            refetchAppSettingData();
            dispatch(setNotification(Date.now()));
            setAppSettingView((prevState) => {
                if (prevState.appId === id) {
                    return { ...prevState, app_setting_2fa_activation: !prevState.app_setting_2fa_activation };
                }
                return prevState;
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing app 2fa activation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${appSettings && appSettings.appId}'s" 2fa activation email status!!`
            );
            console.log(error);
        } finally {
            setIsApp2FAActivationStatusLoading(false);
        }
    }

    return (
        <div>
            <div>
                <div>
                    {/* <div className="security-outer noti-outer">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'Notifications')}</span>
                            <div class="toggle-spinner">

                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Enable_all_in-app_notifications')}
                            </span>
                            <div className="toggle-spinner">
                                {isLoading === true && (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}
                                {isNotificationLoading === true && (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />)}

                                {isLoading === false && (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="emailnotification"
                                            name="enable_emailnotification"
                                            value="1"
                                            style={{ cursor: "pointer" }}
                                            checked={userView.is_notification_enabled === true}
                                            onChange={() => handleEnableNotificationChange(userView._id)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>)}
                            </div>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Enable_all_email_notification')}
                            </span>
                            <div className="toggle-spinner">
                                {isLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}

                                {isEmailNotificationLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />)}
                                {isLoading === false && (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="emailnotification"
                                            name="enable_emailnotification"
                                            value="1"
                                            checked={userView.is_email_enabled === true}
                                            onChange={() => handleEnableEmailNotificationChange(userView._id)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>)}
                            </div>
                        </div>
                        {(userView?.role_id?._id === userTypeName.Super_Admin || userView?.role_id?._id === userTypeName.Admin) &&
                            <>
                                <div className="security-inner noti-enable">
                                    <span className="custom-switch-description">
                                        {global.config.locate(appSettings && appSettings.language, 'Enable_TimeOff_email_notification')}
                                    </span>
                                    <div className="toggle-spinner">
                                        {isLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}

                                        {isAdminTimeoffEmailNotificationLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />)}
                                        {isLoading === false && (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userView.is_admin_timeoff_email_enabled === true}
                                                    onChange={() => handleEnableAdminTimeoffEmailNotificationChange(userView._id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>)}
                                    </div>
                                </div>
                                <div className="security-inner noti-enable">
                                    <span className="custom-switch-description">
                                        {global.config.locate(appSettings && appSettings.language, 'Enable_Invoice_email_notification')}
                                    </span>
                                    <div className="toggle-spinner">
                                        {isLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}

                                        {isAdminInvoiceEmailNotificationLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />)}
                                        {isLoading === false && (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userView.is_admin_invoice_email_enabled === true}
                                                    onChange={() => handleEnableAdminInvoiceEmailNotificationChange(userView._id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>)}
                                    </div>
                                </div>
                            </>
                        }
                    </div> */}


                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleInvoiceNotificationAccordion}
                                className={isInvoiceNotificationOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Invoice Notification
                                </div>
                            </div>
                            {isInvoiceNotificationOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Creation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user and admin when an invoice is created by a user
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppInvoiceCreationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="useractivitynotification"
                                                        name="enable_useractivitynotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_user_invoice_creation === true}
                                                        onChange={() => handleEnableAppInvoiceCreationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Decision
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify both the user and admin when an invoice is approved or declined by the admin
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppInvoiceDecisionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_user_invoice_decision === true}
                                                        onChange={() => handleEnableAppInvoiceDecisionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Payment
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user when an invoice is marked as paid by the admin
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppInvoicePaymentStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_user_invoice_payment === true}
                                                        onChange={() => handleEnableAppInvoicePaymentStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleReimbursementAccordion}
                                className={isReimbursementOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Reimbursement
                                </div>
                            </div>
                            {isReimbursementOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Reimbursement Request Submission
                                            </div>
                                            <div className="accordion-subtitle">
                                                Get notified when a user submits a reimbursement request
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppReimbursementSubmissionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="emailnotification"
                                                        name="enable_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_reimbursement_submission === true}
                                                        onChange={() => handleEnableAppReimbursementSubmissionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Reimbursement Request Decision
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify both the user and admin when a reimbursement request is approved or declined by the admin.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppReimbursementDecisionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="reimbursement_decision_emailnotification"
                                                        name="enable_reimbursement_decision_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_reimbursement_decision === true}
                                                        onChange={() => handleEnableAppReimbursementDecisionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleTimeOffAccordion}
                                className={isTimeOffOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Time Off
                                </div>
                            </div>
                            {isTimeOffOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Time Off Request Submission
                                            </div>
                                            <div className="accordion-subtitle">
                                                Get notified when a user submits a time off request
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppTimeoffSubmissionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="emailnotification"
                                                        name="enable_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_timeoff_submission === true}
                                                        onChange={() => handleEnableAppTimeoffSubmissionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Time Off Request Decision
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify both the user and admin when a time off request is approved or declined by the admin.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppTimeoffDecisionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="timeoff_decision_emailnotification"
                                                        name="enable_timeoff_decision_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_timeoff_decision === true}
                                                        onChange={() => handleEnableAppTimeoffDecisionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleExpenseAccordion}
                                className={isExpenseOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Expense
                                </div>
                            </div>
                            {isExpenseOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Expense Creation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the admin each time a new expense is added
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppExpenseCreationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="emailnotification"
                                                        name="enable_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_expense_creation === true}
                                                        onChange={() => handleEnableAppExpenseCreationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleEquipmentAccordion}
                                className={isEquipmentOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Equipment
                                </div>
                            </div>
                            {isEquipmentOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Equipment Creation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the admin each time a new equipment is added
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppEquipmentCreationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="emailnotification"
                                                        name="enable_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_equipment_creation === true}
                                                        onChange={() => handleEnableAppEquipmentCreationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleClientIntakeSubmissionAccordion}
                                className={isClientIntakeSubmissionOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Client and Vendor Interaction Notifications
                                </div>
                            </div>
                            {isClientIntakeSubmissionOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Intake Form Submission
                                            </div>
                                            <div className="accordion-subtitle">
                                                Send confirmation to the client who filled out the intake form and notify the admin
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppClientIntakeSubmissionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="clientintakesubmission"
                                                        name="enable_clientintakesubmission"
                                                        value="1"
                                                        checked={appSettingView.app_setting_intake_form_submission === true}
                                                        onChange={() => handleEnableAppClientIntakeSubmissionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Account Creation Alert
                                            </div>
                                            <div className="accordion-subtitle">
                                                Send confirmation to the client and notify admin when a client account is created by the admin.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppClientAccountCreationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="client_creation_emailnotification"
                                                        name="enable_client_creation_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_client_account_creation === true}
                                                        onChange={() => handleEnableAppClientAccountCreationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleProjectClientManagementAccordion}
                                className={isProjectClientManagementOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Project and Client Management Notifications
                                </div>
                            </div>
                            {isProjectClientManagementOpen && (
                                <div className="accordion-content">

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Client Updates
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify relevant users and admins when a new client is created or removed
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppClientUpdatesStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_client_updates === true}
                                                        onChange={() => handleEnableAppClientUpdatesStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Project Updates
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify relevant users and admins when a new project is created or removed
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppProjectUpdatesStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="projectupdatesnotification"
                                                        name="enable_projectupdatesnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_project_updates === true}
                                                        onChange={() => handleEnableAppProjectUpdatesStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>


                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleVendorInvoiceNotificationAccordion}
                                className={isVendorInvoiceNotificationOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Vendor Invoice Notification
                                </div>
                            </div>
                            {isVendorInvoiceNotificationOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Creation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user and admin when an invoice is created by a user
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppVendorInvoiceCreationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="useractivitynotification"
                                                        name="enable_useractivitynotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_vendor_invoice_creation === true}
                                                        onChange={() => handleEnableAppVendorInvoiceCreationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Decision
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify both the user and admin when an invoice is approved or declined by the admin
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppVendorInvoiceDecisionStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_vendor_invoice_decision === true}
                                                        onChange={() => handleEnableAppVendorInvoiceDecisionStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Invoice Payment
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user when an invoice is marked as paid by the admin
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppVendorInvoicePaymentStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_vendor_invoice_payment === true}
                                                        onChange={() => handleEnableAppVendorInvoicePaymentStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleFinancialSettingsAccordion}
                                className={isFinancialSettingsOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Financial Settings Notification
                                </div>
                            </div>
                            {isFinancialSettingsOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Bank Account Updates
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user and admin when a new bank account is added or removed.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppBankAccountStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="bankaccountnotification"
                                                        name="enable_bankaccountnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_bank_account_updates === true}
                                                        onChange={() => handleEnableAppBankAccountStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Currency and Work Week Changes
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the admin when a default currency or the "Work week start date" is changed.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppCurrencyWeekStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_currency_week_changes === true}
                                                        onChange={() => handleEnableAppCurrencyWeekStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Payment Method Updates
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the admin when a new payment method is added or removed.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppPaymentMethodStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_payment_method_updates === true}
                                                        onChange={() => handleEnableAppPaymentMethodStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleBudgetThresholdAccordion}
                                className={isBudgetThresholdOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Project and Budget Notifications
                                </div>
                            </div>
                            {isBudgetThresholdOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Budget Threshold Alert
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify Project Management, Account Manager and Admin when the budget of a project and sprint reaches a predefined threshold
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppBudgetThresholdStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="budgetthreshold"
                                                        name="enable_budgetthreshold"
                                                        value="1"
                                                        checked={appSettingView.app_setting_budget_threshold === true}
                                                        onChange={() => handleEnableAppBudgetThresholdStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleSecurityNotificationAccordion}
                                className={isSecurityNotificationOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    Security Notification
                                </div>
                            </div>
                            {isSecurityNotificationOpen && (
                                <div className="accordion-content">
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                2FA Activation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify all users when Two-Fator Authentication (2FA) is enabled for all the user accounts by the admin.
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isApp2FAActivationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="2faactivationnotification"
                                                        name="enable_2faactivationnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_2fa_activation === true}
                                                        onChange={() => handleEnableApp2FAActivationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>

                    <div className="notification-accordion">
                        <div>
                            <div
                                onClick={toggleUserActivityAccordion}
                                className={isUserActivityOpen ? "accordion-trigger active" : "accordion-trigger"}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="accordion-head">
                                    <span className="accordion-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                            <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                        </svg>
                                    </span>
                                    User Activity Notification
                                </div>
                            </div>
                            {isUserActivityOpen && (
                                <div className="accordion-content">

                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                Running Timer Alert
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user, Team Leader and Admin if a user's time exceeds a set amount of hours
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppRunningTimerAlertStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="runningtimeralert_emailnotification"
                                                        name="enable_runningtimeralert_emailnotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_running_timer_alert === true}
                                                        onChange={() => handleEnableAppRunningTimerAlertStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="acoordion-inner">
                                        <div className="accordion-left">
                                            <div className="accordion-title">
                                                User Activation/Deactivation
                                            </div>
                                            <div className="accordion-subtitle">
                                                Notify the user and admin when a user account is activated or deactivated
                                            </div>
                                        </div>
                                        <div className="accordion-right">
                                            {isAppUserActivationStatusLoading === true && (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                            {appSettingsLoading === true ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <label className="custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-switch-input"
                                                        id="useractivitynotification"
                                                        name="enable_useractivitynotification"
                                                        value="1"
                                                        checked={appSettingView.app_setting_user_activiation === true}
                                                        onChange={() => handleEnableAppUserActivationStatusChange(appSettings.appId)}
                                                    />
                                                    <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoticationSettings;