import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { useMemo } from 'react';


export async function createSubscriptionProduct(name, description, currencyCode, trailPeriod, monthlyPricing, yearlyPricing, features, duration, status, token) {
        console.log("createSubscriptionProduct", name, token);

        try {
                const response = await axios.post(
                        "/api/billing/add-product",
                        new URLSearchParams({
                                name,
                                description,
                                currency: currencyCode,
                                features: features,
                                price: "100",
                                monthlyPricing,
                                yearlyPricing,
                                trial_period: trailPeriod,
                                duration: duration,
                                status: status
                        }),
                        {
                                headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                        Authorization: `Bearer ${token}`
                                }
                        }
                );

                console.log("Response:", response.data);
                return response.data;
        } catch (error) {
                console.error("Error:", error);
                throw error;
        }
}


export function useFetchSubscriptions(token) {
        // Memoize the query key for consistency across renders
        const queryKey = useMemo(() => ["subscriptions", token], [token]);

        // Memoize the fetch function for stable dependency reference
        const fetchFunction = useMemo(
                () => async () => {
                        if (!token) {
                                throw new Error("Token is missing");
                        }
                        const response = await axios.get("/api/billing/products", {
                                headers: {
                                        Authorization: `Bearer ${token}`,
                                },
                        });
                        return response.data;
                },
                [token]
        );

        // Use `useQuery` for data fetching with optimized options
        return useQuery(queryKey, fetchFunction, {
                enabled: !!token, // Prevents fetching if token is not available
                staleTime: 1000 * 60 * 5, // Data remains fresh for 5 minutes
                cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
                refetchOnWindowFocus: false, // Avoids refetching on window focus
                retry: 2, // Retry failed requests up to 2 times
                refetchOnMount: false, // Skip fetching when the component mounts if data is cached
                keepPreviousData: true, // Keeps showing old data until new data is fetched
        });
}